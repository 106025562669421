import React from 'react';
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface TypographyProps extends MuiTypographyProps {
  dataId?: string;
  sx?: SxProps<Theme>;
}

function Typography({ dataId, sx, ...props }: TypographyProps) {
  return <MuiTypography data-id={dataId} sx={sx} {...props}></MuiTypography>;
}

export default Typography;
