import { useState, useEffect } from 'react';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';
import ManageCourseWrapper from 'components/courseBuilder/ManageCourseWrapper';

import { Button } from '@mui/material';

const EditExternalCourse = ({
  courseId,
  hideDialog,
}: {
  courseId?: string;
  hideDialog: (event: any) => void;
}) => {
  const {
    authState: { organization, user },
  } = useAuthState();

  const { callService } = useCallService();

  const [url, setUrl] = useState('');

  const getCourseEditUrl = async () => {
    if (!user.id || !organization.id || !courseId) {
      return;
    }

    const requestData: {
      userId: string;
      courseId: string;
      organizationId: string;
    } = {
      userId: user.id,
      courseId,
      organizationId: organization.id,
    };

    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseEditUrl(requestData),
    });

    if (response) {
      const url = encodeURI(`${response}`);

      console.log('URL:', url);

      setUrl(url);
    }
  };

  useEffect(() => {
    getCourseEditUrl();
  }, [courseId]);

  if (url === '') {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div style={{ height: '90%' }}>
        <ManageCourseWrapper coassembleURL={url} />
      </div>
      <br />
      <Button variant="text" onClick={hideDialog}>
        Close
      </Button>
    </>
  );
};

export default EditExternalCourse;
