import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { SelectOptionProps } from './types';

interface SelectFormFieldProps {
  name: string;
  label: string;
  control: any;
  options: SelectOptionProps[];
  multiple?: boolean;
  errors?: any;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  renderValue?: (value: any) => React.ReactNode;
  customMenuComponent?: (props: { value: string | number; label: string | number }) => JSX.Element;
  selectSx?: SxProps<Theme>
  inputLabelSx?: SxProps<Theme>
  disabled?: boolean;
  onChange?: any
  withSelectedOption?: boolean;
}

const SelectFormField = ({
  name,
  label,
  control,
  options,
  multiple,
  errors,
  margin = 'normal',
  required,
  renderValue,
  customMenuComponent: CustomMenuComponent,
  selectSx,
  inputLabelSx,
  disabled,
  onChange,
  withSelectedOption = false
}: SelectFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{ minWidth: '100%' }}
          margin={margin}
          required={required}
        >
          <InputLabel sx={inputLabelSx} id={`select-list-${name}`}>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            sx={selectSx}
            renderValue={renderValue}
            onChange={(e, b) => {
              const target = e.target;
              if (onChange) {
                if(withSelectedOption) {
                  const selectedValue = target?.value;
                  const selectedOption = multiple
                    ? options.filter((option) => selectedValue.includes(option.value)) // Handle multiple selections
                    : options.find((option) => option.value === selectedValue); // Handle single selection

                  onChange(selectedOption, e);
                } else {
                  onChange(e, target);
                }
              }

              field.onChange(e, target?.value);
            }}
            labelId={`select-list-${name}`}
            error={Boolean(errors)}
            required={required}
            multiple={multiple}
            fullWidth
            disabled={disabled}
          >
            {options.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {CustomMenuComponent ? <CustomMenuComponent value={o.value} label={o.label} /> : o.label }
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={Boolean(errors)}>{errors?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectFormField;
