import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.514 2.813a.287.287 0 00-.265.164l-1.116 2.282c-.177.36-.517.611-.91.668l-2.499.369a.288.288 0 00-.238.201c-.016.051-.04.18.074.291l1.806 1.775c.288.283.419.691.35 1.09l-.425 2.507a.281.281 0 00.112.28.284.284 0 00.316.024l2.234-1.185a1.2 1.2 0 011.122 0l2.233 1.184c.15.08.271.01.317-.024a.282.282 0 00.112-.279l-.426-2.506c-.069-.4.062-.808.35-1.09l1.806-1.776a.279.279 0 00.075-.29.286.286 0 00-.238-.202l-2.498-.369a1.21 1.21 0 01-.91-.669l-1.117-2.281a.285.285 0 00-.265-.164zm-2.935 10.624c-.253 0-.503-.08-.718-.24a1.229 1.229 0 01-.473-1.198l.426-2.506a.288.288 0 00-.083-.254l-1.806-1.775a1.23 1.23 0 01-.311-1.253c.141-.452.517-.775.98-.843L34.09 5a.29.29 0 00.222-.16l1.116-2.283a1.202 1.202 0 012.17 0l1.117 2.282a.291.291 0 00.22.161l2.498.368c.463.068.838.39.98.843.14.446.02.928-.312 1.253L40.297 9.24a.287.287 0 00-.082.253L40.64 12c.078.462-.103.92-.474 1.197-.376.282-.865.32-1.28.099l-2.233-1.183a.298.298 0 00-.28 0l-2.231 1.184c-.179.095-.371.142-.563.142zM18.912 13.438c-.252 0-.503-.082-.717-.242A1.228 1.228 0 0117.722 12l.425-2.506a.288.288 0 00-.083-.254l-1.806-1.775a1.23 1.23 0 01-.311-1.253c.142-.452.517-.775.98-.843L19.425 5a.29.29 0 00.22-.16l1.117-2.283a1.202 1.202 0 012.17 0l1.117 2.282a.291.291 0 00.22.161l2.499.368c.462.068.838.39.98.843.14.446.02.928-.312 1.253L25.629 9.24a.287.287 0 00-.082.253L25.973 12c.079.462-.103.92-.474 1.197-.376.282-.865.32-1.28.099l-2.233-1.183a.298.298 0 00-.279 0l-2.232 1.184c-.179.095-.371.142-.563.142zM4.245 13.438c-.252 0-.503-.082-.717-.242A1.229 1.229 0 013.055 12l.425-2.506a.288.288 0 00-.082-.254L1.59 7.464a1.23 1.23 0 01-.31-1.253c.141-.452.516-.775.979-.843L4.758 5a.29.29 0 00.221-.16l1.117-2.283a1.202 1.202 0 012.17 0l1.116 2.282A.291.291 0 009.603 5l2.498.368c.463.068.838.39.98.843.14.446.02.928-.312 1.253L10.963 9.24a.287.287 0 00-.082.253L11.307 12c.078.462-.104.92-.475 1.197-.375.282-.865.32-1.28.099L7.32 12.112a.298.298 0 00-.28 0l-2.232 1.184a1.195 1.195 0 01-.563.142z"
    />
  </g>
);
