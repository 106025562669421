import Tag from './Tag';
import { ITagColor } from './types';
import { lighten } from 'polished';

interface IKpi {
  kpi: string;
  name: string;
}

const getKpiTagColor = (label: IKpi['kpi']): ITagColor => {
  switch (label) {
    case 'transactions_per_hour':
      return { labelColor: '#BB6BD9', backgroundColor: lighten(0.4, '#BB6BD9') };
    case 'units_per_transaction':
      return { labelColor: '#F39C12', backgroundColor: lighten(0.4, '#F39C12') };
    case 'avg_unit_price':
      return { labelColor: '#9dc067', backgroundColor: lighten(0.4, '#9dc067')  };
    case 'pct_discount':
      return { labelColor: '#e04973fc', backgroundColor: lighten(0.4, '#e04973fc')  };
    case 'pct_upsold':
      return { labelColor: '#1abc9c', backgroundColor: lighten(0.4, '#1abc9c') };
    case 'pct_sales_house':
      return { labelColor: '#ECDD33FF', backgroundColor: lighten(0.4, '#ECDD33FF') };
    default:
      return { labelColor: '#3498DB', backgroundColor: lighten(0.4, '#3498DB')};
  }
};

const KpiTag = ({ kpi, name }: IKpi) => {
  const { labelColor, backgroundColor } = getKpiTagColor(kpi);

  return (
    <Tag
      label={name}
      labelColor={labelColor}
      backgroundColor={backgroundColor}
    />
  );
};

export default KpiTag;
