import React, { memo, useCallback } from 'react';
import DialogContent from '@mui/material/DialogContent';
import MuiDialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export interface DialogProps {
  isOpen: boolean;
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  content: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  fullWidth?: boolean;
  fullScreen?: boolean;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
  noPadding?: boolean;
}

const padding = {
  p: 0,
};

const Dialog = ({
  isOpen,
  title,
  size = 'lg',
  content,
  setIsOpen,
  fullScreen,
  fullWidth = true,
  disableEscapeKeyDown,
  enableBackdropClose = true,
  noPadding = false,
}: DialogProps) => {
  const handleBackdropClick = useCallback(() => {
    if (enableBackdropClose) {
      setIsOpen(false);
    }
  }, [enableBackdropClose]);

  return (
    <MuiDialog
      open={isOpen}
      maxWidth={size}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={handleBackdropClick}
    >
      {title && (
        <>
          <Typography variant="h3" sx={{ mt: 2, ml: 2, mb: 2 }}>
            {title}
          </Typography>
          <Divider />
        </>
      )}

      <DialogContent sx={noPadding ? padding : {}}>{content}</DialogContent>
    </MuiDialog>
  );
};

export default memo(Dialog);
