import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';

import { SaveIcon } from 'material-icons';

import {
  TextFormField,
  EmailFormField,
  SelectFormField,
  PhoneFormField,
} from 'components/formFields';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { LoadingButton } from '@mui/lab';

import {getStatesList} from 'dataSets/states';
import {getTagsList} from 'dataSets/organizations/tags';
import {getSubtypes1List, getSubtypes2List} from 'dataSets/organizations/subtypes';
import {getPricingList} from 'dataSets/organizations/pricing';

export type OrganizationFormInput = {
  id?: string | undefined;
  name: string;
  contactName?: string;
  contactEmail?: string;
  address?: string;
  city?: string;
  state?: string;
  postal?: string;
  phone?: string;
  tag?: string;
  subtype1?: string;
  subtype2?: string;
  pricing?: string;
};

export const ORGANIZATION_FORM_INITIAL_STATE: OrganizationFormInput = {
  name: '',
  contactEmail: '',
  contactName: '',
  address: '',
  city: '',
  state: '',
  postal: '',
  phone: '',
  tag: '',
  subtype1: '',
  subtype2: '',
  pricing: ''
};

const OrganizationForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: OrganizationFormInput;
  onSubmit: (values: OrganizationFormInput) => void;
}) => {
  const edit = defaultValues?.id;

  const { hideDialog } = useDialogDispatcher();

  const preSubmitUpload = async (values: OrganizationFormInput) => {
    onSubmit(values);
    hideDialog();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required('A name is required'),
    contactName: Yup.string().nullable(),
    contactEmail: Yup.string().nullable(),
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    postal: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    tag: Yup.string().required('A tag is required'),
    subtype1: Yup.string().nullable(),
    subtype2: Yup.string().required('A type is required'),
    pricing: Yup.string().required('Pricing type is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OrganizationFormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || undefined,
      name: defaultValues?.name || '',
      contactName: defaultValues?.contactName || '',
      contactEmail: defaultValues?.contactEmail || '',
      address: defaultValues?.address || '',
      city: defaultValues?.city || '',
      state: defaultValues?.state || '',
      postal: defaultValues?.postal || '',
      phone: defaultValues?.phone || '',
      tag: defaultValues?.tag || '',
      subtype1: defaultValues?.subtype1 || '',
      subtype2: defaultValues?.subtype2 || '',
      pricing: defaultValues?.pricing || '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const stateOptions = getStatesList();
  const tagsOptions = getTagsList();
  const subtypes1Options = getSubtypes1List();
  const subtypes2Options = getSubtypes2List();
  const pricingOptions = getPricingList();

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Organization
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <TextFormField
                name="name"
                control={control}
                label="Organization Name*"
                errors={errors.name}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12}>
              <TextFormField
                name="contactName"
                control={control}
                label="Contact Name"
                errors={errors.contactName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EmailFormField
                name="contactEmail"
                control={control}
                label="Contact Email"
                errors={errors.contactEmail}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneFormField
                name="phone"
                control={control}
                label="Contact Phone"
                errors={errors.phone}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} sm={6}>
              <SelectFormField
                  name="tag"
                  control={control}
                  label="Organization tag"
                  errors={errors.tag}
                  margin="dense"
                  options={tagsOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormField
                  name="pricing"
                  control={control}
                  label="Pricing"
                  errors={errors.pricing}
                  margin="dense"
                  options={pricingOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormField
                  name="subtype1"
                  control={control}
                  label="Subtype 1"
                  errors={errors.subtype1}
                  margin="dense"
                  options={subtypes1Options}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectFormField
                  name="subtype2"
                  control={control}
                  label="Subtype 2"
                  errors={errors.subtype2}
                  margin="dense"
                  options={subtypes2Options}
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12}>
              <TextFormField
                name="address"
                control={control}
                label="Street Address"
                errors={errors.address}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFormField
                name="city"
                control={control}
                label="City"
                errors={errors.city}
                margin="dense"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectFormField
                name="state"
                control={control}
                label="State"
                errors={errors.state}
                margin="dense"
                options={stateOptions}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFormField
                name="postal"
                control={control}
                label="Zip Code"
                errors={errors.postal}
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default OrganizationForm;
