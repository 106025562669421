import { useState } from 'react';

import { NumberFormField } from 'components/formFields';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { CoursesAssignation } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

const OnboardingDays = ({
  courseId,
  daysToCompleteDefault,
  control,
}: {
  courseId: string;
  daysToCompleteDefault?: number;
  control: any;
}) => {
  const [daysToComplete, setDaysToComplete] = useState(daysToCompleteDefault);
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const { coursesAssignations } = assignCourseState;

  const setCoursesAssignations = (courseId: string, daysToComplete: number) => {
    let updateCoursesAssignations: CoursesAssignation[] = [];
    if (daysToComplete) {
      updateCoursesAssignations = addCoursesAssignation(
        courseId,
        daysToComplete
      );
    } else {
      updateCoursesAssignations = removeCoursesAssignation(courseId);
    }

    setAssignCourseState((prevState: any) => ({
      ...prevState,
      coursesAssignations: updateCoursesAssignations,
    }));
  };

  const addCoursesAssignation = (courseId: string, daysToComplete: number) => {
    const coursesAssignation: CoursesAssignation = {
      courseId,
      daysToComplete,
    };

    const updateCoursesAssignations = coursesAssignations.filter(
      (coursesAssignation: CoursesAssignation) =>
        coursesAssignation.courseId !== courseId
    );

    updateCoursesAssignations.push(coursesAssignation);

    return updateCoursesAssignations;
  };

  const removeCoursesAssignation = (courseId: string) => {
    const updateCoursesAssignations = coursesAssignations.filter(
      (coursesAssignation: CoursesAssignation) =>
        coursesAssignation.courseId !== courseId
    );

    return updateCoursesAssignations;
  };

  return (
    <NumberFormField
      onBlur={(event: any) => {
        const daysToComplete = event.target.value;
        setCoursesAssignations(courseId, daysToComplete);
      }}
      name="onboardingDays"
      control={control}
      label=""
      margin="dense"
      value={daysToComplete}
      min={1}
      onChange={(e: any) => {
        setDaysToComplete(e.target.value);
      }}
      disableWheel={true}
      triggerFieldOnchange={false}
    />
  );
};

export default OnboardingDays;
