import * as React from 'react';

export default (
  <g>
    <path
      d="M6 15l5.823-5.823a.25.25 0 01.354 0L18 15"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </g>
);
