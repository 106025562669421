import React, { useEffect, useState } from 'react';
import { useCallService } from 'hooks';
import { Box, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import httpRoutes from 'utils/httpRoutes';
import ResultCard from './resultCard';
import { formatCurrency } from 'utils/common';
import { SelectOption } from '../../../../components/filters/filterTypes';

interface SummaryParams {
  groupIdToUse?: string;
  groupIds?: string[];
  kpiId: string;
}

type TypeHandler = (params: SummaryParams) => Promise<any>;

interface SummaryParams {
  groupIdToUse?: string;
  groupIds?: string[];
  kpiId: string;
}

export type SummaryType = 'multi' | 'single' | 'all-time-multi' | 'all-time-single';

interface ISummary {
  type: SummaryType;
  kpiId: string;
  labelResult1: string;
  labelResult2: string;
  labelResult3: string;
  labelResult4: string;
  labelResultToolTip1?: string;
  labelResultToolTip2?: string;
  labelResultToolTip3?: string;
  labelResultToolTip4?: string;
  groupIds?: string[];
  groupId?: string;
  groupsOptions?: SelectOption[];
}

export type Summary = {
  organizationName: string;
  uplift14dayAvg: number;
  uplift30dayAvg: number;
  dollarsAdded14daySum: number;
  dollarsAdded30daySum: number;
  uplift14dayAvgAllTime: number;
  uplift30dayAvgAllTime: number;
  dollarsAdded14daySumAllTime: number;
  dollarsAdded30daySumAllTime: number;
};

const SummaryModule = ({
  type,
  kpiId,
  labelResult1,
  labelResult2,
  labelResult3,
  labelResult4,
  labelResultToolTip1,
  labelResultToolTip2,
  labelResultToolTip3,
  labelResultToolTip4,
  groupId,
  groupIds,
  groupsOptions,
}: ISummary) => {
  const getSummaryText = (type: SummaryType, field: 'title' | 'description') => {
    const info = {
      multi: {
        title: 'Multi Store Summary',
        description: 'Sales impact from all trained employees',
      },
      single: {
        title: 'Single Store Summary',
        description: 'Sales impact across trained employees for this store',
      },
      'all-time-multi': {
        title: 'All Time Multi Store Summary',
        description: 'Sales impact from all trained employees across all time',
      },
      'all-time-single': {
        title: 'All Time Single Store Summary',
        description: 'Sales impact across trained employees for this store, all time',
      },
    };

    if (info[type]) {
      if (info[type][field]) {
        return info[type][field];
      } else {
        return `Invalid field: ${field}`;
      }
    } else {
      return `Invalid type: ${type}`;
    }
  };
  
  const theme = useTheme();
    useMediaQuery(theme.breakpoints.down('md'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { callService } = useCallService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [summaryData, setSummaryData] = useState<Summary>({
    organizationName: '',
    uplift14dayAvg: 0,
    uplift30dayAvg: 0,
    dollarsAdded14daySum: 0,
    dollarsAdded30daySum: 0,
    uplift14dayAvgAllTime: 0,
    uplift30dayAvgAllTime: 0,
    dollarsAdded14daySumAllTime: 0,
    dollarsAdded30daySumAllTime: 0,
  });

  const getSummary = async () => {
    setLoading(true);
    try {
      let response = null;
      let groupIdToUse = groupId || '';

      if (!kpiId) {
        return;
      }

      if (!groupId) {
        groupIdToUse = groupsOptions ? groupsOptions[0].value : '';
      }

      const typeHandlers: Record<string, TypeHandler> = {
        single: async ({ groupIdToUse, kpiId }) => {
          if (!groupIdToUse) throw new Error('groupIdToUse is required for single type');
          const data = await callService({
            resource: httpRoutes.ddl.getSummary({
              groupId: groupIdToUse,
              kpiId,
            }),
          });
          return data.response;
        },
        'all-time-single': async ({ groupIdToUse, kpiId }) => {
          if (!groupIdToUse) throw new Error('groupIdToUse is required for single type');
          const data = await callService({
            resource: httpRoutes.ddl.getAllTimeSummary({
              groupId: groupIdToUse,
              kpiId,
            }),
          });
          return data.response;
        },
        multi: async ({ groupIds, kpiId }) => {
          const data = await callService({
            resource: httpRoutes.ddl.getSummaryMultiStore({
              groupIds,
              kpiId,
            }),
          });
          return data.response;
        },
        'all-time-multi': async ({ groupIds, kpiId }) => {
          const data = await callService({
            resource: httpRoutes.ddl.getAllTimeSummaryMultiStore({
              groupIds,
              kpiId,
            }),
          });
          return data.response;
        },
      };

      const handleType = async (type: string, params: SummaryParams): Promise<any> => {
        const handler = typeHandlers[type];
        if (handler) {
          return await handler(params);
        } else {
          throw new Error(`Unknown type: ${type}`);
        }
      };

      const params: SummaryParams = { groupIdToUse, kpiId, groupIds };
      response = await handleType(type, params);

      if (response) {
        setSummaryData(response);
      } else {
        setError(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSummary();
  }, [groupId, kpiId]);

  return (
    <Box
      sx={{
        margin: '5px',
        p: 2,
        borderRadius: '15px',
        backgroundColor: 'white',
        flex: '0.63 0 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
            textTransform: 'uppercase',
          }}
        >
          {getSummaryText(type, 'title')}
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          {getSummaryText(type, 'description')}
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ width: '100%', height: '100%' }}>
          <Skeleton variant="rectangular" height="50%" width="100%" />
        </Box>
      ) : (
        <Grid
          container
          rowSpacing={2}
          sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
        >
          <ResultCard
            result={`${formatCurrency(summaryData.dollarsAdded14daySum)}`}
            label={labelResult1}
            tooltipLabel={labelResultToolTip1}
            icon="dollar"
            color="#56C596"
          />
          <ResultCard
            result={`${summaryData.uplift14dayAvg || 0}%`}
            label={labelResult2}
            tooltipLabel={labelResultToolTip2}
            icon="percentage"
            color="#12D1D0"
          />
          <ResultCard
            result={`${formatCurrency(summaryData.dollarsAdded30daySum)}`}
            label={labelResult3}
            tooltipLabel={labelResultToolTip3}
            icon="dollar"
            color="#199CC5"
          />
          <ResultCard
            result={`${summaryData.uplift30dayAvg || 0}%`}
            label={labelResult4}
            tooltipLabel={labelResultToolTip4}
            icon="percentage"
            color="#FF5572"
          />
        </Grid>
      )}
    </Box>
  );
};

export default SummaryModule;
