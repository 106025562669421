import StyledTab from './StyledTab';
import StyledTabs from './StyledTabs';
import { TabPanel } from './TabPanel';

function a11yProps(index: number) {
  return {
    id: `group-tab-${index}`,
    'aria-controls': `group-tabpanel-${index}`,
  };
}

export { StyledTabs, StyledTab, TabPanel, a11yProps };
