import { FC, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { ControlledDataGrid } from 'components/dataGrid/components/DataGrid';
import CommunitiesTable from './CommunitiesTable';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

const CommunitiesListGrid: FC<any> = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => {
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { name } = assignCourseState;
  const [loading, setLoading] = useState(false);
  const [communitiesHasBeenFound, setCommunitiesHasBeenFound] = useState(false);
  const { callService } = useCallService();

  useEffect(() => {
    getOrganizationHasGroups();
  }, []);

  const getOrganizationHasGroups = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource:
          httpRoutes.assignCourse.getOrganizationHasGroups(organizationId),
      });

      if (response) {
        setCommunitiesHasBeenFound(response.hasGroups);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const changeName = (name: string) => {
    setAssignCourseState((prevState: any) => ({
      ...prevState,
      name: name,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h3">
            Create a name for your assignment
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Box mb={matchDownSM ? 0 : 2}>
            <TextField
              sx={{ width: '100%' }}
              name="address"
              label="Assignment name"
              margin="dense"
              value={name}
              onChange={(e) => changeName(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} mb={1}>
          <FormSectionTitle title="Select Your Communities" />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Raleway',
              marginTop: '15px',
            }}
          >
            Select which communities should be included in the assignment
          </Typography>
        </Grid>
        {!communitiesHasBeenFound ? (
          <Alert severity="info">The organization has no communities.</Alert>
        ) : (
          <Grid item xs={12} sx={{ height: '400px', overflowY: 'scroll' }}>
            <CommunitiesTable
              organizationId={organizationId}
              isOrganizationManager={isOrganizationManager}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Communities = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => (
  <ControlledDataGrid>
    <CommunitiesListGrid
      organizationId={organizationId}
      isOrganizationManager={isOrganizationManager}
    />
  </ControlledDataGrid>
);

export default Communities;
