import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.782 16.107L13.79 6.104c-.737-1.476-2.843-1.476-3.58 0L5.219 16.107A2 2 0 007.008 19h9.985a2 2 0 001.79-2.893zM15.58 5.21c-1.473-2.951-5.685-2.951-7.158 0L3.428 15.214C2.101 17.873 4.035 21 7.008 21h9.985c2.972 0 4.906-3.127 3.579-5.786L15.579 5.21z"
    />
    <path d="M13 8a1 1 0 10-2 0v4a1 1 0 102 0V8zM12 15a1 1 0 100 2 1 1 0 000-2z" />
  </g>
);
