import { useForm } from 'react-hook-form';

import { Box, Button, Grid } from '@mui/material';

import { FileFormField } from 'components/formFields';
import FormSection from 'components/form/FormSection';

import { SaveIcon } from 'material-icons';
import { LoadingButton } from '@mui/lab';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useSnackbar } from 'notistack';
import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import fileManagement from 'utils/s3';

export interface IUploadEmployeeFormInput {
  employeesFile?: File[];
}

const UploadEmployees = ({
  organizationId,
  groupId,
  onSuccess,
}: {
  organizationId?: string;
  groupId?: string;
  onSuccess: VoidFunction;
}) => {
  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();
  const { callService } = useCallService();

  const onSubmit = async ({
    employeesFileUrl,
  }: {
    employeesFileUrl: string;
  }) => {
    if (organizationId && groupId) {
      const { response } = await callService({
        resource: httpRoutes.ddl.uploadEmployeesByOrganizationAndGroup({
          employeesFileUrl,
          organizationId,
          groupId,
        }),
        successMessage: 'Employees imported successfully!',
      });

      if (response) {
        hideDialog();
        onSuccess();
      }
    }
  };

  const preSubmit = async (values: IUploadEmployeeFormInput) => {
    let fileUrl = '';
    let completedName = '';
    if (!values.employeesFile) {
      enqueueSnackbar('Please select a file to upload before proceeding.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } else {
      const { name } = values.employeesFile[0];

      const splittedName = name.split('.');

      completedName = splittedName.shift() || '';

      const extension = splittedName.pop();

      if (extension !== 'xlsx') {
        enqueueSnackbar('Need to import a xlsx format file.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
        return;
      }

      enqueueSnackbar('Uploading file. Please stay on page.', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
      const fileName = `${completedName}-${Date.now()}.${extension}`;

      const buffer: ArrayBuffer = await values.employeesFile[0].arrayBuffer();
      const fileToUpload = new File([buffer], fileName);

      fileUrl = encodeURIComponent(fileName);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: fileToUpload,
        key: `ddl/upload-employees/${fileUrl}`,
      });

      if (response) {
        enqueueSnackbar('File uploaded successfully!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }
    await onSubmit({
      employeesFileUrl: fileUrl,
    });
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IUploadEmployeeFormInput>({
    mode: 'onBlur',
  });

  return (
    <Box>
      <form onSubmit={handleSubmit(preSubmit)}>
        <FormSection title="Select the file to upload">
          <Grid container>
            <Grid item xs={12}>
              <FileFormField
                name="employeesFile"
                control={control}
                label="Employees File"
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="space-between"
              columnSpacing={1}
            >
              <Button variant="text" onClick={hideDialog}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </FormSection>
      </form>
    </Box>
  );
};

export default UploadEmployees;
