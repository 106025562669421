import React from 'react';

import { Box } from '@mui/material';

const Counter = ({
  label,
  counter,
  isPercentage = false,
  color,
  reverse = false,
}: {
  label: string;
  counter: number | string;
  isPercentage?: boolean;
  color?: string;
  reverse?: boolean;
}) => {
  if (!counter) {
    counter = 0.0;
  }

  counter = counter.toString().includes('.')
    ? parseFloat(counter.toString()).toFixed(2)
    : counter.toString();
  const formattedCounter = counter
    ? counter.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: reverse ? 'column-reverse' : 'column',
        gap: 3,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          fontFamily: 'Roboto',
          fontWeight: '300',
          fontSize: '40px',
          lineHeight: '56px',
          color: color || '#162738',
        }}
      >
        {`${formattedCounter}${isPercentage ? '%' : ''}`}
      </Box>
      <Box
        sx={{
          flex: 1,
          fontFamily: 'Raleway',
          fontWeight: '700',
          fontSize: '14px',
          textTransform: 'uppercase',
          lineHeight: '14px',
          color: '#205072',
        }}
      >
        {label}
      </Box>
    </Box>
  );
};

export default Counter;
