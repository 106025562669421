import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Grid, Box, Tab, Tabs, IconButton } from '@mui/material';
import PageContainer from 'components/PageContainer';
import DisplayText from 'components/DisplayText';

import { useCallService } from 'hooks';

import { EditIcon, CommunityIcon, ContentCopyIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import AllMembers from '../partials/AllMembers';
import CommunityBundles from '../partials/CommunityBundles';

import httpRoutes from 'utils/httpRoutes';

import {
  CommunityFormInput,
  COMMUNITY_FORM_INITIAL_STATE,
} from '../forms/CommunityForm';
import { Edit } from '../dialogs/community';
import { Button } from '../../../components/buttons';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`group-tabpanel-${index}`}
      aria-labelledby={`group-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `group-tab-${index}`,
    'aria-controls': `group-tabpanel-${index}`,
  };
}

const Community = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [community, setCommunity] = useState<CommunityFormInput>(
    COMMUNITY_FORM_INITIAL_STATE
  );

  const params = useParams();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();

  const groupId = params && params.id ? params?.id.toString() : '';

  const groupInvitationUrl = `${window.location.protocol}//${window.location.host}/community/invitation/${groupId}`;

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const showEditDialog = () => {
    showDialog({
      content: <Edit onSuccess={getCommunity} defaultValues={community} />,
      size: 'sm',
    });
  };

  const getCommunity = async () => {
    setLoading(true);
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(params?.id),
        });

        if (response) {
          hideDialog();
          setCommunity(response);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunity();
  }, []);

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(groupInvitationUrl);
    enqueueSnackbar('Copied invitation link!', {
      variant: 'default',
      autoHideDuration: 2000,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageContainer
      title={community.name}
      icon={<CommunityIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        isAdmin && (
          <Button
            label="Edit Community"
            onClick={showEditDialog}
            startIcon={<EditIcon />}
          />
        )
      }
    >
      <Box>
        <Box>
          <DisplayText
            variant="semiBold"
            type="bodySmall"
            text="Invitation Link:"
          />
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-all' }}
        >
          <DisplayText
            variant="regular"
            type="bodySmall"
            text={groupInvitationUrl}
          />
          <IconButton onClick={copyToClipBoard} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="group tab"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Community Members" {...a11yProps(0)} />
              <Tab label="Course Bundles" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {!loading && <AllMembers />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!loading && (
              <CommunityBundles groupId={groupId} groupName={community.name} />
            )}
          </TabPanel>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default Community;
