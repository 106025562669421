import { Box } from '@mui/material';

const RequiredChip = ({
  courseCategoryName,
}: {
  courseCategoryName?: string;
}) => {
  if (
    courseCategoryName === 'Compliance' ||
    courseCategoryName === 'Company Required'
  ) {
    return (
      <Box
        sx={{
          height: '20px',
          borderRadius: '30px',
          border: '1px solid #FF5572',
          display: 'flex',
          alignItems: 'center',
          px: 1,
        }}
      >
        <p
          style={{
            margin: 0,
            color: '#FF5572',
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '800',
            fontSize: '10px',
            lineHeight: '20px',
          }}
        >
          REQUIRED
        </p>
      </Box>
    );
  }
  return null;
};

export default RequiredChip;
