import { useContext } from 'react';
import BusinessUnitContext from '../contexts/BusinessUnitContext';

export const useBusinessUnitState = () => {
  const { businessUnitState } = useContext(BusinessUnitContext);

  return {
    businessUnitState,
  };
};
