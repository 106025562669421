import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.154 15.56a8 8 0 017.275-9.54C10.331 5.956 11 6.692 11 7.5V13h5.5c.808 0 1.544.67 1.48 1.571a8 8 0 01-15.826.99zm2.303-3.856A6 6 0 1015.917 15H11a2 2 0 01-2-2V8.084a6 6 0 00-4.543 3.62z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3.5c0-.808.67-1.544 1.57-1.48a8 8 0 017.41 7.41c.064.901-.672 1.57-1.48 1.57H15a2 2 0 01-2-2V3.5zm2 .584V9h4.916A6 6 0 0015 4.084z"
    />
  </g>
);
