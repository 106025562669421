import {
  addYears,
  addMonths,
  subYears,
  subMonths,
  subDays,
  addDays,
  parseISO,
  addWeeks,
  subWeeks,
} from 'date-fns';

export const validateAge = (birthDate: Date, validAge = 21) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const currentYear = currentDate.getFullYear();

  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();
  const birthYear = birthDate.getFullYear();

  let age = currentYear - birthYear;
  if (
    currentMonth < birthMonth - 1 ||
    (currentMonth === birthMonth - 1 && currentDay < birthDay)
  ) {
    age--;
  }

  return age >= validAge;
};

export const getDateDifference = (fromDate: Date, toDate = new Date()) => {
  const _difference = toDate.getTime() - fromDate.getTime();
  return Math.ceil(_difference / (1000 * 3600 * 24));
};

export type DateConfig =
  | '1 year'
  | '1 month'
  | '1 week'
  | '1 day'
  | 'custom'
  | 'none';
interface DateRange {
  calculatedMinDate: Date | undefined;
  calculatedMaxDate: Date | undefined;
}
export const calculateDateRange = (
  minConfig: DateConfig,
  maxConfig: DateConfig,
  customMinDate?: string,
  customMaxDate?: string
): DateRange => {
  const calculateDate = (config: string, baseDate: Date): Date | undefined => {
    const subtract = config.startsWith('-');
    const [amount, unit] = config.split(' ');
    const value = parseInt(amount.replace('-', ''));

    switch (unit) {
      case undefined:
        return undefined;
      case 'none':
        return undefined;
      case 'year':
      case 'years':
        return subtract ? subYears(baseDate, value) : addYears(baseDate, value);
      case 'month':
      case 'months':
        return subtract
          ? subMonths(baseDate, value)
          : addMonths(baseDate, value);
      case 'week':
      case 'weeks':
        return subtract ? subWeeks(baseDate, value) : addWeeks(baseDate, value);
      case 'day':
      case 'days':
        return subtract ? subDays(baseDate, value) : addDays(baseDate, value);
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  };

  const today = new Date();
  const calculatedMinDate =
    minConfig === 'custom' && customMinDate
      ? parseISO(customMinDate)
      : calculateDate(minConfig, today);
  const calculatedMaxDate =
    maxConfig === 'custom' && customMaxDate
      ? parseISO(customMaxDate)
      : calculateDate(maxConfig, today);

  return { calculatedMinDate, calculatedMaxDate };
};

export const isInRange = (
  date: Date,
  fromRange?: Date,
  toRange?: Date
): boolean => {
  if (!fromRange || !toRange || !date) return false;
  const removeTime = (date: Date) => new Date(date.toDateString());

  return (
    removeTime(date) >= removeTime(fromRange) &&
    removeTime(date) <= removeTime(toRange)
  );
};

export const formatDate = (dateString?: string | Date) => {
  if (!dateString) return;

  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateUSA = (dateString?: string | Date) => {
  if (!dateString) return;

  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${month}/${day}/${year}`;
};

export const isAValidDateFilter = (
  acceptedDateRangeFilter: any,
  from: Date,
  to: Date
): boolean => {
  from = new Date(from);
  to = new Date(to);
  const isValidFrom = isInRange(
    from,
    acceptedDateRangeFilter.calculatedMinDate,
    acceptedDateRangeFilter.calculatedMaxDate
  );
  const isValidTo = isInRange(
    to,
    acceptedDateRangeFilter.calculatedMinDate,
    acceptedDateRangeFilter.calculatedMaxDate
  );
  return isValidFrom && isValidTo;
};
