import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import MultiStepForm from '../forms/MultiStepForm';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';

const Create = ({
  onSuccess,
}: {
  onSuccess: (scormAlert?: boolean) => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { callService } = useCallService();

  const onSubmit = async (values: CourseBuilderFormInput) => {
    try {
      setIsSubmitting(true);

      const { response } = await callService({
        resource: httpRoutes.courseManagement.create(values),
        successMessage: 'Course created successfully!',
      });

      if (response) {
        onSuccess(Boolean(values.courseFileChanged));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <MultiStepForm isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </Grid>
  );
};

export default Create;
