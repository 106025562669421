/* eslint-disable react/jsx-key */
import { useState } from 'react';
import _ from 'lodash';

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Controller, FieldError } from 'react-hook-form';
import { useDebouncedEffect } from 'hooks';
import { TagSelectOptionType } from 'types/TagSelectOptionType';

const filter = createFilterOptions<TagSelectOptionType>();

type TagSelectFormFieldProps = {
  name: string;
  label: string;
  control: any;
  errors?: FieldError[] | FieldError;
  margin?: 'normal' | 'dense' | 'none';
  placeholder?: string;
  getOptions: (label: string) => any;
  creatable?: boolean;
  disabled?: boolean;
};

const TagSelectFormField = ({
  name,
  label,
  control,
  placeholder,
  getOptions,
}: TagSelectFormFieldProps) => {
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<TagSelectOptionType[]>([]);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async () => {
    if (inputValue) {
      setOptionsLoading(true);
      const response = await getOptions(inputValue);

      setOptionsLoading(false);
      setOptions(response);
    }
  };

  useDebouncedEffect(fetchOptions, 200, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id="tags"
          multiple
          freeSolo
          clearOnBlur
          selectOnFocus
          value={value}
          loading={areOptionsLoading}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_event, items) => {
            items.forEach((item, index) => {
              if (item.newValue) {
                item.label = item.inputValue;
                delete item.inputValue;
                delete item.newValue;
                item.id = '';
              }

              if (typeof item === 'string') {
                // see if item is in options
                const _existingTag = options.find(
                  (option) => option.label === item
                );
                // if it is, replace the string with the matching option
                if (_existingTag) {
                  items.splice(index, 1, _existingTag);
                } else {
                  // otherwise, add it as a new tag
                  const _newItem = {
                    label: item,
                    id: '',
                  };

                  items.splice(index, 1, _newItem);
                }
              }
            });

            const uniqueValues = _.uniqBy(items, 'label');

            onChange(uniqueValues);
          }}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          getOptionLabel={(option) => option.label}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.label
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
                newValue: true,
              });
            }

            return filtered;
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {areOptionsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default TagSelectFormField;
