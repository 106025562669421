import { useState } from 'react';
import { useCallService } from 'hooks';

import Form, { RewardFormInput } from '../forms/Form';

import resources from '../resources';

const Create = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { callService } = useCallService();

  const onSubmit = async (values: RewardFormInput) => {
    setIsSubmitting(true);
    try {
      const { response } = await callService({
        resource: resources.create(values),
        successMessage: 'Reward created successfully!',
      });

      if (response) {
        onSuccess();
      }
    } catch (e) {
      console.log('Error', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default Create;
