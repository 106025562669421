import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CourseCarouselProps } from './types';
import MobileCourseCarrousel from './MobileCourseCarrousel';
import CourseCarousel from './CourseCarrousel';
import React from 'react';

//TO DO
// Use same library for both carrousels.
// Optimize images size for mobile.
const ResponsiveCarrousel = ({ title, courses, titleComponent, mobileTitleComponent, style }: CourseCarouselProps) => {
  const theme = useTheme();
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  return matchUpXS ? (
    <MobileCourseCarrousel style={style} titleComponent={mobileTitleComponent} title={title} courses={courses} />
  ) : (
    <CourseCarousel style={style} titleComponent={titleComponent} title={title} courses={courses} />
  );
};

export default ResponsiveCarrousel;
