import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

const DeleteUser = ({
  onSuccess,
  hideDialog,
  values,
}: {
  onSuccess: VoidFunction;
  hideDialog: VoidFunction;
  values: { id: string; email: string };
}) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const onSubmit = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.deleteUser(values),
      successMessage: 'User deleted successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <Stack>
      <WarningAmberOutlinedIcon
        sx={{
          fontSize: '160px',
          color: theme.palette.warning.dark,
          margin: '0 auto',
        }}
      />
      <Typography fontSize={24} fontWeight="bold" textAlign="center">
        {values.email}
      </Typography>
      <Typography variant="body1" align="center">
        Are you sure you want to delete this user?
      </Typography>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>

        <Button variant="contained" color="error" onClick={onSubmit}>
          Yes, Delete
        </Button>
      </Grid>
    </Stack>
  );
};

export default DeleteUser;
