import { Outlet } from 'react-router-dom';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

const HasPermission = ({ permissions }: { permissions: string[] }) => {
  const { authState } = useAuthState();

  const matches = authState.permissions.filter((permission) =>
    permissions.includes(permission)
  );

  if (matches.length) {
    return <Outlet />;
  }
  return <div>You do not have permission to access this resource.</div>;
};

export default HasPermission;
