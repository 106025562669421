import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';

import { ErrorIcon } from 'material-icons';

// import ErrorImage from 'assets/404.svg';

const PageNotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <Paper elevation={2} sx={{ p: 2, mt: 1 }}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          sx={{
            color: theme.palette.grey[700],
            pb: 2,
            mt: 2,
            height: '80vh',
          }}
        >
          <Grid item sx={{ margin: '0 auto' }}>
            <ErrorIcon sx={{ fontSize: '120px' }} />
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              textAlign="center"
              sx={{ mb: 2, mt: 0.75, ml: 1 }}
            >
              Uh oh!
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ mb: 2, mt: 0.75, ml: 1 }}
            >
              The page you are looking for was not found.
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ mb: 2, mt: 0.75, ml: 1 }}
            >
              Please try again or click the button to go the home page.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/')}
              sx={{ margin: 'auto' }}
            >
              Back to Home
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PageNotFound;
