import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useCallService } from 'hooks';

import { Button, Grid, Typography } from 'components/atomic';

import {
  AsyncSelectFormField,
  SelectFormField,
  TextFormField,
} from 'components/formFields';
import { SelectOptionProps } from 'components/formFields/types';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';
import Stack from 'components/atomic/Stack';
import { SendEmail } from 'material-icons';
import { getStatesList } from 'dataSets/states';
import Alert from 'components/Alert';

interface CommunityEmailCampaignProps {
  template: string;
  type: 'email' | 'community' | 'state' | 'global';
  subject: string;
  groupId?: string;
  emails?: string;
  state?: string;
}

const CommunityCampaign = () => {
  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const [templates, setTemplates] = useState<SelectOptionProps[]>([]);

  const onSubmit = async (values: CommunityEmailCampaignProps) => {
    console.log('Values: ', values);
    const { response } = await callService({
      resource: httpRoutes.emailer.sendCommunityCampaign(values),
      successMessage: 'Campaign created successfully!',
    });

    // if (response) {
    //   hideDialog();
    // }
  };

  const validationSchema = Yup.object().shape({
    template: Yup.string().required(),
    type: Yup.string()
      .oneOf(['email', 'community', 'state', 'global'])
      .required(),
    subject: Yup.string().required(),
    groupId: Yup.string(),
    emails: Yup.string(),
    state: Yup.string(),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CommunityEmailCampaignProps>({
    mode: 'onBlur',
    defaultValues: {
      template: '',
      type: 'community',
      groupId: '',
      emails: '',
      subject: '',
      state: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const getGroups = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.groups.search(value),
    });

    if (response) {
      return response.map((item: { id: string; name: string }) => {
        return { id: item.id, label: item.name };
      });
    }
  };

  useEffect(() => {
    const getEmailTemplates = async () => {
      const { response } = await callService({
        resource: httpRoutes.emailer.getTemplates(),
      });

      if (response) {
        const _options: SelectOptionProps[] = response.map(
          (item: { id: any; name: any }) => ({
            value: item.name,
            label: item.name,
          })
        );
        setTemplates(_options);
      }
    };

    getEmailTemplates();
  }, []);

  const watchType = watch('type', 'community');

  useEffect(() => {
    console.log('Watch Type: ', watchType);
  }, [watchType]);

  return (
    <div>
      <Typography variant="body1">
        Send An Email Template To A Community
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} mb={2} mt={2}>
          <SelectFormField
            name="template"
            control={control}
            options={templates}
            label="Select An Email Template"
            margin="dense"
          />
          <TextFormField
            name="subject"
            label="Subject"
            control={control}
            errors={errors.subject}
            margin="dense"
          />
          <SelectFormField
            name="type"
            control={control}
            options={[
              { value: 'community', label: 'Community' },
              { value: 'email', label: 'Email' },
              { value: 'state', label: 'State' },
              { value: 'global', label: 'Global' },
            ]}
            label="Select An Audience Type"
            margin="dense"
          />
          {watchType === 'email' && (
            <TextFormField
              name="emails"
              label="Emails (comma separated)"
              control={control}
              errors={errors.emails}
              margin="dense"
            />
          )}
          {watchType === 'community' && (
            <AsyncSelectFormField
              name="groupId"
              label="Community"
              control={control}
              getOptions={getGroups}
            />
          )}
          {watchType === 'state' && (
            <SelectFormField
              name="state"
              control={control}
              label="State"
              errors={errors.state}
              options={getStatesList()}
              margin="dense"
            />
          )}
          {watchType === 'global' && (
            <Alert
              severity="warning"
              text="Caution! This will send an email to all users"
            />
          )}
        </Stack>
        <Grid container flexDirection="row" justifyContent="space-between">
          <Button onClick={hideDialog}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            startIcon={<SendEmail />}
            disabled={isSubmitting}
          >
            Send
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default CommunityCampaign;
