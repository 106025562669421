import React, { FC } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import RadioGroupField from 'components/atomic/RadioGroupField';

const AssignmentTypeStep: FC<any> = () => {
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const { assignmentType } = assignCourseState;
  const theme = useTheme();
  useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      flexDirection="column"
      sx={{ p: 2 }}
      justifyContent="center"
    >
      <Grid
        container
        mb={1}
        spacing={1}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={6} mb={1} spacing={1} sx={{ margin: 'auto' }}>
          <Typography variant="h3" sx={{ mb: 4, margin: 'auto' }}>
            Select Your Assignment Type
          </Typography>
          <Typography variant="h5" sx={{ mt: 2 }}>
            What type of learners should be assigned?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ ml: 12, mt: 2, mb: 4 }}>
          <RadioGroupField
            name="assignmentType"
            value={assignmentType}
            sx={{ ml: 2 }}
            onChange={(e) =>
              setAssignCourseState((prevState: any) => ({
                ...prevState,
                assignmentType: e.target.value,
              }))
            }
            options={[
              {
                label: 'Only learners currently in the community',
                value: AssignmentType.Current,
              },
              {
                label: 'Only learners that join the community from now on',
                value: AssignmentType.Future,
              },
              {
                label:
                  'Learners currently in the community and all new learners that join in the future',
                value: AssignmentType.CurrentAndFuture,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssignmentTypeStep;
