import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25293 10H20.7462L18.9978 17.8677C18.5911 19.6979 16.9678 21 15.093 21H9.90606C8.03127 21 6.40801 19.6979 6.00131 17.8677L4.25293 10ZM6.74616 12L7.95369 17.4339C8.15704 18.3489 8.96866 19 9.90606 19H15.093C16.0304 19 16.8421 18.3489 17.0454 17.4339L18.2529 12H6.74616Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2071 4.70711C11.5976 4.31658 11.5976 3.68342 11.2071 3.29289C10.8166 2.90237 10.1834 2.90237 9.79289 3.29289L6.79289 6.29289C6.40237 6.68342 6.40237 7.31658 6.79289 7.70711C7.18342 8.09763 7.81658 8.09763 8.20711 7.70711L11.2071 4.70711ZM13.7929 4.70711C13.4024 4.31658 13.4024 3.68342 13.7929 3.29289C14.1834 2.90237 14.8166 2.90237 15.2071 3.29289L18.2071 6.29289C18.5976 6.68342 18.5976 7.31658 18.2071 7.70711C17.8166 8.09763 17.1834 8.09763 16.7929 7.70711L13.7929 4.70711Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 14C9.94772 14 9.5 14.4477 9.5 15V16C9.5 16.5523 9.94772 17 10.5 17C11.0523 17 11.5 16.5523 11.5 16V15C11.5 14.4477 11.0523 14 10.5 14ZM14.5 14C13.9477 14 13.5 14.4477 13.5 15V16C13.5 16.5523 13.9477 17 14.5 17C15.0523 17 15.5 16.5523 15.5 16V15C15.5 14.4477 15.0523 14 14.5 14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 9C3.5 7.34315 4.84315 6 6.5 6H18.5C20.1569 6 21.5 7.34315 21.5 9C21.5 10.6569 20.1569 12 18.5 12H6.5C4.84315 12 3.5 10.6569 3.5 9ZM6.5 8C5.94772 8 5.5 8.44772 5.5 9C5.5 9.55228 5.94772 10 6.5 10H18.5C19.0523 10 19.5 9.55228 19.5 9C19.5 8.44772 19.0523 8 18.5 8H6.5Z"
    />
  </g>
);
