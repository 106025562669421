import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import postRobot from 'post-robot';

import { Alert, Collapse, IconButton, Box, Button } from '@mui/material';
import { CloseIcon } from 'material-icons';

import CourseProps, {
  Course_Initial_State,
} from 'types/courseTypes/CourseProps';
import UserTestResultType, {
  UTR_Initial_State,
} from 'types/UserTestResultType';

import { CourseType } from 'enum/CourseType';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import Overlay from '../components/Overlay';
import ScormFrame from '../components/ScormFrame';
import { useDialogDispatcher } from '../../../providers/DialogProvider/hooks/useDialogDispatcher';
import CourseReview from '../dialogs/CourseReview';

interface CourseLoaderProps {
  external: boolean;
}

const Course = (props: CourseLoaderProps) => {
  const [course, setCourse] = useState<CourseProps>(Course_Initial_State);
  const [utr, setUtr] = useState<UserTestResultType>(UTR_Initial_State);
  const [courseCompleted, setCourseCompleted] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>('');
  const [initialData, setInitialData] = useState<string>('');

  const params = useParams();
  const navigate = useNavigate();

  const { showDialog } = useDialogDispatcher();

  const showCourseReviewDialog = (utrId: string) => {
    showDialog({
      content: <CourseReview utrId={utrId} />,
      size: 'sm',
      enableBackdropClose: false,
      disableEscapeKeyDown: true,
    });
  };

  const { callService } = useCallService();

  const startCourse = async (external: boolean) => {
    try {
      if (external) {
        const { courseReference = '' } = params;
        await fetchCourse(
          httpRoutes.courses.startCourseByReference(courseReference)
        );
      } else {
        if (params.id) {
          await fetchCourse(httpRoutes.courses.startCourse(params.id));
        }
      }
    } catch (e) {
      console.log(e);
      setLoadingContent(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchCourse = async (promise: Promise<AxiosResponse<any, any>>) => {
    setLoading(true);

    const { response } = await callService({
      resource: promise,
    });

    if (response) {
      if (response.isAssessment) {
        navigate(`/assessment/${response.course.externalCourseId}`);
      } else {
        setCourse(response.course);
        setCourseCompleted(response.completed);
        setOpen(response.completed);
        setUserId(response.utr.userId);
        setUtr(response.utr);

        // initialize utr data with minimal properties to load correctly
        const _data = JSON.parse(response.utr.data);

        const _utrData = {
          id: _data.id || response.id,
          'cmi.exit': _data['cmi.exit'] || '',
          'cmi.location': _data['cmi.location'] || '',
          progress: _data.progress,
          'cmi.session_time': _data['cmi.session_time'] || '',
        };

        setInitialData(encodeURIComponent(JSON.stringify(_utrData)));
      }
    }
  };

  useEffect(() => {
    startCourse(props.external);
  }, [params]);

  const updateCourseProgress = async (values: any) => {
    if (values.utrId && !courseCompleted) {
      const { response } = await callService({
        resource: httpRoutes.courses.updateCourseProgress(values.utrId, values),
      });

      if (
        response &&
        response.status === 'Completed' &&
        response.passed &&
        response.progress == 100
      ) {
        setCourseCompleted(true);

        try {
          const existingReview = await callService({
            resource: httpRoutes.courses.getReviewFromUser(values.utrId),
          });

          if (!existingReview.response || !existingReview.response.id) {
            showCourseReviewDialog(values.utrId);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const processMessage = async (values: { action: string; data: any }) => {
    setData(values.data);
  };

  useEffect(() => {
    const listener = postRobot.on('initialize', async (event: any) => {
      processMessage(event.data);
    });

    const stuckListener = postRobot.on('stuck', async (_: any) => {
      props.external ? navigate('/') : navigate('/courses');
    });

    const loadedListener = postRobot.on('loaded', async (_: any) => {
      setLoadingContent(false);
    });

    return () => {
      listener.cancel();
      stuckListener.cancel();
      loadedListener.cancel();
    };
  }, []);

  useEffect(() => {
    updateCourseProgress(data);
  }, [data]);

  if (loading && (!utr.id || !course.id)) return <Overlay open={true} />;

  return (
    <Box sx={{ width: '100%', height: '100%' }} id="iframe-wrapper">
      <Overlay open={loadingContent} />
      {courseCompleted && (
        <Collapse in={open}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Heads up! You&apos;ve already completed this course. You can take it
            again, we just wanted to let you know.
          </Alert>
        </Collapse>
      )}
      {course.courseType && course.courseType.name === '360 Training' && (
        <Alert
          severity="info"
          action={
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => {
                window.open(course.url);
              }}
            >
              OPEN WINDOW
            </Button>
          }
        >
          This course will open in a new window. Please disable pop-up blockers
          before clicking &apos;OPEN WINDOW&apos;
        </Alert>
      )}
      {course.courseType &&
        course.courseType.name === CourseType.Course &&
        course.url && (
          <ScormFrame
            course={course}
            utrId={utr.id}
            userId={userId}
            utrData={initialData}
          />
        )}
    </Box>
  );
};

export default Course;
