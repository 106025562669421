import DatePicker from 'components/datePicker';

import { FilterProps } from '../filterTypes';
import { DateConfig } from '../../../utils/date';
import { Tooltip } from '@mui/material';

export interface DateFilterProps extends FilterProps {
  minDate?: DateConfig;
  maxDate?: DateConfig;
  customMinDate?: string;
  customMaxDate?: string;
  infoMsg?: string;
}

export default function DateFilter({
  id,
  label,
  value,
  onChange,
  minDate = 'none',
  maxDate = 'none',
  customMinDate,
  customMaxDate,
  infoMsg,
}: DateFilterProps) {
  return (
    <div id={id}>
      {infoMsg ? (
        <Tooltip title={infoMsg} arrow placement="top">
          <DatePicker
            label={label}
            onChange={onChange}
            value={value || null}
            fullWidth={true}
            minDate={minDate}
            maxDate={maxDate}
            customMinDate={customMinDate}
            customMaxDate={customMaxDate}
            sx={{
              backgroundColor: 'white',
              borderRadius: '30px',
              width: '200px',
              height: '40px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
              '& input': {
                padding: '10px 14px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />
        </Tooltip>
      ) : (
        <DatePicker
          label={label}
          onChange={onChange}
          value={value || null}
          fullWidth={true}
          minDate={minDate}
          maxDate={maxDate}
          customMinDate={customMinDate}
          customMaxDate={customMaxDate}
          sx={{
            backgroundColor: 'white',
            borderRadius: '30px',
            width: '200px',
            height: '40px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            '& input': {
              padding: '10px 14px',
              display: 'flex',
              alignItems: 'center',
            },
          }}
        />
      )}
    </div>
  );
}
