import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.011 2.425c-.11 2.519.053 5.773.13 7.062a.14.14 0 00.135.137c1.028.059 4.57.223 7.117-.152.006-1.404-.955-3.308-2.402-4.754-1.484-1.482-3.24-2.293-4.958-2.293h-.022zm3.304 8.788a56.02 56.02 0 01-3.126-.091A1.645 1.645 0 01.643 9.574C.565 8.252.396 4.893.516 2.294A1.425 1.425 0 011.906.927c2.136-.062 4.341.93 6.145 2.73 1.758 1.758 2.874 4.067 2.842 5.884a1.44 1.44 0 01-1.223 1.406c-1.358.205-2.948.267-4.355.267z"
      fill="#fff"
    />
  </g>
);
