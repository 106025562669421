import React, { RefObject, useEffect, useRef, useState } from 'react';

import { Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useCallService, useWindowSize } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import '../chart.css';
import NoDataFound from 'components/NoDataFound';

interface IEmployeeRankingChart {
  kpiId: string;
  groupId: string;
  groupLoading: boolean;
}

interface IEmployee {
  id: string;
  name: string;
  percentSalesHouse: number;
  percentSalesHouseChange: number;
}

interface IEmployeeRanking {
  employees: IEmployee[];
  threshold: number;
  normalizedMean: number;
}

const getVerticalBarWidth = (value: number, maxValue: number) => {
  const width = Math.abs((value * 87.5) / maxValue);
  return `${width + 12.5}%`;
};

const colorsByValues: any = {
  '#342B71': '-25',
  '#205072': '0',
  '#329D9C': '25',
  '#56C596': '50',
  '#D1FC9B': '75',
  '#CFF4D2': '100',
};

const createChartLinesValues = (numbers: number[], maxNumber: number) => {
  const chuckSize = 5; // The size of the chuck (excluding the first and last elements)
  const proportion = maxNumber / chuckSize; // The proportion between each chuck step
  const charLinesValues = []; // The resulting ladder array
  for (let i = 0; i < chuckSize; i++) {
    charLinesValues.push(Math.round(i * proportion)); // Add each chuck step to the array (rounded to the nearest integer)
  }

  return [...charLinesValues, maxNumber]; // Add the max number to the array and return the next divisible by ten
};

// function to give hex color depending on the percentage value
// if value is < -25% then color is #205072
// if value is < 0% but > -25% then color is #329D9C
// if value is < 25% but > 0% then color is #56C596
// if value is < 50% but > 25% then color is #71AB8C
// if value is > 50% then color is #71AB8C
const getColor = (value: number) => {
  if (value < -25) {
    return '#342B71';
  } else if (value < 0) {
    return '#205072';
  } else if (value < 25) {
    return '#329D9C';
  } else if (value < 50) {
    return '#56C596';
  } else if (value < 75) {
    return '#D1FC9B';
  } else {
    return '#CFF4D2';
  }
};

const HorizontalBarCharts = ({
  employeeRanking: { employees, threshold, normalizedMean },
  loading,
}: {
  employeeRanking: IEmployeeRanking;
  loading: boolean;
}) => {
  const { width } = useWindowSize();
  const parentRef: RefObject<HTMLDivElement> = useRef(null);
  const childRef: RefObject<HTMLDivElement> = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const isChildVisible = (): boolean => {
    if (!parentRef.current || !childRef.current) return false;

    const parentRect = parentRef.current.getBoundingClientRect();
    const childRect = childRef.current.getBoundingClientRect();

    return (
      childRect.top >= parentRect.top &&
      childRect.left >= parentRect.left &&
      childRect.bottom <= parentRect.bottom &&
      childRect.right <= parentRect.right
    );
  };

  useEffect(() => {
    const isVisible = isChildVisible();
    setIsVisible(isVisible);
  }, [width]);

  const theme = useTheme();
  const matchDownDM = useMediaQuery(theme.breakpoints.down('md'));
  const matchUpXL = useMediaQuery(theme.breakpoints.up('xl'));

  const maxValue = 100;

  const chartLinesValues = createChartLinesValues(
    employees.map((employee) => employee.percentSalesHouse),
    maxValue
  );

  const hasEmployees = employees.length > 0;

  const showNormalisedMean = !loading && hasEmployees && normalizedMean !== 0;
  const showThreshold = !loading && hasEmployees && threshold !== 0;

  return (
    <div
      ref={parentRef}
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      {showNormalisedMean && (
        <>
          <div
            ref={childRef}
            style={{
              width: '119px',
              height: '24px',
              background: '#162738',
              borderRadius: '3px',
              position: 'absolute',
              left: isVisible
                ? getVerticalBarWidth(normalizedMean, maxValue)
                : `calc(${getVerticalBarWidth(
                    normalizedMean,
                    maxValue
                  )} - 117px)`,
            }}
          >
            <Box
              sx={{
                top: '-1%',
                fontFamily: 'Roboto',
                fontWeight: '700',
                height: '100%',
                fontSize: '12px',
                lineHeight: '16px',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Avg House Brands %
            </Box>
          </div>
          <Box
            sx={{
              top: '1%',
              width: '2px',
              height: matchUpXL ? '87.2%' : '95.2%',
              border: '1px solid #333',
              position: 'absolute',
              left: getVerticalBarWidth(normalizedMean, maxValue),
            }}
          ></Box>
        </>
      )}

      {showThreshold && (
        <>
          <Box
            sx={{
              width: '119px',
              height: '24px',
              top: '4.7%',
              background: '#FF5572',
              borderRadius: '3px',
              position: 'absolute',
              left: isVisible
                ? getVerticalBarWidth(threshold, maxValue)
                : `calc(${getVerticalBarWidth(threshold, maxValue)} - 117px)`,
            }}
          >
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '700',
                height: '100%',
                fontSize: '12px',
                lineHeight: '16px',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Threshold
            </Box>
          </Box>
          <Box
            sx={{
              width: '2px',
              height: matchUpXL ? '82%' : '90%',
              top: '6.2%',
              border: '1px solid #FF5572',
              position: 'absolute',
              left: getVerticalBarWidth(threshold, maxValue),
            }}
          ></Box>
        </>
      )}
      <Box
        className="custom-scrollbar"
        sx={{
          height: '450px',
          overflowY: 'scroll',
          backgroundSize: '17.5% 10px',
          backgroundPosition: '15.1% 0',
          backgroundImage:
            loading || !hasEmployees
              ? 'white'
              : 'linear-gradient(to bottom, transparent 5px, white 5px), linear-gradient(to right, gray 1px, transparent 1px)',
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px',
          py: hasEmployees ? 2 : 0,
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={450} />
        ) : hasEmployees ? (
          employees.map((employee) => {
            const calculatedWidth = employee.percentSalesHouse;

            const color = getColor(employee.percentSalesHouse);

            const isUnderThreshold = employee.percentSalesHouse < threshold;

            return (
              <Grid container key={employee.id}>
                <Grid item xs={1.5} sx={{ paddingRight: '2px' }}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      color: isUnderThreshold ? '#FF5572' : '#162738',
                      fontSize: matchDownDM ? '8px' : '10px',
                      lineHeight: '130%',
                      textAlign: 'center',
                    }}
                  >
                    {employee.name}
                  </Box>
                </Grid>
                <Grid item xs={10.5}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    {calculatedWidth > 5 ? (
                      <Box
                        sx={{
                          height: '19px',
                          width: `${calculatedWidth}%`,
                          background: `${color}`,
                          borderRadius: '0px 5px 5px 0px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: '700',
                            fontSize: '13px',
                            lineHeight: '14px',
                            color: 'white',
                            pr: 1,
                          }}
                        >
                          {employee.percentSalesHouse}
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box
                          sx={{
                            height: '19px',
                            width: `${calculatedWidth}%`,
                            background: `${color}`,
                            borderRadius: '0px 5px 5px 0px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        />
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: '700',
                            fontSize: '13px',
                            lineHeight: '14px',
                            color: '#FF5572',
                            pr: 1,
                          }}
                        >
                          {employee.percentSalesHouse}
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Box
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <NoDataFound />
          </Box>
        )}
      </Box>
      {!loading && hasEmployees && (
        <Box sx={{ display: 'flex', width: '100%', background: 'white' }}>
          <Box sx={{ width: '12.3%', visibility: 'hidden' }}>0</Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
              width: '17%',
            }}
          >
            {chartLinesValues[0]}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
              width: '17.5%',
            }}
          >
            {chartLinesValues[1]}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
              width: '17.5%',
            }}
          >
            {chartLinesValues[2]}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
              width: '17.5%',
            }}
          >
            {chartLinesValues[3]}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
              width: '15.5%',
            }}
          >
            {chartLinesValues[4]}
          </Box>
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              color: '#162738',
            }}
          >
            {chartLinesValues[5]}
          </Box>
        </Box>
      )}
    </div>
  );
};

const HouseBrandsEmployeesPerformanceChart = ({
  kpiId,
  groupId,
  groupLoading,
}: IEmployeeRankingChart) => {
  const { callService } = useCallService();
  const [employeesRanking, setEmployeesRanking] = useState<IEmployeeRanking>({
    employees: [],
    threshold: 0.0626,
    normalizedMean: 0.3,
  });

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchDownDM = useMediaQuery(theme.breakpoints.down('md'));
  const matchUpLG = useMediaQuery(theme.breakpoints.up('lg'));

  const getEmployeesRanking = async () => {
    if (!groupLoading) {
      try {
        setLoading(true);
        const { response } = await callService({
          resource: httpRoutes.ddl.getEmployeePerformance({
            groupId,
            kpiId,
          }),
        });

        if (response) {
          const { employees, threshold, normalizedMean } = response;
          const mappedEmployees = employees.map((employee: any) => {
            const { firstName, lastName, percentSalesHouse, percentSalesHouseChange, id } =
              employee;
            return {
              id,
              percentSalesHouse,
              percentSalesHouseChange,
              name: `${firstName} ${lastName}`,
            };
          });

          setEmployeesRanking({
            employees: mappedEmployees,
            threshold: parseFloat(threshold),
            normalizedMean: parseFloat(normalizedMean),
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getEmployeesRanking();
  }, [groupId]);

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'scroll',
        background: '#FFFFFF',
        borderRadius: '15px',
        p: matchDownDM ? 2 : 4,
      }}
    >
      <Box mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          EMPLOYEE PERFORMANCE
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by employee house brands
        </Box>
      </Box>
      <Grid container spacing={1.5} sx={{ overflow: 'hidden' }}>
        <Grid item container md={12} xl={9}>
          <HorizontalBarCharts
            employeeRanking={employeesRanking}
            loading={loading}
          />
        </Grid>
        <Grid item container md={12} xl={3} rowSpacing={1} columnSpacing={4}>
          <Grid item xl={12} lg={6} sx={{ width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#FAFAFA',
                borderRadius: '15px',
                py: 4,
                pl: 3,
                pr: matchDownDM ? 1 : 4,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  Average House Brands percentage & Threshold
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>Dark blue</span> line
                  shows the average house brands percentage of all employees in your roster.
                </Box>

                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  <span style={{ fontWeight: 'bold', color: '#FF5572' }}>
                    Pink
                  </span>{' '}
                  line shows{' '}
                  <span style={{ fontWeight: 'bold', color: '#FF5572' }}>
                    threshold
                  </span>{' '}
                  of employees who are 20% below average house brands percentage.
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  These employees have been assigned a course to uplift
                  performance.
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={6} sx={{ width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                background: '#FAFAFA',
                borderRadius: '15px',
                py: 4,
                pl: 3,
                pr: matchDownDM ? 1 : 4,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '700',
                    fontSize: '14px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  House Brands Percentage Change
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  These colors show the improvement of each employee over time.
                  Found by comparing an average of the last 5 months to their
                  performance this month.
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '157%',
                    color: '#162738',
                  }}
                >
                  Each color shows a the total % change for an individual
                  employee.
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    ...(matchUpLG && {
                      flexDirection: 'column',
                    }),
                  }}
                >
                  {Object.keys(colorsByValues).map((color: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: matchDownSM ? '2px' : 1,
                      }}
                      key={`${color}-${colorsByValues[color]}`}
                    >
                      <Box
                        sx={{
                          width: matchDownSM ? '10px' : '14px',
                          height: matchDownSM ? '10px' : '14px',
                          background: color,
                          borderRadius: '100px',
                        }}
                      />
                      <Box
                        sx={{
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                          fontSize: '10px',
                          lineHeight: '140%',
                          color: '#162738',
                        }}
                      >
                        {`< ${colorsByValues[color]}%`}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HouseBrandsEmployeesPerformanceChart;
