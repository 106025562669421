/* eslint-disable */
import {
  memo,
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import KpiContext, { KPI_INITIAL_STATE } from './contexts/KpiContext';
import httpRoutes from '../../utils/httpRoutes';
import { useCallService } from 'hooks';

const KpiProvider = ({ children }: { children: ReactNode }) => {
  const [kpiState, setKpis] = useState(KPI_INITIAL_STATE);
  const { callService } = useCallService();

  const getKpisService = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.kpis.getKpis(),
      });

      if (response) {
        setKpis({ kpis: response });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getKpisService();
  }, []);

  const getKpis = useCallback(() => {
    return kpiState.kpis;
  }, [kpiState]);

  const value = useMemo(
    () => ({
      kpiState,
      getKpis,
    }),
    [kpiState, getKpis]
  );

  return <KpiContext.Provider value={value}>{children}</KpiContext.Provider>;
};

export default memo(KpiProvider);
