import Cookies from 'js-cookie';

export const getToken = (): string => {
    const token = Cookies.get('st-token');

    return token || '';
};

export const isAuthenticated = (): boolean => {
    return !!getToken();
};
