import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { useState } from 'react';
import Form, { UserFormInput } from '../forms/UserForm';
import PublicForm, {PublicUserFormInput} from '../forms/PublicUserForm';

import {useAuthState} from '../../../providers/AuthProvider/hooks/useAuthState';

const Create = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { callService } = useCallService();
  const {
    authState: { isAdmin },
  } = useAuthState();

  const onSubmit = async (values: UserFormInput | PublicUserFormInput) => {
    setIsSubmitting(true);
    const { response } = await callService({
      resource: httpRoutes.userManagement.createUser(values),
      successMessage: 'User created successfully!',
    });

    if (response) {
      onSuccess();
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      {isAdmin && (
          <Form
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
          />
      )}

      {!isAdmin && (
          <PublicForm
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
          />
      )}
    </div>
  );
};

export default Create;
