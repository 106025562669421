export default (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.83881 3.54547H9.16089L8.94854 10.1055H7.05116L6.83881 3.54547ZM9.20244 11.9336C9.19321 12.5984 8.63461 13.1339 8.00216 13.1339C7.33738 13.1339 6.79264 12.5984 6.80187 11.9336C6.79264 11.2781 7.33738 10.7472 8.00216 10.7472C8.63461 10.7472 9.19321 11.2781 9.20244 11.9336Z"
      fill="#FF5572"
    />
  </svg>
);
