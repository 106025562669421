import { useState, useEffect } from 'react';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import LoadingButton from 'components/buttons/LoadingButton';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';
import { useSnackbar } from 'notistack';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import httpRoutes from 'utils/httpRoutes';

import resources from '../../user/resources';

// Earned Date, Expiration
// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Certificate Name',
  },
];

const LOADING_STATE = {
  gridData: true,
  downloading: false,
  downloadingId: '',
};

const UserCertificatesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const {
    authState: { user },
  } = useAuthState();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(LOADING_STATE);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    const getUserCertificates = async () => {
      try {
        setLoading((prevState) => ({ ...prevState, gridData: true }));
        const { response } = await callService({
          resource: resources.getUserCertificates({
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          const items = response.items.map(
            (item: { course: { name: any } }) => {
              return { courseName: item.course.name, ...item };
            }
          );
          setGridData({
            rows: items,
            totalRows: response.totalRows,
          });
        }
      } finally {
        setLoading((prevState) => ({ ...prevState, gridData: false }));
      }
    };

    getUserCertificates();
  }, [state, filters]);

  const downloadCertificate = async (userCertificate: any) => {
    const {
      id,
      num,
      course: { name: courseName },
    } = userCertificate;

    setLoading((prevState) => ({
      ...prevState,
      downloading: true,
      downloadingId: userCertificate.id,
    }));

    try {
      const { response } = await callService({
        resource: httpRoutes.users.downloadCertificate(id),
        throwOnError: true,
      });

      if (response) {
        // update file name
        const _fileName = `${user.firstName}-${user.lastName}-${courseName}-${num}.pdf`;

        const _fileData = new Blob([response], { type: 'application/pdf' });

        fileSaver.saveAs(_fileData, _fileName);
      }
    } catch(e) {
      enqueueSnackbar('Certificate download failed.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        downloading: false,
        downloadingId: '',
      }));
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Organization',
      field: 'organizationId',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Date Achieved',
      field: 'earned',
      sortable: false,
      filterable: false,
      flex: 0.2,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.earned
              ? new Date(params.row.earned).toLocaleString()
              : '-'}
          </>
        );
      },
    },
    {
      headerName: 'Expiration',
      field: 'expiresOn',
      sortable: false,
      filterable: false,
      type: 'dateTime',
      flex: 0.2,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.expiresOn
              ? new Date(params.row.expiresOn).toLocaleString()
              : '-'}
          </>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'userCertificateId',
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      ColumnComponent: ({ row }: { row: any }) => {
        if (row.certificateId) {
          return (
            <LoadingButton
              onClick={() => downloadCertificate(row)}
              label="Download"
              variant="text"
              loading={loading.downloading && loading.downloadingId === row.id}
            />
          );
        }

        return <>--</>;
      },
    },
  ];

  return (
    <DataGrid
      rows={gridData.rows}
      columns={columns}
      filters={filterModel}
      tableTitle="My Certificates"
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading.gridData}
    />
  );
};

const UserCertificates = () => (
  <ControlledDataGrid>
    <UserCertificatesGrid />
  </ControlledDataGrid>
);

export default UserCertificates;
