import { ReactNode, createContext } from 'react';

export type RightDrawerProviderState = {
  title?: string;
  open?: boolean;
  size?: 'sm' | 'md' | 'lg';
  content?: ReactNode;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
};

export type RightDrawerContextProps = {
  rightDrawerState: RightDrawerProviderState;
  showRightDrawer: (params: RightDrawerProviderState) => void;
  hideRightDrawer: () => void;
};

export const RIGHT_DRAWER_INITIAL_STATE: RightDrawerProviderState = {
  open: false,
  title: '',
  size: 'sm',
  content: null,
  disableEscapeKeyDown: false,
  enableBackdropClose: true,
};

export const RIGHT_DRAWER_CONTEXT_INITIAL_STATE: RightDrawerContextProps = {
  rightDrawerState: RIGHT_DRAWER_INITIAL_STATE,
  showRightDrawer: () => null,
  hideRightDrawer: () => null,
};

const RightDrawerContext = createContext<RightDrawerContextProps>(
  RIGHT_DRAWER_CONTEXT_INITIAL_STATE
);

export default RightDrawerContext;
