import {
  Navigate,
  Params,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';

import MainLayout from 'layout/MainLayout';

import { BundleDetailPage, Bundles } from 'features/bundles/pages';

import { Assessment, Course, CourseList } from 'features/courses/pages';

import { CoursesAdmin } from 'features/course-admin/pages';

import { Dashboard } from 'features/dashboard/pages';

import {
  BasketSize,
  Upselling,
  OrgManagement,
  OrgManagementEmployees,
  OrgManagementGroups,
  MatchEmployees,
  EmployeeSummaryView,
  EmployeeView,
  HouseBrands,
  MultiStore,
  SingleStore,
} from 'features/ddl/pages';

import { Organizations, Organization } from 'features/organizations/pages';

import {
  Profile,
  UserCertificates,
  UserCommunities,
} from 'features/user/pages';

import { Certificates } from 'features/certificates/pages';

import {
  ApproveMembers,
  Communities,
  Community,
  InvitationConfirm,
} from 'features/communities/pages';

import { Rewards } from 'features/rewards/pages';

import UserCourses from 'features/user/pages/UserCourses';

import {
  UserManagement,
  LearnerActivity,
} from 'features/user-management/pages';

import {
  AdminCourseReport,
  AdminGroupsByCourse,
  AdminGroupReport,
  AdminLearnerReport,
  CreatorCourseReport,
  CourseReport,
  CourseActivityReport,
  GroupsByCourse,
  LearnerCourseReport,
  GroupCourseReport,
  FacilitatorGroupReport,
  GroupCourseLearnerReport,
  AdminGroupCourseReport,
  CreatorGroupsByCourse,
  LearnersSpecificCommunityReport,
  LearnersMultiCommunityReport,
  CourseAssigmentFacilitatorGroupReport,
  AdminCourseAssigmentGroupReport,
  CourseAssigmentGroupCourseReport,
  AdminCourseAssigmentGroupCourseReport,
  CourseAssigmentGroupCourseLearnerReport,
} from 'features/reporting';
import AdminMembershipReport from 'features/reporting/membership/AdminMembershipReport';
import UserRewards from 'features/reporting/course-activity/UserRewards';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import HasPermission from 'components/HasPermission';

import { Cronjobs } from '../features/cronjob/pages';
import { NpmLogs } from '../features/admin-panel/logs';
import { Loops } from '../features/admin-panel/loops';

/*import EditCourse from 'features/course-builder/pages/EditCourse';
import CreateCourse from 'features/course-builder/pages/CreateCourse';*/
import AssignmentPublicView from 'features/course-assignment/AssignmentPublicView';
import OrganizationCourses from 'features/course-builder/pages/OrganizationCourses';
import CoassembleCourse from 'features/courses/pages/CoasssembleCourse';

const RequireAuth = ({
  children,
  getFallBackUrl = () => '',
}: {
  children: JSX.Element;
  getFallBackUrl?: (params: Params) => string;
}) => {
  const { authState } = useAuthState();
  const location = useLocation();
  const params = useParams();

  if (authState.authenticated) {
    return children;
  }

  const fallbackUrl = getFallBackUrl({
    ...params,
    queryParams: location.search,
  });

  return (
    <Navigate to={fallbackUrl || '/login'} state={{ from: location }} replace />
  );
};

// const breadcrumbNameMap: { [key: string]: string } = {
//   admin: 'Admin',
//   reports: 'Reports',
//   courses: 'Courses',
//   course: 'Course',
//   learners: 'Learners',
//   communities: 'Communities',
//   groups: 'Community',
//   user: 'User',
//   details: 'Details',
// };

const AdminRoutes: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '/admin/course-list',
        element: <CoursesAdmin />,
      },
      {
        path: '/admin/certificates',
        element: <Certificates />,
      },
      {
        path: '/admin/communities',
        element: <Communities isAdmin />,
      },
      {
        path: '/admin/communities/:id',
        element: <Community isAdmin/>,
      },
      {
        path: '/admin/organizations',
        element: <Organizations />,
      },
      {
        path: '/admin/organizations/:id',
        element: <Organization />,
      },
      {
        path: '/admin/rewards',
        element: <Rewards />,
      },
      {
        path: '/admin/bundles',
        element: <Bundles />,
      },
      {
        path: '/admin/bundles/:bundleId',
        element: <BundleDetailPage />,
      },
      {
        path: '/admin/user-management',
        element: <UserManagement />,
      },
      {
        path: '/admin/user-management/learner-activity/:id',
        element: <LearnerActivity />,
      },
      {
        path: '/admin/reports',
        // element: <ReportLayout breadcrumbNameMap={breadcrumbNameMap} />,
        children: [
          {
            path: '/admin/reports/courses',
            element: <AdminCourseReport />,
          },
          {
            path: '/admin/reports/courses/:id',
            element: <AdminGroupsByCourse />,
          },
          {
            path: '/admin/reports/learners',
            element: <AdminLearnerReport />,
          },
          {
            path: '/admin/reports/learners/:id',
            element: <LearnerCourseReport isAdmin />,
          },
          {
            path: '/admin/reports/groups',
            element: <AdminGroupReport />,
          },
          {
            path: '/admin/reports/groups/:id',
            element: <AdminGroupCourseReport />,
          },
          {
            path: '/admin/reports/groups/:id/course/:courseId',
            element: <GroupCourseLearnerReport />,
          },
          {
            path: '/admin/reports/membership',
            element: <AdminMembershipReport />,
          },
          {
            path: '/admin/reports/course-assignment',
            element: <AdminCourseAssigmentGroupReport />,
          },
          {
            path: '/admin/reports/course-assignment/:id',
            element: <AdminCourseAssigmentGroupCourseReport />,
          },
          {
            path: '/admin/reports/course-assignment/:id/course/:courseId',
            element: <CourseAssigmentGroupCourseLearnerReport />,
          },
        ],
      },
      {
        path: '/admin/ddl',
        children: [
          {
            path: '/admin/ddl/org-management',
            element: <OrgManagement />,
          },
          {
            path: '/admin/ddl/org-management/:organizationId',
            element: <OrgManagementGroups />,
          },
          {
            path: '/admin/ddl/org-management/:organizationId/group/:groupId',
            element: <OrgManagementEmployees />,
          },
          {
            path: '/admin/ddl/org-management/:organizationId/group/:groupId/match-employees',
            element: <MatchEmployees />,
          },
          {
            path: '/admin/ddl/:organizationId/group/:groupId/ticket-size',
            element: <BasketSize />,
          },
          {
            path: '/admin/ddl/ticket-size',
            element: <BasketSize />,
          },
          /*{
            path: '/admin/ddl/ticket-time',
            element: <TicketTime />,
          },
          {
            path: '/admin/ddl/upselling',
            element: <Upselling />,
          },*/
        ],
      },
      {
        path: '/admin/cronjobs',
        element: <Cronjobs />,
      },

      {
        path: '/admin/npmlogs',
        element: <NpmLogs />,
      },
      {
        path: '/admin/loops',
        element: <Loops />,
      },
    ],
  },
];

const PrivateRoutes: RouteObject[] = [
  {
    path: '/community/invitation/:communityId',
    element: (
      <RequireAuth
        getFallBackUrl={({ communityId = '', queryParams = '' }) =>
          `/community/login-invitation/${communityId}${queryParams}`
        }
      >
        <InvitationConfirm />
      </RequireAuth>
    ),
  },
  {
    path: '/organization/:organizationId/course/:courseReference',
    element: (
      <RequireAuth
        getFallBackUrl={({ organizationId = '', courseReference = '' }) =>
          `/organization/${organizationId}/course/${courseReference}/validate-email`
        }
      >
        <Course external={true} />
      </RequireAuth>
    ),
  },
  {
    path: '/organization/:organizationId/dashboard',
    element: (
      <RequireAuth
        getFallBackUrl={({ organizationId = '' }) =>
          `/organization/${organizationId}/dashboard/validate-email`
        }
      >
        <MainLayout>
          <Dashboard />
        </MainLayout>
      </RequireAuth>
    ),
  },
  {
    path: '',
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/profile',
        children: [
          {
            path: '/profile',
            element: <Profile />,
          },
          {
            path: '/profile/certificates',
            element: <UserCertificates />,
          },
          {
            path: '/profile/rewards',
            element: <UserRewards />,
          },
          {
            path: '/profile/communities',
            element: <UserCommunities />,
          },
          {
            path: '/profile/courses',
            element: <UserCourses />,
          },
        ],
      },
      {
        path: '/courses',
        element: <CourseList />,
      },
      {
        path: '/course/:id',
        element: <Course external={false} />,
      },
      {
        path: '/assessment/:id',
        element: <Assessment />,
      },
      {
        path: '/communities',
        element: <HasPermission permissions={['communities:facilitator']} />,
        children: [
          {
            path: '/communities',
            index: true,
            element: <Communities />,
          },
          {
            path: '/communities/:id',
            element: <Community />,
          },
          {
            path: '/communities/pending-members',
            element: <ApproveMembers />,
          },
        ],
      },
      {
        path: '/user-management',
        element: <UserManagement />,
      },
      {
        path: '/course-list',
        element: <OrganizationCourses />,
      },
      { path: '/bundles', element: <Bundles /> },
      {
        path: '/bundles/:bundleId',
        element: <BundleDetailPage />,
      },
      {
        path: '/course-assignment',
        element: (
          <HasPermission
            permissions={['assignments:write', 'assignments:read']}
          />
        ),
        children: [
          {
            path: '/course-assignment',
            index: true,
            element: <AssignmentPublicView />,
          },
        ],
      },
      {
        path: '/reports',
        // element: <ReportLayout breadcrumbNameMap={breadcrumbNameMap} />,
        children: [
          {
            path: '/reports/user',
            element: <CourseActivityReport />,
          },
          {
            path: '/reports/learners/group/:id',
            element: <LearnersSpecificCommunityReport />,
          },
          {
            path: '/reports/learners',
            element: <LearnersMultiCommunityReport />,
          },
          {
            path: '/reports/learners/:id',
            element: <LearnerCourseReport />,
          },
          {
            path: '/reports/courses',
            element: <CourseReport />,
          },
          {
            path: '/reports/courses/:id',
            element: <GroupsByCourse />,
          },
          {
            path: '/reports/engagement-report',
            element: <CreatorCourseReport />,
          },
          {
            path: '/reports/engagement-report/:id',
            element: <CreatorGroupsByCourse />,
          },
          {
            path: '/reports/groups',
            element: <FacilitatorGroupReport />,
          },
          {
            path: '/reports/groups/:id',
            element: <GroupCourseReport />,
          },
          {
            path: '/reports/groups/:id/course/:courseId',
            element: <GroupCourseLearnerReport />,
          },
          {
            path: '/reports/course-assignment',
            element: <CourseAssigmentFacilitatorGroupReport />,
          },
          {
            path: '/reports/course-assignment/:id',
            element: <CourseAssigmentGroupCourseReport />,
          },
          {
            path: '/reports/course-assignment/:id/course/:courseId',
            element: <CourseAssigmentGroupCourseLearnerReport />,
          },
        ],
      },
      /*{
        path: '/course-builder',
        element: <CreateCourse />,
      },
      {
        path: '/course-editor',
        element: <EditCourse />,
      },*/
      {
        path: '/course/course-builder/:externalCourseId',
        element: <CoassembleCourse />,
      },
      {
        path: '/ddl',
        children: [
          {
            path: '/ddl/ticket-size',
            element: <BasketSize />,
          },
          {
            path: '/ddl/house-brands',
            element: <HouseBrands />,
          },
          {
            path: '/ddl/upselling',
            element: <Upselling />,
          },
          {
            path: '/ddl/performance-report',
            element: <MultiStore />,
          },
          {
            path: '/ddl/performance-report/group/:groupId',
            element: <SingleStore />,
          },
          {
            path: '/ddl/performance-report/group/:groupId/kpi/:kpiName/',
            element: <EmployeeSummaryView />,
          },
          {
            path: '/ddl/performance-report/group/:groupId/user/:userId',
            element: <EmployeeView />,
          },
        ],
      },
    ],
  },
];

export default PrivateRoutes.concat(AdminRoutes);
