import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Course Name',
    field: 'course_name',
    sortable: true,
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Total Learners',
    field: 'total_learners',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Total Started',
    field: 'total_started',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Total Completed',
    field: 'total_completed',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Percent Completed',
    field: 'percentage_completed',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const _percentage = Number(params.row.percentage_completed);

      if (_percentage === 1) {
        return <>100 %</>;
      }

      if (_percentage === 0) {
        return <>0.0 %</>;
      }

      return <>{(_percentage * 100).toPrecision(2)} %</>;
    },
  },
  {
    headerName: 'Average Score',
    field: 'average_score',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      if (Number(params.row.average_score) === 100) {
        return '100 %';
      }
      if (params.row.average_score) {
        return <>{Number(params.row.average_score).toPrecision(2)} %</>;
      }
      return <>-- %</>;
    },
  },
];

const loadingState = {
  courses: true,
  exporting: false,
};

let coursesRequestController: AbortController | undefined;

const CourseReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [courseCategories, setCourseCategories] = useState<SelectOption[]>([]);

  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getCourseCategories = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.courses.getCourseCategories(),
        });

        if (response) {
          const options = [
            { label: 'All Categories', value: '' } as SelectOption,
            ...response.map((category: any) => ({
              label: category.name,
              value: category.id,
            })),
          ];
          setCourseCategories(options);
        }
      } catch (e) {
        console.log('Error', e);
      }
    };

    getCourseCategories();
  }, []);

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (coursesRequestController) {
      coursesRequestController.abort();
    }
    try {
      coursesRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.reporting.facilitatorCourseReport.getSummary({
          controller: coursesRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        coursesRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, courses: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportCourses = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.facilitatorCourseReport.exportSummary({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csvRows = Papa.unparse(response, {
          header: false,
          columns: [
            'course_name',
            'total_learners',
            'total_started',
            'total_completed',
            'percentage_completed',
            'average_score',
          ],
        });
        const csvHeader = Papa.unparse({
          fields: [
            'Course Name',
            'Total Learners',
            'Total Started',
            'Total Completed',
            'Percent Completed',
            'Average Score',
          ],
          data: [],
        });
        const csv = csvHeader + csvRows;
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Course Summary Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
    {
      id: 'courseCategoryId',
      type: 'select',
      label: 'Category',
      options: courseCategories,
    },
  ];

  return (
    <PageContainer
      title="Facilitator Course Report"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        totalRows={gridData.totalRows}
        filters={filterModel}
        handleFilterChange={setFilters}
        handleExport={exportCourses}
        loading={loading.courses}
        onRowClick={(params) => {
          navigate(`/reports/courses/${params.course_id}`, {
            state: { name: params.course_name },
          });
        }}
      />
    </PageContainer>
  );
};

const CourseReport = () => (
  <ControlledDataGrid>
    <CourseReportGrid />
  </ControlledDataGrid>
);

export default CourseReport;
