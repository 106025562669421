import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon, EditIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

import EditCourseRecord from '../dialogs/EditCourseRecord';
import { IconButton } from '@mui/material';

const loadingState = {
  categories: true,
  courses: true,
  exporting: false,
};

const LearnerCourseReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [user, setUser] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const { showDialog, hideDialog } = useDialogDispatcher();

  const { id = '' } = useParams();

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const onSuccess = () => {
    getCourses();
    hideDialog();
  };

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.course.getLearnerCourseReport({
          userId: id,
          isAdmin: true,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setUser(response.user);
        setGridData({
          rows: response.data.items || [],
          totalRows: response.data.totalRows || 0,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const getCourseCategories = async () => {
    setLoading((prevState) => ({ ...prevState, categories: true }));

    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    if (response) {
      const _categoriesList: SelectOption[] = [
        {
          label: 'All Categories',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _categoriesList.push({ label: item.name, value: item.id });
      });

      setCategories(_categoriesList);

      setLoading((prevState) => ({ ...prevState, categories: false }));
    }
  };

  const showEditDialog = (values: any) => {
    if (!values.id) {
      return;
    }

    showDialog({
      content: (
        <EditCourseRecord
          onSuccess={onSuccess}
          courseId={values.id}
          userId={id}
        />
      ),
      size: 'sm',
    });
  };

  const exportCourses = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));

      const { response } = await callService({
        resource: httpRoutes.reporting.course.exportLearnerCourseReport({
          isAdmin: true,
          userId: id,
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, `${user.email} Learner Course Report.csv`);

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 1.25,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: 'Started',
      field: 'start',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 1,
      ColumnComponent: (params: any) => {
        if (params.row.start) {
          return <>{new Date(params.row.start).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Completed',
      field: 'end',
      sortable: false,
      filterable: false,
      type: 'date',
      headerAlign: 'center',
      align: 'right',
      flex: 1,
      ColumnComponent: (params: any) => {
        if (params.row.end) {
          return <>{new Date(params.row.end).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Passed',
      field: 'passed',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'boolean',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return <>{params.row.passed ? 'Yes' : 'No'}</>;
      },
    },
    {
      headerName: 'Hours',
      field: 'estimatedHours',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return <>{params.row.estimatedHours} hrs</>;
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        if (params.row.status === 'To-Do') {
          return <></>;
        }

        return (
          <>
            <IconButton onClick={() => showEditDialog(params.row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
    {
      id: 'courseCategoryId',
      type: 'select',
      label: 'Course Category',
      options: categories,
    },
  ];

  return (
    <PageContainer
      title="Learner Activity: Course Report"
      subtitle={`Learner Name: ${user.firstName} ${user.lastName}. Email: ${user.email}.`}
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        totalRows={gridData.totalRows}
        handleExport={exportCourses}
        filters={filterModel}
        handleFilterChange={setFilters}
        loading={loading.courses}
      />
    </PageContainer>
  );
};

const LearnerCourseReport = () => (
  <ControlledDataGrid>
    <LearnerCourseReportGrid />
  </ControlledDataGrid>
);

export default LearnerCourseReport;
