import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ClearIcon } from 'material-icons';

import { BlurFilterProps } from '../filterTypes';
import Icon from 'icons';
import { Tooltip } from '@mui/material';

export default function BlurTextFilter({
  id,
  label,
  value = '',
  onBlur,
  findOnPressEnter = false,
  infoMsg,
}: BlurFilterProps) {
  const [txtValue, setTxtValue] = React.useState('');
  const clearText = () => {
    setTxtValue('');
    onBlur('');
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <Box>
      {infoMsg ? (
        <Tooltip title={infoMsg} arrow placement="top">
          <TextField
            fullWidth
            id={id}
            placeholder={label}
            value={txtValue}
            onChange={(e) => setTxtValue(e.target.value)}
            onBlur={(_) => onBlur(txtValue)}
            onKeyUp={(e) => (findOnPressEnter ? handleKeyDown(e) : () => {})}
            size="small"
            sx={{
              background: 'white',
              borderRadius: '30px',
              '& .MuiOutlinedInput-input': {
                width: 'auto',
                padding: '10px 8px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '30px',
                },
                '&:hover fieldset': {
                  borderColor: '#56C596',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#56C596',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="lens" fill="#56C596" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    visibility: value ? 'visible' : 'hidden',
                    cursor: 'pointer',
                    color: '#56C596',
                  }}
                  onClick={clearText}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      ) : (
        <TextField
          fullWidth
          id={id}
          placeholder={label}
          value={txtValue}
          onChange={(e) => setTxtValue(e.target.value)}
          onBlur={(_) => onBlur(txtValue)}
          onKeyUp={(e) => (findOnPressEnter ? handleKeyDown(e) : () => {})}
          size="small"
          sx={{
            background: 'white',
            borderRadius: '30px',
            '& .MuiOutlinedInput-input': {
              width: 'auto',
              padding: '10px 8px',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderRadius: '30px',
              },
              '&:hover fieldset': {
                borderColor: '#56C596',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#56C596',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="lens" fill="#56C596" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  visibility: value ? 'visible' : 'hidden',
                  cursor: 'pointer',
                  color: '#56C596',
                }}
                onClick={clearText}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </Box>
  );
}
