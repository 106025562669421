import { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import logo from 'assets/logos/logo.png';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import PageContainer from 'components/PageContainer';
import { ManageCourses } from '../dialogs';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { EditIcon } from 'material-icons';
import Button from 'components/buttons/Button';
import {useAuthState} from '../../../providers/AuthProvider/hooks/useAuthState';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const BundleCoursesGrid = ({
  bundleId,
  bundleName,
}: {
  bundleId: string;
  bundleName: string;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const {
    authState: { user, isAdmin },
  } = useAuthState();

  useEffect(() => {
    getBundleCourses();
  }, [filters, state]);

  const getBundleCourses = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.bundles.getBundleCourses({
          bundleId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showManageCoursesDialog = () => {
    showDialog({
      content: (
        <ManageCourses
          onSuccess={getBundleCourses}
          bundleId={bundleId}
          bundleName={bundleName}
        />
      ),
      size: 'md',
    });
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Image',
      field: 'imageUrl',
      sortable: false,
      filterable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        if (params.row.imageUrl) {
          return (
            <img
              src={`${REACT_APP_CLOUDFRONT_URL}/${params.row.imageUrl}`}
              height="40"
            />
          );
        }
        return <img src={logo} alt="Seed Talent Logo" height="40" />;
      },
    },
    {
      headerName: 'Course Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Course Reference',
      field: 'reference',
      sortable: true,
    },
    {
      headerName: 'Category',
      field: 'courseCategory.name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return <Box>{params.row.courseCategory.name}</Box>;
      },
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      pageAction={
      isAdmin || user.managerOrganizationId &&
        <Button
          label="Manage Courses"
          onClick={showManageCoursesDialog}
          startIcon={<EditIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const BundleCourses = ({
  bundleId,
  bundleName,
}: {
  bundleId: string;
  bundleName: string;
}) => (
  <ControlledDataGrid>
    <BundleCoursesGrid bundleId={bundleId} bundleName={bundleName} />
  </ControlledDataGrid>
);

export default BundleCourses;
