export default function themePalette() {
  return {
    common: {
      green: '#56C596',
    },
    primary: {
      main: '#16915D',
    },
    secondary: {
      main: '#205072',
    },
  };
}
