import React from 'react';

import { Controller } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

type RadioFormFieldOptionsType = {
  label: string;
  value: string | boolean;
  disabled?: boolean;
};

interface RadioFormFieldProps {
  name: string;
  control: any;
  errors?: any;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioFormFieldOptionsType[];
  row?: boolean;
}

const RadioFormField = ({
  name,
  control,
  errors,
  disabled = false,
  onChange,
  options,
  row = true,
}: RadioFormFieldProps) => (
  <Controller
    name={name}
    disabled={disabled}
    control={control}
    render={({ field }) => {
      const { onChange: fieldOnChange, ...rest } = field;

      return (
        <FormControl error={Boolean(errors)}>
          <RadioGroup
            {...rest}
            onChange={(e, checked) => {
              if (onChange) {
                onChange(e);
              }
              fieldOnChange(e, checked);
            }}
            row={row}
          >
            {options.map((option) => (
              <FormControlLabel
                disabled={option.disabled}
                key={option.label}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }}
  />
);

export default RadioFormField;
