import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDialogDispatcher } from '../../../../../providers/DialogProvider/hooks/useDialogDispatcher';
import { useForm } from 'react-hook-form';
import {
  TextFormField,
  ToggleFormField,
} from '../../../../../components/formFields';
import LoadingButton from '../../../../../components/buttons/LoadingButton';
import { SaveIcon } from '../../../../../material-icons';

const textFieldStyle = {
  background: 'white',
  '& .MuiInputLabel-root': {
    '&.Mui-focused': { color: '#56C596' },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

export interface IEmployeeFormInput {
  posId?: string;
  notes?: string;
  ddlActivated: boolean;
  userPosId: string;
}

interface IEmployeeFormProps {
  defaultValues?: IEmployeeFormInput;
  onSubmit: (values: IEmployeeFormInput) => void;
  isAlreadyMapped: boolean;
}

const EmployeeForm = ({
  defaultValues,
  onSubmit,
  isAlreadyMapped,
}: IEmployeeFormProps) => {
  const theme = useTheme();

  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { hideDialog } = useDialogDispatcher();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IEmployeeFormInput>({
    mode: 'onBlur',
    defaultValues: {
      posId: defaultValues?.posId || '',
      notes: defaultValues?.notes || '',
      ddlActivated: defaultValues?.ddlActivated || false,
      userPosId: defaultValues?.userPosId || 'No Point of sale',
    },
    shouldUnregister: false,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container alignItems="center" columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="userPosId"
              control={control}
              label="Point of Sale (Source System)"
              disabled={true}
              margin="dense"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="posId"
              control={control}
              label="Pos Id"
              errors={errors.posId}
              margin="dense"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="notes"
              control={control}
              label="Notes"
              errors={errors.notes}
              margin="dense"
              multiline
              rows={4}
            />
          </Box>
        </Grid>
      </Grid>
      {isAlreadyMapped && (
        <Grid
          container
          alignItems="center"
          columnSpacing={3}
          rowSpacing={3}
          sx={{ mb: matchUpXS ? 3 : 1 }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '17px',
                }}
              >
                Enrolled in Data Driven Learning
              </Box>
              <Box>
                <ToggleFormField name="ddlActivated" control={control} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          label="Save"
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          loadingPosition="start"
          icon={<SaveIcon />}
        />
      </Grid>
    </form>
  );
};

export default EmployeeForm;
