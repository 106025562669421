import React from 'react';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface AvatarProps extends MuiAvatarProps {
  dataId?: string;
  sx?: SxProps<Theme>;
}

function Avatar({ dataId, sx, ...props }: AvatarProps) {
  return <MuiAvatar data-id={dataId} sx={sx} {...props}></MuiAvatar>;
}

export default Avatar;
