import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { EditIcon } from 'material-icons';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';
import { Button } from 'components/buttons';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import ManageBundles from '../dialogs/ManageBundles';

import httpRoutes from 'utils/httpRoutes';
import { useAuthState } from '../../../providers/AuthProvider/hooks/useAuthState';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Bundle Name',
  },
];

const CommunityBundlesGrid = ({
  groupId,
  groupName,
}: {
  groupId: string;
  groupName: string;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const navigate = useNavigate();
  const { showDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const {
    authState: { user, isAdmin },
  } = useAuthState();
  const showManageBundlesDialog = () => {
    showDialog({
      content: (
        <ManageBundles
          onSuccess={getCommunityBundles}
          groupId={groupId}
          groupName={groupName}
        />
      ),
      size: 'md',
    });
  };

  useEffect(() => {
    getCommunityBundles();
  }, [filters, state]);

  const getCommunityBundles = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getGroupBundlesPaginated({
          groupId: groupId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 1,
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      pageAction={
        isAdmin || user.managerOrganizationId && (
          <Button
            label="Manage Bundles"
            onClick={showManageBundlesDialog}
            startIcon={<EditIcon />}
          />
        )
      }
      isSecondary
      elevation={0}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        onCellClick={(params) => {
          if (params.field === 'name') {
            return navigate(`/bundles/${params.row.id}`);
          }
        }}
        loading={loading}
      />
    </PageContainer>
  );
};

const CommunityBundles = ({
  groupId,
  groupName,
}: {
  groupId: string;
  groupName: string;
}) => (
  <ControlledDataGrid>
    <CommunityBundlesGrid groupId={groupId} groupName={groupName} />
  </ControlledDataGrid>
);

export default CommunityBundles;
