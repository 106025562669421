import { useContext } from 'react';
import PendingMembersContext from '../contexts/PendingMembersContext';

export const usePendingMembersState = () => {
  const { pendingMembersState } = useContext(PendingMembersContext);

  return {
    pendingMembersState,
  };
};
