import { Box } from '@mui/material';
import { intervalDisplayName } from '../utils';

const NoDataAvailable = ({ interval }: { interval: string }) => {
  const text =
    interval === 'latest'
      ? 'Latest data is not available for this kpi'
      : `No data available for this kpi from ${intervalDisplayName[interval]}`;

  return (
    <Box sx={{ color: '#666', wordWrap: 'break-word', fontSize: '13px' }}>
      {text}
    </Box>
  );
};

export default NoDataAvailable;
