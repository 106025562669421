import {
  Grid,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
  StepperProps,
} from '@mui/material';

interface StepperType extends StepperProps {
  steps: string[];
  currentStepIndex: number;
  goTo?: any;
  alternativeLabel?: boolean;
  completedSteps?: { [k: number]: boolean };
}

const FormStepper = ({
  steps,
  currentStepIndex,
  goTo = undefined,
  alternativeLabel = false,
  nonLinear = false,
  completedSteps = {},
}: StepperType) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  return !matchDownMD ? (
    <Grid container mb={2} sx={{ width: '100%' }}>
      <Stepper
        alternativeLabel={alternativeLabel}
        activeStep={currentStepIndex}
        nonLinear={nonLinear}
        sx={{ width: '100%' }}
      >
        {steps.map((title, index) => (
          <Step key={index} completed={completedSteps[index] === true}>
            <StepButton
              color="inherit"
              onClick={goTo ? () => goTo(index) : undefined}
            >
              {title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Grid>
  ) : null;
};

export default FormStepper;
