import { toFixedIfDecimal } from 'utils/common';

export const kpiLabel = (kpiName?: string) => {
  switch (kpiName) {
    case 'ticket_size':
      return 'Ticket Size';
    case 'revenue_per_hour':
      return 'Revenue Per Hour';
    case 'transactions_per_hour':
      return 'Transactions Per Hour';
    case 'units_per_transaction':
      return 'Units Per Transaction';
    case 'avg_unit_price':
      return 'Average Unit Price';
    case 'pct_discount':
      return 'Percent Discount';
    default:
      return 'Ticket Size';
  }
};

export const intervalDisplayName: Record<string, string> = {
  lastWeek: 'last week',
  lastMonth: 'last month',
  lastQuarter: 'last quarter',
  lastYear: 'last year',
};

export const describeChange = (
  percentageChange: number,
  interval: string,
  kpiId: string
): string => {
  // Normalize the intervals for display

  // KPI-specific descriptions for the change
  const descriptionMap: Record<
    string,
    { positive: string; negative: string; same: string }
  > = {
    ticket_size: {
      positive: 'larger ticket size than',
      negative: 'smaller ticket size than',
      same: 'no changes in ticket size since',
    },
    transactions_per_hour: {
      positive: 'more transactions per hour than',
      negative: 'fewer transactions per hour than',
      same: 'no changes in transactions per hour since',
    },
    units_per_transaction: {
      positive: 'more units per transaction than',
      negative: 'fewer units per transaction than',
      same: 'no changes in units per transaction since',
    },
    avg_unit_price: {
      positive: 'higher average unit price than',
      negative: 'lower average unit price than',
      same: 'no changes in average unit price since',
    },
    pct_discount: {
      positive: 'higher discount percentage than',
      negative: 'lower discount percentage than',
      same: 'no changes in discount percentage since',
    },
  };

  // Select the appropriate description based on the percentage change and kpiId
  const changeDescription =
    percentageChange > 0
      ? descriptionMap[kpiId].positive
      : percentageChange < 0
      ? descriptionMap[kpiId].negative
      : descriptionMap[kpiId].same; // Handle the case when percentageChange is 0

  // Construct and return the final message
  return `${changeDescription} ${intervalDisplayName[interval]}`;
};

export const formatKpiWithSymbol = (kpiName: string, stat: number) => {
  const fixedValue = stat ? toFixedIfDecimal(stat) : 0;

  switch (kpiName) {
    case 'ticket_size':
    case 'avg_unit_price':
    case 'Average Unit Price':
    case 'Ticket Size':
      return `$${fixedValue}`;
    case 'units_per_transaction':
    case 'transactions_per_hour':
    case 'Units Per Transaction':
    case 'Transactions Per Hour':
      return `${fixedValue}`;
    case 'pct_discount':
    case 'Percent Discount':
      return `${fixedValue}%`;
    default:
      return `$${fixedValue}`;
  }
};

export const dateFilterOptions = [
  {
    value: 'latest',
    label: 'Latest',
  },
  {
    value: 'lastWeek',
    label: 'Last Week',
  },
  {
    value: 'lastMonth',
    label: 'Last Month',
  },
  {
    value: 'lastQuarter',
    label: 'Last Quarter',
  },
  {
    value: 'lastYear',
    label: 'Last Year',
  },
];

export const kpis = [
  {
    kpiName: 'Ticket Size',
    kpiId: 'ticket_size',
  },
  {
    kpiName: 'Transactions Per Hour',
    kpiId: 'transactions_per_hour',
  },
  {
    kpiName: 'Units Per Transaction',
    kpiId: 'units_per_transaction',
  },
  {
    kpiName: 'Average Unit Price',
    kpiId: 'avg_unit_price',
  },
  {
    kpiName: 'Percent Discount',
    kpiId: 'pct_discount',
  },
];

export const formatLastUpdatedDate = (updatedDate: string) => {
  if (!updatedDate) return '';

  const date = new Date(updatedDate);
  return `${date.getDate()}, ${date.toLocaleString('default', {
    month: 'long',
  })} ${date.getFullYear()}`;
};
