import * as React from 'react';

export default (
  <g fill="none">
    <path
      d="M45 49c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z"
      fill="#FCBEC9"
    />
    <path
      d="M17 23c0-4.889 4.491-11 12.999-11C38.006 12 43 18.111 43 23"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.997 51H9.003C8.449 51 8 51.399 8 51.89v1.22c0 .491.449.89 1.003.89h41.994c.554 0 1.003-.399 1.003-.89v-1.22c0-.491-.449-.89-1.003-.89z"
      stroke="#205072"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 33v-3.5h34V33M15 24h30M17 25v4M22 25v4M27 25v4M33 25v4M43 25v4M38 25v4M49 50.5v-17H11v17"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 50.5V40.1c0-.867.6-2.6 3-2.6s3 1.733 3 2.6v10.4M27 50.5V40.1c0-.867.6-2.6 3-2.6s3 1.733 3 2.6v10.4"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 20.5L30 26l4.5-4 3-3.5.5-4-1.5-4.5L32 7l-5 .5-4 3.5-1 5.5 1.5 4z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 7.2c-4.08 0-7.389 3.447-7.389 7.699 0 2.426 1.41 4.694 3.383 6.476l3.633 3.28a.553.553 0 00.746 0l3.633-3.28c1.973-1.782 3.383-4.05 3.383-6.476 0-4.252-3.308-7.7-7.389-7.7zm-9.5 7.699C20.5 9.432 24.753 5 30 5s9.5 4.432 9.5 9.899c0 3.329-1.91 6.15-4.111 8.138l-3.633 3.28a2.6 2.6 0 01-3.512 0l-3.633-3.28c-2.2-1.988-4.111-4.81-4.111-8.138z"
      fill="#205072"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.17 12.342c.44.455.44 1.194 0 1.65l-4.5 4.666a1.097 1.097 0 01-1.59 0l-2.25-2.333a1.198 1.198 0 010-1.65 1.097 1.097 0 011.59 0l1.455 1.508 3.705-3.841a1.097 1.097 0 011.59 0z"
      fill="#205072"
    />
    <path
      d="M37 50.5V40.1c0-.867.6-2.6 3-2.6s3 1.733 3 2.6v10.4"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);
