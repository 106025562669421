import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Papa from 'papaparse';
import fileSaver from 'file-saver';

import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Learner Name',
  },
];

const loadingState = {
  courses: true,
  exporting: false,
  downloading: false,
  item: '',
  course: true,
};

let learnersRequestController: AbortController | undefined;

const GroupCourseLearnerReportGrid = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [course, setCourse] = useState({
    name: '',
    category: '',
  });
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const params = useParams();

  const groupId = params.id || '';
  const courseId = params.courseId || '';

  const getCourse = async () => {
    try {
      if (courseId) {
        const { response } = await callService({
          resource: httpRoutes.courses.getCourseById(courseId),
        });

        if (response) {
          setCourse({
            name: response.name,
            category: response.courseCategory.name,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, course: false }));
    }
  };

  const getLearners = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (learnersRequestController) {
      learnersRequestController.abort();
    }

    try {
      learnersRequestController = new AbortController();
      if (groupId && courseId) {
        const { response } = await callService({
          resource:
            httpRoutes.reporting.courseAssignment.getGroupLearnerCourseReport({
              controller: learnersRequestController,
              courseId,
              isAdmin: true,
              groupId,
              params: { ...state },
              filters: filters,
            }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          learnersRequestController = undefined;
          setLoading((prevState) => ({ ...prevState, courses: false }));
        }
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (groupId && courseId) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource:
            httpRoutes.reporting.courseAssignment.exportGroupLearnerCourseReport(
              {
                courseId,
                groupId,
                params: { ...state },
                filters: filters,
              }
            ),
          successMessage: 'Export complete!',
        });

        if (response) {
          const formattedCsv = response.map((item: any) => {
            const {
              firstName,
              lastName,
              dateAssigned,
              dueDate,
              status,
              start,
              end,
              score,
            } = item;

            return {
              'Leaner Name': `${firstName} ${lastName}`,
              'Date Assigned': dateAssigned
                ? new Date(dateAssigned).toLocaleString()
                : '',
              'Due Date': dueDate ? new Date(dueDate).toLocaleString() : '',
              Status: status,
              'Start Date': start ? new Date(start).toLocaleString() : '',
              'End Date': end ? new Date(end).toLocaleString() : '',
              Score: score,
            };
          });

          const csv = Papa.unparse(formattedCsv);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(
            csvData,
            `${course.name} - Course Assignment Report.csv`
          );

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Learner Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.75,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.firstName} {params.row.lastName}
          </>
        );
      },
    },
    {
      headerName: 'Date Assigned',
      field: 'dateAssigned',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.dateAssigned) {
          return <>{new Date(params.row.dateAssigned).toLocaleString()}</>;
        }
        return <>-</>;
      },
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.dueDate) {
          return <>{new Date(params.row.dueDate).toLocaleString()}</>;
        }
        return <>-</>;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: 'Start Date',
      field: 'start',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.start) {
          return <>{new Date(params.row.start).toLocaleString()}</>;
        }
        return <>-</>;
      },
    },
    {
      headerName: 'Complete Date',
      field: 'end',
      sortable: true,
      filterable: false,
      type: 'date',
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.end) {
          return <>{new Date(params.row.end).toLocaleString()}</>;
        }
        return <>-</>;
      },
    },
    {
      headerName: 'Score',
      field: 'score',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.25,
    },
  ];

  useEffect(() => {
    getLearners();
  }, [filters, state]);

  useEffect(() => {
    getCourse();
  }, [courseId]);

  return (
    <PageContainer>
      <Box>
        {loading.course ? (
          <Skeleton variant="rectangular" height={80} width="50%" />
        ) : (
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '500',
                fontSize: matchUpXS ? '12px' : '24px',
                lineHeight: '130%',
                color: '#162738',
              }}
            >
              {course.category}
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchUpXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {course.name}
            </Box>
          </Box>
        )}
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleExport={exportGroups}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          loading={loading.courses}
        />
      </Box>
    </PageContainer>
  );
};

const GroupCourseLearnerReport = () => (
  <ControlledDataGrid>
    <GroupCourseLearnerReportGrid />
  </ControlledDataGrid>
);

export default GroupCourseLearnerReport;
