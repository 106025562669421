import React, { useState, useEffect } from 'react';

import DatePicker from 'components/datePicker';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  TextField,
  Button,
  Grid,
  Skeleton,
  useMediaQuery,
} from '@mui/material';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import GroupCourseAssignmentTable from './GroupCourseAssignmentTable';

type AssignmentType = {
  name: string;
  assignmentType: string;
  groupCourseAssignmentsDetails: any[];
  assignmentDate: Date;
};

const today = new Date();

const Assignment = ({ assignmentId }: { assignmentId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<AssignmentType>({
    name: '',
    assignmentType: '',
    groupCourseAssignmentsDetails: [],
    assignmentDate: today,
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  useEffect(() => {
    const getItem = async () => {
      setIsLoading(true);
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getSummaryDetails(assignmentId),
      });

      if (response) {
        const { name, assignmentDate, assignmentType, groupCourseAssignments } =
          response;

        const groupCourseAssignmentsDetails = groupCourseAssignments.map(
          (groupCourseAssignment: any) => {
            return {
              group: groupCourseAssignment.group.name,
              course: groupCourseAssignment.course.name,
              learners: groupCourseAssignment.userAssignments.length,
              daysToComplete: groupCourseAssignment.daysToComplete,
            };
          }
        );

        setDefaultValues({
          name,
          assignmentType,
          assignmentDate: new Date(assignmentDate),
          groupCourseAssignmentsDetails,
        });
        setIsLoading(false);
      }
    };

    if (assignmentId) {
      getItem();
    }
  }, [assignmentId]);

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Skeleton
          variant="rectangular"
          height={matchDownSM ? '550px' : '620px'}
          width="100%"
        />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mb: matchDownSM ? 0 : 2,
        }}
      >
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: matchDownSM ? '20px' : '32px',
          }}
        >
          Review your assignment
        </Box>
      </Box>
      <Box>
        <TextField
          label="Name Assignment (read only)"
          fullWidth
          value={defaultValues.name}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
      <Box>
        <TextField
          label="Assignment Type (read only)"
          fullWidth
          value={
            defaultValues.assignmentType === 'CurrentAndFuture'
              ? 'Current And Future'
              : defaultValues.assignmentType
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ flex: 1 }}>
          <DatePicker
            label="Assignment date (read only)"
            value={defaultValues.assignmentDate}
            fullWidth
            readOnly
          />
        </Box>
      </Box>
      <Box>
        <GroupCourseAssignmentTable
          rows={defaultValues.groupCourseAssignmentsDetails}
        />
      </Box>
      <Grid container>
        <Button type="button" variant="contained" onClick={hideDialog}>
          Close
        </Button>
      </Grid>
    </Box>
  );
};

export default Assignment;
