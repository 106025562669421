import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import ManageCommunityBundlesForm, {
  ManageCommunityBundlesFormInput,
} from '../forms/ManageCommunityBundlesForm';

import httpRoutes from 'utils/httpRoutes';

const ManageBundles = ({
  onSuccess,
  groupId,
  groupName,
}: {
  onSuccess: VoidFunction;
  groupId: string;
  groupName: string;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const [isLoading, setIsLoading] = useState(false);
  const [bundles, setBundles] = useState<{ id: string; name: string }[]>([]);

  const getGroupBundles = async () => {
    setIsLoading(true);
    const { response } = await callService({
      resource: httpRoutes.groups.getGroupBundlesPaginated({
        groupId,
        params: { page: 0, limit: 500 },
        filters: new Map(),
      }),
    });

    if (response) {
      setBundles(
        response.items.map((bundle: any) => ({
          id: bundle.id,
          name: bundle.name,
        }))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGroupBundles();
  }, [groupId]);

  const onSubmit = async (values: ManageCommunityBundlesFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.groups.updateGroupBundles(values),
      successMessage: 'Communities updated successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <ManageCommunityBundlesForm
        groupId={groupId}
        groupName={groupName}
        onSubmit={onSubmit}
        bundles={bundles}
        loading={isLoading}
      />
    </div>
  );
};

export default ManageBundles;
