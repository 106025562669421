import { Box, Skeleton } from '@mui/material';

const CourseLoading = () => (
  <Box
    sx={{
      height: '390px',
      width: '100%',
      borderRadius: '12px',
      mt: 8,
    }}
  >
    <Skeleton variant="rounded" height="100%" sx={{ borderRadius: '12px' }} />
  </Box>
);

export default CourseLoading;
