import { useCallService } from 'hooks';

import Form, { CertificateFormInput } from '../forms/Form';

import resources from '../resources';

const Edit = ({
  onSuccess,
  defaultValues,
}: {
  onSuccess: VoidFunction;
  defaultValues: CertificateFormInput;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: CertificateFormInput) => {
    const { response } = await callService({
      resource: resources.update(values),
      successMessage: 'Certificate updated successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <Form defaultValues={defaultValues} onSubmit={onSubmit} />
    </div>
  );
};

export default Edit;
