import { Box } from '@mui/material';

const BlockedChip = ({
  untilDate,
  isLastRound,
}: {
  untilDate: string;
  isLastRound: boolean;
}) => {
  const date = new Date(untilDate);

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const text = isLastRound ? 'BLOCKED' : `BLOCKED until ${formattedDate}`;

  return (
    <Box sx={{ position: 'absolute', right: '5px', top: '5px' }}>
      <Box
        sx={{
          height: '20px',
          borderRadius: '30px',
          border: '1px solid #FF5572',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          px: 1,
        }}
      >
        <p
          style={{
            margin: 0,
            color: '#FF5572',
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '800',
            fontSize: '10px',
            lineHeight: '20px',
          }}
        >
          {text}
        </p>
      </Box>
    </Box>
  );
};

export default BlockedChip;
