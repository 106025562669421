import Box from '@mui/material/Box';
import * as React from 'react';

const Difference = ({ value }: { value: number | null }) => {
  if(value === null) {
    return null;
  }

  const color = value === 0 ? '#507DBC' : value > 0 ? '#7fd0b2' : '#FF5572';

  const symbol = value === 0 || value < 0 ? '' : '+';

  const formattedValue = value.toFixed(2);

  return (
    <Box
      sx={{
        fontFamily: 'Roboto',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '500',
        color: color,
      }}
    >
      {`${symbol}${formattedValue}`}
    </Box>
  );
};

export default Difference;
