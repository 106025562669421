import { Box, Typography } from '@mui/material';

interface ITitles {
  title: string;
  name: string;
  role: string;
}

const Titles = (props: ITitles) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Typography variant="h1">
          {props.title}Data Driven Learning Dashboard
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3">
          for {props.name} {props.role}
        </Typography>
      </Box>
    </>
  );
};

export default Titles;
