import React, { useEffect, useState } from 'react';
import Icon from 'icons';
import { lighten } from 'polished';
import { Avatar, Box, useMediaQuery, Grid } from '@mui/material';
import StatsBarLoading from './StatsBarLoading';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { useTheme } from '@mui/material/styles';

let statsRequestController: AbortController | undefined;

const StatsBar = ({
  courseId,
  startDate,
  endDate,
  state,
  name,
  setLoading,
  loading,
}: {
  courseId: string;
  startDate: Date | null;
  endDate: Date | null;
  state?: string;
  name?: string;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
}) => {
  const theme = useTheme();
  const matchUpXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const [stats, setStats] = useState(undefined);

  const statsItems = [
    {
      icon: 'house',
      label: 'Communities',
      color: '#FF5572',
      field: 'totalCommunities',
    },
    {
      icon: 'three-users',
      label: startDate && endDate ? 'new learners' : 'learners',
      color: '#12D1D0',
      field: 'totalLearners',
    },
    {
      icon: 'clock',
      label: 'Hours of engagement',
      color: '#56C596',
      field: 'hoursOfEngagement',
    },
    {
      icon: 'score',
      label: 'average quiz score',
      color: '#199CC5',
      field: 'averageScore',
    },
  ];

  const getStats = async () => {
    try {
      if (statsRequestController) statsRequestController.abort();
      if (courseId) {
        statsRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getCreatorCourseStats(
            courseId,
            startDate,
            endDate,
            state,
            name,
            statsRequestController
          ),
        });

        if (response) {
          setStats(response);
          statsRequestController = undefined;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState: any) => ({ ...prevState, stats: false }));
    }
  };

  useEffect(() => {
    getStats();
  }, [courseId, loading.stats === true]);

  return (
    <Box>
      {loading.stats ? (
        <Box
          sx={{
            borderRadius: '15px',
            backgroundColor: 'white',
            p: 4,
          }}
        >
          <StatsBarLoading />
        </Box>
      ) : (
        <Grid
          container
          rowGap={4}
          sx={{
            p: 3,
            borderRadius: '15px',
            backgroundColor: 'white',
            justifyContent: !matchUpXL ? 'center' : 'unset',
          }}
        >
          {statsItems.map((statItem) => (
            <Grid
              item
              key={statItem.label}
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
              xs={12}
              sm={4}
              md={4}
              xl={3}
            >
              <Box sx={matchUpXS ? { flex: 1 } : {}}>
                <Avatar
                  sx={{
                    backgroundColor: lighten(0.3, statItem.color),
                    opacity: '0.9',
                    width: 62,
                    height: 62,
                  }}
                >
                  <Icon
                    name={statItem.icon}
                    size="extraLarge"
                    fill={statItem.color}
                  />
                </Avatar>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  ...(matchUpXS && { flex: 1 }),
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '14px',
                    textTransform: 'uppercase',
                  }}
                >
                  {statItem.label}
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '40px',
                    lineHeight: '56px',
                  }}
                >
                  {stats && stats[statItem.field]
                    ? statItem.field === 'hoursOfEngagement'
                      ? parseFloat(stats[statItem.field]).toFixed(2)
                      : stats[statItem.field]
                    : 0}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default StatsBar;
