import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import AuthWrapper from '../../authentication/components/AuthWrapper';
import SignUpContent from '../../authentication/fragments/signup/SignUp';
import Authentication from '../../authentication/fragments/login/Authentication';
import RightDescriptionContent from '../../authentication/fragments/rightDescriptionContent';

const CommunityInvitation = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [group, setGroup] = useState<any>({});
  const [groupLoading, setGroupLoading] = useState(true);

  const { communityId = '' } = useParams();

  const { callService } = useCallService();

  const handleIsSignup = () => {
    setIsSignup((v) => !v);
  };

  const getGroup = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getOne(communityId),
      });

      if (response) {
        setGroup(response);
      }
    } finally {
      setGroupLoading(false);
    }
  };

  useEffect(() => {
    getGroup();
  }, []);

  return (
    <AuthWrapper
      leftContent={
        isSignup ? (
          <SignUpContent
            handleBackToLogin={handleIsSignup}
            redirectUrl={`community/invitation/${communityId}`}
          />
        ) : (
          <Authentication
            handleBackToLogin={handleIsSignup}
            redirectUrl={`community/invitation/${communityId}`}
          />
        )
      }
      rightContent={
        <RightDescriptionContent
          isPrivate={group && group.private}
          communityLoading={groupLoading}
          communityName={group ? group.name : ''}
        />
      }
    />
  );
};

export default CommunityInvitation;
