import { FieldValues, UseControllerProps } from 'react-hook-form';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import React, { FC } from 'react';

interface DateTimePickerProps {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
}

export type DateTimeFormFieldProps<T extends FieldValues> =
  DateTimePickerProps & UseControllerProps<T>;

const DateTimePicker: FC<DateTimePickerProps> = ({
  label,
  value,
  onChange,
  ...props
}) => {
  const handleChange = (newValue: Date | null) => {
    onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        label={label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} {...props} />}
      />
    </LocalizationProvider>
  );
};

DateTimePicker.displayName = 'Date Time Picker';

export default DateTimePicker;
