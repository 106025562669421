import React from 'react';

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import DatePicker from 'components/datePicker';
import { FormControl, FormHelperText, Box } from '@mui/material';

interface DateProps {
  label: string;
  disabled?: boolean;
  margin?: 'normal' | 'dense' | 'none';
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
  fullWidth?: boolean;
  errors?: any;
  disablePast?: boolean,
}

export type DateFormFieldProps<T extends FieldValues> = DateProps &
  UseControllerProps<T>;

const DateFormField = <T extends FieldValues>(props: DateFormFieldProps<T>) => {
  const {
    label,
    disabled = false,
    name,
    control,
    margin = 'normal',
    fullWidth = false,
    disablePast = false,
    errors,
  } = props;

  return (
    <FormControl margin={margin} fullWidth={fullWidth} error={Boolean(errors)}>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field }) => (
          <>
            <DatePicker
              {...field}
              disabled={disabled}
              onChange={field.onChange}
              value={field.value}
              label={label}
              error={Boolean(errors)}
              disablePast={disablePast}
            />
            <Box sx={{ height: '20px' }}>
              <FormHelperText error={Boolean(errors)}>
                {errors?.message}
              </FormHelperText>
            </Box>
          </>
        )}
      />
    </FormControl>
  );
};

export default DateFormField;
