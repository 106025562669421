import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent, Skeleton, useMediaQuery
} from '@mui/material';

import { dateFilterOptions, formatLastUpdatedDate } from '../utils';
import httpRoutes from '../../../../utils/httpRoutes';
import { useEffect, useState } from 'react';
import { useCallService } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import { last } from 'lodash';
import { useTheme } from '@mui/material/styles';

const SelectIntervalFilter = ({
  value,
  setValue,
}: {
  value: string;
  setValue: any;
}) => {
  const params = useParams();
  const { groupId = '', userId = '' } = params;

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const [updatedDate, setUpdatedDate] = useState<string>('');
  const [updatedDateLoading, setUpdatedDateLoading] = useState<boolean>(false);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  const getLastUpdatedDate = async () => {
    try {
      if (groupId || userId) {
        setUpdatedDateLoading(true);
        const { response } = await callService({
          resource: userId
            ? httpRoutes.ddl.performanceReport.getEmployeeLastUpdatedDate(
                groupId,
                userId
              )
            : httpRoutes.ddl.performanceReport.getStoreLastUpdatedDate(groupId),
        });

        if (response) {
          const date = formatLastUpdatedDate(response);
          setUpdatedDate(date);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setUpdatedDateLoading(false);
    }
  };

  useEffect(() => {
    getLastUpdatedDate();
  }, [groupId, userId]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Box
        sx={{
          fontFamily: 'Raleway',
          fontSize: '12px',
          lineHeight: '150%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'end',
          columnGap: 0.5
        }}
      >
        Last updated date
        {
          updatedDateLoading ? <Skeleton height="16px" width="115px" variant="rounded" /> : <span style={{ color: '#666', fontWeight: '600' }}>{updatedDate}</span>
        }
      </Box>
      <Box sx={{ width: '200px' }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              '&.Mui-focused': { color: '#56C596' },
            }}
          >
            Interval
          </InputLabel>
          <Select
            sx={{
              textAlign: 'center',
              background: 'white',
              borderRadius: '30px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
            }}
            value={value}
            label="Interval"
            onChange={handleChange}
          >
            {dateFilterOptions.map((dt) => (
              <MenuItem value={dt.value} key={dt.value}>
                {dt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SelectIntervalFilter;
