import { IChunkSizesStrategy, IChunkSizeStrategy } from './interfaces';
import { ChunkTypes } from './enum/ChunkTypes';
import { IAmountStrategy } from './strategies/AmountStrategy';
import { IPercentageStrategy } from './strategies/PercentageStrategy';

export class ChunkSizesStrategy implements IChunkSizesStrategy {
  private readonly _chunksizeStrategies: Record<string, IChunkSizeStrategy> =
    {};

  constructor(
    amountStrategy: IAmountStrategy,
    percentageStrategy: IPercentageStrategy
  ) {
    this._chunksizeStrategies[ChunkTypes.Amount] =
      amountStrategy as IChunkSizeStrategy;
    this._chunksizeStrategies[ChunkTypes.Percentage] =
      percentageStrategy as IChunkSizeStrategy;
  }

  public calculateChunkSize(
    name: string,
    promises: any[],
    chunks: number
  ): number {
    const strategy = this._chunksizeStrategies[name];
    if (!strategy) {
      return -1;
    }

    return strategy.execute(promises, chunks);
  }
}

export * from './strategies';
export * from './interfaces';
export { ChunkTypes } from './enum/ChunkTypes';
