import { RouteObject } from 'react-router-dom';

import {
  Login,
  SignUp,
  ForgotPassword,
  CompleteRegistration,
  ResetPassword,
  ValidateEmail,
} from 'features/authentication/pages';

import PageNotFound from 'components/PageNotFound';
import {
  ApproveUserRequest,
  DenyUserRequest,
} from 'features/communities/pages';

import CommunityInvitation from 'features/communities/pages/CommunityInvitation';
import CompleteValidation from 'features/authentication/pages/CompleteValidation';

const PublicRoutes: RouteObject[] = [
  {
    path: '/organization/:organizationId/dashboard/validate-email',
    element: <ValidateEmail />,
  },
  {
    path: '/organization/:organizationId/course/:courseReference/validate-email',
    element: <ValidateEmail />,
  },
  {
    path: '/organization/:organizationId/course/:courseReference/complete-validation/:token',
    element: <CompleteValidation />,
  },
  {
    path: '/organization/:organizationId/dashboard/complete-validation/:token',
    element: <CompleteValidation />,
  },
  {
    path: '/complete-registration/:invitationToken',
    element: <CompleteRegistration />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/invitation/:communityId',
    element: <CommunityInvitation />,
  },
  {
    path: '/community/login-invitation/:communityId',
    element: <CommunityInvitation />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/community/:communityId/approve-request/:userId',
    element: <ApproveUserRequest />,
  },
  {
    path: '/community/:communityId/deny-request/:userId',
    element: <DenyUserRequest />,
  },
  {
    path: '/health',
    element: <div>I am healthy!!</div>,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];

export default PublicRoutes;
