import { useState, useEffect } from 'react';
import { io, Socket} from 'socket.io-client';
import { REACT_APP_API_URL } from 'config/config';
import { getToken } from '../utils/authentication';
/**
 * Use socket.io with jwt authentication. The server will automatically
 * subscribe to channel by facilityId
 * Usage:
 *  const [socket] = useSocket();
 *   useEffect(() => {
 *       socket.on('EVENT_NAME', payload => {
 *           // do something
 *       });
 *   }, []);
 */
const useSocket = () => {
  const [socket, setSocket] = useState<Socket>(
    io(REACT_APP_API_URL, {
      transports: ['websocket'],
      auth: { token: getToken() },
    })
  );

  const onSuccessConnection = () => {
    console.log('Socket connection successfully!');
  };

  const onErrorConnection = (err: any) => {
    console.log(`connect_error due to ${err.message}`);
  };

  useEffect(() => {
    socket.on('connect', onSuccessConnection);
    socket.on('connect_error', onErrorConnection);

    return () => {
      socket.off('connect', onSuccessConnection);
      socket.off('connect_error', onErrorConnection);
    };
  }, [socket]);

  return { socket, setSocket};
};

export default useSocket;
