import { useCallService } from 'hooks';

import OrganizationForm, {
  OrganizationFormInput,
} from '../forms/OrganizationForm';

import resources from '../resources';

const Edit = ({
  onSuccess,
  defaultValues,
}: {
  onSuccess: VoidFunction;
  defaultValues: OrganizationFormInput;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: OrganizationFormInput) => {
    const { response } = await callService({
      resource: resources.organization.update(values),
      successMessage: 'Organization updated successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <OrganizationForm defaultValues={defaultValues} onSubmit={onSubmit} />
    </div>
  );
};

export default Edit;
