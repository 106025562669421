import { useState, useEffect } from 'react';

import { useCallService } from 'hooks';

import MultiStepForm from '../forms/MultiStepForm';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';

import httpRoutes from 'utils/httpRoutes';
//import { formatCourseFormValues } from '../utils';
import LoadingState from 'components/LoadingState';
import { Grid } from '@mui/material';

const Edit = ({
  onSuccess,
  courseId,
}: {
  onSuccess: (scormAlert: boolean) => void;
  courseId: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<CourseBuilderFormInput>();
  const { callService } = useCallService();

  const onSubmit = async (values: CourseBuilderFormInput) => {
    try {
      setIsSubmitting(true);

      const { response } = await callService({
        resource: httpRoutes.courseManagement.update(values),
        successMessage: 'Course updated successfully!',
      });

      if (response) {
        onSuccess(Boolean(values.courseFileChanged));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getItem = async () => {
      const { response } = await callService({
        resource: httpRoutes.courseManagement.getOne(courseId),
      });

      if (response) {
        const {
          courseMessage,
          courseStates,
          courseType,
          externalCourseId,
          ...otherValues
        } = response;

        const defaultValues = {
          ...otherValues,
          courseTypeName: courseType ? courseType.name : '',
          courseMessage,
          externalCourseId: externalCourseId ? externalCourseId : '',
          states: courseStates
            ? courseStates.map((item: any) => item.courseState)
            : [],
        };

        setDefaultValues(defaultValues);
        setIsLoading(false);
      }
    };

    getItem();
  }, [courseId]);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <MultiStepForm
        isSubmitting={isSubmitting}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isEdit={true}
      />
    </Grid>
  );
};

export default Edit;
