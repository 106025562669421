import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import KpiCard from '../kpiCard/KpiCard';

type EmployeeKpiCardProps = {
  kpiName: string;
  kpiId: string;
  interval: string;
};

const EmployeeKpiCard = ({ kpiId, kpiName, interval }: EmployeeKpiCardProps) => {
  const params = useParams();
  const { groupId, userId } = params;

  const { callService } = useCallService();

  const [scoreCard, setScoreCard] = useState<any>(null);
  const [scoreCardLoading, setScoreCardLoading] = useState(false);
  const [intervalLoading, setIntervalLoading] = useState(false);

  const getScoreCard = async () => {
    if (groupId && userId) {
      try {
        if (scoreCard === null) {
          // Initial load
          setScoreCardLoading(true);
        } else {
          // Interval change
          setIntervalLoading(true);
        }

        const { response } = await callService({
          resource: httpRoutes.ddl.performanceReport.getSingleEmployeeScoreCards({
            groupId,
            userId,
            interval,
            kpiId
          }),
        });

        if (response) {
          setScoreCard((prevScoreCard: any) => {
            if (!prevScoreCard) {
              return response;
            } else {
              return {
                ...prevScoreCard,
                percentageChange: response.percentageChange,
                rank: response.rank,
                intervalStat: response.intervalStat
              };
            }
          });
        }
      } finally {
        if (scoreCard === null) {
          // Initial load
          setScoreCardLoading(false);
        } else {
          // Interval change
          setIntervalLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    getScoreCard();
  }, [groupId, userId, interval]);

  return (
    <KpiCard
      isLoading={scoreCardLoading}
      intervalLoading={intervalLoading}
      kpiId={kpiId}
      kpiName={kpiName}
      percentageChange={scoreCard?.percentageChange}
      rank={scoreCard?.rank}
      total={scoreCard?.total}
      stat={scoreCard?.stat}
      intervalStat={scoreCard?.intervalStat}
      interval={interval}
      statType={scoreCard?.statType}
    />
  );
};

export default EmployeeKpiCard;
