import { useBusinessUnitState } from 'providers/BusinessUnitProvider/hooks/useBusinessUnitState';
import RightRestaurantDescriptionContent from './RightRestaurantDescriptionContent';
import RightCannabisDescriptionContent from './RightCannabisDescriptionContent';

const RightDescriptionContent = ({
  isPrivate = false,
  communityLoading = false,
  communityName,
}: {
  isPrivate?: boolean;
  communityLoading?: boolean;
  communityName?: string;
}) => {
  const { businessUnitState } = useBusinessUnitState();
  const { businessUnit } = businessUnitState;

  const rightDescriptionContent =
    businessUnit === 'restaurant' ? (
      <RightRestaurantDescriptionContent
        isPrivate={isPrivate}
        communityLoading={communityLoading}
        communityName={communityName}
      />
    ) : (
      <RightCannabisDescriptionContent
        isPrivate={isPrivate}
        communityLoading={communityLoading}
        communityName={communityName}
      />
    );

  return rightDescriptionContent;
};

export default RightDescriptionContent;
