import React, { useEffect, useState } from 'react';
import Icon from 'icons';
import { Avatar, Box, useMediaQuery, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { lighten } from 'polished';
import StatsLoading from './StatsLoading';

const statsItems = [
  {
    icon: 'house',
    label: 'Communities',
    color: '#FF5572',
    field: 'totalCommunities',
  },
  {
    icon: 'three-users',
    label: 'learners',
    color: '#12D1D0',
    field: 'totalLearners',
  },
];

const Stats = () => {
  const { callService } = useCallService();

  const [stats, setStats] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.reporting.group.getFacilitatorGroupReportStatsAsFacilitator(),
      });

      if (response) {
        setStats(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      {loading ? (
        <Box
          sx={{
            borderRadius: '15px',
            backgroundColor: 'white',
            p: 3,
          }}
        >
          <StatsLoading />
        </Box>
      ) : (
        <Grid
          container
          gap={4}
          sx={{
            p: 3,
            borderRadius: '15px',
            backgroundColor: 'white',
          }}
        >
          {statsItems.map((statItem) => (
            <Grid
              item
              key={statItem.label}
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
              xs={12}
            >
              <Box sx={{ flex: 1 }}>
                <Avatar
                  sx={{
                    backgroundColor: lighten(0.3, statItem.color),
                    opacity: '0.9',
                    width: 62,
                    height: 62,
                  }}
                >
                  <Icon
                    name={statItem.icon}
                    size="extraLarge"
                    fill={statItem.color}
                  />
                </Avatar>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '14px',
                    textTransform: 'uppercase',
                  }}
                >
                  {statItem.label}
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '40px',
                    lineHeight: '56px',
                  }}
                >
                  {stats ? stats[statItem.field] : ''}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Stats;
