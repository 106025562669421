import React from 'react';

import { useFormContext } from 'react-hook-form';

import * as Yup from 'yup';

import { Box, Grid, Typography } from '@mui/material';

import {
  CheckboxFormField,
  DateFormField,
  SelectFormField,
  TextFormField,
} from 'components/formFields';

import { SelectOptionProps } from 'components/formFields/types';

import userIncentivesOptions from '../../../hooks/userIncentivesOptions';
import { getRewardTypeName } from '../../../utils';
import LoadingState from 'components/LoadingState';

const rewardTypeIdValidation = Yup.string().test(
  'rewardTypeId-conditional-validation',
  'Reward type is required.',
  function (value: any) {
    // Custom validation function
    const { rewardAssigned } = this.parent;
    if (rewardAssigned && !value) {
      return false;
    }
    return true;
  }
);

export const secondValidationStep = Yup.object().shape({
  rewardAssigned: Yup.boolean(),
  rewardTypeId: rewardTypeIdValidation,
});

const Incentives = () => {
  const {
    isFetching,
    rewardTypes,
    completionRates,
    swagCompleteCoursesReceives,
    raffleAvailable,
    sampleCompleteCoursesReceives,
  } = userIncentivesOptions();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  if (isFetching) return <LoadingState />;

  const watchRewardTypeId = watch('rewardTypeId');
  const watchRewardAssigned = watch('rewardAssigned');

  const rewardTypeName = getRewardTypeName(rewardTypes, watchRewardTypeId);

  const isSwag = rewardTypeName === 'Swag';
  const isSample = rewardTypeName === 'Sample';
  const isRaffle = rewardTypeName === 'Raffle';

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid container xs={12} sm={12} mb={1}>
        <Grid item xs={12} mb={2}>
          <Typography variant="h6">Completion Reward</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxFormField
            name="rewardAssigned"
            label="Reward Assigned"
            control={control}
          />
        </Grid>
        <TextFormField
          name="rewardTypeName"
          control={control}
          label="rewardTypeName"
          margin="dense"
          sx={{ display: 'none' }}
        />
        <Grid item xs={12} sm={9}>
          <SelectFormField
            name="rewardTypeId"
            control={control}
            disabled={!watchRewardAssigned}
            withSelectedOption
            onChange={(option: SelectOptionProps) => {
              setValue('rewardTypeName', option.label);
            }}
            label="Reward Type"
            options={rewardTypes}
            errors={errors.rewardTypeId}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '200px' }}>
        {isSwag && (
          <>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h4">Swag</Typography>
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={12}>
                <TextFormField
                  name="swagName"
                  control={control}
                  label="Swag Type"
                  disabled={!watchRewardAssigned}
                  errors={errors.swagName}
                  placeholder="Type what the swag item is"
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1} sx={{ mb: '20px' }}>
              <Grid item xs={12} sm={6}>
                <SelectFormField
                  name="isSwagActive"
                  control={control}
                  disabled={!watchRewardAssigned}
                  label="Is swag available"
                  options={raffleAvailable}
                  errors={errors.isSwagActive}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateFormField
                  errors={errors.swagExpiresOn}
                  name="swagExpiresOn"
                  label="Expires on"
                  control={control}
                  margin={'dense'}
                  fullWidth={true}
                  disablePast={true}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <SelectFormField
                  name="swagType"
                  control={control}
                  label="1:1 Complete Course Receives"
                  withSelectedOption
                  disabled={!watchRewardAssigned}
                  options={swagCompleteCoursesReceives}
                  errors={errors.swagType}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={2}>
                <SelectFormField
                  name="swagCompletionThreshold"
                  control={control}
                  label="Select Swag Completion Rate %"
                  withSelectedOption
                  disabled={!watchRewardAssigned}
                  options={completionRates}
                  errors={errors.swagCompletionThreshold}
                  margin="dense"
                />
              </Grid>
            </Grid>
          </>
        )}

        {isSample && (
          <>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} mb={1}>
                <Typography variant="h4">Sampling</Typography>
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={12}>
                <TextFormField
                  name="sampleName"
                  control={control}
                  label="Sample Type"
                  disabled={!watchRewardAssigned}
                  errors={errors.sampleName}
                  placeholder="Type what the sample item is"
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1} sx={{ mb: '20px' }}>
              <Grid item xs={12} sm={6}>
                <SelectFormField
                  name="isSampleActive"
                  control={control}
                  disabled={!watchRewardAssigned}
                  label="Is sample available"
                  options={raffleAvailable}
                  errors={errors.isSampleActive}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateFormField
                  errors={errors.sampleExpiresOn}
                  name="sampleExpiresOn"
                  label="Expires on"
                  control={control}
                  margin={'dense'}
                  fullWidth={true}
                  disablePast={true}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <SelectFormField
                  name="sampleType"
                  control={control}
                  label="1:1 Complete Course Receives"
                  withSelectedOption
                  disabled={!watchRewardAssigned}
                  options={sampleCompleteCoursesReceives}
                  errors={errors.sampleType}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={2}>
                <SelectFormField
                  name="sampleCompletionThreshold"
                  control={control}
                  label="Select Sample Completion Rate %"
                  withSelectedOption
                  disabled={!watchRewardAssigned}
                  options={completionRates}
                  errors={errors.sampleCompletionThreshold}
                  margin="dense"
                />
              </Grid>
            </Grid>
          </>
        )}

        {isRaffle && (
          <>
            <Grid container>
              <Grid item xs={12} mb={1}>
                <Typography variant="h4">Raffling/Contests</Typography>
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={12}>
                <TextFormField
                  name="contestName"
                  control={control}
                  disabled={!watchRewardAssigned}
                  label="Contest Type"
                  errors={errors.contestName}
                  placeholder='Text box to write a small description of the “activation/contest"'
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <SelectFormField
                  name="isRaffleActive"
                  control={control}
                  disabled={!watchRewardAssigned}
                  label="Is raffle available"
                  options={raffleAvailable}
                  errors={errors.isRaffleActive}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateFormField
                  fullWidth={true}
                  errors={errors.expiresOn}
                  name="expiresOn"
                  label="Expires on"
                  margin={'dense'}
                  control={control}
                  disablePast={true}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default Incentives;
