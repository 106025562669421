import { IPromiseManager } from './interfaces';
import { IPromiseManagerFactory } from './interfaces/IPromiseManagerFactory';
import {
  AmountStrategy,
  ChunkSizesStrategy,
  PercentageStrategy,
} from './chunkSizeStrategy';
import { PromiseManager } from './PromiseManager';

export class PromiseManagerFactory implements IPromiseManagerFactory {
  public create(): IPromiseManager {
    const chunkSizesStrategy = new ChunkSizesStrategy(
      new AmountStrategy(),
      new PercentageStrategy()
    );
    const promiseManager = new PromiseManager(chunkSizesStrategy);
    return promiseManager;
  }
}
