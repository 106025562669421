import { Box } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useState, SyntheticEvent } from 'react';
import Button from '../../../components/buttons/Button';
import { SaveIcon } from '../../../material-icons';
import { useDialogDispatcher } from '../../../providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from '../../../hooks';
import httpRoutes from '../../../utils/httpRoutes';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useNavigate } from 'react-router-dom';

interface CourseReviewProps {
  utrId: string;
  isModal?: boolean;
  onSuccess?: () => void;
}

const CourseReview = ({
  utrId,
  isModal = true,
  onSuccess,
}: CourseReviewProps) => {
  const [reviewValue, setReviewValue] = useState<number | undefined>(undefined);
  const [reviewText, setReviewText] = useState<string | undefined>(undefined);
  const [missingReview, setMissingReview] = useState<boolean>(false);
  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const navigate = useNavigate();
  const {
    authState: { isAlias },
  } = useAuthState();

  const handleReviewValueChange = (
    event: SyntheticEvent,
    value: number | null
  ) => {
    setReviewValue(Number(value));
  };

  const handleReviewTextChange = (event: any) => {
    setReviewText(event.target.value);
  };

  const submitReview = async () => {
    if (isAlias) {
      navigate('/');
      return;
    }

    if (!reviewValue) {
      setMissingReview(true);
      return;
    }

    setMissingReview(false);

    try {
      const { response } = await callService({
        resource: httpRoutes.courses.review(utrId, reviewValue, reviewText),
      });

      if (response) {
        if (isModal) {
          hideDialog();
        }

        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px',
      }}
    >
      <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
        <Box
          sx={{
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '32px',
            fontWeight: '400',
          }}
        >
          What did you think of the course?
        </Box>
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <Rating
          name="simple-controlled"
          value={reviewValue}
          onChange={handleReviewValueChange}
          size="large"
        />
      </Box>
      <Box sx={{ width: '100%', marginBottom: '20px' }}>
        <Box sx={{ marginBottom: '8px', textAlign: 'left' }}>
          Tell us more (optional)
        </Box>
        <Box>
          <textarea
            value={reviewText}
            onChange={handleReviewTextChange}
            style={{
              width: '100%',
              height: '150px',
              boxSizing: 'border-box',
              padding: '8px',
            }}
          />
        </Box>
      </Box>
      {missingReview && (
        <Box sx={{ alignSelf: 'flex-end' }}>
          <Box sx={{ marginBottom: '8px', textAlign: 'right', color: 'red' }}>
            Please rate this course to continue
          </Box>
        </Box>
      )}
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Button
          label="Submit"
          variant="contained"
          color="primary"
          onClick={submitReview}
          startIcon={<SaveIcon />}
        />
      </Box>
    </Box>
  );
};

export default CourseReview;
