import { useState, useEffect } from 'react';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import {
  DeleteIcon,
  EditIcon,
  CommunityMemberIcon,
  AddIcon,
} from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import RemoveOrganizationMemberForm, {
  RemoveOrganizationMemberFormInput,
} from '../forms/RemoveOrganizationMemberForm';

import DeleteMember from '../dialogs/DeleteMember';

import CreateOrganizationMemberForm from '../forms/CreateOrganizationMemberForm';

import resources from '../resources';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Member Name',
  },
];

const MembersGrid = ({ organizationId }: { organizationId: string }) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const { showDialog, hideDialog } = useDialogDispatcher();

  const showCreateDialog = () => {
    showDialog({
      content: (
        <CreateOrganizationMemberForm
          organizationId={organizationId}
          onSuccess={getMembers}
        />
      ),
      size: 'sm',
    });
  };

  const showEditDialog = (values: any) => {
    const _memberFormData: RemoveOrganizationMemberFormInput = {
      id: values.userOrganizations[0].id,
    };

    const _metaData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    showDialog({
      content: (
        <RemoveOrganizationMemberForm
          onSuccess={getMembers}
          defaultValues={_memberFormData}
          metaData={_metaData}
        />
      ),
      size: 'sm',
    });
  };

  const showDeleteDialog = (values: any) => {
    let name = values.email;

    if (values.firstName && values.lastName) {
      name = values.firstName + ' ' + values.lastName;
    }

    showDialog({
      content: (
        <DeleteMember
          onSuccess={getMembers}
          hideDialog={hideDialog}
          name={name}
          userOrganization={values.userOrganizations[0]}
        />
      ),
      size: 'sm',
    });
  };

  const getMembers = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: resources.organization.getOrganizationUsersPaginated({
          organizationId: organizationId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        const _rows = response.items.map((item: any) => {
          return { ...item, ...item.userOrganizations[0] };
        });
        setGridData({
          rows: _rows,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers();
  }, [filters, state]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditDialog(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => showDeleteDialog(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      title="Organization Members"
      icon={<CommunityMemberIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Add Member"
          onClick={showCreateDialog}
          startIcon={<AddIcon />}
        />
      }
      isSecondary
      elevation={0}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const Members = ({ organizationId }: { organizationId: string }) => (
  <ControlledDataGrid>
    <MembersGrid organizationId={organizationId} />
  </ControlledDataGrid>
);

export default Members;
