import { Box } from '@mui/material';
import colorsByIndex from '../colors';

const CoursesCategoryList = ({ categories }: { categories: string[]}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {
        categories.map((category, index) => {
          return (
           <Box key={category} sx={{ display: 'flex', gap: 1}}>
             <Box
               sx={{
                 height: '10px',
                 width: '10px',
                 backgroundColor: colorsByIndex[index],
                 borderRadius: '10px',
               }}
             />
             <Box
               sx={{
                 fontFamily: 'Roboto',
                 fontWeight: '400',
                 fontSize: '12px',
                 lineHeight: '12px',
                 color: '#969696',
               }}
             >
               {category}
             </Box>
           </Box>
          );
        })
      }
    </Box>
  );
};

export default CoursesCategoryList;
