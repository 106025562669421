import { useState, useEffect } from 'react';

import { Checkbox } from '@mui/material';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import {
  DeleteIcon,
  EditIcon,
  CommunityMemberIcon,
  AddIcon,
} from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import CreateCommunityMemberForm from '../forms/CreateCommunityMemberForm';
import UpdateCommunityMemberForm, {
  UpdateCommunityMemberFormInput,
} from '../forms/UpdateCommunityMemberForm';

import DeleteMember from '../dialogs/DeleteMember';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Member Name',
  },
];

const MembersGrid = ({ groupId }: { groupId: string }) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const { showDialog, hideDialog } = useDialogDispatcher();

  const updateFacilitator = async (id: string, checked: boolean) => {
    try {
      await callService({
        resource: httpRoutes.groups.updateFacilitatorMember(id, checked),
        successMessage: 'Member updated successfully!',
        throwOnError: true,
      });

      const updateRows = gridData.rows;
      updateRows.forEach((row) => {
        if (row.id === id) {
          row.facilitator = checked;
        }
      });

      setGridData({
        rows: updateRows,
        totalRows: gridData.totalRows,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateManager = async (id: string, checked: boolean) => {
    try {
      await callService({
        resource: httpRoutes.groups.updateManagerMember(id, checked),
        successMessage: 'Member updated successfully!',
        throwOnError: true,
      });

      const updateRows = gridData.rows;
      updateRows.forEach((row) => {
        if (row.id === id) {
          row.manager = checked;
          if (checked) {
            row.facilitator = false;
            row.notify = false;
          }
        }
      });

      setGridData({
        rows: updateRows,
        totalRows: gridData.totalRows,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateGroupMemberNotification = async (
    id: string,
    checked: boolean
  ) => {
    try {
      await callService({
        resource: httpRoutes.groups.updateGroupMemberNotification(id, checked),
        successMessage: 'Member updated successfully!',
        throwOnError: true,
      });

      const updateRows = gridData.rows;
      updateRows.forEach((row) => {
        if (row.id === id) {
          row.notify = checked;
        }
      });

      setGridData({
        rows: updateRows,
        totalRows: gridData.totalRows,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const showCreateDialog = () => {
    showDialog({
      content: (
        <CreateCommunityMemberForm groupId={groupId} onSuccess={getMembers} />
      ),
      size: 'sm',
    });
  };

  const showEditDialog = (values: any) => {
    const _memberFormData: UpdateCommunityMemberFormInput = {
      id: values.userGroups[0].id,
      facilitator: values.userGroups[0].facilitator,
      notify: values.userGroups[0].notify,
      pending: values.userGroups[0].pending,
    };

    const _metaData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    showDialog({
      content: (
        <UpdateCommunityMemberForm
          onSuccess={getMembers}
          defaultValues={_memberFormData}
          metaData={_metaData}
        />
      ),
      size: 'sm',
    });
  };

  const showDeleteDialog = (values: any) => {
    let name = values.email;

    if (values.firstName && values.lastName) {
      name = values.firstName + ' ' + values.lastName;
    }

    showDialog({
      content: (
        <DeleteMember
          onSuccess={getMembers}
          hideDialog={hideDialog}
          name={name}
          userGroup={values.userGroups[0]}
        />
      ),
      size: 'sm',
    });
  };

  useEffect(() => {
    getMembers();
  }, [filters, state]);

  const getMembers = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getGroupUsersPaginated({
          groupId: groupId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        const _rows = response.items.map((item: any) => {
          return { ...item, ...item.userGroups[0] };
        });

        setGridData({
          rows: _rows,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Facilitator',
      field: 'facilitator',
      type: 'boolean',
      sortable: true,
      filterable: false,
      flex: 0.15,
      // ColumnComponent: (params: any) => {
      //   return (
      //     <Checkbox
      //       disabled={params.row.manager}
      //       checked={params.row.facilitator}
      //       onClick={(e: any) => {
      //         updateFacilitator(params.row.id, e.target.checked);
      //       }}
      //     />
      //   );
      // },
    },
    {
      headerName: 'Manager',
      field: 'manager',
      type: 'boolean',
      sortable: true,
      filterable: false,
      flex: 0.15,
      // ColumnComponent: (params: any) => {
      //   return (
      //     <Checkbox
      //       checked={params.row.manager}
      //       onClick={(e: any) => {
      //         updateManager(params.row.id, e.target.checked);
      //       }}
      //     />
      //   );
      // },
    },
    {
      headerName: 'Send Notifications',
      field: 'notify',
      type: 'boolean',
      sortable: false,
      filterable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return <>{params.row.notify ? 'Yes' : 'No'}</>;
      },
      // ColumnComponent: (params: any) => {
      //   return (
      //     <Checkbox
      //       disabled={params.row.manager}
      //       checked={params.row.notify}
      //       onClick={(e: any) => {
      //         updateGroupMemberNotification(params.row.id, e.target.checked);
      //       }}
      //     />
      //   );
      // },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton
              disabled={params.row.manager}
              onClick={() => showEditDialog(params.row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => showDeleteDialog(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      isSecondary
      elevation={0}
      // pageAction={
      //   <Button
      //     label="Add Member"
      //     onClick={showCreateDialog}
      //     startIcon={<AddIcon />}
      //   />
      // }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const ActiveMembers = ({ groupId }: { groupId: string }) => (
  <ControlledDataGrid>
    <MembersGrid groupId={groupId} />
  </ControlledDataGrid>
);

export default ActiveMembers;
