import * as React from 'react';

export default (
  <g fill="none">
    <path
      d="M16.5 31C23.956 31 30 24.956 30 17.5S23.956 4 16.5 4 3 10.044 3 17.5 9.044 31 16.5 31z"
      fill="#CFF4D2"
    />
    <path
      d="M44.29 22.36h-10.3c-.55 0-1-.45-1-1v-10.3c0-.55.45-1 1-1s1 .45 1 1v9.3h9.3c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#205072"
    />
    <path
      d="M30.38 50.2H15.2c-.55 0-1-.45-1-1V11.06c0-.55.45-1 1-1h18.79c.27 0 .52.11.71.29L45 20.65c.19.19.29.44.29.71v4.67c0 .55-.45 1-1 1s-1-.45-1-1v-4.25l-9.71-9.71H16.2V48.2h14.18c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#205072"
    />
    <path
      d="M27.381 37.89h-6.51c-.55 0-1-.45-1-1s.45-1 1-1h6.51c.55 0 1 .45 1 1s-.45 1-1 1zM23.701 43.93h-2.83c-.55 0-1-.45-1-1s.45-1 1-1h2.83c.55 0 1 .45 1 1s-.45 1-1 1zM30.001 31.84h-9.13c-.55 0-1-.45-1-1s.45-1 1-1h9.13c.55 0 1 .45 1 1s-.45 1-1 1zM30.001 25.8h-9.13c-.55 0-1-.45-1-1s.45-1 1-1h9.13c.55 0 1 .45 1 1s-.45 1-1 1zM26.541 19.76h-5.67c-.55 0-1-.45-1-1s.45-1 1-1h5.67c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#205072"
    />
    <path
      d="M41.549 55.04c-8.37 0-15.17-6.81-15.17-15.17s6.81-15.17 15.17-15.17 15.17 6.81 15.17 15.17-6.81 15.17-15.17 15.17zm0-28.35c-7.26 0-13.17 5.91-13.17 13.17s5.91 13.17 13.17 13.17 13.17-5.91 13.17-13.17-5.91-13.17-13.17-13.17z"
      fill="#205072"
    />
    <path
      d="M42 40a4 4 0 100-8 4 4 0 000 8zM49 51v-2.082C49 45.098 45.866 42 42 42M42 42c-3.856 0-7 3.163-7 7.065V52"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);
