import { Box, Skeleton } from '@mui/material';
import React from 'react';

const TotalCoursesLoading = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Skeleton variant="rectangular" height="100%" width="100%" />
  </Box>
);

export default TotalCoursesLoading;
