import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 11.5C19.5 10.6716 20.1716 10 21 10V10C21.8284 10 22.5 10.6716 22.5 11.5V11.5C22.5 12.3284 21.8284 13 21 13V13C20.1716 13 19.5 12.3284 19.5 11.5V11.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48069 13.8047L7.21565 17.4795C7.27628 17.7826 7.54244 18.0008 7.85158 18.0008C8.20976 18.0008 8.50011 17.7104 8.50011 17.3523V14.0008H10.5001V17.3523C10.5001 18.815 9.31432 20.0008 7.85158 20.0008C6.58908 20.0008 5.50208 19.1097 5.25449 17.8717L4.51953 14.1969L6.48069 13.8047Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 7.50919C21.5 4.80596 18.5659 3.12317 16.2327 4.48818L12.3344 6.76876C10.9556 7.57534 9.38709 8.00045 7.78976 8.00045H6C4.067 8.00045 2.5 9.56746 2.5 11.5005C2.5 13.4334 4.067 15.0005 6 15.0005H7.78569C9.38534 15.0005 10.9561 15.4268 12.3362 16.2356L16.2304 18.5177C18.5636 19.8851 21.5 18.2025 21.5 15.4981V7.50919ZM17.2426 6.21447C18.2425 5.62947 19.5 6.35067 19.5 7.50919V15.4981C19.5 16.6571 18.2415 17.3782 17.2416 16.7922L13.3474 14.5101C12.7573 14.1643 12.1387 13.8756 11.5 13.6465V9.35585C12.1375 9.12743 12.7551 8.83974 13.3443 8.49504L17.2426 6.21447ZM9.5 9.86669C8.93632 9.95541 8.36457 10.0005 7.78976 10.0005H6C5.17157 10.0005 4.5 10.672 4.5 11.5005C4.5 12.3289 5.17157 13.0005 6 13.0005H7.78569C8.36188 13.0005 8.935 13.0457 9.5 13.1349V9.86669Z"
    />
  </g>
);
