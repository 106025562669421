import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import DataGrid from 'components/dataGrid/components/DataGrid';
import { FilterState } from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';

import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { decimalToPercentage, formatPercentageSymbol } from 'utils/common';

import ThresholdTag from '../ThresholdTag';
import { useKpiDispatcher } from 'providers/KpiProvider/hooks/useKpiDispatcher';

let employeeRequestController: AbortController | undefined;

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Employee Name',
  },
];
// set columns
const columns = (normalizedMean: number, threshold: number) => [
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    ColumnComponent: (params: any) => {
      const { firstName, lastName } = params.row;

      const name = `${firstName} ${lastName}`;
      return (
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontSize: '16px',
            color: 'black',
          }}
        >
          {name}
        </Box>
      );
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    ColumnComponent: (params: any) => {
      const { percentSalesHouse } = params.row;

      return (
        <ThresholdTag
          normalizedMean={normalizedMean}
          threshold={threshold}
          value={percentSalesHouse}
        />
      );
    },
  },
  {
    headerName: 'House Brands %',
    field: 'ticketSize',
    sortable: true,
    ColumnComponent: (params: any) => {
      const { percentSalesHouse } = params.row;
      return <>{formatPercentageSymbol(percentSalesHouse)} </>;
    },
  },
  {
    headerName: 'Ticket Change',
    field: 'ticketSizeChange',
    sortable: true,
    ColumnComponent: (params: any) => {
      const { percentSalesHouseChange } = params.row;
      return <>{formatPercentageSymbol(percentSalesHouseChange)} </>;
    },
  },
  {
    headerName: 'Orders',
    field: 'totalOrdersPerDay',
    sortable: true,
    ColumnComponent: (params: any) => {
      const { totalOrdersPerDay } = params.row;
      return <>{totalOrdersPerDay || '-'} </>;
    },
  },
];

const EmployeeTableViewDataGrid = ({
  kpiId,
  groupId,
  groupLoading,
}: {
  kpiId: string;
  groupId: string;
  groupLoading: boolean;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [groupDailySalesStats, setGroupDailySalesStats] = useState<any>({
    threshold: 0,
    normalizedMean: 0,
  });
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const { getKpis } = useKpiDispatcher();

  const getEmployees = async () => {
    if (!groupLoading) {
      setLoading(true);
      if (employeeRequestController) {
        employeeRequestController.abort();
      }
      try {
        employeeRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.ddl.getEmployeePaginated({
            groupId,
            kpiId,
            controller: employeeRequestController,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          setGroupDailySalesStats({
            threshold: parseFloat(response.threshold),
            normalizedMean: parseFloat(response.normalizedMean),
          });
          employeeRequestController = undefined;
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getEmployees();
  }, [filters, state, groupId]);

  return (
    <DataGrid
      rows={gridData.rows}
      columns={columns(
        groupDailySalesStats.normalizedMean,
        groupDailySalesStats.threshold
      )}
      filters={filterModel}
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading}
    />
  );
};

const HouseBrandsEmployeeTableView = ({
  kpiId,
  groupId,
  groupLoading,
}: {
  kpiId: string;
  groupId: string;
  groupLoading: boolean;
}) => (
  <ControlledDataGrid>
    <EmployeeTableViewDataGrid
      kpiId={kpiId}
      groupId={groupId}
      groupLoading={groupLoading}
    />
  </ControlledDataGrid>
);

export default HouseBrandsEmployeeTableView;
