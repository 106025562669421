import AuthWrapper from '../components/AuthWrapper';
import RightDescriptionContent from '../fragments/rightDescriptionContent';
import ForgotPasswordContent from '../fragments/forgotPassword/ForgotPassword';

const ForgotPassword = () => (
  <AuthWrapper
    leftContent={<ForgotPasswordContent />}
    rightContent={<RightDescriptionContent isPrivate={true} />}
  />
);

export default ForgotPassword;
