import React from 'react';

export default (
  <g fill="none">
    <circle cx={11.5} cy={11.5} r={10.5} stroke="#12D1D0" strokeWidth={2} />
    <circle cx={11.5} cy={11.5} r={5.5} stroke="#12D1D0" strokeWidth={2} />
    <path
      d="M5 20v15l6.5-5.5L18 35V20"
      stroke="#12D1D0"
      strokeWidth={2}
      strokeLinejoin="round"
    />
  </g>
);
