import { useNavigate } from 'react-router-dom';

import { Badge, Box } from '@mui/material';

import { LinkProps } from 'layout/MainLayout/LeftNavigationDrawer/types';
import DisplayText from 'components/DisplayText';
import Icon from 'icons';
import { usePendingMembersState } from 'providers/PendingMembersProvider/hooks/usePendingMembersState';

const selectedStyle = {
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: '30px',
  width: '100%',
  p: '10px',
  gap: 1,
  cursor: 'pointer',
};

const normalStyle = { display: 'flex', gap: 1, p: '10px', cursor: 'pointer' };

const LeftNavigationItemBody = ({
  item,
  handleSelectedOption,
  selectedOption,
  drawerIsOpen = false,
}: {
  item: LinkProps;
  handleSelectedOption: (param: string, isAdmin?: boolean) => void;
  selectedOption: string;
  drawerIsOpen?: boolean;
  hasNotifications?: boolean;
}) => {
  const router = useNavigate();
  const { link, icon, label } = item;

  const handleOnClick = (id: string, isAdmin?: boolean) => {
    handleSelectedOption(id, isAdmin);
    router(link);
  };
  return (
    <Box
      onClick={() => handleOnClick(link, item.isAdmin)}
      sx={
        selectedOption !== link
          ? normalStyle
          : drawerIsOpen
          ? selectedStyle
          : {
              ...selectedStyle,
              borderRadius: '0px',
              p: '10px 0 10px 10px',
            }
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          name={icon}
          size="medium"
          fill={selectedOption === link ? '#56C596' : 'white'}
        />
      </Box>
      {drawerIsOpen && (
        <Box
          sx={{
            color: selectedOption === link ? '#56C596' : 'white',
          }}
        >
          <DisplayText type="bodyMedium" variant="regular" text={label} />
        </Box>
      )}
    </Box>
  );
};

const LeftNavigationItem = ({
  item,
  handleSelectedOption,
  selectedOption,
  drawerIsOpen = false,
}: {
  item: LinkProps;
  handleSelectedOption: (param: string, isAdmin?: boolean) => void;
  selectedOption: string;
  drawerIsOpen?: boolean;
}) => {
  const { pendingMembersState } = usePendingMembersState();

  const dontShowBadge =
    (!pendingMembersState.hasNotifications && item.checkNotifications) ||
    !item.checkNotifications;

  return (
    <Box
      sx={{
        display: 'flex',
        p: drawerIsOpen ? '0px 30px 0px 20px' : 0,
        gap: 1,
      }}
    >
      {!dontShowBadge ? (
        <Badge
          key={`Badge_${item.label}`}
          color="error"
          variant="dot"
        >
          <LeftNavigationItemBody
            item={item}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
            drawerIsOpen={drawerIsOpen}
          />
        </Badge>
      ) : (
        <LeftNavigationItemBody
          item={item}
          handleSelectedOption={handleSelectedOption}
          selectedOption={selectedOption}
          drawerIsOpen={drawerIsOpen}
        />
      )}
    </Box>
  );
};

export default LeftNavigationItem;
