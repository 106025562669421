export const formatDate = (date?: Date | undefined, daysToAdd = 0) => {
  if (!date) return '';

  const originalDate = new Date(date);
  originalDate.setDate(originalDate.getDate() + daysToAdd);

  const year = originalDate.getFullYear();
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();

  return `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}/${year}`;
};
