import React from 'react';
import Button from 'components/atomic/Button';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';

import { CourseCategory } from 'enum/CourseCategory';
import { CourseType } from 'enum/CourseType';

const getButtonLabel = (status?: string, courseCategoryName?: string, courseTypeName?: string): string => {
  if((courseCategoryName === CourseCategory.Assessments && status === 'Completed') || (courseTypeName === CourseType.Assessments && status === 'Completed')) {
      return 'Completed';
  }

  if (status === 'In-Progress') {
    return 'Continue';
  }

  if (status === 'Completed') {
    return 'Completed, revisit Course';
  }

  return 'Start course';
};

const getButtonColor = (status?: string, courseCategoryName?: string, courseTypeName?: string): string => {
  if((courseCategoryName === CourseCategory.Assessments && status === 'Completed') || (courseTypeName === CourseType.Assessments && status === 'Completed')) {
    return 'linear-gradient(180deg, #56C596 0%, #16915D 100%)';
  }

  if (status === 'In-Progress') {
    return 'linear-gradient(180deg, #56C596 0%, #16915D 100%)';
  }

  if (status === 'Completed') {
    return 'linear-gradient(180deg, #113145 0%, #113145 131.25%)';
  }

  return 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)';
};

const CourseCardButton = ({
  courseName,
  status,
  handleOnClick,
  progress,
  canStartCourse,
  courseCategoryName,
  courseTypeName,
}: {
  courseName?: string;
  status?: string;
  handleOnClick: () => void;
  progress?: number;
  canStartCourse: boolean;
  courseCategoryName?: string;
  courseTypeName?: string;
}) => {
  return (
    <Button
      dataId={courseName}
      disabled={!canStartCourse}
      sx={{
        width: '100%',
        borderRadius: '30px',
        height: '48px',
        background: getButtonColor(status, courseCategoryName, courseTypeName),
      }}
      startIcon={
        status === 'In-Progress' ? (
          <CircularProgressWithLabel variant="determinate" value={progress} />
        ) : null
      }
      variant="contained"
      onClick={handleOnClick}
    >
      {getButtonLabel(status, courseCategoryName, courseTypeName)}
    </Button>
  );
};

export default CourseCardButton;
