import * as React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import Dablicator from 'assets/images/icons/dablicator-logo.svg';
import Dogwalkers from 'assets/images/icons/dogwalkers-logo.svg';
import NineteenOhSix from 'assets/images/icons/1906-logo.svg';
import CrescoLabs from 'assets/images/icons/cresco-labs-logo.svg';
import Incredibles from 'assets/images/icons/incredibles.svg';
import Rythm from 'assets/images/icons/rythm.svg';
import Verano from 'assets/images/icons/verano.svg';
import GrowSalesMisc from 'assets/images/icons/grow-sales-misc.svg';

const RightCannabisDescriptionContent = ({
  isPrivate = false,
  communityLoading = false,
  communityName,
}: {
  isPrivate?: boolean;
  communityLoading?: boolean;
  communityName?: string;
}) => {
  return (
    <>
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="60px"
          width="100%"
          sx={{ mb: 3 }}
        />
      ) : (
        communityName && (
          <Box
            mb={3}
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontSize: '26px',
              lineHeight: '30px',
              textAlign: 'center',
            }}
          >
            Welcome to the{' '}
            <span style={{ fontWeight: '600' }}>{communityName}</span>{' '}
            community!
          </Box>
        )
      )}
      <Box
        sx={{
          color: '#162738',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: communityName ? '20px' : '32px',
          lineHeight: '45px',
        }}
      >
        Join tens of thousands of cannabis professionals advancing their
        knowledge and skill sets through Seed Talent!
      </Box>
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="166px"
          width="100%"
          sx={{ mb: 3, mt: 2 }}
        />
      ) : isPrivate ? (
        <>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '32px',
              mt: 2,
            }}
          >
            Seed Talent centralizes your professional development for all
            compliance, company info, cannabis foundations, and brand training
            directly from the top brands you sell today!
          </Box>
          <Grid
            container
            rowSpacing={1}
            mt={2}
            mb={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} sm={4} md={2} lg={4}>
              <img height="70px" src={Dablicator} alt="Dablicator" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={4}>
              <img height="70px" src={Dogwalkers} alt="Dogwalkers" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={4}>
              <img height="70px" src={NineteenOhSix} alt="NineteenOhSix" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={3}>
              <img height="70px" src={CrescoLabs} alt="CrescoLabs" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={3}>
              <img height="70px" src={Incredibles} alt="Incredibles" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={3}>
              <img height="70px" src={Rythm} alt="Rythm" />
            </Grid>
            <Grid item xs={6} sm={4} md={2} lg={3}>
              <img height="70px" src={Verano} alt="Verano" />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            color: '#162738',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '32px',
            my: 2,
          }}
        >
          Stay up to date on the latest in cannabis and product foundations
          through Seed Talent’s extensive library of brand and foundational
          content!
        </Box>
      )}
      <Box
        sx={{
          color: '#162738',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '18px',
          lineHeight: '32px',
        }}
      >
        Earn rewards from brands all while learning to be better stewards for
        your customers and advancing your Canna-Career!
      </Box>
      <Grid container mt={2}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <img height="150px" src={GrowSalesMisc} alt="Seed Talent" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '31px',
            }}
          >
            Grow sales, skillsets, and passion for the plant all in one spot!
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RightCannabisDescriptionContent;
