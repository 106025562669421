import React from 'react';
import SvgIcon from './SvgIcon';

export default function createSvgIcon(path, name, svgProps = {}) {
    const Component = React.memo(
        React.forwardRef((props, ref) => (
            <SvgIcon name={name} ref={ref} {...svgProps} {...props}>
                {path}
            </SvgIcon>
        ))
    );

    Component.displayName = `${name}Icon`;

    return <Component />;
}
