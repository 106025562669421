import { FC, useEffect, useState } from 'react';

import { Button, Grid, Tooltip, Typography } from '@mui/material';

import * as Yup from 'yup';

import { FieldError, useFormContext } from 'react-hook-form';

import {
  TextFormField,
  SelectFormField,
  AutoCompleteFormField,
  NumberFormField,
  MultipleSelectCheckmarksFormField,
  TagSelectFormField,
  DateFormField,
  AsyncSelectFormField,
  RadioFormField,
  FileFormField,
  CheckboxFormField,
} from 'components/formFields';

import Dropzone from 'components/dropzone/DropzoneImage';
import { SelectOptionProps } from 'components/formFields/types';

import { getStatesList } from 'dataSets/states';

import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

import { filterPreRequisiteOptions } from '../../../utils';
import LoadingState from 'components/LoadingState';
import useCourseOptions from 'features/course-admin/hooks/useCourseOptions';
import { CourseInfoProps } from 'types/courseTypes/CourseInfoProps';
import { IconButton } from 'components/buttons';
import { EditIcon } from 'material-icons';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import EditExternalCourse from 'features/course-admin/dialogs/EditExternalCourse';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const firstValidationStep = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  reference: Yup.string().nullable(),
  archived: Yup.boolean().nullable(),
  description: Yup.string().required('A description is required').max(1024),
  imageUrl: Yup.string().nullable(),
  url: Yup.string().nullable(),
  prerequisiteId: Yup.string().nullable(),
  contentCreatorId: Yup.string().nullable(),
  rewardId: Yup.string().nullable(),
  certificateId: Yup.string().nullable(),
  searchType: Yup.string().nullable(),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        label: Yup.string().required(),
        newValue: Yup.boolean().nullable(),
      })
    )
    .nullable(),
  estimatedHours: Yup.number().min(0).nullable(true).notRequired(),
  customerId: Yup.string().nullable(),
  externalCourseId: Yup.string().nullable(),
  courseTypeId: Yup.number().required('Please select a course type'),
  courseCategoryId: Yup.number().required('Please select a course category'),
  organizationId: Yup.string().required(
    'Please select an organization for this course'
  ),
  kpiId: Yup.string().nullable(),
  revisionDate: Yup.date()
    .nullable()
    .when('isRevision', {
      is: 'true',
      then: Yup.date()
        .required('Date is required')
        .min(today, 'Date must be later than today'),
      otherwise: Yup.date().nullable(),
    }),
});

const CourseInfo: FC<CourseInfoProps> = ({
  setFiles,
  imageUrl,
  isEdit = false,
  courseSourceId,
  courseBuilderId,
}) => {
  const {
    courseTypes,
    courseCategories,
    rewards,
    courses,
    certificates,
    kpis,
    isFetching,
    getCourseTags,
    getOrganizations,
  } = useCourseOptions();

  const { callService } = useCallService();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const [defaultPrerequisiteLoading, setDefaultPrerequisiteLoading] =
    useState(false);
  const [defaultCertificateLoading, setDefaultCertificateLoading] =
    useState(false);
  const [defaultOrganizationLoading, setDefaultOrganizationLoading] =
    useState(false);

  const [defaultPrerequisite, setDefaultPrerequisite] = useState<any>(null);
  const [defaultCertificate, setDefaultCertificate] = useState<any>(null);
  const [defaultOrganization, setDefaultOrganization] = useState<any>(null);
  const { showDialog, hideDialog } = useDialogDispatcher();

  const prerequisiteId = watch('prerequisiteId');
  const certificateId = watch('certificateId');
  const organizationId = watch('organizationId');

  useEffect(() => {
    const getPrerequisiteDefault = () => {
      try {
        if (prerequisiteId) {
          setDefaultPrerequisiteLoading(true);
          const prerequisite = courses.find(
            (item: any) => item.value === prerequisiteId
          );

          if (prerequisite) {
            setDefaultPrerequisite({
              value: prerequisite.value,
              label: prerequisite.label,
            });
            setDefaultPrerequisiteLoading(false);
          }
        }
      } catch (e) {
        setDefaultPrerequisiteLoading(false);
      }
    };

    getPrerequisiteDefault();
  }, [courses, prerequisiteId]);

  useEffect(() => {
    const getDefaultCertificate = () => {
      try {
        if (certificateId) {
          setDefaultCertificateLoading(true);
          const certificate = certificates.find(
            (item: any) => item.value === certificateId
          );

          if (certificate) {
            setDefaultCertificate({
              value: certificate.value,
              label: certificate.label,
            });
            setDefaultCertificateLoading(false);
          }
        }
      } catch (e) {
        setDefaultCertificateLoading(false);
      }
    };

    getDefaultCertificate();
  }, [certificates, certificateId]);

  useEffect(() => {
    const getDefaultOrganization = async () => {
      try {
        if (organizationId) {
          const { response } = await callService({
            resource: httpRoutes.organizations.getOne(organizationId),
          });

          if (response) {
            setDefaultOrganization({ id: response.id, label: response.name });
            setDefaultOrganizationLoading(false);
          }
        }
      } catch (e) {
        setDefaultOrganizationLoading(false);
      }
    };

    getDefaultOrganization();
  }, [organizationId]);

  const showPreviewDialog = (courseId: string) => {
    showDialog({
      content: (
        <EditExternalCourse courseId={courseId} hideDialog={hideDialog} />
      ),
      fullScreen: true,
    });
  };

  if (
    defaultPrerequisiteLoading ||
    defaultCertificateLoading ||
    defaultOrganizationLoading ||
    isFetching
  )
    return <LoadingState />;

  const watchCourseType = watch('courseTypeName', '');
  const watchCourseTypeId = watch('courseTypeId', undefined);
  const watchCourseRevision = watch('isRevision', '');

  const isAssessment = watchCourseType === 'Assessments';
  const is360Training = watchCourseType === '360 Training';

  const isRevision = watchCourseRevision.toString() == 'true';

  const filteredPreRequisites = filterPreRequisiteOptions(
    isAssessment,
    courses,
    watchCourseTypeId
  );

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        columnSpacing={1}
      >
        <Tooltip
          placement="bottom-start"
          title="For best results, the image should cover entire area."
        >
          <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Dropzone
              maxFiles={1}
              maxSize={5000000}
              handleAcceptedFiles={(acceptedFiles: File[]) => {
                setFiles(acceptedFiles);
              }}
              defaultValue={imageUrl}
              helperText="Drag and drop the main image here."
            />
          </Grid>
        </Tooltip>

        <TextFormField
          name="courseTypeName"
          control={control}
          label="courseTypeName"
          margin="dense"
          sx={{ display: 'none' }}
        />
        <Grid item xs={12} mt={1}>
          <Grid item xs={12} md={12}>
            <TextFormField
              name="name"
              control={control}
              label="Course Name*"
              errors={errors.name}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFormField
              name="description"
              control={control}
              label="Description*"
              errors={errors.description}
              margin="dense"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1} mb={3}>
        <Grid item xs={12} md={12} mb={1}>
          {courseBuilderId ? (
            <Button
              component="label"
              variant="outlined"
              startIcon={<EditIcon />}
              sx={{ marginRight: '1rem' }}
              onClick={() => showPreviewDialog(courseBuilderId)}
            >
              Edit course content
            </Button>
          ) : (
            <FileFormField
              name="courseFile"
              control={control}
              label={isEdit ? 'Update Course File' : 'Course File'}
              margin="dense"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="courseTypeId"
            control={control}
            label="Course Type*"
            withSelectedOption
            onChange={(option: SelectOptionProps) => {
              setValue('courseTypeName', option.label);
            }}
            options={courseTypes}
            disabled={isFetching}
            errors={errors.courseTypeId}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="courseCategoryId"
            control={control}
            label="Course Category*"
            disabled={isFetching}
            options={courseCategories}
            errors={errors.courseCategoryId}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <AutoCompleteFormField
            defaultValue={defaultPrerequisite}
            name="prerequisiteId"
            control={control}
            label="Prerequisite"
            options={filteredPreRequisites}
            errors={errors.prerequisiteId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteFormField
            defaultValue={defaultCertificate}
            name="certificateId"
            control={control}
            label="Certificate"
            options={certificates}
            errors={errors.certificateId}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <NumberFormField
            name="estimatedHours"
            control={control}
            label="Educational Hours"
            errors={errors.estimatedHours}
            icon={<span>hours</span>}
            position="end"
            margin="dense"
            step={0.01}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectFormField
            name="rewardId"
            control={control}
            label="Completion Reward"
            options={rewards}
            disabled={isFetching}
            errors={errors.rewardId}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">360 Training Settings</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="externalCourseId"
            control={control}
            label="360 Course ID"
            errors={errors.externalCourseId}
            margin="dense"
            disabled={!is360Training}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="customerId"
            control={control}
            label="360 Customer ID"
            errors={errors.customerId}
            margin="dense"
            disabled={!is360Training}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">Data Driven Learning</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormField
            name="kpiId"
            control={control}
            label="KPI"
            errors={errors.kpiId}
            margin="dense"
            disabled={isFetching}
            options={kpis}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h4">Course Metadata</Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">States Multi Select</Typography>
          </Grid>
          <MultipleSelectCheckmarksFormField
            name="states"
            limitTags={3}
            color="#1565c0"
            options={getStatesList('name')}
            control={control}
            label="Select all states"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} mb={0}>
            <Typography variant="h6">Date First Published</Typography>
          </Grid>
          <DateFormField
            name="dateFirstPublished"
            errors={errors.dateFirstPublished}
            control={control}
            label={''}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Publishing Version</Typography>
          </Grid>
          <RadioFormField
            name="isRevision"
            control={control}
            options={[
              {
                label: 'New course',
                value: false,
              },
              { label: 'Revision', value: true, disabled: !isEdit },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} mb={0}>
            <Typography variant="h6">Enter Revision Date</Typography>
          </Grid>
          <DateFormField
            errors={errors.revisionDate}
            name="revisionDate"
            control={control}
            label={''}
            disabled={!isRevision}
          />
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection="row"
        justifyContent="flex-start"
        columnSpacing={1}
      >
        <Grid item xs={12} mt={1}>
          <Grid item xs={12} mb={1}>
            <Typography variant="h4">Course Tags</Typography>
          </Grid>
          <TagSelectFormField
            name="tags"
            control={control}
            label="Course Tags"
            errors={errors.tags as unknown as FieldError[]}
            margin="dense"
            getOptions={getCourseTags}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Grid item xs={12} mb={1}>
            <Typography variant="h4">Search Organizations</Typography>
          </Grid>
          <AsyncSelectFormField
            name="organizationId"
            control={control}
            label="Search Organizations"
            defaultValue={defaultOrganization}
            getOptions={getOrganizations}
            errors={errors.organizationId}
          />
        </Grid>
      </Grid>
      {isEdit && (
        <Grid container mt={2}>
          <CheckboxFormField
            name="archived"
            control={control}
            label="Archive Course"
            errors={errors.archived}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CourseInfo;
