import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import DateTimePicker from 'components/datePicker/DateTimePicker';
import { FormControl, FormControlLabel } from '@mui/material';

interface DateTimeProps {
  label: string;
  margin?: 'normal' | 'dense' | 'none';
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
}

export type DateTimeFormFieldProps<T extends FieldValues> = DateTimeProps &
  UseControllerProps<T>;

const DateTimeFormField = <T extends FieldValues>(
  props: DateTimeFormFieldProps<T>
) => {
  const { label, name, control, margin = 'normal' } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <FormControl error={Boolean(errors[name])} margin={margin}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            onChange={field.onChange}
            value={field.value}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};

export default DateTimeFormField;
