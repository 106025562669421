import React from 'react';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
} from '@mui/material';

type RadioFormFieldOptionsType = {
  label: string;
  value: string | boolean;
  disabled?: boolean;
};

interface RadioGroupFieldProps {
  value: any;
  name: string;
  options: RadioFormFieldOptionsType[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  row?: boolean;
  sx?: SxProps<Theme>;
}

const RadioGroupField = ({
  value,
  name,
  onChange,
  options,
  row = true,
  sx,
}: RadioGroupFieldProps) => (
  <RadioGroup name={name} value={value} onChange={onChange} row={row} sx={sx}>
    {options.map((option) => (
      <FormControlLabel
        disabled={option.disabled}
        key={option.label}
        value={option.value}
        control={<Radio />}
        label={option.label}
      />
    ))}
  </RadioGroup>
);

export default RadioGroupField;
