import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import RichTextEditor from '@mantine/rte';

interface RichTextFormFieldProps {
  value?: string | undefined;
  name: string;
  sx?: any;
  controls?: any;
  label?: string;
  control?: any;
  placeholder?: string;
  onChange?: (value: any) => void;
  errors?: any;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  inputLabelSx?: SxProps<Theme>;
}

const RichTextFormField = ({
  name,
  label,
  control,
  value,
  sx = { minWidth: '100%' },
  controls = [
    ['bold', 'italic', 'underline', 'link', 'image'],
    ['unorderedList', 'h1', 'h2', 'h3'],
    ['sup', 'sub'],
    ['alignLeft', 'alignCenter', 'alignRight'],
  ],
  placeholder,
  onChange,
  errors,
  margin = 'normal',
  required,
  inputLabelSx,
}: RichTextFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: fieldOnchange, value: fieldValue } }) => {
        if(name ==='successMessage') {
          console.log('field', value);
        }

        return (
          <FormControl sx={sx} margin={margin} required={required}>
            {label && (
              <InputLabel sx={inputLabelSx} id={`rich-text-box-${name}-label`}>
                {label}
              </InputLabel>
            )}
            <RichTextEditor
              id={`rich-textbox-${name}`}
              controls={controls}
              value={fieldValue}
              onChange={(e) => {
                if(onChange) {
                  onChange(e);
                }
                fieldOnchange(e);
              }}
              //placeholder={placeholder}
            />
            <FormHelperText error={Boolean(errors)}>
              {errors?.message}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default RichTextFormField;
