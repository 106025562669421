import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';
import DisplayText from 'components/DisplayText';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  formatNumber,
  formatPercentageSymbolWithPrecision,
} from 'utils/common';

const loadingState = {
  groups: true,
  exporting: false,
};

const AdminGroupReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getGroups();
  }, [filters, state]);

  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.courseAssignment.getAdminGroupReport({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportGroups = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.courseAssignment.exportAdminGroupReport({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            name,
            learnerCount,
            assignedCourses,
            startedCount,
            completedCount,
            pastDueCount,
          } = item;

          // const percentStarted = (assignedCourses > 0) ? (startedCount / assignedCourses) * 100 : 0;
          //
          // const percentStartedLabel = formatPercentageSymbolWithPrecision(percentStarted);

          const percentCompleted =
            assignedCourses > 0 ? (completedCount / assignedCourses) * 100 : 0;

          const percentCompletedLabel =
            formatPercentageSymbolWithPrecision(percentCompleted);

          return {
            'Community Name': name,
            Learners: learnerCount,
            'Assigned Courses': assignedCourses,
            'Total Started': startedCount,
            'Total Completed': completedCount,
            'Past Due': pastDueCount,
            // 'Percent Started': percentStartedLabel,
            'Percent Completed': percentCompletedLabel,
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Admin Course Assignment Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Community Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Learners',
      field: 'learnerCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.learnerCount);
      },
    },
    {
      headerName: 'Assigned Courses',
      field: 'assignedCourses',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.assignedCourses);
      },
    },
    {
      headerName: 'Total Started',
      field: 'startedCount',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.startedCount);
      },
    },
    {
      headerName: 'Total Completed',
      field: 'completedCount',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.completedCount);
      },
    },
    {
      headerName: 'Past due',
      field: 'pastDueCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.pastDueCount);
      },
    },
    // {
    //   headerName: 'Percent Started',
    //   field: 'percentStarted',
    //   sortable: false,
    //   filterable: false,
    //   headerAlign: 'center',
    //   align: 'right',
    //   flex: 0.5,
    //   ColumnComponent: (params: any) => {
    //     const { assignedCourses, startedCount } = params.row;
    //
    //     const percentStarted = (assignedCourses > 0) ? (startedCount / assignedCourses) * 100 : 0;
    //
    //     const percentStartedLabel = formatPercentageSymbolWithPrecision(percentStarted);
    //
    //     return <>{percentStartedLabel}</>;
    //   },
    // },
    {
      headerName: 'Percent Completed',
      field: 'test',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { assignedCourses, completedCount } = params.row;
        const percentCompleted =
          assignedCourses > 0 ? (completedCount / assignedCourses) * 100 : 0;

        const percentCompletedLabel =
          formatPercentageSymbolWithPrecision(percentCompleted);

        return <>{percentCompletedLabel}</>;
      },
    },
  ];

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Group Name',
    },
  ];

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4, gap: 1 }}>
        <Box sx={{ mb: 2 }}>
          <DisplayText
            type="h2"
            text="Course Assignment Report"
            variant="semiBold"
            style={{ color: '#162738' }}
          />
        </Box>
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          handleExport={exportGroups}
          loading={loading.groups}
          onRowClick={(params) => {
            navigate(`/admin/reports/course-assignment/${params.id}`);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const AdminGroupReport = () => (
  <ControlledDataGrid>
    <AdminGroupReportGrid />
  </ControlledDataGrid>
);

export default AdminGroupReport;
