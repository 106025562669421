import { useEffect, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import Icon from 'icons';

type userProfile = {
  name: string;
  email: string;
  primaryGroupName: string;
};

const LearnerProfile = ({ learnerId }: { learnerId: string }) => {
  const { callService } = useCallService();

  const [loading, setLoading] = useState(true);
  const [learner, setLearner] = useState<userProfile>({
    name: '',
    email: '',
    primaryGroupName: '',
  });

  useEffect(() => {
    const getLearner = async () => {
      try {
        if (learnerId) {
          const response = await callService({
            resource: httpRoutes.reporting.learner.getLearnerProfile(learnerId),
          });

          if (response) {
            const { firstName, lastName, email, userGroups } =
              response.response;

            let name = '';
            if (firstName) {
              name = firstName;
            }

            if (lastName) {
              name = `${name} ${lastName}`;
            }

            const primaryUserGroup = userGroups.find(
              (group: any) => group.isPrimary
            );

            const group = primaryUserGroup ? primaryUserGroup.group : undefined;

            const primaryGroupName = group ? group.name : '';

            setLearner({
              name,
              email,
              primaryGroupName,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    };

    getLearner();
  }, [learnerId]);

  if (loading) {
    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Skeleton variant="rectangular" height="100%" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '32px',
          lineHeight: '130%',
          textAlign: 'center',
          color: '#329D9C',
        }}
      >
        {learner.name}
      </Box>

      {learner.primaryGroupName && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="location" fill="#162738" />
          </Box>
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '130%',
              color: '#162738',
            }}
          >
            {learner.primaryGroupName}
          </Box>
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="mail" fill="#162738" />
        </Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '130%',
            color: '#162738',
          }}
        >
          {learner.email}
        </Box>
      </Box>
    </Box>
  );
};

export default LearnerProfile;
