import { useState, useEffect } from 'react';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { RewardIcon, EditIcon, DeleteIcon, AddIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/Create';
import Edit from '../dialogs/Edit';

import resources from '../resources';

import { RewardFormInput } from '../forms/Form';
import { Box } from '@mui/material';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Reward Name',
  },
];

const RewardsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const onSuccess = () => {
    hideDialog();
    getRewards();
  };

  const showCreateDialog = () => {
    showDialog({
      content: <Create onSuccess={onSuccess} />,
      size: 'sm',
    });
  };

  const showEditDialog = (values: RewardFormInput) => {
    showDialog({
      content: <Edit onSuccess={onSuccess} defaultValues={values} />,
      size: 'sm',
    });
  };

  useEffect(() => {
    getRewards();
  }, [filters, state]);

  const getRewards = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: resources.getMany({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Template',
      field: 'template',
      sortable: false,
      filterable: false,
      flex: 0.25,
    },
    {
      headerName: 'Subject',
      field: 'subject',
      sortable: false,
      filterable: false,
      hide: true,
      flex: 0.2,
    },
    {
      headerName: 'URL',
      field: 'url',
      sortable: false,
      filterable: false,
      hide: true,
      flex: 0.2,
    },
    {
      headerName: 'Active',
      field: 'active',
      sortable: true,
      filterable: false,
      type: 'boolean',
      flex: 0.2,
    },
    {
      headerName: 'Archived',
      field: 'archived',
      sortable: false,
      filterable: false,
      hide: true,
      type: 'boolean',
      flex: 0.2,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => showEditDialog(params.row)}>
              <EditIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <PageContainer
      title="Rewards"
      icon={<RewardIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Create Reward"
          onClick={showCreateDialog}
          startIcon={<AddIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const Rewards = () => (
  <ControlledDataGrid>
    <RewardsGrid />
  </ControlledDataGrid>
);

export default Rewards;
