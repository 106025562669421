import React from 'react';
import {Box, Skeleton} from '@mui/material';

const CourseCompletionsLoading = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <Skeleton variant="rounded" height="56px" width="121px" />
    </Box>
);

export default CourseCompletionsLoading;
