import * as React from 'react';

export default (
  <g fill="none">
    <path
      d="M24.69 48.85c7.455 0 13.5-6.044 13.5-13.5s-6.045-13.5-13.5-13.5c-7.456 0-13.5 6.044-13.5 13.5s6.044 13.5 13.5 13.5z"
      fill="#9CE8F7"
    />
    <path
      d="M44.81 16.491l2.973-.67-2.151 4.347-1.272-2.774M33.093 11.927l-2.814.636 2.155-4.35 1.213 2.656M37.315 17.666c3.04 1.506 6.274 1.173 7.224-.744.95-1.916-.745-4.691-3.785-6.197-3.04-1.506-6.274-1.174-7.223.743-.95 1.917.744 4.691 3.784 6.198zM44.458 14.307l-6.396 3.575M42.64 12.033l-7.422 4.144M39.693 10.383l-6.117 3.418M14.635 41.808l3.599 3.333c.686.635.727 1.706.092 2.392l-6.374 6.886a1.693 1.693 0 01-2.393.092l-3.6-3.333a1.693 1.693 0 01-.091-2.392l6.374-6.886a1.693 1.693 0 012.393-.092zM18.51 45.009l3.172-3.426-1.745-1.615-3.172 3.426M18.154 47.719l-6.084-5.633M12.035 54.33l-6.084-5.633M21.35 39.139l1.05.973-1.064 1.15-1.051-.973 1.064-1.15zM20.578 42.374l-1.544-1.43M19.54 43.494l-1.543-1.43"
      stroke="#205072"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.27 47.427l2.81 2.602a.328.328 0 00.467-.018l3.221-3.48a.328.328 0 00-.018-.465l-2.772-2.567"
      stroke="#205072"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.675 29.572l-.734-1.805M18.941 27.767c.89-.2 3.78-1.565 4.457-3.472a5.848 5.848 0 00-3.84 1.803c.655-.835 2.228-3.21 1.532-5.827-1.98 1.862-2.387 3.805-2.44 4.878a9.186 9.186 0 00-3.194-5.976 9.16 9.16 0 001.878 6.51c-.788-.725-2.435-1.833-5.155-1.792 1.325 2.36 4.108 2.961 5.161 3.109a5.876 5.876 0 00-4.01 1.384c1.819.9 4.84-.142 5.621-.621"
      stroke="#205072"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.504 14.055l-10.536 4.28a1.905 1.905 0 00-1.048 2.482l5.13 12.625a1.905 1.905 0 002.481 1.048l10.536-4.28a1.905 1.905 0 001.048-2.482l-5.129-12.625a1.905 1.905 0 00-2.482-1.048z"
      stroke="#205072"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.578 12.838l-4.643 1.886.981 2.414 4.642-1.886-.98-2.414z"
      stroke="#205072"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.808 8.283l-6.497 2.64 1.697 4.178 6.497-2.64-1.697-4.178z"
      stroke="#205072"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.859 5.425a1.502 1.502 0 011.956.826l1.133 2.788-2.788 1.132-1.132-2.787a1.502 1.502 0 01.826-1.956l.005-.003z"
      stroke="#205072"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.614 44.211a1.148 1.148 0 01-1.146-1.148v-.459c0-.445-.258-.855-.662-1.042l-.436-.204a1.148 1.148 0 01-.556-1.527 1.21 1.21 0 00.107-.499 1.18 1.18 0 00-.333-.828 1.146 1.146 0 01-.01-1.616l.01-.009.338-.343c.315-.316.422-.783.266-1.201l-.164-.455a1.072 1.072 0 01-.071-.391c.013-.472.31-.886.75-1.05.45-.166.752-.593.752-1.074 0-.636.515-1.148 1.146-1.148h.485a1.14 1.14 0 001.035-.663l.205-.437a1.143 1.143 0 011.524-.552c.151.072.316.107.48.107a1.13 1.13 0 00.827-.338 1.152 1.152 0 011.622 0l.338.343c.316.311.782.418 1.2.262l.453-.164a1.17 1.17 0 01.392-.072c.48 0 .91.303 1.075.757.165.454.596.757 1.076.757.63 0 1.142.512 1.142 1.144v.48M36.6 33.273v.913M33.205 36.732l-.564 1.015M42.266 38.736v.983M38.605 42.47l-.609 1.104M41.996 48.529l1.311-.33M42.147 44.331l1.008-.33M47.76 46.5l-1.137-1.078"
      stroke="#205072"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.492 40.387a5.037 5.037 0 011.107-4.296 1.194 1.194 0 01-.24-.391 1.237 1.237 0 00-1.587-.74l-.493.179a1.24 1.24 0 01-1.298-.29l-.369-.37a1.235 1.235 0 00-1.75 0c-.37.37-.93.468-1.4.25a1.238 1.238 0 00-1.65.592v.005l-.217.471a1.24 1.24 0 01-1.125.717h-.51c-.685 0-1.24.557-1.24 1.242 0 .526-.334.993-.828 1.167a1.238 1.238 0 00-.737 1.589l.186.476c.165.454.054.962-.288 1.304l-.37.37a1.24 1.24 0 000 1.754c.37.37.467.93.25 1.402a1.242 1.242 0 00.59 1.652h.005l.471.218c.436.204.72.64.72 1.126v.53c0 .685.556 1.242 1.24 1.242.52 0 .987.334 1.16.828.231.645.942.979 1.587.747l.489-.178a1.24 1.24 0 011.302.29l.369.37a1.235 1.235 0 001.75 0c.37-.37.925-.468 1.397-.25a1.238 1.238 0 001.648-.592v-.005l.218-.471a1.24 1.24 0 011.125-.717h.52c.684 0 1.24-.556 1.24-1.242 0-.525.333-.993.826-1.166.645-.236.973-.949.738-1.59l-.178-.49a1.252 1.252 0 01.285-1.304l.373-.369a5.048 5.048 0 01-5.316-4.051v-.009zM49.813 31.867l.382-.063M31.857 50.234l.378-.067M53 34.996l-.498-.071M29.116 48.342L29 48.057"
      stroke="#205072"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);
