import { useCallService } from 'hooks';
import PrimaryGroupForm, { PrimaryGroupInput } from '../forms/PrimaryGroupForm';
import resources from 'features/user/resources';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useState } from 'react';

const PrimaryGroup = () => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const {
    authState: {
      user: { id },
    },
  } = useAuthState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (values: PrimaryGroupInput) => {
    setIsSubmitting(true);

    const { response } = await callService({
      resource: resources.setPrimaryGroup(id, values.groupId),
      successMessage: 'Primary group has been assigned!',
    });

    if (response) {
      setIsSubmitting(false);
      hideDialog();
    }
  };

  return (
    <div>
      <PrimaryGroupForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default PrimaryGroup;
