import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import DisplayText from 'components/DisplayText';

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value?: number }
) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#00FFF0',
          svg: {
            circle: {
              strokeLinecap: 'round',
            },
          },
        }}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 0,
          svg: {
            color: 'black',
            opacity: 0.2,
            circle: {
              strokeDashoffset: '0px !important',
            },
          },
        }}
        thickness={3}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DisplayText
          type="b4"
          variant="bold"
          text={`${props.value ? Math.round(props.value) : 0}%`}
        />
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
