import { Box, Container, Grid } from '@mui/material';
import SeedTalentLogoHorizontal from 'assets/images/icons/seed-talent-logo-horizontal-white.svg';
import useWindowSize from 'hooks/useWindowSize';

const AuthWrapper = ({
  leftContent,
  rightContent,
}: {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) => {
  const windowSize = useWindowSize();

  return (
    <Box
      sx={{
        width: '100%',
        height: windowSize.height,
        overflow: 'auto'
      }}
    >
      <Container sx={{ height: '100%', justifyContent: 'center', px: { xs: 0 } }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            sx={{
              background:
                'linear-gradient(151.36deg, #054662 15.62%, #67B398 141.87%)',
            }}
          >
            <Grid container sx={{ pt: { xs: 2, lg: 8, xl: 8}, pb: { xs: 2, lg: 8, xl: 8 }, px: { xs: 2, lg: 12, xl: 12 }}}>
              <Grid item xs={12} mb={6}>
                <img height="50px" src={SeedTalentLogoHorizontal} alt="Seed Talent" />
              </Grid>
              {leftContent}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ backgroundColor: '#F3F7FD' }}>
            <Grid
              container
              sx={{
                pt: { xs: 2, lg: 15, xl: 15 },
                pb: { xs: 2, lg: 8, xl: 8 },
                px: { xs: 2, lg: 11, xl: 11 },
              }}
            >
              {rightContent}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AuthWrapper;
