import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import fileSaver from 'file-saver';
import Papa from 'papaparse';

import resources from '../../organizations/resources';

const loadingState = {
  learners: true,
  exporting: false,
};

let learnerRequestController: AbortController | undefined;

const LearnerReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  const getGroups = async (value: string) => {
    const { response } = await callService({
      resource: resources.groups.search(value),
    });

    if (response) {
      return response.map((item: { id: string; name: string }) => {
        return { id: item.id, label: item.name };
      });
    }
  };

  const exportLearners = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.learner.exportAdminReport({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Learner Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getLearners();
  }, [filters, state]);

  const getLearners = async () => {
    setLoading((prevState) => ({ ...prevState, learners: true }));
    if (learnerRequestController) {
      learnerRequestController.abort();
    }
    try {
      learnerRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.reporting.learner.getAdminReport({
          controller: learnerRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        learnerRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, learners: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, learners: false }));
    }
  };

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'email',
      type: 'text',
      label: 'Learner Email',
    },
    {
      id: 'group',
      type: 'async',
      label: 'Groups',
      getOptions: getGroups,
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      filterable: false,
      flex: 0.25,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      filterable: false,
      flex: 0.25,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.25,
    },
    {
      headerName: 'Courses',
      field: 'totalCourses',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.1,
    },
    {
      headerName: 'Started',
      field: 'totalStarted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.1,
    },
    {
      headerName: 'Completed',
      field: 'totalCompleted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.1,
    },
    {
      headerName: 'YTD Hours',
      field: 'ytdHours',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.1,
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.1,
      ColumnComponent: (params: any) => {
        if (params.row.averageScore === 100) {
          return <>{params.row.averageScore} %</>;
        } else if (params.row.averageScore > 0) {
          return <>{params.row.averageScore} %</>;
        }
        return <>-- %</>;
      },
    },
  ];

  return (
    <PageContainer
      title="Learner Report"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleExport={exportLearners}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.learners}
        onRowClick={(params) => {
          navigate(`/admin/reports/learners/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

const LearnerReport = () => (
  <ControlledDataGrid>
    <LearnerReportGrid />
  </ControlledDataGrid>
);

export default LearnerReport;
