export enum ExecutionResultStatus {
  Success = 'success',
  Failed = 'failed',
}

export class ExecutionError {
  public value: any;
  public reason: any;

  public constructor(value: any, reason: any) {
    this.value = value;
    this.reason = reason;
  }
}

export class ExecutionResult<T> {
  public status: ExecutionResultStatus;
  public errors: ExecutionError[];
  public results: T[];

  public constructor() {
    this.status = ExecutionResultStatus.Success;
    this.errors = [];
    this.results = [];
    this.build();
  }

  private build() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: any = this;
    Object.defineProperty(this.errors, 'push', {
      configurable: true,
      enumerable: false,
      writable: true,
      value: function (...args: ExecutionError[]) {
        const result = Array.prototype.push.apply(this, args);

        self.status = ExecutionResultStatus.Failed;

        return result;
      },
    });
  }
}
