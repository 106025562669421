const categoryPriorities: Record<string, number> = {
  'Internal Training': 5,
  Compliance: 4,
  'Brand Training': 3,
  Assessments: 2,
  'Industry Principles': 1,
  'Career Principles': 0,
};

export const orderCourses = (courses: any[]) => {
  const coursesWithPriorty = courses.map((c: any) => {
    const priority: number | null = categoryPriorities[c.name] || 0;
    return {
      ...c,
      priority,
    };
  });

  const orderedCourses = coursesWithPriorty
    .sort((x, y) => {
      return x.priority - y.priority;
    })
    .reverse();

  return orderedCourses;
};
