import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import DisplayText from '../../../components/DisplayText';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Stats from './partials/stats/Stats';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Course Name',
    field: 'courseName',
    sortable: true,
    filterable: false,
    flex: 1.25,
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: false,
    filterable: false,
    flex: 0.5,
  },
  {
    headerName: 'Started',
    field: 'start',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 1,
    ColumnComponent: (params: any) => {
      if (params.row.start) {
        return <>{new Date(params.row.start).toLocaleString()}</>;
      }
      return <>--</>;
    },
  },
  {
    headerName: 'Completed',
    field: 'end',
    sortable: false,
    filterable: false,
    type: 'date',
    headerAlign: 'center',
    align: 'right',
    flex: 1,
    ColumnComponent: (params: any) => {
      if (params.row.end) {
        return <>{new Date(params.row.end).toLocaleString()}</>;
      }
      return <>--</>;
    },
  },
  {
    headerName: 'Passed',
    field: 'passed',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'boolean',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return <>{params.row.passed ? 'Yes' : 'No'}</>;
    },
  },
  {
    headerName: 'Hours',
    field: 'estimatedHours',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return <>{params.row.estimatedHours} hrs</>;
    },
  },
];

const loadingState = {
  categories: true,
  courses: true,
  exporting: false,
};

const LearnerCourseReportGrid = ({
  isAdmin = false,
}: {
  isAdmin?: boolean;
}) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [user, setUser] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const params = useParams();

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const userId = params.id || '';

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      if (userId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getLearnerCourseReport({
            isAdmin,
            userId,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setUser(response.user);
          setGridData({
            rows: response.data.items || [],
            totalRows: response.data.totalRows || 0,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const getCourseCategories = async () => {
    setLoading((prevState) => ({ ...prevState, categories: true }));

    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    if (response) {
      const _categoriesList: SelectOption[] = [
        {
          label: 'All Categories',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _categoriesList.push({ label: item.name, value: item.id });
      });

      setCategories(_categoriesList);

      setLoading((prevState) => ({ ...prevState, categories: false }));
    }
  };

  const exportCourses = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));

      if (userId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.course.exportLearnerCourseReport({
            isAdmin,
            userId,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, `${user.email} Learner Course Report.csv`);

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
    {
      id: 'courseCategoryId',
      type: 'select',
      label: 'Course Category',
      options: categories,
    },
  ];

  return (
    <PageContainer>
      <Box sx={{ gap: 1 }}>
        <Box sx={{ mb: 2 }}>
          <DisplayText
            type="h2"
            text="Learners Report"
            variant="semiBold"
            style={{ color: '#162738' }}
          />
        </Box>
        <Stats learnerId={userId} isAdmin={isAdmin} />
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          totalRows={gridData.totalRows}
          handleExport={exportCourses}
          filters={filterModel}
          handleFilterChange={setFilters}
          loading={loading.courses}
        />
      </Box>
    </PageContainer>
  );
};

const LearnerCourseReport = ({ isAdmin = false }: { isAdmin?: boolean }) => (
  <ControlledDataGrid>
    <LearnerCourseReportGrid isAdmin={isAdmin} />
  </ControlledDataGrid>
);

export default LearnerCourseReport;
