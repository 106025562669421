import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FieldError } from 'react-hook-form';

import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Checkbox,
  Table,
  Box,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  useMediaQuery,
} from '@mui/material';

import { DeleteIcon, SaveIcon } from 'material-icons';

import {
  TextFormField,
  EmailFormField,
  PhoneFormField,
  SelectFormField,
  BirthdayFormField,
  MultiSelectFormField,
  PasswordFormField,
  ToggleFormField,
} from 'components/formFields';
import { SelectOptionProps } from 'components/formFields/types';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { LoadingButton } from '@mui/lab';

import { getStatesList } from 'dataSets/states';

import { deleteAttribute } from 'utils/common';
import httpRoutes from 'utils/httpRoutes';
import { validateAge } from 'utils/date';

import { useCallService, useDebouncedEffect } from 'hooks';
import resources from '../../organizations/resources';
import { getPosOptions } from 'dataSets/pos';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from 'components/buttons/IconButton';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';

export type UserFormInput = {
  id?: string;
  lastName?: string;
  firstName?: string;
  email: string;
  phone?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  postal?: string;
  optIn?: boolean;
  status?: string;
  emailSecondary?: string;
  archived?: number;
  picture?: string;
  jobTitle?: string;
  fbId?: string;
  pos?: string;
  posId?: string;
  birthDate?: string;
  userRoles?: any[];
  newUserRoles?: string[];
  userGoups?: ICommunity[];
  newUserGroups?: ICommunity[];
  password?: string;
  confirmNewPassword?: string;
};

export const INITIAL_FORM_STATE: UserFormInput = {
  id: '',
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  postal: '',
  emailSecondary: '',
  archived: 0,
  picture: '',
  jobTitle: '',
  fbId: '',
  pos: '',
  posId: '',
  birthDate: '',
  newUserGroups: [],
  password: '',
  confirmNewPassword: '',
};

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

interface ICommunity {
  groupId: string;
  name: string;
  isPrimary: number;
  notify: number;
  facilitator: number;
  manager: number;
  ddl: number;
}

const UserForm = ({
  defaultValues,
  onSubmit,
  isSubmitting,
}: {
  defaultValues?: UserFormInput;
  onSubmit: (values: UserFormInput) => void;
  isSubmitting: boolean;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [roles, setRoles] = useState<SelectOptionProps[]>([]);
  const [userGroups, setUserGroups] = useState<ICommunity[]>(
    defaultValues && defaultValues.newUserGroups
      ? defaultValues.newUserGroups
      : []
  );
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  // set default roles on edit
  const userRoleDefaultValues = defaultValues?.userRoles
    ? defaultValues.userRoles.map((role) => role.roleId)
    : [];

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const create = (values: UserFormInput) => {
    Object.keys(values).forEach((key) => {
      if (values[key as keyof UserFormInput] === '') {
        delete values[key as keyof UserFormInput];
      }
    });
    values.newUserGroups = userGroups;
  };

  const update = (values: UserFormInput) => {
    if (!values.password) {
      deleteAttribute(values, 'password');
    }

    const userRoles = values?.userRoles?.map((x) => x.roleId);
    const roleArraysAreEqual = _.isEqual(userRoles, values?.newUserRoles);
    if (roleArraysAreEqual) {
      deleteAttribute(values, 'newUserRoles');
    }

    const userGroupsAreEqual = _.isEqual(userGroups, values.userGoups);
    if (!userGroupsAreEqual) {
      values.newUserGroups = userGroups;
    }
  };

  const preSubmitUpload = async (values: UserFormInput) => {
    const isUpdate = values.id !== undefined;

    if (!isUpdate) {
      create(values);
    } else {
      update(values);
    }

    deleteAttribute(values, 'userGoups');
    deleteAttribute(values, 'userRoles');
    deleteAttribute(values, 'confirmNewPassword');

    onSubmit({ ...values });
  };

  const addCommunity = (community: ICommunity) => {
    const updatedUserGroups = [...userGroups];

    updatedUserGroups.push(community);
    setUserGroups(updatedUserGroups);
  };

  const onIsPrimaryClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    updatedUserGroups.forEach((x) => (x.isPrimary = 0));

    if (communityToUpdate) {
      communityToUpdate.isPrimary = communityToUpdate.isPrimary === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onManagerClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.manager = communityToUpdate.manager === 1 ? 0 : 1;
      communityToUpdate.facilitator = 0;
      communityToUpdate.notify = 0;
    }

    setUserGroups(updatedUserGroups);
  };

  const onFacilitatorClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.facilitator =
        communityToUpdate.facilitator === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onDDLClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.ddl = communityToUpdate.ddl === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const onNotifyClick = (id: string) => {
    const updatedUserGroups = [...userGroups];
    const communityToUpdate = updatedUserGroups.find(
      (community) => community.groupId === id
    );

    if (communityToUpdate) {
      communityToUpdate.notify = communityToUpdate.notify === 1 ? 0 : 1;
    }

    setUserGroups(updatedUserGroups);
  };

  const removeCommunity = (id: string) => {
    const updatedUserGroups = userGroups.filter(
      (community) => community.groupId !== id
    );

    if (updatedUserGroups.length === 1) {
      updatedUserGroups[0].isPrimary = 1;
    }

    setUserGroups(updatedUserGroups);
  };

  // getUserRoles
  const getRoles = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.getRoles(),
    });

    if (response) {
      setRoles(
        response.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  };

  const getGroups = async () => {
    try {
      setOptionsLoading(true);
      const communityListIds = userGroups.map((x) => x.groupId);

      const { response } = await callService({
        resource: resources.groups.search(inputValue, communityListIds),
      });

      if (response) {
        setOptions(
          response.map((item: { id: string; name: string }) => {
            return { id: item.id, label: item.name };
          })
        );
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useDebouncedEffect(getGroups, 200, [inputValue]);

  const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    email: Yup.string().required('An email is required'),
    phone: Yup.string().nullable(),
    address_1: Yup.string().nullable(),
    address_2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    postal: Yup.string()
      .matches(
        /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/,
        'Please enter a valid US or Canadian postal code'
      )
      .required('Postal code is required'),
    status: Yup.string().nullable(),
    emailSecondary: Yup.string().nullable(),
    picture: Yup.string().nullable(),
    jobTitle: Yup.string().nullable(),
    fbId: Yup.string().nullable(),
    pos: Yup.string().nullable(),
    posId: Yup.string().nullable(),
    birthDate: Yup.string()
      .nullable()
      .test(
        'test-birthDate',
        'You must be older than 21 years old',
        function (value) {
          if (!value) {
            return true;
          }

          const [month, day, year] = value.split('/');

          if (month && day && year) {
            const date = new Date(value);
            return validateAge(date);
          }
          return true;
        }
      ),
    userRoles: Yup.array().nullable(),
    newUserRoles: Yup.array().min(1, 'Please select at least one role'),
    password: Yup.string().nullable(),
    confirmNewPassword: Yup.string()
      .nullable()
      .test(
        'test-comparePasswords',
        'Passwords must match',
        function comparePasswords() {
          const { password, confirmNewPassword } = this.parent;
          return password === confirmNewPassword;
        }
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || undefined,
      lastName: defaultValues?.lastName || '',
      firstName: defaultValues?.firstName || '',
      email: defaultValues?.email || '',
      phone: defaultValues?.phone || '',
      address_1: defaultValues?.address_1 || '',
      address_2: defaultValues?.address_2 || '',
      city: defaultValues?.city || '',
      state: defaultValues?.state || '',
      postal: defaultValues?.postal || '',
      status: 'Active',
      emailSecondary: defaultValues?.emailSecondary || '',
      archived: defaultValues?.archived || 0,
      picture: defaultValues?.picture || '',
      jobTitle: defaultValues?.jobTitle || '',
      fbId: defaultValues?.fbId || '',
      pos: defaultValues?.pos || '',
      posId: defaultValues?.posId || '',
      birthDate: defaultValues?.birthDate || '',
      userRoles: defaultValues?.userRoles || [],
      newUserRoles: userRoleDefaultValues,
      userGoups: defaultValues?.newUserGroups || [],
      password: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const stateOptions = getStatesList();

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column">
          <Typography variant="h2" sx={{ pb: 2 }}>
            {defaultValues?.id ? 'Update User Record' : 'Create A New User'}
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="General Info" />
            <Grid item xs={12} md={6}>
              <TextFormField
                name="firstName"
                control={control}
                label="First Name*"
                errors={errors.firstName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFormField
                name="lastName"
                control={control}
                label="Last Name*"
                errors={errors.lastName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <EmailFormField
                name="email"
                control={control}
                label="Email*"
                errors={errors.email}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <EmailFormField
                name="emailSecondary"
                control={control}
                label="Secondary Email"
                errors={errors.emailSecondary}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PhoneFormField
                name="phone"
                control={control}
                label="Phone Number"
                errors={errors.phone}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectFormField
                name="pos"
                options={getPosOptions()}
                control={control}
                label="Point of Sale"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFormField
                name="posId"
                control={control}
                label="Point of Sale Id"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6} mb={2}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17px',
                  }}
                >
                  Archived
                </Box>
                <ToggleFormField name="archived" control={control} />
              </Box>
            </Grid>
            <FormSectionTitle title="Location" />
            <Grid item xs={12} md={8}>
              <TextFormField
                name="address_1"
                control={control}
                label="Street Address"
                errors={errors.address_1}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFormField
                name="address_2"
                control={control}
                label="Suite/Apt #"
                errors={errors.address_2}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFormField
                name="city"
                control={control}
                label="City"
                errors={errors.city}
                margin="dense"
              />
            </Grid>
            <Grid item xs={3}>
              <SelectFormField
                name="state"
                control={control}
                label="State"
                errors={errors.state}
                options={stateOptions}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFormField
                name="postal"
                control={control}
                label="Post/Zip Code"
                errors={errors.postal}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Security" />
            <Grid item xs={12}>
              <MultiSelectFormField
                name="newUserRoles"
                control={control}
                errors={errors.newUserRoles as FieldError[]}
                label="Roles*"
                options={roles}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordFormField
                name="password"
                label="New Password"
                control={control}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordFormField
                name="confirmNewPassword"
                label="Confirm New Password"
                control={control}
                margin="dense"
                errors={errors.confirmNewPassword}
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Communities" />
            <Grid item xs={12}>
              <Autocomplete
                id="search"
                freeSolo
                fullWidth
                selectOnFocus
                loading={areOptionsLoading}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={(_event, value) => {
                  if (value) {
                    setInputValue('');
                    const newCommunity = {
                      name: value.label,
                      groupId: value.id,
                      isPrimary: userGroups.length === 0 ? 1 : 0,
                      facilitator: 0,
                      manager: 0,
                      ddl: 0,
                      notify: 0,
                    };
                    addCommunity(newCommunity);
                  }
                }}
                options={options}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Find communities"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <>
                          {areOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box
              sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
              mt={2}
            >
              {userGroups.length > 0 && (
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#f4f4f4',
                      '& th:nth-of-type(1)': {
                        borderRadius: '1em 0 0 0',
                      },
                      '& th:nth-of-type(7)': {
                        borderRadius: '0 1em 0 0',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="80%"
                      >
                        Community Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Primary
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Manager
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Facilitator
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        DDL Access
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      >
                        Notify
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& tr:nth-of-type(even)': {
                        backgroundColor: '#f4f4f4',
                      },
                      '& tr:last-of-type': {
                        '& td:nth-of-type(1)': {
                          borderRadius: '0 0 0 1em',
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(2)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(3)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(4)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(5)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(6)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(7)': {
                          borderRadius: '0 0 1em 0',
                          borderBottom: '0px',
                        },
                      },
                      '& tr:first-of-type': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    {userGroups.map((community) => (
                      <TableRow key={community.groupId}>
                        <TableCell
                          sx={{
                            padding: matchDownSM
                              ? '0px 0px 0px 16px'
                              : '0px 16px',
                            fontSize: matchDownSM ? '10px' : '14px',
                          }}
                          size="small"
                          width="80%"
                        >
                          {community.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.isPrimary === 1}
                              onChange={() =>
                                onIsPrimaryClick(community.groupId)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.manager === 1}
                              onChange={() => onManagerClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              disabled={community.manager === 1}
                              checked={community.facilitator === 1}
                              onChange={() =>
                                onFacilitatorClick(community.groupId)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              checked={community.ddl === 1}
                              onChange={() => onDDLClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Checkbox
                              size="small"
                              disabled={community.manager === 1}
                              checked={community.notify === 1}
                              onChange={() => onNotifyClick(community.groupId)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeCommunity(community.groupId);
                            }}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Additional Info" />
            <Grid item xs={12} md={6}>
              <TextFormField
                name="jobTitle"
                control={control}
                label="Job Title"
                errors={errors.jobTitle}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BirthdayFormField
                name="birthDate"
                control={control}
                label="Birthday"
                errors={errors.birthDate}
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default UserForm;
