import React from 'react';

import { Box, Skeleton } from '@mui/material';

const StatsLoading = () => (
    <Box sx={{ width: '100%'}}>
        <Skeleton variant="rectangular" height="190px" width="100%" />
    </Box>
);

export default StatsLoading;
