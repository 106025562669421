import { useCallService } from 'hooks';

import resources from 'features/certificates/resources';
import Form, { CertificateFormInput } from '../forms/Form';

const Create = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const { callService } = useCallService();

  const onSubmit = async (values: CertificateFormInput) => {
    const { response } = await callService({
      resource: resources.create(values),
      successMessage: 'Certificate created successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} />
    </div>
  );
};

export default Create;
