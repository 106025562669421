import { FC } from 'react';

interface Props {
  showComponent: boolean;
  children?: React.ReactNode;
}

const ConditionalComponent: FC<Props> = ({ showComponent, children }) => {
  return <div>{showComponent ? children : null}</div>;
};

export default ConditionalComponent;
