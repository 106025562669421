import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import { CheckboxFormField } from 'components/formFields';

import httpRoutes from 'utils/httpRoutes';

export type UpdateCommunityMemberFormInput = {
  id: string;
  facilitator: boolean;
  notify: boolean;
  pending: boolean;
};

const UpdateCommunityMemberForm = ({
  defaultValues,
  metaData,
  onSuccess,
}: {
  defaultValues: UpdateCommunityMemberFormInput;
  metaData: { firstName?: string; lastName?: string; email: string };
  onSuccess: VoidFunction;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: UpdateCommunityMemberFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.groups.updateMember(values),
      successMessage: 'Member updated successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdateCommunityMemberFormInput>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues.id,
      facilitator: defaultValues.facilitator,
      notify: defaultValues.notify,
      pending: defaultValues.pending,
    },
    shouldUnregister: false,
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Update Community Member
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pb: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Name: </span>
                {metaData.firstName} {metaData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pb: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Email: </span>
                {metaData.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <CheckboxFormField
                name="facilitator"
                control={control}
                label="Community Facilitator"
                errors={errors.facilitator}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CheckboxFormField
                name="notify"
                control={control}
                label="Send Notifications"
                errors={errors.notify}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default UpdateCommunityMemberForm;
