import React, { useEffect, useState } from 'react';

import { Avatar, Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { lighten } from 'polished';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import Icon from 'icons';

import CircularProgress from '../../../shared/CircularProgress';
import LearnerProfile from '../learnerProfile/LearnerProfile';

type Item = {
  icon: string;
  label: string;
  color: string;
  field: keyof LearnersStats;
};

const verticalItems: Item[] = [
  {
    icon: 'folder',
    label: 'Courses',
    color: '#12D1D0',
    field: 'totalCourses',
  },
  {
    icon: 'check',
    label: 'Completions',
    color: '#199CC5',
    field: 'totalCompletions',
  },
];

const horizontalItems: Item[] = [
  {
    icon: 'in-progress-outlined',
    label: 'In Progress',
    color: '#56C596',
    field: 'totalInProgressCourses',
  },
  {
    icon: 'clock',
    label: 'Hours of engagement',
    color: '#56C596',
    field: 'hoursOfEngagement',
  },
  {
    icon: 'score',
    label: 'average quiz score',
    color: '#199CC5',
    field: 'avgGrade',
  },
];

type LearnersStats = {
  totalCourses: number;
  totalCompletions: number;
  totalInProgressCourses: number;
  completedCoursePercentage: number;
  hoursOfEngagement: number;
  avgGrade: number;
};

const Stats = ({ learnerId, isAdmin = false }: { learnerId: string; isAdmin?: boolean }) => {
  const theme = useTheme();
  const matchUpXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const [stats, setStats] = useState<LearnersStats>({
    totalCourses: 0,
    totalCompletions: 0,
    totalInProgressCourses: 0,
    completedCoursePercentage: 0,
    avgGrade: 0,
    hoursOfEngagement: 0,
  });

  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      if (learnerId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.learner.getLearnerReportStats(learnerId, isAdmin),
        });

        if (response) {
          const {
            totalCourses,
            totalCompletions,
            totalInProgressCourses,
            hoursOfEngagement,
            avgGrade
          } = response;

          const completedCoursePercentage =
            totalCompletions > 0
              ? Math.round((totalCompletions * 100) / Number(totalCourses))
              : 0;

          setStats({
            totalCourses,
            totalCompletions,
            totalInProgressCourses,
            completedCoursePercentage,
            hoursOfEngagement,
            avgGrade,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        <Box
          sx={{
            flex: '0.25 0 auto',
            width: matchDownLG ? '100%' : '345px',
            p: 3,
            borderRadius: '15px',
            backgroundColor: 'white',
          }}
        >
          <LearnerProfile learnerId={learnerId} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0.5 0 auto',
            width: matchDownLG ? '100%' : '345px',
            gap: 1,
          }}
        >
          <Grid
            container
            gap={4}
            sx={{
              p: 3,
              borderRadius: '15px',
              backgroundColor: 'white',
            }}
          >
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height="250px"/>
            ) : (
              verticalItems.map((statItem: Item) => (
                <Grid
                  item
                  key={statItem.label}
                  sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                  xs={12}
                >
                  <Box sx={{ flex: 1 }}>
                    <Avatar
                      sx={{
                        backgroundColor: lighten(0.3, statItem.color),
                        opacity: '0.9',
                        width: 62,
                        height: 62,
                      }}
                    >
                      <Icon
                        name={statItem.icon}
                        size="extraLarge"
                        fill={statItem.color}
                      />
                    </Avatar>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: 'Raleway',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '14px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {statItem.label}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: 'Raleway',
                        fontWeight: '600',
                        fontSize: '40px',
                        lineHeight: '56px',
                      }}
                    >
                      {stats ? stats[statItem.field] : ''}
                    </Box>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0.25 0 auto',
            width: matchDownLG ? '100%' : '345px',
            p: 3,
            borderRadius: '15px',
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {
            loading ? <Skeleton variant="rectangular" width="100%" height="250px"/> : (
              <>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    lineHeight: '120%',
                    maxWidth: '250px',
                    color: '#205072',
                  }}
                >
                  % Completed
                </Box>
                <Box>
                  <CircularProgress
                    value={stats.completedCoursePercentage}
                    color="#56C596"
                  />
                </Box>
              </>
            )
          }
        </Box>
      </Box>
      <Grid
        container
        rowGap={4}
        sx={{
          p: 3,
          borderRadius: '15px',
          backgroundColor: 'white',
          justifyContent: !matchUpXL ? 'center' : 'unset',
        }}
      >
        {
          loading ? <Skeleton variant="rectangular" width="100%" height="70px"/> :
            horizontalItems.map((statItem) => (
              <Grid
                item
                key={statItem.label}
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={3}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, statItem.color),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name={statItem.icon}
                      size="extraLarge"
                      fill={statItem.color}
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {statItem.label}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {stats ? stats[statItem.field] : ''}
                  </Box>
                </Box>
              </Grid>
            ))
        }
      </Grid>
    </Box>
  );
};

export default Stats;
