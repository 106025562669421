import React from 'react';
import {Box, Skeleton} from '@mui/material';

const TopCoursesLoading = () => (
  <Box sx={{ width: '100%', height: '100%'}}>
    <Skeleton variant="rectangular" height="382px" width="100%" />
  </Box>
);

export default TopCoursesLoading;
