import React from 'react';
import MuiButton from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  label: string;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  sx?: SxProps<Theme>;
};

const defaultProps = {
  variant: 'contained',
  size: 'medium',
  color: 'primary',
  disabled: false,
  startIcon: null,
  endIcon: null,
  type: 'button',
};

export const buttonDefaultSx = (variant: 'contained' | 'outlined' | 'text') =>
  variant === 'contained'
    ? {
        width: '100%',
        borderRadius: '30px',
        height: '48px',
        background: 'linear-gradient(180deg, #56C596 0%, #16915D 100%)',
        color: '#FFF',
      }
    : {
        color: '#16915D',
      };

function Button({
  onClick,
  label,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  disabled = false,
  startIcon,
  endIcon,
  type = 'button',
  sx,
}: ButtonProps) {
  return (
    <MuiButton
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      sx={{ ...buttonDefaultSx(variant), ...sx }}
    >
      {label}
    </MuiButton>
  );
}

Button.defaultProps = defaultProps;

export default Button;
