import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import {Box, TextField} from '@mui/material';
import {SxProps} from '@mui/system';

interface NumberFormFieldProps {
  name: string;
  placeholder?: string;
  control: any;
  errors?: FieldError;
  icon?: React.ReactChild;
  position?: 'start' | 'end';
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  sx?: SxProps
}

const defaultSx = {
  background: 'white',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const NumberFormField = ({
  name,
  placeholder,
  control,
  errors,
  icon,
  position = 'start',
  margin = 'normal',
  required,
  sx
}: NumberFormFieldProps) => {
  let inputProps = {};

  if (icon) {
    if (position === 'start') {
      inputProps = {
        startAdornment: icon,
        pattern: '[0-9]{1,15}',
      };
    }

    if (position === 'end') {
      inputProps = {
        endAdornment: icon,
        pattern: '[0-9]{1,15}',
      };
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <TextField
            {...field}
            required={required}
            type="number"
            variant="outlined"
            sx={{...defaultSx, ...sx}}
            placeholder={placeholder}
            fullWidth
            margin={margin}
            error={Boolean(errors)}
            InputProps={inputProps}
          />
          <Box sx={{ width: 'fit-content', color: '#d32f2f', fontWeight: 'bold', minHeight: '19px' }}>
            {errors ? errors.message : ''}
          </Box>
        </Box>
      )}
    />
  );
};

export default NumberFormField;
