import AuthWrapper from '../../authentication/components/AuthWrapper';
import RightDescriptionContent from '../../authentication/fragments/rightDescriptionContent';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { Box, Grid } from '@mui/material';
import Button from 'components/buttons/Button';

const DenyUserRequestContent = () => {
  const { communityId = '', userId = '' } = useParams();
  const navigate = useNavigate();

  const { callService } = useCallService();

  const confirmJoin = async () => {
    await callService({
      resource: httpRoutes.groups.denyMemberRequest(communityId, userId),
      successMessage: 'Success! User has been denied access.',
    });
  };

  useEffect(() => {
    confirmJoin();
  }, []);

  const goHome = () => {
    navigate('/');
  };

  return (
    <Box>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          User has been denied access to the community.
        </Box>
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="submit"
          label="Go Home"
          onClick={goHome}
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
          }}
        />
      </Grid>
    </Box>
  );
};

const ApproveUserRequest = () => {
  return (
    <AuthWrapper
      leftContent={<DenyUserRequestContent />}
      rightContent={<RightDescriptionContent isPrivate={true} />}
    />
  );
};

export default ApproveUserRequest;
