import Tag from '../Tag';
import { ITagColor } from '../types';

interface IThresholdTag {
  threshold: number;
  value: number;
  normalizedMean: number;
}

const getThresholdTagColorByLabel = (label: string): ITagColor => {
  switch (label) {
    case 'Below Threshold':
      return { labelColor: '#F54F6B', backgroundColor: '#FFEDF1' };
    case 'Above Threshold':
      return { labelColor: '#205072', backgroundColor: '#E6EFFC' };
    default:
      return { labelColor: '#14804A', backgroundColor: '#E1FCEF' };
  }
};

const getThresholdTagLabel = (
  normalizedMean: number,
  threshold: number,
  value: number
) => {
  if (value > normalizedMean) {
    return 'Above Average';
  }

  if (value > threshold) {
    return 'Above Threshold';
  }

  return 'Below Threshold';
};

const ThresholdTag = ({ threshold, value, normalizedMean }: IThresholdTag) => {
  const label = getThresholdTagLabel(normalizedMean, threshold, value);

  const { labelColor, backgroundColor } = getThresholdTagColorByLabel(label);

  return (
    <Tag
      label={label}
      labelColor={labelColor}
      backgroundColor={backgroundColor}
    />
  );
};

export default ThresholdTag;
