import {
  instance as apiClient,
  multiPartInstance,
  pendoInstance,
  posImporterInstance,
} from 'utils/axios';

import { PaginationInterface } from 'components/dataGrid/components/DataGrid';

import { UserFormInput } from 'features/user-management/forms/UserForm';
import { CommunityFormInput } from 'features/communities/forms/CommunityForm';
import { IForgotPasswordInputs } from 'features/authentication/fragments/forgotPassword/ForgotPassword';
import { CreateCommunityMemberFormInput } from 'features/communities/forms/CreateCommunityMemberForm';
import { UpdateCommunityMemberFormInput } from 'features/communities/forms/UpdateCommunityMemberForm';
import { CommunityBundleFormInput } from 'features/bundles/forms/CommunityBundleForm';
import { CourseBundleFormInput } from 'features/bundles/forms/CourseBundleForm';
import { FilterState } from 'components/filters/Filters';
import { IConfirmRegistrationFormInputs } from 'features/authentication/fragments/completeRegistration/CompleteRegistration';
import { RewardFormInput } from 'features/rewards/forms/Form';
import { BundleFormInput } from 'features/bundles/forms/BundleForm';
import { IDashboardStats } from 'features/dashboard/pages/Dashboard';
import { IValidateEmailInputs } from 'features/authentication/fragments/passwordLess/ValidateEmail';
import { IFirstTimeUserFormInputs } from 'features/authentication/fragments/passwordLess/CompleteValidation';
import { CourseRecordFormRequest } from 'features/user-management/forms/CourseRecordForm';
import { IOrganizationFormInput } from '../features/ddl/pages/orgManagement/forms/OrganizationForm';
import { IGroupFormInput } from '../features/ddl/pages/orgManagement/forms/GroupForm';
import { IEmployeeFormInput } from '../features/ddl/pages/orgManagement/forms/EmployeeForm';
import { ILoopsValidateEmailInputs } from '../features/admin-panel/loops/Loops';
import { PublicUserFormInput } from '../features/user-management/forms/PublicUserForm';
import { AssignCourseFormInput } from 'features/course-assignment/forms/MultiStepForm';
import {
  CreateGroupCourseAssignmentRequest,
  CreateUserAssignmentRequest,
  UserAssignmentEmailRequest,
} from 'features/course-assignment/interfaces';
import UserUploadFormInput from 'features/user-management/forms/UserUploadForm/UserUploadFormInput';
import { CourseRequest } from 'types/courseTypes/CourseRequest';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';
import CoassembleType from 'types/CoassembleType';
import { ManageCommunityBundlesFormInput } from 'features/communities/forms/ManageCommunityBundlesForm';

const apiBase = '/api/v1';

export const pendoRoutes = {
  track(payload: any) {
    return pendoInstance.post('/data/track', payload);
  },
};

const httpRoutes = {
  loops: {
    createContact(payload: ILoopsValidateEmailInputs) {
      return apiClient.post(`${apiBase}/loops/create-contact`, payload);
    },
  },
  authentication: {
    loginRequest(payload: any) {
      return apiClient.post(`${apiBase}/auth/login`, payload);
    },
    forgotPasswordRequest(payload: IForgotPasswordInputs) {
      return apiClient.post(`${apiBase}/auth/recover/request`, payload);
    },
    validatePasswordRequestToken(token: string) {
      return apiClient.get(`${apiBase}/auth/recover/${token}`);
    },
    resetPasswordConfirmation({
      password,
      token,
    }: {
      password: string;
      token: string;
    }) {
      return apiClient.post(`${apiBase}/auth/recover/confirm`, {
        params: { password, token },
      });
    },
    searchGroups(value: string) {
      return apiClient.get(`${apiBase}/auth/groups/search`, {
        params: { name: value },
      });
    },
    organization: {
      validateEmailRequest(payload: IValidateEmailInputs) {
        return apiClient.post(`${apiBase}/auth/organization`, payload);
      },
      validateEmailToken(token: string) {
        return apiClient.get(`${apiBase}/auth/organization/${token}`);
      },
      completeSignup(payload: IFirstTimeUserFormInputs) {
        return apiClient.put(
          `${apiBase}/on-boarding/organization/complete-registration`,
          payload
        );
      },
    },
  },
  onBoarding: {
    signUp(payload: any) {
      return apiClient.post(`${apiBase}/on-boarding/sign-up`, payload);
    },
    completeRegistration(
      token: string,
      payload: IConfirmRegistrationFormInputs
    ) {
      return apiClient.put(
        `${apiBase}/on-boarding/complete-registration/${token}`,
        payload
      );
    },
    validateInvitationToken(token: string) {
      return apiClient.get(`${apiBase}/on-boarding/invitation-token/${token}`);
    },
  },
  bundles: {
    create(payload: BundleFormInput) {
      return apiClient.post(`${apiBase}/bundles`, payload);
    },
    update(payload: BundleFormInput) {
      return apiClient.put(`${apiBase}/bundles`, payload);
    },
    delete(id: string) {
      return apiClient.put(`${apiBase}/bundles/${id}/delete`);
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/bundles/${id}`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        organizationId: filters.get('organizationId'),
        active: filters.get('active'),
      };

      return apiClient.get(`${apiBase}/bundles/paginated`, {
        params: formattedParams,
      });
    },
    getBundles() {
      return apiClient.get(`${apiBase}/bundles/`);
    },
    searchBundles(value: string, idsToFilter?: string[]) {
      return apiClient.get(`${apiBase}/bundles/search`, {
        params: { name: value, idsToFilter },
      });
    },
    getAllCourses() {
      return apiClient.get(`${apiBase}/course-admin/all`);
    },
    getAllCoursesByBundleId(bundleId: string) {
      return apiClient.get(
        `${apiBase}/bundles/${bundleId}/courses?page=0&limit=1000`
      );
    },
    updateBundleCourses(values: CourseBundleFormInput) {
      return apiClient.put(
        `${apiBase}/bundles/${values.bundleId}/update-courses`,
        values
      );
    },
    removeCourseFromBundle(values: CourseBundleFormInput) {
      return apiClient.put(
        `${apiBase}/bundles/${values.bundleId}/remove-course`,
        values
      );
    },
    getBundleCourses({
      bundleId,
      params,
      filters,
    }: {
      bundleId: string;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/bundles/${bundleId}/courses`, {
        params: formattedParams,
      });
    },
    getBundleCommunities({
      bundleId,
      params,
      filters,
    }: {
      bundleId: string;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/bundles/${bundleId}/communities`, {
        params: formattedParams,
      });
    },
    updateBundleCommunities(values: CommunityBundleFormInput) {
      return apiClient.put(
        `${apiBase}/bundles/${values.bundleId}/update-communities`,
        values
      );
    },
    addCommunitiesToBundle(values: CommunityBundleFormInput) {
      return apiClient.put(
        `${apiBase}/bundles/${values.bundleId}/add-communities`,
        values
      );
    },
    removeCommunityFromBundle(values: CommunityBundleFormInput) {
      return apiClient.put(
        `${apiBase}/bundles/${values.bundleId}/remove-community`,
        values
      );
    },
  },
  courseBuilder: {
    getCourseUrl({
      courseId,
      userId,
      organizationId,
    }: {
      courseId: number;
      userId: string;
      organizationId: string;
    }) {
      return apiClient.get(
        `${apiBase}/coassemble/?id=${courseId}&identifier=${userId}&clientIdentifier=${organizationId}`
      );
    },
    getCourseUTRId({
      externalCourseId,
      organizationId,
    }: {
      externalCourseId: number;
      organizationId: string;
    }) {
      return apiClient.get(
        `${apiBase}/coassemble/get-utr-id/?externalCourseId=${externalCourseId}&organizationId=${organizationId}`
      );
    },
    getCourseEditUrl({
      userId,
      courseId,
      organizationId,
    }: {
      userId: string;
      courseId: string;
      organizationId: string;
    }) {
      return apiClient.get(
        `${apiBase}/coassemble/editurl?id=${courseId}&identifier=${userId}&clientIdentifier=${organizationId}`
      );
    },
    getCourseCreateUrl({
      userId,
      organizationId,
      flow,
    }: {
      userId: string;
      organizationId: string;
      flow?: 'ai' | 'document' | null;
    }) {
      return apiClient.get(`${apiBase}/coassemble/createurl`, {
        params: {
          identifier: userId,
          clientIdentifier: organizationId,
          flow: flow,
        },
      });
    },
    updateCourseProgress(values: CoassembleType) {
      return apiClient.put(`${apiBase}/coassemble/utr`, values);
    },
    getCoursePreviewUrl({
      userId,
      courseId,
      organizationId,
    }: {
      userId: string;
      courseId: string;
      organizationId: string;
    }) {
      return apiClient.get(
        `${apiBase}/coassemble/previewurl?id=${courseId}&identifier=${userId}&clientIdentifier=${organizationId}`
      );
    },
  },
  courseManagement: {
    search(value: string, idsToFilter?: string[]) {
      return apiClient.get(`${apiBase}/course-admin/search`, {
        params: { name: value, idsToFilter },
      });
    },
    createAdmin(payload: CourseRequest) {
      return apiClient.post(`${apiBase}/course-admin`, payload);
    },
    create(payload: CourseBuilderFormInput) {
      return apiClient.post(`${apiBase}/course-admin/course-builder`, payload);
    },
    updateAdmin(payload: CourseRequest) {
      return apiClient.put(`${apiBase}/course-admin`, payload);
    },
    update(payload: CourseBuilderFormInput) {
      return apiClient.put(`${apiBase}/course-admin/course-builder`, payload);
    },
    archive(courseId: string, value: boolean) {
      return apiClient.put(
        `${apiBase}/course-admin/archive/${courseId}/${value}`
      );
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/course-admin/${id}`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        active: filters.get('active'),
      };

      return apiClient.get(`${apiBase}/course-admin/paginated`, {
        params: formattedParams,
      });
    },
    getCourseTypes() {
      return apiClient.get(`${apiBase}/course-admin/types`);
    },
    getCourses() {
      return apiClient.get(`${apiBase}/course-admin/all`);
    },
    getCertificates() {
      return apiClient.get(`${apiBase}/certificates/all`);
    },
    getRewards() {
      return apiClient.get(`${apiBase}/rewards/all`);
    },
    getTags(value: string) {
      return apiClient.get(`${apiBase}/course-admin/tags`, {
        params: { label: value },
      });
    },
    getCoursesPaginatedByGroupId({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/course-admin/courses/${groupId}/paginated`,
        {
          params: formattedParams,
        }
      );
    },
  },
  courses: {
    getCoursesByCategory({
      filters,
      controller,
    }: {
      filters: FilterState;
      controller?: any;
    }) {
      const formattedParams = {
        name: filters.get('name'),
        categoryId: filters.get('category'),
        rewardTypeId: filters.get('rewardTypeId'),
        sortBy: filters.get('sortBy'),
      };
      return apiClient.get(`${apiBase}/courses/by-category`, {
        signal: controller.signal,
        params: formattedParams,
      });
    },
    getCourseCategories() {
      return apiClient.get(`${apiBase}/courses/categories`);
    },
    getRequiredCourses({ filters }: { filters: FilterState }) {
      const formattedParams = {
        name: filters.get('name'),
        categoryId: filters.get('category'),
        sortBy: filters.get('sortBy'),
      };
      return apiClient.get(`${apiBase}/courses/in-progress`, {
        params: formattedParams,
      });
    },
    getLatest30() {
      return apiClient.get(`${apiBase}/courses/latest`);
    },
    getContentCreators() {
      return apiClient.get(`${apiBase}/user-management/content-creators`);
    },
    getCourseById(courseId: string) {
      return apiClient.get(`${apiBase}/courses/${courseId}`);
    },
    startCourse(courseId: string) {
      apiClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      return apiClient.get(`${apiBase}/courses/${courseId}/start`);
    },
    startCourseByReference(courseReference: string) {
      apiClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      return apiClient.get(
        `${apiBase}/courses/reference/${courseReference}/start`
      );
    },
    updateCourseProgress(
      utrId: string,
      values: { cmiData: string; duration: number; utrId: string }
    ) {
      const reqBody = {
        // cmiData: decodeURIComponent(values.cmiData),
        cmiData: values.cmiData,
        duration: values.duration,
        utrId: values.utrId,
      };

      return apiClient.put(`${apiBase}/courses/${utrId}/update`, reqBody);
    },
    review(
      utrId: string,
      reviewValue: number | undefined,
      reviewText: string | undefined
    ) {
      const reqBody = {
        userTestResultId: utrId,
        review: reviewValue,
        reviewText: reviewText,
      };

      return apiClient.post(`${apiBase}/course-review`, reqBody);
    },
    getReviewFromUser(utrId: string | undefined) {
      return apiClient.get(`${apiBase}/course-review/${utrId}`);
    },
  },
  dashboard: {
    getStats(): Promise<IDashboardStats> {
      return apiClient.get(`${apiBase}/users/dashboard/stats`);
    },
    getRequiredCourses() {
      return apiClient.get(`${apiBase}/courses/required`);
    },
    getOutstandingCourses() {
      return apiClient.get(`${apiBase}/courses/outstanding`);
    },
  },
  emailer: {
    getTemplates() {
      return apiClient.get(`${apiBase}/emailer/templates`);
    },
    sendCommunityCampaign(payload: any) {
      return apiClient.post(`${apiBase}/emailer/send-campaign`, payload);
    },
  },
  groups: {
    searchByOrganizationId({
      params,
      organizationId,
    }: {
      params: Pick<PaginationInterface, 'limit'>;
      organizationId: string;
    }) {
      return apiClient.get(`${apiBase}/groups/search/${organizationId}`, {
        params,
      });
    },
    search(value: string, organizationIdsForFilter?: string[]) {
      return apiClient.get(`${apiBase}/groups/search`, {
        params: { name: value, organizationIdsForFilter },
      });
    },
    searchByOrganizations(value: string, organizationIds?: string[]) {
      return apiClient.get(`${apiBase}/groups/search/by-organizations`, {
        params: { name: value, organizationIds },
      });
    },
    create(payload: CommunityFormInput) {
      return apiClient.post(`${apiBase}/groups`, payload);
    },
    update(payload: CommunityFormInput) {
      return apiClient.put(`${apiBase}/groups`, payload);
    },
    delete(id: string) {
      return apiClient.put(`${apiBase}/groups/${id}/delete`);
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/groups/${id}`);
    },
    getTypes() {
      return apiClient.get(`${apiBase}/groups/types`);
    },
    getManyPaginated({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/paginated`, {
        params: formattedParams,
      });
    },
    getManyByDDLFacilitator(kpiId?: string) {
      return apiClient.get(`${apiBase}/groups/by-ddl-facilitator`, {
        params: { kpiId },
      });
    },
    getGroupBundlesPaginated({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/${groupId}/bundles/paginated`, {
        params: formattedParams,
      });
    },
    getGroupUsersPaginated({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/${groupId}/users/paginated`, {
        params: formattedParams,
      });
    },
    getUserGroupByUserIdAndGroupId({
      userId,
      groupId,
    }: {
      userId: string;
      groupId: string;
    }) {
      return apiClient.get(`${apiBase}/groups/${groupId}/users/${userId}`);
    },
    addBundle(values: CommunityBundleFormInput) {
      return apiClient.put(`${apiBase}/groups/add-bundle`, values);
    },
    removeBundle(values: CommunityBundleFormInput) {
      return apiClient.put(`${apiBase}/groups/remove-bundle`, values);
    },
    updateGroupBundles(values: ManageCommunityBundlesFormInput) {
      return apiClient.put(`${apiBase}/groups/update-bundles`, values);
    },
    addMember(values: CreateCommunityMemberFormInput) {
      return apiClient.put(`${apiBase}/groups/add-member`, values);
    },
    updateMember(values: UpdateCommunityMemberFormInput) {
      return apiClient.put(`${apiBase}/groups/update-member`, values);
    },
    updateFacilitatorMember(id: string, facilitator: boolean) {
      return apiClient.put(`${apiBase}/groups/update-member/facilitator`, {
        id,
        facilitator,
      });
    },
    updateGroupMemberNotification(id: string, notify: boolean) {
      return apiClient.put(`${apiBase}/groups/update-member/notify`, {
        id,
        notify,
      });
    },
    updateManagerMember(id: string, manager: boolean) {
      return apiClient.put(`${apiBase}/groups/update-member/manager`, {
        id,
        manager,
      });
    },
    removeMember(values: UpdateCommunityMemberFormInput) {
      return apiClient.put(`${apiBase}/groups/remove-member`, values);
    },
    leaveGroup(values: UpdateCommunityMemberFormInput) {
      return apiClient.put(`${apiBase}/groups/member-leave`, values);
    },
    getPendingMembersByGroup({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/${groupId}/pending-users`, {
        params: formattedParams,
      });
    },
    // Admin Route
    getPendingMembers({
      params,
      filters,
      controller,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        state: filters.get('state'),
        groupName: filters.get('groupName'),
      };

      return apiClient.get(`${apiBase}/groups/pending-users`, {
        params: formattedParams,
        signal: controller.signal,
      });
    },
    getPendingMembersByFacilitator({
      params,
      filters,
      controller,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        state: filters.get('state'),
        groupName: filters.get('groupName'),
      };

      return apiClient.get(`${apiBase}/groups/pending-users`, {
        params: formattedParams,
        signal: controller.signal,
      });
    },
    getPendingUsersCount() {
      return apiClient.get(`${apiBase}/groups/pending-users/count`);
    },
    approveMemberRequest(groupId: string, userId: string) {
      return apiClient.put(
        `${apiBase}/groups/${groupId}/approve-request/${userId}`
      );
    },
    denyMemberRequest(groupId: string, userId: string) {
      return apiClient.put(
        `${apiBase}/groups/${groupId}/deny-request/${userId}`
      );
    },
    getFacilitators(groupId: string) {
      return apiClient.get(`${apiBase}/groups/facilitators/${groupId}`);
    },
    getHasGroupsByUserId(userId: string) {
      return apiClient.get(`${apiBase}/groups/assigned/${userId}`);
    },
    addToSeedTalentPublicGroup() {
      return apiClient.post(`${apiBase}/groups/st-public-group`);
    },
  },
  organizations: {
    getAll() {
      return apiClient.get(`${apiBase}/organizations/`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/organizations/paginated`, {
        params: formattedParams,
      });
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/organizations/${id}`);
    },
    getGroups({ organizationId }: { organizationId: string }) {
      const formattedParams = {
        page: '0',
        limit: '500',
        orderBy: 'name',
      };

      return apiClient.get(
        `${apiBase}/organizations/${organizationId}/groups/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    search(value?: string) {
      return apiClient.get(`${apiBase}/organizations/search`, {
        params: { value },
      });
    },
  },
  reporting: {
    facilitatorCourseReport: {
      getSummary({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/facilitator-course/`, {
          params: formattedParams,
          signal: controller.signal,
        });
      },
      exportSummary({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/facilitator-course/export`, {
          params: formattedParams,
        });
      },
      getDetails({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/facilitator-course/${courseId}`,
          {
            params: formattedParams,
          }
        );
      },
      exportDetails({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/facilitator-course/${courseId}/export`,
          {
            params: formattedParams,
          }
        );
      },
    },
    course: {
      getAdminCourseReport({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/admin/`, {
          signal: controller.signal,
          params: formattedParams,
        });
      },
      exportAdminCourseReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/admin/export`, {
          params: formattedParams,
        });
      },
      getCreatorCourseReport({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
          startDate: filters.get('startDate')
            ? filters.get('startDate').toISOString().split('T')[0]
            : undefined,
          endDate: filters.get('endDate')
            ? filters.get('endDate').toISOString().split('T')[0]
            : undefined,
        };

        return apiClient.get(`${apiBase}/reports/course/creator/`, {
          params: formattedParams,
          signal: controller.signal,
        });
      },
      exportCreatorCourseReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
          startDate: filters.get('startDate')
            ? filters.get('startDate').toISOString().split('T')[0]
            : undefined,
          endDate: filters.get('endDate')
            ? filters.get('endDate').toISOString().split('T')[0]
            : undefined,
        };

        return apiClient.get(`${apiBase}/reports/course/creator/export`, {
          params: formattedParams,
        });
      },
      getCreatorCourseGroupReport({
        courseId,
        params,
        filters,
        controller,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
          state: filters.get('state'),
          startDate: filters.get('startDate')
            ? filters.get('startDate').toISOString().split('T')[0]
            : undefined,
          endDate: filters.get('endDate')
            ? filters.get('endDate').toISOString().split('T')[0]
            : undefined,
        };

        return apiClient.get(`${apiBase}/reports/course/creator/${courseId}`, {
          params: formattedParams,
          signal: controller.signal,
        });
      },
      exportCreatorCourseGroupReport({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          state: filters.get('state'),
          startDate: filters.get('startDate')
            ? filters.get('startDate').toISOString().split('T')[0]
            : undefined,
          endDate: filters.get('endDate')
            ? filters.get('endDate').toISOString().split('T')[0]
            : undefined,
        };

        return apiClient.get(
          `${apiBase}/reports/course/creator/${courseId}/export`,
          {
            params: formattedParams,
          }
        );
      },
      getFacilitatorCourseReport({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/`, {
          params: formattedParams,
          signal: controller.signal,
        });
      },
      exportFacilitatorCourseReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/export`, {
          params: formattedParams,
        });
      },
      getLearnerCourseReport({
        userId,
        isAdmin = false,
        params,
        filters,
      }: {
        userId: string;
        isAdmin?: boolean;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/${
            isAdmin ? 'admin/' : ''
          }learner/${userId}`,
          {
            params: formattedParams,
          }
        );
      },
      exportLearnerCourseReport({
        userId,
        isAdmin = false,
        params,
        filters,
      }: {
        userId: string;
        isAdmin?: boolean;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/${
            isAdmin ? 'admin/' : ''
          }learner/${userId}/export`,
          {
            params: formattedParams,
          }
        );
      },
      getGroupLearnerCourseReport({
        courseId,
        groupId,
        params,
        filters,
        controller,
      }: {
        courseId: string;
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/${courseId}/${groupId}/learners`,
          {
            signal: controller.signal,
            params: formattedParams,
          }
        );
      },
      getGroupLearnerCourseReportStats({
        courseId,
        groupId,
      }: {
        courseId: string;
        groupId: string;
      }) {
        return apiClient.get(
          `${apiBase}/reports/course/${courseId}/${groupId}/stats`
        );
      },
      exportGroupLearnerCourseReport({
        courseId,
        groupId,
        params,
        filters,
      }: {
        courseId: string;
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/${courseId}/${groupId}/learners/export`,
          {
            params: formattedParams,
          }
        );
      },
      getAdminGroupsByCourse({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/admin/${courseId}/groups`,
          {
            params: formattedParams,
          }
        );
      },
      exportAdminGroupsByCourse({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/admin/${courseId}/groups/export`,
          {
            params: formattedParams,
          }
        );
      },
      getFacilitatorGroupsByCourse({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/course/${courseId}/groups`, {
          params: formattedParams,
        });
      },
      exportFacilitatorGroupsByCourse({
        courseId,
        params,
        filters,
      }: {
        courseId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course/${courseId}/groups/export`,
          {
            params: formattedParams,
          }
        );
      },
      getUserCourseDetails({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/user-details`, {
          params: formattedParams,
        });
      },
      exportUserCourseDetails({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
          courseCategoryId: filters.get('courseCategoryId'),
        };

        return apiClient.get(`${apiBase}/reports/course/user-details/export`, {
          params: formattedParams,
        });
      },
      downloadCourseCertificate(userCertificateId: string) {
        multiPartInstance.defaults.responseType = 'arraybuffer';

        return multiPartInstance.get(
          `${apiBase}/reports/course/certificate-download/${userCertificateId}`
        );
      },
      getCreatorCourseTopCommunities({
        courseId,
        startDate,
        endDate,
        state,
        name,
        order,
        controller,
      }: {
        courseId: string;
        startDate: Date | null;
        endDate: Date | null;
        state?: string;
        name?: string;
        order: string;
        controller?: any;
      }) {
        const startDateParam = startDate
          ? startDate.toISOString().split('T')[0]
          : undefined;
        const endDateParam = endDate
          ? endDate.toISOString().split('T')[0]
          : undefined;
        return apiClient.get(
          `${apiBase}/reports/course/creator/${courseId}/top-communities`,
          {
            params: {
              state,
              name,
              order,
              startDate: startDateParam,
              endDate: endDateParam,
            },
            signal: controller.signal,
          }
        );
      },
      getCreatorCourseTopStates({
        courseId,
        startDate,
        endDate,
        state,
        name,
        order,
        controller,
      }: {
        courseId: string;
        startDate: Date | null;
        endDate: Date | null;
        state?: string;
        name?: string;
        order: string;
        controller?: any;
      }) {
        const startDateParam = startDate
          ? startDate.toISOString().split('T')[0]
          : undefined;
        const endDateParam = endDate
          ? endDate.toISOString().split('T')[0]
          : undefined;
        return apiClient.get(
          `${apiBase}/reports/course/creator/${courseId}/top-states`,
          {
            params: {
              state,
              name,
              order,
              startDate: startDateParam,
              endDate: endDateParam,
            },
            signal: controller.signal,
          }
        );
      },
      getCourseCompletions(
        courseId: string,
        startDate: Date | null,
        endDate: Date | null,
        state?: string,
        name?: string,
        controller?: any
      ) {
        const startDateParam = startDate
          ? startDate.toISOString().split('T')[0]
          : undefined;
        const endDateParam = endDate
          ? endDate.toISOString().split('T')[0]
          : undefined;
        return apiClient.get(
          `${apiBase}/reports/course/creator/${courseId}/course-completions`,
          {
            params: {
              state,
              name,
              startDate: startDateParam,
              endDate: endDateParam,
            },
            signal: controller.signal,
          }
        );
      },
      getCreatorCourseStats(
        courseId: string,
        startDate: Date | null,
        endDate: Date | null,
        state?: string,
        name?: string,
        controller?: any
      ) {
        const startDateParam = startDate
          ? startDate.toISOString().split('T')[0]
          : undefined;
        const endDateParam = endDate
          ? endDate.toISOString().split('T')[0]
          : undefined;
        return apiClient.get(
          `${apiBase}/reports/course/creator/${courseId}/stats`,
          {
            params: {
              state,
              name,
              startDate: startDateParam,
              endDate: endDateParam,
            },
            signal: controller.signal,
          }
        );
      },
    },
    group: {
      getAdminGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/group/admin/`, {
          params: formattedParams,
        });
      },
      exportAdminGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/group/admin/export`, {
          params: formattedParams,
        });
      },
      getAdminGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/group/admin/${groupId}/courses`,
          {
            params: formattedParams,
          }
        );
      },
      exportAdminGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/group/admin/${groupId}/courses/export`,
          {
            params: formattedParams,
          }
        );
      },
      getAdminCommunityStats() {
        return apiClient.get(`${apiBase}/reports/group/admin/stats`);
      },
      getAdminTopCommunities() {
        return apiClient.get(`${apiBase}/reports/group/admin/top-communities`);
      },
      getAdminTopLearners() {
        return apiClient.get(`${apiBase}/reports/group/admin/top-learners`);
      },
      getAdminTotalCourses() {
        return apiClient.get(`${apiBase}/reports/group/admin/total-courses`);
      },
      getFacilitatorGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/group/`, {
          params: formattedParams,
        });
      },
      getFacilitatorGroupReportTotalCourses() {
        return apiClient.get(`${apiBase}/reports/group/total-courses`);
      },
      getFacilitatorGroupReportTopCommunitiesAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/group/top-communities`);
      },
      getFacilitatorGroupReportTopLearnersAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/group/top-learners`);
      },
      getTopLearnersByGroup(groupId: string) {
        return apiClient.get(
          `${apiBase}/reports/group/${groupId}/top-learners`
        );
      },
      getTopCoursesByGroup(groupId: string) {
        return apiClient.get(`${apiBase}/reports/group/${groupId}/top-courses`);
      },
      getTotalCoursesByGroup(groupId: string) {
        return apiClient.get(
          `${apiBase}/reports/group/${groupId}/total-courses`
        );
      },
      getTopCommunities() {
        return apiClient.get(`${apiBase}/reports/group/top-communities`);
      },
      getTopLearners() {
        return apiClient.get(`${apiBase}/reports/group/top-learners`);
      },
      getStatsByGroup(groupId: string) {
        return apiClient.get(`${apiBase}/reports/group/${groupId}/stats`);
      },
      getFacilitatorGroupReportStatsAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/group/stats`);
      },
      exportFacilitatorGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/group/export`, {
          params: formattedParams,
        });
      },
      getGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(`${apiBase}/reports/group/${groupId}/courses`, {
          params: formattedParams,
        });
      },
      exportGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };
        return apiClient.get(
          `${apiBase}/reports/group/${groupId}/courses/export`,
          {
            params: formattedParams,
          }
        );
      },
    },
    learner: {
      avgHoursLearning(groupId: string) {
        return apiClient.get(`${apiBase}/reports/learner/avg-hours-learning`, {
          params: { groupId },
        });
      },
      getLearnerReportStatsAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/learner/stats`);
      },
      getLearnerReportLearnersStatsAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/learner/learners-stats`);
      },
      getTopCoursesAsFacilitator() {
        return apiClient.get(`${apiBase}/reports/learner/top-courses`);
      },
      getAdminReport({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          email: filters.get('email'),
          group: filters.get('group'),
        };

        return apiClient.get(`${apiBase}/reports/learner/admin`, {
          params: formattedParams,
          signal: controller.signal,
        });
      },
      exportAdminReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          email: filters.get('email'),
          group: filters.get('group'),
        };

        return apiClient.get(`${apiBase}/reports/learner/admin/export`, {
          params: formattedParams,
        });
      },
      getLearnerReportStats(learnerId: string, isAdmin = false) {
        return apiClient.get(
          `${apiBase}/reports/learner/${
            isAdmin ? 'admin/' : ''
          }${learnerId}/stats`
        );
      },
      getLearnerProfile(learnerId: string) {
        return apiClient.get(`${apiBase}/reports/learner/${learnerId}/profile`);
      },
      getFacilitatorReport({
        params,
        filters,
        controller,
      }: {
        params: PaginationInterface;
        filters: FilterState;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          email: filters.get('email'),
          groupId: filters.get('groupId'),
        };

        return apiClient.get(`${apiBase}/reports/learner/`, {
          params: formattedParams,
          signal: controller.signal,
          timeout: 300000,
        });
      },
      exportFacilitatorReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          email: filters.get('email'),
          groupId: filters.get('groupId'),
        };

        return apiClient.get(`${apiBase}/reports/learner/export`, {
          params: formattedParams,
        });
      },
    },
    courseAssignment: {
      getAdminGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/admin/groups-with-due-dates`,
          {
            params: formattedParams,
          }
        );
      },
      exportAdminGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };
        return apiClient.get(
          `${apiBase}/reports/course-assignment/admin/groups-with-due-dates/export`,
          {
            params: formattedParams,
          }
        );
      },
      getFacilitatorGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/groups-with-due-dates`,
          {
            params: formattedParams,
          }
        );
      },
      exportFacilitatorGroupReport({
        params,
        filters,
      }: {
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };
        return apiClient.get(
          `${apiBase}/reports/course-assignment/groups-with-due-dates/export`,
          {
            params: formattedParams,
          }
        );
      },
      getAdminGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/admin/${groupId}/courses-with-due-dates`,
          {
            params: formattedParams,
          }
        );
      },
      exportAdminGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/admin/${groupId}/courses-with-due-dates/export`,
          {
            params: formattedParams,
          }
        );
      },
      getGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/${groupId}/courses-with-due-dates`,
          {
            params: formattedParams,
          }
        );
      },
      exportGroupCourseReport({
        groupId,
        params,
        filters,
      }: {
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/${groupId}/courses-with-due-dates/export`,
          {
            params: formattedParams,
          }
        );
      },
      getGroupLearnerCourseReport({
        courseId,
        groupId,
        params,
        filters,
        isAdmin = false,
        controller,
      }: {
        courseId: string;
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
        isAdmin: boolean;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/${
            isAdmin ? 'admin/' : ''
          }${groupId}/${courseId}/learners-with-due-dates`,
          {
            signal: controller.signal,
            params: formattedParams,
          }
        );
      },
      exportGroupLearnerCourseReport({
        courseId,
        groupId,
        params,
        filters,
      }: {
        courseId: string;
        groupId: string;
        params: PaginationInterface;
        filters: FilterState;
      }) {
        const formattedParams = {
          ...params,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/reports/course-assignment/${groupId}/${courseId}/learners-with-due-dates/export`,
          {
            params: formattedParams,
          }
        );
      },
    },
  },
  rewards: {
    create(payload: RewardFormInput) {
      return apiClient.post(`${apiBase}/rewards`, payload);
    },
    update(payload: RewardFormInput) {
      return apiClient.put(`${apiBase}/rewards`, payload);
    },
    delete(id: string) {
      return apiClient.put(`${apiBase}/rewards/delete/${id}`);
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/rewards/${id}`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        active: filters.get('active'),
      };

      return apiClient.get(`${apiBase}/rewards/paginated`, {
        params: formattedParams,
      });
    },
    exportRewardCodes(rewardId: string) {
      return apiClient.get(`${apiBase}/rewards/${rewardId}/export`);
    },
  },
  users: {
    createUserAccount(payload: any) {
      return apiClient.post(`${apiBase}/users/`, payload);
    },
    updateUserProfile(payload: any) {
      return apiClient.put(`${apiBase}/users/profile`, payload);
    },
    getUserProfile() {
      return apiClient.get(`${apiBase}/users/profile`);
    },
    searchUsers(value: string) {
      return apiClient.get(`${apiBase}/users/search`, {
        params: { name: value },
      });
    },
    downloadCertificate(userCertificateId: string) {
      multiPartInstance.defaults.responseType = 'arraybuffer';

      return multiPartInstance.get(
        `${apiBase}/users/certificate/download/${userCertificateId}`
      );
    },
    getUserShouldSelectPrimaryGroup(userId: string) {
      return apiClient.get(`${apiBase}/users/primary-group/${userId}`);
    },
  },
  userManagement: {
    getMany({
      params,
      filters,
      controller,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        status: filters.get('status'),
        role: filters.get('role'),
        group: filters.get('group'),
      };

      return apiClient.get(`${apiBase}/user-management/paginated`, {
        signal: controller.signal,
        params: formattedParams,
      });
    },
    getOne(userId: string) {
      return apiClient.get(`${apiBase}/user-management/${userId}`);
    },
    getUTRRecord(courseId: string, userId: string) {
      return apiClient.get(`${apiBase}/user-management/user-test-result`, {
        params: { courseId, userId },
      });
    },
    updateUTRRecord(payload: CourseRecordFormRequest) {
      return apiClient.put(
        `${apiBase}/user-management/update-course-record`,
        payload
      );
    },
    createUser(user: UserFormInput | PublicUserFormInput) {
      return apiClient.post(`${apiBase}/user-management`, user);
    },
    updateUser(user: UserFormInput | PublicUserFormInput) {
      return apiClient.put(`${apiBase}/user-management/`, user);
    },
    deleteUser(user: UserFormInput) {
      return apiClient.put(`${apiBase}/user-management/delete`, user);
    },
    getRoles() {
      return apiClient.get(`${apiBase}/user-management/roles`);
    },
    uploadUsers(uploadRequest: UserUploadFormInput) {
      return apiClient.post(`${apiBase}/user-management/upload`, uploadRequest);
    },
    logInAsUser(userId: string) {
      return apiClient.get(`${apiBase}/user-management/${userId}/log-in-as`);
    },
    mergeUsers(userId: string, newUserId: string) {
      return apiClient.put(
        `${apiBase}/user-management/${userId}/merge-with/${newUserId}`
      );
    },
    searchUsers(value: string, notInGroupId?: string) {
      return apiClient.get(`${apiBase}/user-management/search`, {
        params: { name: value, notInGroupId },
      });
    },
  },
  ddl: {
    performanceReport: {
      multiStore: {
        getCommunities({
          params,
          filters,
          controller,
        }: {
          filters: Map<string, any>;
          params: PaginationInterface;
          controller?: any;
        }) {
          const formattedParams = {
            ...params,
            name: filters.get('name'),
          };

          return apiClient.get(
            `${apiBase}/performance-report/scorecard/stores`,
            {
              params: formattedParams,
              signal: controller.signal,
            }
          );
        },
        export({ filters }: { filters: Map<string, any> }) {
          const formattedParams = {
            name: filters.get('name'),
          };

          return apiClient.get(
            `${apiBase}/performance-report/scorecard/stores/export`,
            {
              params: formattedParams,
            }
          );
        },
      },
      getSingleStoreScoreCards({
        groupId,
        kpiId,
        interval,
      }: {
        groupId: string;
        kpiId: string;
        interval: string;
      }) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/stores/${groupId}/${kpiId}`,
          {
            params: { interval },
          }
        );
      },
      getSingleEmployeeScoreCards({
        groupId,
        userId,
        kpiId,
        interval,
      }: {
        groupId: string;
        userId: string;
        kpiId: string;
        interval: string;
      }) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/stores/${groupId}/${userId}/${kpiId}`,
          {
            params: { interval },
          }
        );
      },
      getSingleEmployeeStats(userId: string, groupId: string) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/stores/${groupId}/user/${userId}/employee-stats`
        );
      },
      getEmployeePerformance({
        groupId,
        kpiName,
        interval,
      }: {
        groupId: string;
        kpiName: string;
        interval: string;
      }) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/stores/${groupId}/${kpiName}/employee-performance`,
          {
            params: { interval },
          }
        );
      },
      getEmployeePaginated({
        groupId,
        kpiName,
        interval,
        params,
        filters,
        controller,
      }: {
        groupId: string;
        kpiName: string;
        interval: string;
        params: PaginationInterface;
        filters: Map<string, any>;
        controller?: any;
      }) {
        const formattedParams = {
          ...params,
          interval,
          name: filters.get('name'),
        };

        return apiClient.get(
          `${apiBase}/performance-report/scorecard/stores/${groupId}/${kpiName}/employees/paginated`,
          {
            signal: controller.signal,
            params: formattedParams,
          }
        );
      },
      getStoreLastUpdatedDate(groupId: string) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/last-updated/store/${groupId}`
        );
      },
      getEmployeeLastUpdatedDate(groupId: string, userId: string) {
        return apiClient.get(
          `${apiBase}/performance-report/scorecard/last-updated/store/${groupId}/user/${userId}`
        );
      },
    },
    updateOrganization(values: IOrganizationFormInput) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/organizations`,
        values
      );
    },
    getOrganizationsPaginated({
      params,
      filters,
      controller,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/organizations/paginated`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    getOneOrganizationById(id: string) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/organizations/${id}`
      );
    },
    getOneGroupById(id: string) {
      return apiClient.get(`${apiBase}/data-driven-learning/groups/${id}`);
    },
    getGroupsByOrganizationPaginated({
      organizationId,
      params,
      filters,
      controller,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/organizations/${organizationId}/groups/paginated`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    getEmployeesByOrganizationAndGroupPaginated({
      organizationId,
      groupId,
      params,
      filters,
      controller,
    }: {
      organizationId: string;
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        status: filters.get('status'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/organization/${organizationId}/group/${groupId}/employees/paginated`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    getEmployeesByOrganizationAndGroup({
      organizationId,
      groupId,
    }: {
      organizationId: string;
      groupId: string;
    }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/organization/${organizationId}/group/${groupId}/employees`
      );
    },
    getSingleStoreEmployeeStatusByGroup({
      groupId,
      kpiId,
      params,
      filters,
      controller,
    }: {
      groupId: string;
      kpiId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/${groupId}/${kpiId}/employee-status/paginated`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    exportSingleStoreEmployeeStatusByGroup({
      groupId,
      kpiId,
      filters,
      controller,
    }: {
      groupId: string;
      kpiId: string;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/${groupId}/${kpiId}/employee-status/export`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    exportEmployeesByOrganizationAndGroup({
      organizationId,
      groupId,
      params,
      filters,
    }: {
      organizationId: string;
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        status: filters.get('status'),
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/organization/${organizationId}/group/${groupId}/employees/export`,
        {
          params: formattedParams,
        }
      );
    },
    uploadEmployeesByOrganizationAndGroup({
      organizationId,
      groupId,
      employeesFileUrl,
    }: {
      organizationId: string;
      groupId: string;
      employeesFileUrl: string;
    }) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/organization/${organizationId}/group/${groupId}/employees/upload`,
        {
          employeesFileUrl,
        }
      );
    },
    updateGroup({
      values,
      organizationId,
      groupId,
    }: {
      values: IGroupFormInput;
      organizationId: string;
      groupId: string;
    }) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/organizations/${organizationId}/groups/${groupId}`,
        values
      );
    },
    updateEmployee({
      values,
      employeeId,
      groupId,
    }: {
      values: IEmployeeFormInput;
      employeeId: string;
      groupId: string;
    }) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/employee/${employeeId}/group/${groupId}`,
        values
      );
    },
    getCompletionRate({ groupId, kpiId }: { groupId: string; kpiId: string }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/completion-rate/group/${groupId}/kpi/${kpiId}`
      );
    },
    getCompletionRateMultiStore({
      groupIds,
      kpiId,
    }: {
      groupIds?: string[];
      kpiId: string;
    }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/multi-store/completion-rate/kpi/${kpiId}`,
        {
          params: { groupIds },
        }
      );
    },
    getSummary({ groupId, kpiId }: { groupId: string; kpiId: string }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/summary-module/group/${groupId}/kpi/${kpiId}`
      );
    },
    getAllTimeSummary({ groupId, kpiId }: { groupId: string; kpiId: string }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/all-time-summary-module/group/${groupId}/kpi/${kpiId}`
      );
    },
    getSummaryMultiStore({
      groupIds,
      kpiId,
    }: {
      groupIds?: string[];
      kpiId: string;
    }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/multi-store/summary-module/kpi/${kpiId}`,
        {
          params: { groupIds },
        }
      );
    },
    getAllTimeSummaryMultiStore({
      groupIds,
      kpiId,
    }: {
      groupIds?: string[];
      kpiId: string;
    }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/multi-store/all-time-summary-module/kpi/${kpiId}`,
        {
          params: { groupIds },
        }
      );
    },
    getMultiStoreCommunitiesPaginated({
      kpiId,
      params,
      filters,
      controller,
    }: {
      kpiId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        kpiId,
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/multi-store/communities/paginated`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    exportMultiStoreCommunities({
      kpiId,
      filters,
      controller,
    }: {
      kpiId: string;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        name: filters.get('name'),
        kpiId,
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/multi-store/communities/export`,
        {
          params: formattedParams,
          signal: controller.signal,
        }
      );
    },
    getEmployeePerformance({
      groupId,
      kpiId,
    }: {
      groupId: string;
      kpiId: string;
    }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/${groupId}/employee-performance`,
        {
          params: { kpiId },
        }
      );
    },
    getEmployeePaginated({
      groupId,
      kpiId,
      params,
      filters,
      controller,
    }: {
      groupId: string;
      kpiId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
      controller?: any;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        kpiId,
      };

      return apiClient.get(
        `${apiBase}/data-driven-learning/single-store/${groupId}/employees/paginated`,
        {
          signal: controller.signal,
          params: formattedParams,
        }
      );
    },
    getLearnerFlow() {
      return apiClient.get(
        `${apiBase}/data-driven-learning/learner-flow/courses`
      );
    },
    getMatchEmployees({ groupId }: { groupId: string }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/match-employees/${groupId}/employees`
      );
    },
    matchEmployeesBudtenderInBulk({
      groupId,
      payload,
    }: {
      groupId: string;
      payload: any;
    }) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/match-employees/${groupId}/employees`,
        payload
      );
    },
    getDutchiePermissionsLocations({ groupId }: { groupId: string }) {
      return apiClient.get(
        `${apiBase}/data-driven-learning/dutchie/${groupId}/permissionsLocation`
      );
    },
    setDutchieStoreName({
      groupId,
      dtStoreName,
    }: {
      groupId: string;
      dtStoreName: string;
    }) {
      return apiClient.put(
        `${apiBase}/data-driven-learning/match-dt-store/${groupId}`,
        {
          dtStoreName,
        }
      );
    },
  },
  kpis: {
    getKpis() {
      return apiClient.get(`${apiBase}/kpi`);
    },
  },
  npmlogs: {
    api: {
      getLogFiles() {
        return apiClient.get(`${apiBase}/npm-log/getLogFiles`);
      },
      getFileContent(fileName: string) {
        return apiClient.get(`${apiBase}/npm-log/getFileContent/${fileName}`);
      },
      getNewestLogFileContent() {
        return apiClient.get(`${apiBase}/npm-log/getNewestLogFileContent`);
      },
    },
    posapi: {
      getLogFiles() {
        return posImporterInstance.get(`${apiBase}/npm-log/getLogFiles`);
      },
      getFileContent(fileName: string) {
        return posImporterInstance.get(
          `${apiBase}/npm-log/getFileContent/${fileName}`
        );
      },
      getNewestLogFileContent() {
        return posImporterInstance.get(
          `${apiBase}/npm-log/getNewestLogFileContent`
        );
      },
    },
  },
  cronjobs: {
    happyCabbage: {
      mapUsersMetadata() {
        return apiClient.post(`${apiBase}/cron-job/mapUsersMetadata`);
      },
      createCourseAssignment() {
        return apiClient.post(`${apiBase}/cron-job/createCourseAssignment`);
      },
      courseCompletionRecords() {
        return apiClient.post(
          `${apiBase}/cron-job/createCourseCompletionRecords`
        );
      },
      budtenderDailySnapshot() {
        return apiClient.post(
          `${apiBase}/cron-job/createBudtenderDailySnapshot`
        );
      },
      groupDailySalesStats() {
        return apiClient.post(`${apiBase}/cron-job/createGroupDailySalesStats`);
      },
      updateAssignedOn(assignedOnDate: any) {
        return apiClient.post(`${apiBase}/cron-job/updateAssignedOn`, {
          assignedOnDate,
        });
      },
      executeCreatorContentReportSummaryRefresh() {
        return apiClient.post(
          `${apiBase}/cron-job/executeCreatorContentReportSummaryRefresh`
        );
      },
      executeCreatorContentReportDetailsRefresh() {
        return apiClient.post(
          `${apiBase}/cron-job/executeCreatorContentReportDetailsRefresh`
        );
      },
      executeFacilitatorCourseReportRefresh() {
        return apiClient.post(
          `${apiBase}/cron-job/executeFacilitatorCourseReportRefresh`
        );
      },
      executeCommunityReportRefresh() {
        return apiClient.post(
          `${apiBase}/cron-job/executeCommunityReportRefresh`
        );
      },
      executeCommunityReportDetailsRefresh() {
        return apiClient.post(
          `${apiBase}/cron-job/executeCommunityReportDetailsRefresh`
        );
      },
      turnOffMaterializedViews() {
        return apiClient.post(`${apiBase}/cron-job/turnOffMaterializedViews`);
      },
      turnOnMaterializedViews() {
        return apiClient.post(`${apiBase}/cron-job/turnOnMaterializedViews`);
      },
      getMaterializedViewSPStatus() {
        return apiClient.get(`${apiBase}/cron-job/getMaterializedViewSPStatus`);
      },
    },
    dutchie: {
      onboardGroup(groupId: string) {
        return posImporterInstance.post(
          `${apiBase}/dutchie/onboard/${groupId}`
        );
      },
      mapEmployeeMetadata() {
        return posImporterInstance.post(
          `${apiBase}/dutchie/mapEmployeesMetadata`
        );
      },
      mapProductsAttributes() {
        return posImporterInstance.post(
          `${apiBase}/dutchie/mapProductsAttributes`
        );
      },
      mapProducts() {
        return posImporterInstance.post(`${apiBase}/dutchie/mapProducts`);
      },
      mapTransactions() {
        return posImporterInstance.post(`${apiBase}/dutchie/mapTransactions`);
      },
      mapInventory() {
        return posImporterInstance.post(`${apiBase}/dutchie/mapInventory`);
      },
      executeEmployeePctDiscountRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeEmployeePctDiscountRefresh`
        );
      },
      executeStoreTicketSizeRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeStoreTicketSizeRefresh`
        );
      },
      executeStorePctDiscountRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeStorePctDiscountRefresh`
        );
      },
      executeStoreUnitsPerTransactionRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeStoreUnitsPerTransactionRefresh`
        );
      },
      executeEmployeeTicketSizeRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeEmployeeTicketSizeRefresh`
        );
      },
      executeEmployeeUnitsPerTransactionRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeEmployeeUnitsPerTransactionRefresh`
        );
      },
      executeEmployeeAvgUnitPriceRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeEmployeeAvgUnitPriceRefresh`
        );
      },
      executeStoreTransactionsPerHourRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeStoreTransactionsPerHourRefresh`
        );
      },
      executeEmployeeTransactionsPerHourRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeEmployeeTransactionsPerHourRefresh`
        );
      },
      executeStoreAvgUnitPriceRefresh() {
        return posImporterInstance.post(
          `${apiBase}/cron-job/executeStoreAvgUnitPriceRefresh`
        );
      },
    },
    seedtalent: {
      generateUserCourses() {
        return apiClient.post(`${apiBase}/cron-job/generateUserCourses`);
      },
      executeINSERTEngagementReportDaily() {
        return apiClient.post(
          `${apiBase}/cron-job/executeINSERTEngagementReportDaily`
        );
      },
      flushEngagementReportCache() {
        return apiClient.get(`${apiBase}/cron-job/flushEngagementReportCache`);
      },
    },
  },
  integrationPartner: {
    getIntegrationPartner() {
      return apiClient.get(`${apiBase}/integration-partner`);
    },
  },
  courseRound: {
    getCourseRoundsByCourseId(courseId: string) {
      return apiClient.get(`${apiBase}/course-round/course/${courseId}`);
    },
  },
  rewardTypes: {
    getAll() {
      return apiClient.get(`${apiBase}/reward-type`);
    },
  },
  assignCourse: {
    create(payload: AssignCourseFormInput) {
      return apiClient.post(`${apiBase}/organization-assignment`, {
        name: payload.name,
        organizationId: payload.organizationId,
        assignmentType: payload.assignmentType,
      });
    },
    createGroupCourseAssignment(payload: CreateGroupCourseAssignmentRequest) {
      return apiClient.post(
        `${apiBase}/organization-assignment/group-course-assignment`,
        payload
      );
    },
    createUserAssignment(payload: CreateUserAssignmentRequest) {
      return apiClient.post(
        `${apiBase}/organization-assignment/user-assignment`,
        payload
      );
    },
    sendEmailToUserAssignment(payload: UserAssignmentEmailRequest) {
      return apiClient.post(
        `${apiBase}/organization-assignment/user-assignment-email`,
        payload
      );
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/organization-assignment/${id}`);
    },
    getSummaryDetails(id: string) {
      return apiClient.get(
        `${apiBase}/organization-assignment/${id}/summary-details`
      );
    },
    getAll({
      organizationId,
      params,
      filters,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/organization-assignment/${organizationId}/summary`,
        {
          params: formattedParams,
        }
      );
    },
    getOrganizationGroupsPaginated({
      organizationId,
      isOrganizationManager,
      params,
      filters,
    }: {
      organizationId: string;
      isOrganizationManager: boolean;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        isOrganizationManager: isOrganizationManager,
        name: filters.get('name'),
        state: filters.get('state'),
      };

      return apiClient.get(
        `${apiBase}/organization-assignment/${organizationId}/groups/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    getOrganizationHasGroups(organizationId: string) {
      return apiClient.get(
        `${apiBase}/organization-assignment/${organizationId}/hasGroups`
      );
    },
    getCoursesPaginated({
      params,
      filters,
      groupsIds,
      ids,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
      groupsIds?: string[];
      ids?: any[];
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
        groupsIds,
        idsToIgnore: ids,
      };
      return apiClient.get(
        `${apiBase}/organization-assignment/courses/paginated/`,
        {
          params: formattedParams,
        }
      );
    },
    remove(assignmentId: string) {
      return apiClient.delete(
        `${apiBase}/organization-assignment/${assignmentId}`
      );
    },
  },
};
export default httpRoutes;
