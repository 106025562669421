import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useParams, useLocation } from 'react-router-dom';

import EmailFormField from 'components/formFields/EmailFormField2';
import Button from 'components/buttons/Button';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';

export interface IValidateEmailInputs {
  email: string;
  organizationId: string;
  courseReference: string;
  redirectUrl?: string;
}

const ValidateEmail = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { callService } = useCallService();

  const { organizationId, courseReference } = useParams();
  const location: any = useLocation();

  const isOnDashboard = location.pathname.includes('dashboard');

  const onSubmit = async (values: IValidateEmailInputs) => {
    const { response } = await callService({
      resource:
        httpRoutes.authentication.organization.validateEmailRequest(values),
    });

    if (response) {
      // if auth response
      // if empty response
      // if invalid response
      setIsSubmitted(true);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IValidateEmailInputs>({
    defaultValues: {
      email: '',
      organizationId: organizationId,
      courseReference: courseReference,
      redirectUrl: isOnDashboard
        ? `organization/${organizationId}/dashboard/complete-validation`
        : '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '100%',
          }}
        >
          {!isSubmitted
            ? 'Please enter your email below.'
            : 'Validation email sent!'}
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          {!isSubmitted
            ? // eslint-disable-next-line quotes
              "We'll send you a magic link to log in."
            : 'Click the link in your email to continue'}
        </Box>
      </Grid>
      <Grid xs={12} item mb={3}>
        {!isSubmitted && (
          <EmailFormField
            name="email"
            control={control}
            placeholder="Email"
            errors={errors.email}
            margin="dense"
          />
        )}
      </Grid>
      <Grid item xs={12} mb={1}>
        {!isSubmitted && (
          <Button
            type="submit"
            label="Send email"
            disabled={isSubmitting}
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '20px',
              lineHeight: '24px',
              background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
            }}
          />
        )}
      </Grid>
    </form>
  );
};

export default ValidateEmail;
