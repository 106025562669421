interface Kpi {
  kpi: string;
  name: string;
  id: string;
}

interface Stat {
  value: string;
  kpi: Kpi;
}
export const filterKpiOptions = (
  isHappyCabbage: boolean,
  kpiOptions: any[]
) => {
  return kpiOptions.filter((item: any) =>
    isHappyCabbage ? item.value : item.kpi != 'pct_sales_house'
  );
};

export const findKpiId = (kpi: string, kpis: Kpi[]): any => {
  return kpis.find((item: Kpi) => item.kpi === kpi)?.id;
};

export const getValueByKpi = (stats: Stat[], kpiKey: string): string | undefined => {
  const stat = stats.find((s) => s.kpi.kpi === kpiKey);
  return stat?.value;
};