import { Grid, Box } from '@mui/material';
import useCallService from 'hooks/useCallService';
import {useNavigate, useParams} from 'react-router-dom';
import httpRoutes from 'utils/httpRoutes';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from 'components/buttons/Button';
import PasswordFormField from 'components/formFields/PasswordFormField2';

export interface IForgotPasswordInputs {
  token: string;
  password: string;
  passwordConfirmation: string;
}

const ResetPassword = () => {
  const { callService } = useCallService();
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: IForgotPasswordInputs) => {
    const { response } = await callService({
      resource: httpRoutes.authentication.resetPasswordConfirmation(values),
    });

    if (response) {
      navigate('/login');
    }
  };

  const validationSchema = Yup.object().shape({
    token: Yup.string().required(),
    password: Yup.string().max(255).required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password must be confirmed'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IForgotPasswordInputs>({
    defaultValues: {
      token: token,
      password: '',
      passwordConfirmation: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '100%',
          }}
        >
          Reset password
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          Please set your new password to continue.
        </Box>
      </Grid>
      <Grid xs={12} item mb={3}>
        <PasswordFormField
          name="password"
          control={control}
          placeholder="Password"
          errors={errors.password}
        />
      </Grid>
      <Grid xs={12} item mb={3}>
        <PasswordFormField
          name="passwordConfirmation"
          control={control}
          placeholder="Confirm Password"
          errors={errors.passwordConfirmation}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="submit"
          label="Reset password"
          disabled={isSubmitting}
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
          }}
        />
      </Grid>
    </form>

  );
};

export default ResetPassword;
