import AuthWrapper from '../components/AuthWrapper';
import ValidateEmailFragment from '../fragments/passwordLess/ValidateEmail';
import RightDescriptionContent from '../fragments/rightDescriptionContent';

const ValidateEmail = () => (
  <AuthWrapper
    leftContent={<ValidateEmailFragment />}
    rightContent={<RightDescriptionContent isPrivate={false} />}
  />
);

export default ValidateEmail;
