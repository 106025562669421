import { ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';

const FormSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Grid container>
      <Typography variant="h5" sx={{ pt: 2 }}>
        {title}
      </Typography>
      <Grid container>{children}</Grid>
    </Grid>
  );
};

export default FormSection;
