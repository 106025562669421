import React, { createContext, useContext, useState, memo } from 'react';
import {
  Box,
  Select,
  styled,
  MenuItem,
  TableContainer,
  IconButton,
  Avatar,
  Button,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { Filters } from 'components/filters/components';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Icon from 'icons';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { GridSortDirection } from '@mui/x-data-grid';
import { FilterType } from 'components/filters/filterTypes';
import LoadingTableBody from 'components/dataGrid/components/LoadingTableBody';
import { format as dateFnsFormat } from 'date-fns';
import NoDataFound from 'components/NoDataFound';
import { useTheme } from '@mui/material/styles';
import { DataGridColumnProps, usdFormatter } from './DataGridColumn';

const Container = styled(Box)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPaginationSelect = styled(Select)`
  color: white;
  border-radius: 30px;
  height: 26px;
  font-family: 'Roboto';
  background: linear-gradient(180deg, #4b8994 0%, #2e5170 131.25%);
`;

export type DataGridModel = {
  rows: any[];
  totalRows: number;
};

export type PaginationInterface = {
  page: number;
  limit: number;
  order?: GridSortDirection;
  orderBy?: string;
};

const INITIAL_DATAGRID_STATE: PaginationInterface = {
  page: 0,
  limit: 50,
  order: 'asc',
  orderBy: '',
};

export const DataGridContext = createContext<{
  state: PaginationInterface;
  setState: any;
}>({ state: INITIAL_DATAGRID_STATE, setState: () => null });

const DataGridProvider = DataGridContext.Provider;

export const useDataGridState = () => {
  const { state, setState } = useContext(DataGridContext);
  return { state, setState };
};

export const ControlledDataGrid = ({ children }: any) => {
  const [state, setState] = useState(INITIAL_DATAGRID_STATE);

  return (
    <DataGridProvider value={{ state, setState }}>{children}</DataGridProvider>
  );
};

interface IPagination {
  totalRows: number;
  page: number;
  rowsPerPage: number;
  handlePageChange: (event: any, page: any) => void;
  handleRowsPerPageChange: (event: any) => void;
}

const Pagination = ({
  totalRows,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
}: IPagination) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const hasPrevious = page !== 0;
  const hasNext = totalRows > 0 && page + 1 !== totalPages;

  const handleBackButtonClick = (event: any) => {
    handlePageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    handlePageChange(event, page + 1);
  };

  const from = totalRows > 0 ? rowsPerPage * page + 1 : 0;
  const to = rowsPerPage * (page + 1);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Rows Per Page
        </Box>
        <StyledPaginationSelect
          label={`${rowsPerPage}`}
          value={rowsPerPage}
          IconComponent={(props: any) => (
            <KeyboardArrowDownRoundedIcon
              sx={{ color: 'white !important' }}
              className={`material-icons ${props.className}`}
            />
          )}
          onChange={handleRowsPerPageChange}
        >
          {[25, 50, 100].map((option) => (
            <MenuItem key={`menu-item-${option}`} value={option}>
              {option}
            </MenuItem>
          ))}
        </StyledPaginationSelect>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            display: matchDownSM ? 'none' : 'block',
          }}
        >
          {`${from} - ${to > totalRows ? totalRows : to} of ${totalRows}`}
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Box sx={{ cursor: 'pointer' }}>
            <IconButton disabled={!hasPrevious} onClick={handleBackButtonClick}>
              <Avatar
                sx={{
                  background: hasPrevious
                    ? 'linear-gradient(180deg, #4B8994 0%, #2E5170 131.25%)'
                    : 'white',
                  height: 30,
                  width: 30,
                }}
              >
                <KeyboardArrowLeftRoundedIcon
                  sx={{
                    color: hasPrevious ? 'white' : '#205072',
                  }}
                  fontSize="medium"
                />
              </Avatar>
            </IconButton>
          </Box>
          <Box sx={{ cursor: 'pointer' }}>
            <IconButton disabled={!hasNext} onClick={handleNextButtonClick}>
              <Avatar
                sx={{
                  background: hasNext
                    ? 'linear-gradient(180deg, #4B8994 0%, #2E5170 131.25%)'
                    : 'white',
                  height: 30,
                  width: 30,
                }}
              >
                <KeyboardArrowRightRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: hasNext ? 'white' : '#205072',
                  }}
                />
              </Avatar>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface IHeader {
  filters: FilterType[];
  handleFilterChange: (value: any) => void;
  handleExport?: () => void;
  filtersButtonCallback?: () => void;
  tableTitle?: string;
  sync?: boolean;
}

const Header = ({
  filters,
  handleFilterChange,
  handleExport,
  filtersButtonCallback,
  tableTitle,
  sync,
}: IHeader) => {
  const [exportLoading, setExportLoading] = useState(false);
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const onExport = async () => {
    if (handleExport) {
      try {
        setExportLoading(true);
        await handleExport();
      } finally {
        setExportLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '15px 15px 0 0',
        width: '100%',
        background: 'linear-gradient(180deg, #4b8994 0%, #2e5170 131.25%)',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px 24px',
        gap: 1,
        boxShadow: '10px black',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexDirection: matchDownSM ? 'column' : 'row',
          justifyContent: 'space-between',
          color: 'white',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '130%',
            width: '100%',
          }}
        >
          {tableTitle || ''}
        </Box>

        <Box
          sx={{
            alignItems: 'right',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Filters filters={filters} onFilterChange={handleFilterChange} />
        </Box>
        {sync && (
          <Box
            sx={{
              ...(matchDownSM && { width: '100%' }),
              alignItems: 'right',
            }}
          >
            <Button
              sx={{
                p: 2,
                background: 'linear-gradient(180deg, #56C596 0%, #16915D 100%)',
                color: 'white',
                borderRadius: '30px',
                ...(matchDownSM && { width: '100%' }),
              }}
              onClick={filtersButtonCallback}
            >
              <SearchIcon />
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%',
                  textTransform: 'uppercase',
                }}
              >
                Apply Filters
              </Box>
            </Button>
          </Box>
        )}

        {handleExport && (
          <Box
            sx={{
              ...(matchDownSM && { width: '100%' }),
              alignItems: 'right',
            }}
          >
            <Button
              sx={{
                p: 2,
                background: 'linear-gradient(180deg, #56C596 0%, #16915D 100%)',
                color: 'white',
                borderRadius: '30px',
                ...(matchDownSM && { width: '100%' }),
              }}
              disabled={exportLoading}
              onClick={onExport}
            >
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '100%',
                  textTransform: 'uppercase',
                }}
              >
                {exportLoading ? 'Exporting..' : 'Export'}
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface ITableBodyRow {
  row: any;
  columns: any[];
  actionColumn: any;
  renderFormattedValue: (field: any, type: any, label: any) => any;
  rowIdentifier: string | number;
  handleCellClick?: (params: any) => void;
  handleRowClick?: (params: any) => void;
}

const TableBodyRow = ({
  row,
  columns,
  actionColumn: ActionColumn,
  renderFormattedValue,
  rowIdentifier,
  handleCellClick,
  handleRowClick,
}: ITableBodyRow) => {
  const onRowClick = () => {
    if (handleRowClick) {
      handleRowClick(row);
    }
  };

  const onCellClick = (field: string) => {
    if (handleCellClick) {
      handleCellClick({ row, field });
    }
  };

  return (
    <TableRow
      style={{ background: 'white' }}
      sx={{ cursor: handleRowClick ? 'pointer' : 'auto', height: '54px' }}
      onClick={onRowClick}
    >
      {columns.map(
        ({ field, ColumnComponent, flex, type, label, align }, index) => {
          const textAlign = align || (type === 'number' ? 'right' : 'left');

          const _isClickable =
            (handleRowClick && index === 0) || handleCellClick;

          return (
            <TableCell
              key={`${field}-${row[rowIdentifier]}`}
              sx={{
                py: 0,
                flex,
                color: _isClickable ? '#508AC2' : 'black',
                fontSize: _isClickable ? '16px' : '14px',
                cursor: _isClickable ? 'pointer' : 'auto',
              }}
              align={textAlign}
              onClick={() => {
                onCellClick(field);
              }}
            >
              {ColumnComponent ? (
                <ColumnComponent
                  value={row[field]}
                  type={type}
                  label={label}
                  row={row}
                />
              ) : (
                <Box
                  sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '130%',
                    color: _isClickable ? '#508AC2' : 'black',
                  }}
                >
                  {renderFormattedValue(row[field], type, label)}
                </Box>
              )}
            </TableCell>
          );
        }
      )}
    </TableRow>
  );
};

interface ITableHeader {
  columns: any[];
  orderBy?: string;
  order?: GridSortDirection;
  handleSortColumn: (field: string, order: string) => void;
}

const TableHeader = ({
  columns,
  orderBy,
  order,
  handleSortColumn,
}: ITableHeader) => {
  const onSortColumn = (field: string, orderBy: string) => (event: any) => {
    handleSortColumn(field, orderBy);
  };

  return (
    <TableHead>
      <TableRow style={{ background: 'white' }}>
        {columns.map((column) => {
          const columnIsSorted = orderBy === column.field;

          return (
            <TableCell
              key={column.field}
              sx={{
                borderBottom: `1px solid ${
                  columnIsSorted ? '#56C596' : 'rgba(224, 224, 224, 1)'
                }`,
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '150%',
                  }}
                >
                  {column.headerName}
                </Box>
                {column.sortable && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={onSortColumn(column.field, 'desc')}
                    >
                      <Icon
                        name={
                          columnIsSorted && order === 'desc'
                            ? 'filled-sort-arrow-down'
                            : 'sort-arrow-down'
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={onSortColumn(column.field, 'asc')}
                    >
                      <Icon
                        name={
                          columnIsSorted && order === 'asc'
                            ? 'filled-sort-arrow-up'
                            : 'sort-arrow-up'
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IDatatable {
  totalRows: number;
  filters: FilterType[];
  handleFilterChange: (value: any) => void;
  rows: any[];
  columns: DataGridColumnProps[];
  sync?: boolean;
  loading?: boolean;
  actionColumn?: any;
  showEmptyRows?: boolean;
  rowIdentifier?: string | number;
  onCellClick?: (params: any) => void;
  onRowClick?: (params: any) => void;
  handleExport?: () => void;
  filtersButtonCallback?: () => void;
  tableTitle?: string;
}

const DataGrid = ({
  filters,
  handleFilterChange,
  totalRows,
  rows,
  columns,
  actionColumn: ActionColumn,
  sync = false,
  loading = false,
  showEmptyRows = true,
  rowIdentifier = 'id',
  onCellClick,
  onRowClick,
  handleExport,
  filtersButtonCallback,
  tableTitle = '',
}: IDatatable) => {
  const { state, setState } = useContext(DataGridContext);

  const { order, orderBy, page, limit } = state;

  const onPageChange = (_: any, page: any) => {
    setState((prevState: any) => ({ ...prevState, page }));
  };

  const onRowsPerPageChange = (event: any) => {
    const limit = event.target.value;
    setState((prevState: any) => ({ ...prevState, limit }));
  };

  const onSortColumn = (field: string, order: string) => {
    const orderBy = field;

    setState((prevState: any) => {
      if (prevState.orderBy == orderBy && prevState.order === order) {
        return {
          ...prevState,
          order: INITIAL_DATAGRID_STATE.order,
          orderBy: INITIAL_DATAGRID_STATE.orderBy,
        };
      }

      return { ...prevState, order, orderBy };
    });
  };

  const handleFilterChangeWithoutPagination = (value: any) => {
    if (!sync) {
      setState((prevState: any) => {
        return {
          page: 0,
          limit: prevState.limit,
          order: prevState.order,
          orderBy: prevState.orderBy,
        };
      });
    }

    handleFilterChange(value);
  };

  const renderFormattedValue = (value: any, type: any, label: any) => {
    if (type !== 'boolean' && !value && value !== 0) {
      return '-';
    }

    let response = value;

    /* eslint-disable no-restricted-globals */
    if (type) {
      if (type === 'fixed' && !isNaN(value)) {
        response = Number(value).toFixed(2);
      }

      if (type === 'percentage') {
        response = `${(Number(value) * 100).toPrecision(4)} %`;
      }

      if (type === 'usdCurrency') {
        response = usdFormatter.format(Number(value));
      }

      if (type === 'dateTime') {
        response = dateFnsFormat(new Date(value), 'MM/dd/yy h:mm a');
      }

      if (type === 'boolean') {
        if (value) {
          return <CheckRoundedIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />;
        } else {
          return <CloseRoundedIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />;
        }
      }
    }

    if (label) {
      response += label;
    }

    return response;
  };

  const emptyRows = limit - rows.length;

  return (
    <Container id="datagrid-container">
      <TableContainer id="table-container" sx={{ overflowY: 'hidden' }}>
        <Pagination
          totalRows={totalRows}
          page={page}
          rowsPerPage={limit}
          handlePageChange={onPageChange}
          handleRowsPerPageChange={onRowsPerPageChange}
        />
        <Box
          sx={{
            display: 'flex',
            borderRadius: '15px',
            flexDirection: 'column',
          }}
        >
          <Header
            sync={sync}
            filters={filters}
            handleFilterChange={handleFilterChangeWithoutPagination}
            handleExport={handleExport}
            tableTitle={tableTitle}
            filtersButtonCallback={filtersButtonCallback}
          />
          <Box sx={{ width: '100%', overflow: 'auto' }}>
            <Table>
              <TableHeader
                columns={columns}
                orderBy={orderBy}
                order={order}
                handleSortColumn={onSortColumn}
              />
              {loading && (
                <TableBody>
                  <LoadingTableBody rowsPerPage={limit} columns={columns} />
                </TableBody>
              )}

              {!loading && rows.length > 0 && (
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableBodyRow
                        key={index}
                        handleCellClick={onCellClick}
                        handleRowClick={onRowClick}
                        row={row}
                        rowIdentifier={rowIdentifier}
                        columns={columns}
                        actionColumn={ActionColumn}
                        renderFormattedValue={renderFormattedValue}
                      />
                    );
                  })}
                  {emptyRows > 0 && showEmptyRows && (
                    <TableRow style={{ height: 52 * emptyRows }}>
                      <TableCell colSpan={columns.length} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </Box>
          {!loading && rows.length === 0 && <NoDataFound />}
        </Box>
        {rows.length >= 50 && (
          <Pagination
            totalRows={totalRows}
            page={page}
            rowsPerPage={limit}
            handlePageChange={onPageChange}
            handleRowsPerPageChange={onRowsPerPageChange}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default memo(DataGrid);
