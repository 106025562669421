import AuthWrapper from '../components/AuthWrapper';
import Authentication from '../fragments/login/Authentication';
import RightDescriptionContent from '../fragments/rightDescriptionContent';

const Login = () => (
  <AuthWrapper
    leftContent={<Authentication />}
    rightContent={<RightDescriptionContent isPrivate={true} />}
  />
);

export default Login;
