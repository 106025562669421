/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { NumberFormField, TextFormField } from 'components/formFields';

import IconButton from 'components/buttons/IconButton';

import RichTextFormField from 'components/formFields/RichTextFormField';
import { transformEmptyStringIntoUndefined } from 'utils/common';

export type AssesmentFormInput = {
  id?: string;
  successMessage?: string;
  firstFailureMessage?: string;
  secondFailureMessage?: string;
  thirdFailureMessage?: string;
  fourthFailureMessage?: string;
  firstCourseRoundAttempts?: number;
  lockoutPeriod?: number;
  secondCourseRoundAttempts?: number;
};

export const fourthValidationStep = Yup.object().shape({
  firstCourseRoundAttempts: Yup.number()
    .integer('Must be an integer')
    .transform(transformEmptyStringIntoUndefined)
    .min(0, 'Must be zero or greater')
    .nullable(true)
    .notRequired(),
  lockoutPeriod: Yup.number()
    .integer('Must be an integer')
    .transform(transformEmptyStringIntoUndefined)
    .min(0, 'Must be zero or greater')
    .nullable(true)
    .notRequired(),
  secondCourseRoundAttempts: Yup.number()
    .integer('Must be an integer')
    .transform(transformEmptyStringIntoUndefined)
    .min(0, 'Must be zero or greater')
    .nullable(true)
    .notRequired(),
  successMessage: Yup.string().required('Success Message is required'),
  firstFailureMessage: Yup.string().required(
    'First Failure Message is required'
  ),
  secondFailureMessage: Yup.string().required(
    'Second Failure Message is required'
  ),
  thirdFailureMessage: Yup.string().required('Third Message is required'),
  fourthFailureMessage: Yup.string().required('Fourth Message is required'),
});

const Assessments = ({
  defaultValues,
}: {
  defaultValues?: AssesmentFormInput;
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  return (
    <Grid container flexDirection="column" sx={{ p: 2 }}>
      <Typography variant="h3" sx={{ pb: 2 }}>
        For Assessments
      </Typography>
      <Grid item xs={12} mb={1}>
        <Typography variant="h4">Assessments Settings</Typography>
      </Grid>
      <Grid item container mt={1}>
        <Grid item xs={12} mb={1}>
          <TextFormField
            name="assessmentId"
            control={control}
            label="Assessment Id"
            errors={errors.assessmentId}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid item container mb={2} spacing={1}>
        <Grid item xs={6} md={4}>
          <NumberFormField
            name="firstCourseRoundAttempts"
            control={control}
            label="# of Attempts (Round 1)"
            errors={errors.firstCourseRoundAttempts}
            margin="dense"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <NumberFormField
            name="lockoutPeriod"
            control={control}
            label="Lockout period"
            errors={errors.lockoutPeriod}
            margin="dense"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <NumberFormField
            name="secondCourseRoundAttempts"
            control={control}
            label="# of Attempts (Round 2)"
            errors={errors.secondCourseRoundAttempts}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} mb={1}>
            <Box
              sx={{
                color: '#162738',
                fontFamily: 'Roboto',
                fontSize: '22px',
                fontWeight: '500',
              }}
            >
              Success and failure messages
            </Box>
          </Grid>
          <Box>
            <IconButton onClick={handleOpen} color="inherit">
              {open ? <RemoveRoundedIcon /> : <AddRoundedIcon />}
            </IconButton>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
      </Box>
      {open && (
        <Grid container mt={2}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4">Success Messages</Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h5">Message for success</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextFormField
              name="successMessage"
              label="Message for success"
              control={control}
              // value={successMessageValue}
              // onChange={setSuccessMessageValue}
              placeholder="Message for success"
              errors={errors.successMessage}
            />
          </Grid>
          <Grid item xs={12} mb={2} marginTop={2}>
            <Typography variant="h4">User Status Failure Messages</Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h5">First Failure</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextFormField
              name="firstFailureMessage"
              label="First Failure"
              placeholder="Message for first failure"
              control={control}
              errors={errors.firstFailureMessage}
            />
          </Grid>
          <Grid item xs={12} mb={2} marginTop={2}>
            <Typography variant="h5">Second Failure</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextFormField
              name="secondFailureMessage"
              label="Second Failure"
              placeholder="Message for second failure"
              control={control}
              errors={errors.secondFailureMessage}
            />
          </Grid>
          <Grid item xs={12} mb={2} marginTop={2}>
            <Typography variant="h5">Third Failure</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextFormField
              name="thirdFailureMessage"
              label="Third Failure"
              placeholder="Message for third failure"
              control={control}
              errors={errors.thirdFailureMessage}
            />
          </Grid>
          <Grid item xs={12} mb={2} marginTop={2}>
            <Typography variant="h5">Fourth time fail</Typography>
          </Grid>
          <Grid item xs={12}>
            <RichTextFormField
              name="fourthFailureMessage"
              placeholder="Message for fourth time fail"
              label="Fourth time fail"
              control={control}
              errors={errors.fourthFailureMessage}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Assessments;
