import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { CloseIcon, SaveIcon } from 'material-icons';

import { AsyncSelectFormField } from 'components/formFields';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

import { UserFormInput } from '../forms/UserForm';
import DisplayText from 'components/DisplayText';

const MergeUser = ({
  user,
  onSuccess,
}: {
  user: any;
  onSuccess: () => void;
}) => {
  const [open, setOpen] = useState(true);
  const [newUserId, setNewUserId] = useState(undefined);

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: any) => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.mergeUsers(
        values.userId,
        values.newUserId
      ),
      successMessage: 'Users merged successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  const getUsers = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.searchUsers(value),
    });

    if (response) {
      return response.map((item: UserFormInput) => {
        return {
          id: item.id,
          label: `${item.firstName} ${item.lastName} (${item.email})`,
        };
      });
    }
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string().max(255).required('Please select a user'),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      userId: user.id,
      newUserId: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });
  
  watch('newUserId');

  useEffect(() => {
    const subscription = watch((value) => setNewUserId(value.newUserId));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Merge Users
          </Typography>
          <Collapse in={open}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Caution: {user.firstName} {user.lastName} ({user.email}) will be
              archived.
            </Alert>
          </Collapse>
          <Grid item sx={{ m: 1 }}>
            <DisplayText
              text={`Merging ${user.firstName} ${user.lastName} (${user.email}) into:`}
              type="bodyMedium"
            />
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
            sx={{ p: 1 }}
          >
            {' '}
            <Grid item xs={12} mt={1}>
              <AsyncSelectFormField
                name="newUserId"
                control={control}
                label="Search User"
                getOptions={getUsers}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" columnSpacing={1}>
            <Button variant="text" onClick={hideDialog}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={(isSubmitting || !newUserId)}
              loading={isSubmitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MergeUser;