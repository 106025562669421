import { useState, useEffect } from 'react';

import { useCallService } from 'hooks';

import MultiStepForm from '../forms/MultiStepForm';

import httpRoutes from 'utils/httpRoutes';
import { formatCourseFormValues } from '../utils';
import LoadingState from 'components/LoadingState';
import { CourseFormInput } from 'types/courseTypes/CourseFormInput';

const Edit = ({
  onSuccess,
  courseId,
}: {
  onSuccess: (scormAlert: boolean) => void;
  courseId: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState<CourseFormInput>();
  const { callService } = useCallService();

  const onSubmit = async (values: CourseFormInput) => {
    try {
      setIsSubmitting(true);
      const formattedValues = formatCourseFormValues(values);

      const { response } = await callService({
        resource: httpRoutes.courseManagement.updateAdmin(formattedValues),
        successMessage: 'Course updated successfully!',
      });

      if (response) {
        onSuccess(Boolean(values.courseFileChanged));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getItem = async () => {
      const { response } = await callService({
        resource: httpRoutes.courseManagement.getOne(courseId),
      });

      if (response) {
        const {
          courseMessage,
          courseStates,
          courseRounds,
          courseType,
          courseIncentive,
          courseCommunication,
          courseOutreach,
          externalCourseId,
          revisionDate,
          dateFirstPublished,
          courseSourceId,
          ...otherValues
        } = response;

        const safeCourseMessage = courseMessage || {};

        const firstCourseRound =
          courseRounds.find((item: any) => item.roundNumber == 1) || {};
        const secondCourseRound =
          courseRounds.find((item: any) => item.roundNumber == 2) || {};

        const { attempts: firstCourseRoundAttempts, lockoutPeriod } =
          firstCourseRound;
        const { attempts: secondCourseRoundAttempts } = secondCourseRound;

        const {
          successMessage,
          firstFailureMessage,
          secondFailureMessage,
          thirdFailureMessage,
          fourthFailureMessage,
        } = safeCourseMessage;

        const isAssessment = courseType.name === 'Assessments';
        const is360Training = courseType.name === '360 Training';

        const defaultValues = {
          ...otherValues,
          firstPublishedDate: dateFirstPublished,
          firstCourseRoundAttempts,
          lockoutPeriod,
          secondCourseRoundAttempts,
          successMessage,
          firstFailureMessage,
          secondFailureMessage,
          thirdFailureMessage,
          fourthFailureMessage,
          courseTypeName: courseType ? courseType.name : '',
          courseMessage,
          courseIncentive,
          courseCommunication,
          courseOutreach,
          assessmentId: isAssessment ? externalCourseId : '',
          externalCourseId: is360Training ? externalCourseId : '',
          courseBuilderId: courseSourceId === 3 ? externalCourseId : '',
          courseSourceId: courseSourceId,
          rewardTypeId: courseIncentive?.rewardTypeId,
          rewardAssigned: !!courseIncentive,
          states: courseStates
            ? courseStates.map((item: any) => item.courseState)
            : [],
          revisionDate,
        };

        if (courseIncentive) {
          defaultValues.rewardTypeName = courseIncentive.rewardType.name;
          if (courseIncentive.rewardType.name === 'Swag') {
            defaultValues.swagName = courseIncentive.name;
            defaultValues.swagType = courseIncentive.type;
            defaultValues.swagCompletionThreshold =
              courseIncentive.completionThreshold;
            defaultValues.isSwagActive = courseIncentive.isActive
              ? 'yes'
              : 'no';
            defaultValues.swagExpiresOn = courseIncentive.expiresOn;
          }

          if (courseIncentive.rewardType.name === 'Sample') {
            defaultValues.sampleName = courseIncentive.name;
            defaultValues.sampleType = courseIncentive.type;
            defaultValues.sampleCompletionThreshold =
              courseIncentive.completionThreshold;
            defaultValues.isSampleActive = courseIncentive.isActive
              ? 'yes'
              : 'no';
            defaultValues.sampleExpiresOn = courseIncentive.expiresOn;
          }

          if (courseIncentive.rewardType.name === 'Raffle') {
            defaultValues.contestName = courseIncentive.name;
            defaultValues.isRaffleActive = courseIncentive.isActive
              ? 'yes'
              : 'no';
            defaultValues.expiresOn = courseIncentive.expiresOn;
          }
        }

        if (courseCommunication) {
          defaultValues.hasCommunicationSupport = 'yes';
          defaultValues.emailBlastDate = courseCommunication.emailBlastDate
            ? courseCommunication.emailBlastDate
            : null;
          defaultValues.socialMediaBlastDate =
            courseCommunication.socialMediaBlastDate
              ? courseCommunication.socialMediaBlastDate
              : null;
        }

        if (courseOutreach) {
          defaultValues.outReachActivationDescription =
            courseOutreach.description;
          defaultValues.startActivationDate = courseOutreach.startDate;
          defaultValues.outReachDaysActive = courseOutreach.daysActive;
          defaultValues.outReachStates =
            courseOutreach.courseOutreachStates.map(
              (courseOutreachState: any) => courseOutreachState.state
            );
        }

        setDefaultValues(defaultValues);
        setIsLoading(false);
      }
    };

    getItem();
  }, [courseId]);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <div>
      <MultiStepForm
        isSubmitting={isSubmitting}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        isEdit={true}
      />
    </div>
  );
};

export default Edit;
