import React from 'react';
import {
  Box,
  CircularProgress as MaterialCircularProgress,
} from '@mui/material';

import DisplayText from 'components/DisplayText';

const CircularProgress = ({
  value,
  size = 124,
  color = '#00FFF0',
}: {
  value: number;
  size?: number;
  color?: string;
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <MaterialCircularProgress
        variant="determinate"
        size={size}
        sx={{
          color,
          svg: {
            circle: {
              strokeLinecap: 'round',
              strokeWidth: 5,
            },
          },
        }}
        value={value}
        thickness={5}
      />
      <MaterialCircularProgress
        size={size}
        variant="determinate"
        value={100}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 0,
          svg: {
            color: 'black',
            opacity: 0.2,
            circle: {
              strokeDashoffset: '0px !important',
              strokeWidth: 5,
            },
          },
        }}
        thickness={5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '30px',
            lineHeight: '120%',
            color: '#205072',
          }}
        >
          {value ? `${value}%` : '0%'}
        </Box>
      </Box>
    </Box>
  );
};

export default CircularProgress;
