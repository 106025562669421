import * as React from 'react';

export default (
  <g>
    <path
      d="M9 14a5 5 0 00-5 5 3 3 0 003 3h10a3 3 0 003-3 5 5 0 00-5-5H9zM12 2a5 5 0 100 10 5 5 0 000-10z"
      fillOpacity={0.75}
    />
  </g>
);
