import { Box } from '@mui/material';
import CourseCard from '../../../layout/NewLayout/CourseCarrousel/CourseCard';

const CoursesByType = ({ courses }: { courses: any[] }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 4, gap: 1 }}>
      {courses.map((course) => (
        <CourseCard key={course.id} course={course} />
      ))}
    </Box>
  );
};

export default CoursesByType;
