type UserTestResultType = {
  id?: string | undefined;
  courseId: string;
  userId: string;
  status: string;
  created?: any;
  updated?: any;
  progress?: number;
  data?: string | null;
  duration?: number;
  score?: number;
  start?: any;
  end?: any | null;
  passed?: boolean;
  failCount?: number;
  locked?: boolean;
  lockedTime?: any;
  lockCount?: number;
};

export const UTR_Initial_State: UserTestResultType = {
  courseId: '',
  userId: '',
  status: '',
};

export default UserTestResultType;
