import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import Papa from 'papaparse';
import fileSaver from 'file-saver';
import {formatCurrency, formatPercentageSymbol} from '../../../utils/common';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Search by store',
  },
];

let organizationRequestController: AbortController | undefined;

const MultiStoreCommunitiesGrid = ({
  kpiId,
  columns,
  onGroupClick,
}: {
  kpiId: string;
  columns: (navigateToGroup: any) => DataGridColumnProps[];
  onGroupClick: (groupId: string) => void;
}) => {
  const loadingState = {
    groups: true,
    exporting: false,
  };
  const navigate = useNavigate();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigateToGroup = (id: string) => {
    onGroupClick(id);
  };

  const getMultiStoreCommunities = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    if (organizationRequestController) {
      organizationRequestController.abort();
    }
    try {
      organizationRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.getMultiStoreCommunitiesPaginated({
          controller: organizationRequestController,
          kpiId: kpiId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        organizationRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, groups: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportMultiStoreCommunities = async () => {
    setLoading((prevState) => ({ ...prevState, exporting: true }));
    if (organizationRequestController) {
      organizationRequestController.abort();
    }
    try {
      organizationRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.exportMultiStoreCommunities({
          controller: organizationRequestController,
          kpiId: kpiId,
          filters: filters,
        }),
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            id,
            name,
            status,
            enrolledOn,
            storeTicketAverage,
            storeThreshold,
            dollarsAdded14day,
            uplift14Day,
            dollarsAdded30day,
            uplift30Day
          } = item;

          return {
            'Store': name,
            'Status': status ? 'Enrolled' : 'Not enrolled',
            'Enrolled on': new Date(enrolledOn).toLocaleDateString('en-US'),
            'Store ticket average': formatCurrency(storeTicketAverage),
            'Store threshold': formatCurrency(storeThreshold),
            '14 Day Growth': formatCurrency(dollarsAdded14day),
            '14 Day % Change': formatPercentageSymbol(uplift14Day),
            '30 Day Growth': formatCurrency(dollarsAdded30day),
            '30 Day % Change': formatPercentageSymbol(uplift30Day)
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Multi store employee.csv');
        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  useEffect(() => {
    getMultiStoreCommunities();
  }, [filters, state, kpiId]);

  return (
    <DataGrid
      tableTitle="Stores Enrolled in Data Driven Learning"
      rows={gridData.rows}
      columns={columns(navigateToGroup)}
      filters={filterModel}
      handleFilterChange={setFilters}
      handleExport={exportMultiStoreCommunities}
      totalRows={gridData.totalRows}
      loading={loading.groups}
    />
  );
};

const MultiStoreCommunities = ({
  kpiId,
  columns,
  onGroupClick,
}: {
  kpiId: string;
  columns: (navigateToGroup: any) => DataGridColumnProps[];
  onGroupClick: (store: string) => void;
}) => (
  <ControlledDataGrid>
    <MultiStoreCommunitiesGrid
      columns={columns}
      kpiId={kpiId}
      onGroupClick={onGroupClick}
    />
  </ControlledDataGrid>
);

export default MultiStoreCommunities;
