import CryptoJS from 'crypto-js';
import { PRIVATE_KEY } from '../config/config';

export const encrypt = (plainText: string): string => {
  const { AES } = CryptoJS;
  const ciphertext = AES.encrypt(plainText, PRIVATE_KEY).toString();
  return ciphertext;
};

export const decrypt = (cipherText: string): string => {
  const { AES, enc } = CryptoJS;
  const bytes = AES.decrypt(cipherText, PRIVATE_KEY);
  const decryptedData = bytes.toString(enc.Utf8);
  return decryptedData;
};
