import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { formatPercentageSymbol } from 'utils/common';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Group Name',
  },
];

const loadingState = {
  courses: true,
  exporting: false,
};

const AdminGroupCourseReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getGroups();
  }, [filters, state]);

  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.reporting.group.getAdminGroupCourseReport({
            groupId: params.id,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (params.id) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource: httpRoutes.reporting.group.exportAdminGroupCourseReport({
            groupId: params.id,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, 'Community Course Report.csv');

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Learners',
      field: 'learnerCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
    },
    {
      headerName: 'Total Started',
      field: 'startedCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
    },
    {
      headerName: 'Total Completed',
      field: 'completedCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { averageScore } = params.row;

        if (!averageScore) {
          return <>-</>;
        }

        return formatPercentageSymbol(averageScore);
      },
    },
    {
      headerName: 'Percent Completed',
      field: 'percentageCompleted',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { percentageCompleted } = params.row;

        if (!percentageCompleted) return '-';

        return formatPercentageSymbol(percentageCompleted);
      },
    },
  ];

  return (
    <PageContainer
      title="Community Course Report"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        totalRows={gridData.totalRows}
        filters={filterModel}
        handleFilterChange={setFilters}
        handleExport={exportGroups}
        loading={loading.courses}
        onRowClick={(row) => {
          navigate(`/admin/reports/groups/${params.id}/course/${row.id}`);
        }}
      />
    </PageContainer>
  );
};

const AdminGroupCourseReport = () => (
  <ControlledDataGrid>
    <AdminGroupCourseReportGrid />
  </ControlledDataGrid>
);

export default AdminGroupCourseReport;
