import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LoadingButton from 'components/buttons/LoadingButton';
import PageContainer from 'components/PageContainer';

import Icon from 'icons/Icon';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import EmployeeSelectionTable from './EmployeeSelectionTable';
import { hasDuplicatedHcBudtenderMapId } from '../utils';

import { useSnackbar } from 'notistack';

const SubtitleLoadingState = ({
  loading,
  children,
}: {
  loading: boolean;
  children: any;
}) => {
  return loading ? (
    <Skeleton variant="rectangular" height="30px" width="200px" />
  ) : (
    children
  );
};

const MatchEmployees = () => {
  const params = useParams();
  const { groupId } = params;

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const [groupLoading, setGroupLoading] = useState(false);
  const [group, setGroup] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState<any>({});

  const getGroup = async () => {
    try {
      if (!groupId) return;

      const { response } = await callService({
        resource: httpRoutes.ddl.getOneGroupById(groupId),
      });

      if (response) {
        setGroup(response);
      }
    } finally {
      setGroupLoading(false);
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  const initializeSelectedEmployees = (employees: any) => {
    const currentSelectedEmployees: any = {};
    employees.forEach((employee: any) => {
      const { u_id, hcBudtenderMap_id, hcBudtenderMap_login_id, hcBudtenderMap_pos_id } = employee;
      if (hcBudtenderMap_id) {
        currentSelectedEmployees[employee.u_id] = {
          stUserId: u_id,
          hcBudtenderMapId: hcBudtenderMap_id,
          loginId: hcBudtenderMap_login_id,
          posId: hcBudtenderMap_pos_id
        };
      }
    });

    setSelectedEmployees(currentSelectedEmployees);
  };

  const handleSelectEmployee = (employee: any) => {
    const currentSelectedEmployees = { ...selectedEmployees };
    currentSelectedEmployees[employee.stUserId] = employee;
    setSelectedEmployees(currentSelectedEmployees);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const payload = Object.values(selectedEmployees).map((employee: any) => {
      const { stUserId, hcBudtenderMapId } = employee;

      return {
        stUserId,
        hcBudtenderMapId,
      };
    });

    if (hasDuplicatedHcBudtenderMapId(payload)) {
      setIsSubmitting(false);
      return enqueueSnackbar('Cannot select duplicated employees', {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    }

    try {
      if (groupId) {
        const { response } = await callService({
          resource: httpRoutes.ddl.matchEmployeesBudtenderInBulk({
            groupId,
            payload,
          }),
        });

        if (response) {
          return enqueueSnackbar('Employees matched successfully!', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        }
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
      setNeedsUpdate((prevState) => !prevState);
    }
  };

  const safeGroup = group || {};

  const { hcGroupMap } = safeGroup;

  const safeHcGroupMap = hcGroupMap || {};

  const integrationPartner = safeHcGroupMap.integrationPartner || {};

  return (
    <PageContainer
      title="Roster Match"
      pageAction={
        <Box>
          <LoadingButton
            type="button"
            variant="contained"
            onClick={handleConfirm}
            disabled={isSubmitting}
            loading={isSubmitting}
            label="Confirm"
          />
        </Box>
      }
    >
      <Box
        p={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'space-between',
          background: 'white',
          borderRadius: '30px 30px 0 0',
        }}
      >
        <SubtitleLoadingState loading={groupLoading}>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '12px' : '20px',
              lineHeight: '130%',
            }}
          >
            {safeGroup.name}
          </Box>
        </SubtitleLoadingState>
        <Box>
          <Icon name="arrow-right" fill="#56C596" />
        </Box>
        <SubtitleLoadingState loading={groupLoading}>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '12px' : '20px',
              lineHeight: '130%',
            }}
          >
            {`POS Integration Roster (${integrationPartner.name})`}
          </Box>
        </SubtitleLoadingState>
      </Box>
      <EmployeeSelectionTable
        needsUpdate={needsUpdate}
        initializeSelectedEmployees={initializeSelectedEmployees}
        onEmployeeSelection={handleSelectEmployee}
        selectedEmployees={selectedEmployees}
      />
    </PageContainer>
  );
};

export default MatchEmployees;
