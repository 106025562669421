import React, { useState } from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface EndAdornmentProps {
  setShowPassword: (showPassword: boolean) => void;
  showPassword: boolean;
}

const EndAdornment = ({ setShowPassword, showPassword }: EndAdornmentProps) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowPassword(!showPassword)}
      onMouseDown={() => setShowPassword(!showPassword)}
      edge="end"
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

interface PasswordFormFieldProps {
  name: string;
  label: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
}

const PasswordFormField = ({
  name,
  label,
  control,
  errors,
  margin = 'normal',
  required,
}: PasswordFormFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          required={required}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          label={label}
          fullWidth
          margin={margin}
          error={Boolean(errors)}
          helperText={
            errors ? <span color="error">{errors.message}</span> : ' '
          }
          InputProps={{
            endAdornment: (
              <EndAdornment
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
            ),
          }}
        />
      )}
    />
  );
};

export default PasswordFormField;
