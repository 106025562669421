/* eslint-disable react/jsx-key */
import { useState } from 'react';

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Controller, FieldError } from 'react-hook-form';

import { useDebouncedEffect } from 'hooks';

type AsyncSelectFormFieldProps = {
  name: string;
  label: string;
  defaultValue?: any;
  errors?: any;
  control: any;
  placeholder?: string;
  getOptions: (name: string) => any;
  disabled?: boolean;
};

const AsyncSelectFormField = ({
  name,
  defaultValue,
  errors,
  label,
  control,
  placeholder,
  getOptions,
  disabled,
}: AsyncSelectFormFieldProps) => {
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async () => {
    if (inputValue) {
      setOptionsLoading(true);

      const response = await getOptions(inputValue);

      setOptions(response);

      setOptionsLoading(false);
    } else {
      setOptions([]);
    }
  };

  useDebouncedEffect(fetchOptions, 200, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          id="search"
          freeSolo
          fullWidth
          selectOnFocus
          defaultValue={defaultValue}
          disabled={disabled}
          loading={areOptionsLoading}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_event, value) => {
            const newValue = value ? value.id : undefined;
            onChange(newValue);
          }}
          options={options}
          getOptionLabel={(option) => option.label || option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={Boolean(errors)}
              helperText={
                errors ? <span color="error">{errors.message}</span> : ' '
              }
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <>
                    {areOptionsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AsyncSelectFormField;
