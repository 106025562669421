import {Box, Grid} from '@mui/material';
import EmployeeCompletionRate from '../components/EmployeeCompletionRate';

import SummaryModule, {SummaryType} from '../components/summary';
import { SelectOption } from '../../../components/filters/filterTypes';

interface IStatistics {
  type: 'multi' | 'single';
  kpiId: string;
  labelResult1: string;
  labelResult2: string;
  labelResult3: string;
  labelResult4: string;
  labelResultToolTip1?: string;
  labelResultToolTip2?: string;
  labelResultToolTip3?: string;
  labelResultToolTip4?: string;
  groupId?: string;
  groupIds?: string[];
  groupsOptions?: SelectOption[];
}

const Statistics = ({
  type,
  kpiId,
  labelResult1,
  labelResult2,
  labelResult3,
  labelResult4,
  labelResultToolTip1,
  labelResultToolTip2,
  labelResultToolTip3,
  labelResultToolTip4,
  groupId,
  groupIds,
  groupsOptions,
}: IStatistics) => {
  const addPrefixToType = (type: SummaryType): SummaryType => {
    return `all-time-${type}` as SummaryType;
  };
  return (
    <Grid container sx={{ marginBottom: '10px' }}>
      <Grid item md={3}>
        <EmployeeCompletionRate
          type={type}
          kpiId={kpiId}
          groupId={groupId}
          groupsOptions={groupsOptions}
        />
      </Grid>
      <Grid item md={4.5}>
        <SummaryModule
          type={type}
          kpiId={kpiId}
          labelResult1={labelResult1}
          labelResult2={labelResult2}
          labelResult3={labelResult3}
          labelResult4={labelResult4}
          labelResultToolTip1={labelResultToolTip1}
          labelResultToolTip2={labelResultToolTip2}
          labelResultToolTip3={labelResultToolTip3}
          labelResultToolTip4={labelResultToolTip4}
          groupId={groupId}
          groupIds={groupIds}
          groupsOptions={groupsOptions}
        />
      </Grid>
      <Grid item md={4.5}>
        <SummaryModule
          type={addPrefixToType(type)}
          kpiId={kpiId}
          labelResult1={labelResult1}
          labelResult2={labelResult2}
          labelResult3={labelResult3}
          labelResult4={labelResult4}
          labelResultToolTip1={labelResultToolTip1}
          labelResultToolTip2={labelResultToolTip2}
          labelResultToolTip3={labelResultToolTip3}
          labelResultToolTip4={labelResultToolTip4}
          groupId={groupId}
          groupIds={groupIds}
          groupsOptions={groupsOptions}
        />
      </Grid>
    </Grid>
  );
};

export default Statistics;
