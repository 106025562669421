import { useState, useEffect } from 'react';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { DeleteIcon, EditIcon, CertificateIcon, AddIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/Create';
import Edit from '../dialogs/Edit';
import Delete from '../dialogs/Delete';

import resources from '../resources';

import { CertificateFormInput } from '../forms/Form';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Certificate Name',
  },
];

const CertificatesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const showCreateDialog = () => {
    showDialog({
      content: <Create onSuccess={getCertificates} />,
      size: 'sm',
    });
  };

  const showEditDialog = (values: CertificateFormInput) => {
    showDialog({
      content: <Edit onSuccess={getCertificates} defaultValues={values} />,
      size: 'sm',
    });
  };

  const showDeleteDialog = (values: CertificateFormInput) => {
    if (!values.name || !values.id) {
      return;
    }

    showDialog({
      content: (
        <Delete
          onSuccess={getCertificates}
          hideDialog={hideDialog}
          certificateName={values.name}
          certificateId={values.id}
        />
      ),
      size: 'sm',
    });
  };

  const getCertificates = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: resources.getMany({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Organization',
      field: 'organizationId',
      sortable: false,
      filterable: false,
      flex: 0.25,
    },
    {
      headerName: 'File',
      field: 'fileName',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Active',
      field: 'active',
      sortable: true,
      filterable: false,
      type: 'boolean',
      flex: 0.2,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditDialog(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => showDeleteDialog(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getCertificates();
  }, [filters, state]);

  return (
    <PageContainer
      title="Certificates"
      icon={<CertificateIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Create Certificate"
          onClick={showCreateDialog}
          startIcon={<AddIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const Certificates = () => (
  <ControlledDataGrid>
    <CertificatesGrid />
  </ControlledDataGrid>
);
export default Certificates;
