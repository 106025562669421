import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import ResponsiveCarrousel from 'layout/NewLayout/CourseCarrousel/ResponsiveCarrousel';

import { Filters } from 'components/filters/components';
import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';

import { useCallService, useDebouncedEffect } from 'hooks';

import { CourseIcon, EmptyStateIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { orderCourses } from '../utils';
import CoursesByType from '../components/CoursesByType';
import CourseListLoading from '../components/CourseListLoading';

const courseSortOptions: SelectOption[] = [
  {
    value: 'name-asc',
    label: 'Course Name (A-Z)',
  },
  {
    value: 'name-desc',
    label: 'Course Name (Z-A)',
  },
  {
    value: 'created-asc',
    label: 'Date (Oldest First)',
  },
  {
    value: 'created-desc',
    label: 'Date (Newest First)',
  },
];

const loadingState = {
  categories: true,
  rewardTypes: true,
  courses: true,
};

const Courses = ({
  courses,
  filters,
}: {
  courses: any[];
  filters: Map<string, any>;
}) => (
  <Box>
    {courses.map((category, index) => {
      if (filters.get('category')) {
        return <CoursesByType key={index} courses={category.courses} />;
      }

      if (category.courses.length > 0) {
        return (
          <Box sx={{ mt: 4 }} key={index}>
            <ResponsiveCarrousel
              title={category.name}
              courses={category.courses}
            />
          </Box>
        );
      }
    })}
  </Box>
);

let coursesRequestController: AbortController | undefined;

const CourseList = () => {
  const [loading, setLoading] = useState(loadingState);
  const [courses, setCourses] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [rewardTypes, setRewardTypes] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterState>(new Map<string, any>());

  const { callService } = useCallService();

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (coursesRequestController) {
      coursesRequestController.abort();
    }

    try {
      coursesRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.courses.getCoursesByCategory({
          controller: coursesRequestController,
          filters: filters,
        }),
      });

      if (response) {
        setCourses(orderCourses(response));
        coursesRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, courses: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const getRewardTypes = async () => {
    setLoading((prevState) => ({ ...prevState, rewardTypes: true }));

    const { response } = await callService({
      resource: httpRoutes.rewardTypes.getAll(),
    });

    if (response) {
      const _rewardTypesList: SelectOption[] = [
        {
          label: 'All Rewards',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _rewardTypesList.push({ label: item.name, value: item.id });
      });

      setRewardTypes(_rewardTypesList);

      setLoading((prevState) => ({ ...prevState, rewardTypes: false }));
    }
  };

  const getCourseCategories = async () => {
    setLoading((prevState) => ({ ...prevState, categories: true }));

    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    if (response) {
      const _categoriesList: SelectOption[] = [
        {
          label: 'All Categories',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _categoriesList.push({ label: item.name, value: item.id });
      });

      setCategories(_categoriesList);

      setLoading((prevState) => ({ ...prevState, categories: false }));
    }
  };

  useDebouncedEffect(getCourses, 200, [filters]);

  const handleFilterUpdate = (values: FilterState) => {
    setFilters(values);
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  useEffect(() => {
    getRewardTypes();
  }, []);

  useEffect(() => {
    getCourses();
  }, [filters]);

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
    {
      id: 'category',
      type: 'select',
      label: 'Course Category',
      options: categories,
    },
    {
      id: 'rewardTypeId',
      type: 'select',
      label: 'Reward Types',
      options: rewardTypes,
    },
    {
      id: 'sortBy',
      type: 'select',
      label: 'Sort By',
      options: courseSortOptions,
    },
  ];

  return (
    <PageContainer
      title="Courses"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <Filters
        filters={filterModel}
        onFilterChange={(value) => {
          handleFilterUpdate(value);
        }}
      />
      {loading.courses ? (
        <CourseListLoading />
      ) : (
        <Courses courses={courses} filters={filters} />
      )}

      {!loading.courses && courses.length < 1 && (
        <Box
          sx={{ display: 'flex', minHeight: '80vh' }}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <EmptyStateIcon sx={{ fontSize: '72px' }} />
          <Typography mt={2} variant="subtitle1">
            Oops! No courses found.
          </Typography>
          <Typography mt={2} variant="body2">
            Please try clearing your filters and trying again.
          </Typography>
        </Box>
      )}
    </PageContainer>
  );
};

export default CourseList;
