import createSvgIcon from './createSvgIcon';

import WarningOutlined from './icons/warning-outlined';
import InProgressOutlined from './icons/in-progress-outlined';
import NewOutlined from './icons/new-outlined';
import CompletedOutlined from './icons/completed-outlined';
import RewardOutlined from './icons/reward-outlined';
import CarrerPrinciples from './icons/carrer-principles';
import CannabisPrinciples from './icons/cannabis-principles';
import CompanyRequired from './icons/company-required';
import StateRequired from './icons/state-required';
import Assessment from './icons/assessments';
import Brand from './icons/brand';
import Organizations from './icons/organizations';
import Communities from './icons/communities';
import UserManagement from './icons/user-management';
import CourseAdmin from './icons/course-admin';
import Reports from './icons/reports';
import Dashboard from './icons/dashboard';
import CourseLibrary from './icons/course-library';
import Logout from './icons/logout';
import CollapseMenu from './icons/collapse-menu';
import BulletPoint from './icons/bullet-point';
import ArrowDown from './icons/arrow-down';
import ArrowUp from './icons/arrow-up';
import ArrowRight from './icons/arrow-right';
import Check from './icons/check';
import FilledCheck from './icons/filled-check';
import FilledWarning from './icons/filled-warning';
import DDL from './icons/ddl';
import House from './icons/house';
import ThreeUsers from './icons/three-users';
import Clock from './icons/clock';
import Score from './icons/score';
import Lens from './icons/lens';
import Filter from './icons/filter';
import FilledSortArrowUp from './icons/filled-sort-arrow-up';
import FilledSortArrowDown from './icons/filled-sort-arrow-down';
import SortArrowDown from './icons/sort-arrow-down';
import SortArrowUp from './icons/sort-arrow-up';
import User from './icons/user';
import Envelope from './icons/envelope';
import Eye from './icons/eye';
import EyeOff from './icons/eye-off';
import Folder from './icons/folder';
import Location from './icons/location';
import Mail from './icons/mail';
import Edit from './icons/edit';
import Dollar from './icons/dollar';
import Percentage from './icons/percentage';
import Star from './icons/star';
import Basket from './icons/basket';
import Lock from './icons/lock';
import Megaphone from './icons/megaphone';
import ChangesTracker from './icons/changes-tracker';
import SportFlag from './icons/sport-flag';

const iconsMap = {
  'warning-outlined': {
    path: WarningOutlined,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'in-progress-outlined': {
    path: InProgressOutlined,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 30 30',
      height: '30',
      width: '30',
    },
  },
  'new-outlined': {
    path: NewOutlined,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 37 33',
      height: '33',
      width: '37',
    },
  },
  'completed-outlined': {
    path: CompletedOutlined,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'reward-outlined': {
    path: RewardOutlined,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 23 36',
      height: '23',
      width: '36',
    },
  },
  'carrer-principles': {
    path: CarrerPrinciples,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  'cannabis-principles': {
    path: CannabisPrinciples,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  'company-required': {
    path: CompanyRequired,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  'state-required': {
    path: StateRequired,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  assessments: {
    path: Assessment,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  brand: {
    path: Brand,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 60 60',
      height: '60',
      width: '60',
    },
  },
  organizations: {
    path: Organizations,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  communities: {
    path: Communities,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'user-management': {
    path: UserManagement,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'course-admin': {
    path: CourseAdmin,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  reports: {
    path: Reports,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  dashboard: {
    path: Dashboard,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'course-library': {
    path: CourseLibrary,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  logout: {
    path: Logout,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'collapse-menu': {
    path: CollapseMenu,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'bullet-point': {
    path: BulletPoint,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'arrow-down': {
    path: ArrowDown,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'arrow-up': {
    path: ArrowUp,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'arrow-right': {
    path: ArrowRight,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 35 24',
      height: '24',
      width: '35',
    },
  },
  'filled-check': {
    path: FilledCheck,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 16 16',
      height: '16',
      width: '16',
    },
  },
  check: {
    path: Check,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'filled-warning': {
    path: FilledWarning,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 16 16',
      height: '16',
      width: '16',
    },
  },
  ddl: {
    path: DDL,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  house: {
    path: House,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'three-users': {
    path: ThreeUsers,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  clock: {
    path: Clock,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  score: {
    path: Score,
    svgDefaultProps: {
      fill: 'white',
      viewBox: '0 0 45 15',
      height: '44',
      width: '15',
    },
  },
  lens: {
    path: Lens,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  filter: {
    path: Filter,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'filled-sort-arrow-up': {
    path: FilledSortArrowUp,
    svgDefaultProps: {
      fill: '#56C596',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'filled-sort-arrow-down': {
    path: FilledSortArrowDown,
    svgDefaultProps: {
      fill: '#56C596',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'sort-arrow-down': {
    path: SortArrowDown,
    svgDefaultProps: {
      fill: '#56C596',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'sort-arrow-up': {
    path: SortArrowUp,
    svgDefaultProps: {
      fill: '#56C596',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  user: {
    path: User,
    svgDefaultProps: {
      fill: '#205072',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  envelope: {
    path: Envelope,
    svgDefaultProps: {
      fill: 'none',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  eye: {
    path: Eye,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  'eye-off': {
    path: EyeOff,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  folder: {
    path: Folder,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  location: {
    path: Location,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  mail: {
    path: Mail,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  edit: {
    path: Edit,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  dollar: {
    path: Dollar,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 18 35',
      height: '18',
      width: '35',
    },
  },
  percentage: {
    path: Percentage,
    svgDefaultProps: {
      fill: '#12131A',
      viewBox: '0 0 27 27',
      height: '27',
      width: '27',
    },
  },
  star: {
    path: Star,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  basket: {
    path: Basket,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  lock: {
    path: Lock,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  megaphone: {
    path: Megaphone,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  changesTracker: {
    path: ChangesTracker,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
  sportFlag: {
    path: SportFlag,
    svgDefaultProps: {
      fill: '#FFFFFF',
      viewBox: '0 0 24 24',
      height: '24',
      width: '24',
    },
  },
};

const Icon = ({ name, ...rest }) => {
  const { path, svgDefaultProps } = iconsMap[name];

  return createSvgIcon(path, name, {
    ...svgDefaultProps,
    ...rest,
  });
};

export default Icon;
