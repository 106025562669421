import { useRoutes } from 'react-router-dom';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const AppRoutes = () => {
  const Routes = PublicRoutes.concat(PrivateRoutes);

  return useRoutes(Routes);
};

export default AppRoutes;
