import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.683 19.517a.842.842 0 101.684 0 .842.842 0 00-1.684 0zm-1.683 0a2.525 2.525 0 105.05 0 2.525 2.525 0 00-5.05 0zM11.258 19.517a.842.842 0 101.684 0 .842.842 0 00-1.684 0zm-1.683 0a2.525 2.525 0 105.05 0 2.525 2.525 0 00-5.05 0z"
    />
    <path
      d="M12.941 9.417a.842.842 0 10-1.683 0v5.05a.842.842 0 001.683 0v-5.05z"
    />
    <path
      d="M12.941 12.783a.842.842 0 00-1.683 0v5.05a.842.842 0 001.683 0v-5.05zM20.517 12.783a.842.842 0 00-1.684 0v5.05a.842.842 0 001.684 0v-5.05zM5.366 12.783a.842.842 0 00-1.683 0v5.05a.842.842 0 001.683 0v-5.05z"
    />
    <path
      d="M19.675 13.625a.842.842 0 100-1.683H4.525a.842.842 0 100 1.683h15.15z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.834 19.517a.842.842 0 101.683 0 .842.842 0 00-1.683 0zm-1.684 0a2.525 2.525 0 105.05 0 2.525 2.525 0 00-5.05 0zM14.67 1H9.55c-.37 0-.688 0-.952.018-.277.019-.555.06-.83.174A2.525 2.525 0 006.402 2.56a2.583 2.583 0 00-.174.83c-.018.263-.018 2.265-.018 2.634v.054c0 .37 0 .688.018.951.019.278.06.556.174.83.256.619.748 1.11 1.367 1.367.274.113.552.155.83.174.263.018.582.018.95.018h5.123c.369 0 .687 0 .951-.018.278-.02.555-.06.83-.174a2.525 2.525 0 001.366-1.367c.114-.274.155-.552.174-.83.018-.263.018-.582.018-.951v-.054c0-.369 0-2.371-.018-2.635a2.586 2.586 0 00-.174-.83 2.525 2.525 0 00-1.366-1.366 2.583 2.583 0 00-.83-.174A15 15 0 0014.671 1zM8.413 2.747a.976.976 0 01.3-.05c.2-.013.46-.014.864-.014 5.471 0 5.732 0 5.931.014a.976.976 0 01.3.05c.207.086.37.25.456.456a.97.97 0 01.05.3c.014.2.014 2.143.014 2.547 0 .404 0 .664-.014.864a.97.97 0 01-.05.3.841.841 0 01-.456.455.976.976 0 01-.3.05c-.199.014-5.528.014-5.931.014-.404 0-.665 0-.864-.014a.977.977 0 01-.3-.05.842.842 0 01-.456-.455.976.976 0 01-.05-.3c-.013-.2-.014-.46-.014-.864 0-.404 0-2.348.014-2.547a.976.976 0 01.05-.3.842.842 0 01.456-.456z"
    />
  </g>
);
