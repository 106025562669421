import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import LoadingButton from 'components/buttons/LoadingButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';
import { useSnackbar } from 'notistack';

import httpRoutes from 'utils/httpRoutes';
import { Box, Skeleton, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import ActivityStats from './partials/activityStats/ActivityStats';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Learner Name',
  },
];

const loadingState = {
  courses: true,
  exporting: false,
  downloading: false,
  item: '',
  course: true,
};

let learnersRequestController: AbortController | undefined;

const GroupCourseLearnerReportGrid = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState({
    name: '',
    category: '',
  });
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const params = useParams();

  const groupId = params.id || '';
  const courseId = params.courseId || '';

  const getCourse = async () => {
    try {
      if (courseId) {
        const { response } = await callService({
          resource: httpRoutes.courses.getCourseById(courseId),
        });

        if (response) {
          setCourse({
            name: response.name,
            category: response.courseCategory.name,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, course: false }));
    }
  };

  const getLearners = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (learnersRequestController) {
      learnersRequestController.abort();
    }

    try {
      learnersRequestController = new AbortController();
      if (groupId && courseId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getGroupLearnerCourseReport({
            controller: learnersRequestController,
            courseId,
            groupId,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          learnersRequestController = undefined;
          setLoading((prevState) => ({ ...prevState, courses: false }));
        }
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (groupId && courseId) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource: httpRoutes.reporting.course.exportGroupLearnerCourseReport({
            courseId,
            groupId,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, 'Learner Course Report.csv');

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  const downloadCertificate = async (values: any) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        downloading: true,
        item: values.id,
      }));

      const { response } = await callService({
        resource: httpRoutes.reporting.course.downloadCourseCertificate(
          values.userCertificateId
        ),
        throwOnError: true,
      });

      if (response) {
        // update file name
        const _fileName = `${values.firstName}-${values.lastName}-${course.name}-${values.num}.pdf`;

        const _fileData = new Blob([response], { type: 'application/pdf' });

        fileSaver.saveAs(_fileData, _fileName);
      }
    } catch (e) {
      enqueueSnackbar('Certificate download failed.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        downloading: false,
        item: '',
      }));
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Learner Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.75,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.firstName} {params.row.lastName}
          </>
        );
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.75,
    },
    {
      headerName: 'Primary community',
      field: 'primaryGroup',
      sortable: false,
      filterable: false,
      flex: 0.75,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: 'Started',
      field: 'start',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.start) {
          return <>{new Date(params.row.start).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Completed',
      field: 'end',
      sortable: false,
      filterable: false,
      type: 'date',
      headerAlign: 'center',
      align: 'right',
      flex: 0.75,
      ColumnComponent: (params: any) => {
        if (params.row.end) {
          return <>{new Date(params.row.end).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Passed',
      field: 'passed',
      sortable: false,
      filterable: false,
      type: 'boolean',
      headerAlign: 'center',
      align: 'right',
      flex: 0.25,
      ColumnComponent: (params: any) => {
        return <>{params.row.passed ? 'Yes' : 'No'}</>;
      },
    },
    {
      headerName: 'Score',
      field: 'score',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.25,
    },
    {
      headerName: 'Certificate',
      field: 'userCertificateId',
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        if (params.row.userCertificateId) {
          return (
            <LoadingButton
              onClick={() => downloadCertificate(params.row)}
              label="Download"
              variant="text"
              loading={loading.downloading && loading.item === params.row.id}
            />
          );
        }

        return <>--</>;
      },
    },
  ];

  useEffect(() => {
    getLearners();
  }, [filters, state]);

  useEffect(() => {
    getCourse();
  }, [courseId]);

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4 }}>
        {loading.course ? (
          <Skeleton variant="rectangular" height={80} width="50%" />
        ) : (
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '500',
                fontSize: matchUpXS ? '12px' : '24px',
                lineHeight: '130%',
                color: '#162738',
              }}
            >
              {course.category}
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchUpXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {course.name}
            </Box>
          </Box>
        )}
        <ActivityStats courseId={courseId} groupId={groupId} />
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleExport={exportGroups}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          loading={loading.courses}
        />
      </Box>
    </PageContainer>
  );
};

const GroupCourseLearnerReport = () => (
  <ControlledDataGrid>
    <GroupCourseLearnerReportGrid />
  </ControlledDataGrid>
);

export default GroupCourseLearnerReport;
