import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from 'components/buttons/LoadingButton';
import { SaveIcon } from 'material-icons';
import {useSnackbar} from 'notistack';

const MatchDutchieStore = ({ group }: { group: any }) => {
  const [options, setOptions] = useState<any>([]);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectedLocation = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
  };

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const responseStoreName = await callService({
        resource: httpRoutes.ddl.setDutchieStoreName({
          groupId: group.id,
          dtStoreName: selectedOption,
        }),
        successMessage: 'Store set successfully!',
      });
      if (responseStoreName) {
        hideDialog();
      }

      enqueueSnackbar(`Retrieving data from Dutchie for group ${group.name}.`, {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const response = await callService({
        resource: httpRoutes.cronjobs.dutchie.onboardGroup(group.id)
      });

      if (response) {
        enqueueSnackbar(`Data successfully retrieved from Dutchie for group ${group.name}.`, {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    } catch (e: any) {
      enqueueSnackbar(`Error when retrieving data from Dutchie for group ${group.name}.`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDutchiePermissionsLocations = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.ddl.getDutchiePermissionsLocations({
          groupId: group.id,
        }),
      });

      if (response) {
        const _options = response.map((permissionsLocation: any) => ({
          label: permissionsLocation,
          value: permissionsLocation,
        }));

        setOptions(_options);
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getDutchiePermissionsLocations();
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '32px',
            lineHeight: '130%',
          }}
        >
          {group.name}
        </Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#1A1A1A',
          }}
        >
          Please review the related store locations and choose your store to
          accurately import data
        </Box>
      </Box>
      <Divider
        sx={{
          my: 3,
        }}
      />
      <Box>
        {optionsLoading ? (
          <Skeleton variant="rectangular" height="100px" />
        ) : (
          <FormControl fullWidth>
            <InputLabel
              sx={{
                '&.Mui-focused': { color: '#56C596' },
              }}
              id="specific-location"
            >
              Select specific store location for data matching
            </InputLabel>
            <Select
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#56C596',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#56C596',
                },
              }}
              labelId="specific-location-label"
              id="specific-location-select"
              value={selectedOption}
              label="Select specific store location for data matching"
              onChange={handleSelectedLocation}
            >
              {options.map((o: any) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          type="button"
          label="Save"
          variant="contained"
          loadingPosition="start"
          onClick={handleSubmit}
          icon={<SaveIcon />}
        />
      </Grid>
    </Box>
  );
};

export default MatchDutchieStore;
