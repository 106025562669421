import { Box, Skeleton } from '@mui/material';
import React from 'react';

const LoadingState = () => {
  return (
    <Box sx={{ width: '100%', mb: 1 }}>
      <Skeleton variant="rectangular" height="500px" width="100%" />
    </Box>
  );
};

export default LoadingState;
