import { useCallService } from 'hooks';

import BundleForm, { BundleFormInput } from '../forms/BundleForm';

import httpRoutes from 'utils/httpRoutes';

const CreateBundle = ({
  onSuccess,
}: {
  onSuccess: (bundleId: string) => void;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: BundleFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.bundles.create(values),
      successMessage: 'Bundle created successfully!',
    });

    if (response) {
      onSuccess(response.id);
    }
  };

  return (
    <div>
      <BundleForm onSubmit={onSubmit} />
    </div>
  );
};

export default CreateBundle;
