import { instance as apiClient } from 'utils/axios';
import { GridSortDirection } from '@mui/x-data-grid';

import { OrganizationFormInput } from './forms/OrganizationForm';
import { CreateOrganizationMemberFormInput } from './forms/CreateOrganizationMemberForm';
import { OrganizationBundleFormInput } from './forms/OrganizationBundleForm';
import { RemoveOrganizationMemberFormInput } from './forms/RemoveOrganizationMemberForm';
import { OrganizationCommunityFormInput } from './forms/OrganizationCommunityForm';

export interface PaginationInterface {
  page: number;
  limit: number;
  orderBy?: string;
  order?: GridSortDirection;
}

const apiBase = '/api/v1';

const resources = {
  organization: {
    create(payload: OrganizationFormInput) {
      return apiClient.post(`${apiBase}/organizations`, payload);
    },
    update(payload: OrganizationFormInput) {
      return apiClient.put(`${apiBase}/organizations`, payload);
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/organizations/${id}`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/organizations/paginated`, {
        params: formattedParams,
      });
    },
    getOrganizationBundlesPaginated({
      organizationId,
      params,
      filters,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/organizations/${organizationId}/bundles/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    getOrganizationCoursesPaginated({
      organizationId,
      params,
      filters,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/organizations/${organizationId}/courses/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    getOrganizationGroupsPaginated({
      organizationId,
      params,
      filters,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/organizations/${organizationId}/groups/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    getOrganizationUsersPaginated({
      organizationId,
      params,
      filters,
    }: {
      organizationId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/organizations/${organizationId}/users/paginated`,
        {
          params: formattedParams,
        }
      );
    },
    addGroup(values: OrganizationCommunityFormInput) {
      return apiClient.put(`${apiBase}/organizations/add-group`, values);
    },
    removeGroup(values: { id: string }) {
      return apiClient.put(`${apiBase}/organizations/remove-group`, values);
    },
    addBundle(values: OrganizationBundleFormInput) {
      return apiClient.put(`${apiBase}/organizations/add-bundle`, values);
    },
    removeBundle(values: OrganizationBundleFormInput) {
      return apiClient.put(`${apiBase}/organizations/remove-bundle`, values);
    },
    addMember(values: CreateOrganizationMemberFormInput) {
      return apiClient.put(`${apiBase}/organizations/add-member`, values);
    },
    removeMember(values: RemoveOrganizationMemberFormInput) {
      return apiClient.put(`${apiBase}/organizations/remove-member`, values);
    },
  },
  groups: {
    search(value: string, organizationIdsForFilter?: string[]) {
      return apiClient.get(`${apiBase}/groups/search`, {
        params: { name: value, organizationIdsForFilter },
      });
    },
    create(payload: OrganizationFormInput) {
      return apiClient.post(`${apiBase}/groups`, payload);
    },
    update(payload: OrganizationFormInput) {
      return apiClient.put(`${apiBase}/groups`, payload);
    },
    delete(id: string) {
      return apiClient.put(`${apiBase}/groups/delete/${id}`);
    },
    getOne(id: string) {
      return apiClient.get(`${apiBase}/groups/${id}`);
    },
    getMany({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/paginated`, {
        params: formattedParams,
      });
    },
    getGroupBundlesPaginated({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/${groupId}/bundles/paginated`, {
        params: formattedParams,
      });
    },
    getGroupUsersPaginated({
      groupId,
      params,
      filters,
    }: {
      groupId: string;
      params: PaginationInterface;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/groups/${groupId}/users/paginated`, {
        params: formattedParams,
      });
    },
  },
  bundles: {
    getBundles() {
      return apiClient.get(`${apiBase}/bundles/`);
    },
    search(value: string) {
      return apiClient.get(`${apiBase}/bundles/search`, {
        params: { name: value },
      });
    },
    getBundleCourses({
      bundleId,
      filters,
    }: {
      bundleId: string;
      filters: Map<string, any>;
    }) {
      const formattedParams = {
        name: filters.get('name'),
      };

      return apiClient.get(`${apiBase}/bundles/${bundleId}/courses`, {
        params: formattedParams,
      });
    },
  },
  members: {
    searchUsers(value: string) {
      return apiClient.get(`${apiBase}/users/search`, {
        params: { label: value },
      });
    },
  },
  courses: {
    search(value: string) {
      return apiClient.get(`${apiBase}/courses/search`, {
        params: {
          name: value,
        },
      });
    },
  },
};

export default resources;
