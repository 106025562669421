import React, { useCallback, useMemo, useState } from 'react';

import Dialog from 'components/dialog/components/Dialog';

import DialogContext, {
  DIALOG_INITIAL_STATE,
  DialogProviderState,
} from './contexts/DialogContext';

interface DialogProviderProps {
  children: React.ReactNode;
}

const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogState, setDialogState] = useState(DIALOG_INITIAL_STATE);

  const showDialog = useCallback(
    ({
      open,
      title,
      size,
      content,
      fullWidth,
      fullScreen,
      disableEscapeKeyDown,
      enableBackdropClose,
      noPadding
    }: DialogProviderState) => {
      setDialogState({
        open: open !== undefined ? open : true,
        title,
        size,
        content,
        fullWidth,
        fullScreen,
        disableEscapeKeyDown,
        enableBackdropClose,
        noPadding
      });
    },
    []
  );

  const hideDialog = useCallback(() => {
    setDialogState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const value = useMemo(
    () => ({
      dialogState,
      showDialog,
      hideDialog,
    }),
    [dialogState, showDialog, hideDialog]
  );

  const setIsOpen = () => {
    if (!dialogState.open) {
      hideDialog();
    }
  };

  return (
    <DialogContext.Provider value={value}>
      <Dialog
        title={dialogState.title}
        size={dialogState.size}
        content={dialogState.content}
        fullWidth={dialogState.fullWidth}
        fullScreen={dialogState.fullScreen}
        disableEscapeKeyDown={dialogState.disableEscapeKeyDown}
        enableBackdropClose={dialogState.enableBackdropClose}
        noPadding={dialogState.noPadding}
        isOpen={Boolean(dialogState.open)}
        setIsOpen={setIsOpen}
      />
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
