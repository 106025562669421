/* eslint-disable global-require */
import React, { memo, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
// import hexToFilter from './hexToFilter';

const iconSizeMap = {
    extraSmall: '10px',
    small: '16px',
    medium: '24px',
    large: '35px',
    extraLarge: '50px',
};

const StyledSvg = styled.svg`
    filter: ${({ color = 'grayDarkest' }) => color};
    height: ${({ size }) => iconSizeMap[size]};
    pointer-events: none;
    width: ${({ size }) => iconSizeMap[size]};
`;

const StyledButton = styled.button`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
`;

// TODO: Figure out how to dynamically inject icon name
// eslint-disable-next-line react/display-name
const SvgIcon = memo(
    forwardRef(
        (
            {
                // eslint-disable-next-line react/prop-types
                children,
                // eslint-disable-next-line react/prop-types
                'aria-label': ariaLabel,
                // eslint-disable-next-line react/prop-types
                viewBox = '0 0 24 24',
                // eslint-disable-next-line react/prop-types
                color,
                // eslint-disable-next-line react/prop-types
                name,
                // eslint-disable-next-line react/prop-types
                size = 'medium',
                // eslint-disable-next-line react/prop-types
                onClick,
                ...rest
            },
            ref
        ) => {
            const WrapperElement = useMemo(
                () =>
                    onClick
                        ? // eslint-disable-next-line react/prop-types,no-shadow,react/display-name
                          memo(({ children }) =>
                              React.createElement(StyledButton, { onClick, 'aria-label': ariaLabel, ...rest }, children)
                          )
                        : React.Fragment,
                [onClick]
            );
            let additionalProps = rest;

            if (onClick) {
                additionalProps = {};
            }

            return (
                <WrapperElement>
                    <StyledSvg
                        color={color}
                        size={size}
                        tabIndex="-1"
                        viewBox={viewBox}
                        ref={ref}
                        role={name ? 'img' : undefined}
                        titleAccess={name}
                        {...additionalProps}
                    >
                        {children}
                        {name ? <title>{name}</title> : null}
                    </StyledSvg>
                </WrapperElement>
            );
        }
    )
);

export default SvgIcon;
