import { Box, Skeleton } from '@mui/material';

const CourseListLoading = () => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 4, gap: 1 }}>
      {[...Array(4)].map((x, i) => (
        <Box
          key={i}
          sx={{
            height: '390px',
            width: '100%',
            borderRadius: '12px',
          }}
        >
          <Skeleton variant="rounded" height="100%" sx={{ borderRadius: '12px' }}/>
        </Box>
      ))}
    </Box>
  );
};

export default CourseListLoading;
