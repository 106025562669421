import { useEffect, useState } from 'react';
import { Box, Skeleton, useMediaQuery } from '@mui/material';

import SingleStoreEmployee from '../../../partials/SingleStoreEmployee';
import Statistics from '../../../components/Statistics';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { SelectOption } from 'components/filters/filterTypes';
import GroupSelectField from '../../../components/GroupSelectField';
import { useTheme } from '@mui/material/styles';

interface ISingleStore {
  kpiId: string;
  groupId: string;
  onGroupChange: (groupId: string) => void;
  groupsOptions: SelectOption[];
}

const SingleStore = ({
  kpiId,
  groupId,
  onGroupChange,
  groupsOptions,
}: ISingleStore) => {
  const [group, setGroup] = useState<any>(null);
  const [groupLoading, setGroupLoading] = useState(false);

  const { callService } = useCallService();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  let groupIdToUse = groupId;

  if (!groupId) {
    groupIdToUse = groupsOptions[0] ? groupsOptions[0].value : '';
  }

  const getGroup = async () => {
    if (groupIdToUse) {
      try {
        setGroupLoading(true);

        if (!groupId) {
          onGroupChange(groupIdToUse);
        }

        const { response } = await callService({
          resource: httpRoutes.groups.getOne(groupIdToUse),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  return (
    <Box>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '25px' : '32px',
              lineHeight: '130%',
              color: '#162738',
            }}
          >
            Basket Size Dashboard
          </Box>
          {groupLoading ? (
            <Skeleton width={200} variant="rectangular" />
          ) : (
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '150%',
                color: '#162738',
              }}
            >
              {`for ${group?.name}`}
            </Box>
          )}
        </Box>
        <Box sx={{ width: '250px' }}>
          <GroupSelectField
            groupId={groupId}
            setGroupId={onGroupChange}
            options={groupsOptions}
            label="Store"
            width="250px"
          />
        </Box>
      </Box>
      <Statistics
        type="single"
        groupId={groupId}
        kpiId={kpiId}
        labelResult1="14 day revenue"
        labelResultToolTip1="Sales Revenue is calculated by taking in the revenue over the 14 days from the course completion date and comparing it to the previous 30 day revenue for each employee."
        labelResult2="14 day % change"
        labelResultToolTip2="Sales Growth percentage change is calculated by taking the average ticket size change over the 14 days from the course completion date and comparing it to the previous 30 day average for each employee."
        labelResult3="30 day revenue"
        labelResultToolTip3="Sales Revenue is calculated by taking in the revenue over the 30 days from the course completion date and comparing it to the previous 30 day revenue for each employee."
        labelResult4="30 day % change"
        labelResultToolTip4="Sales Growth percentage change is calculated by taking the average ticket size change over the 30 days from the course completion date and comparing it to the previous 30 day average for each employee."
        groupsOptions={groupsOptions}
      />
      <SingleStoreEmployee kpiId={kpiId} groupId={groupId} />
    </Box>
  );
};

export default SingleStore;
