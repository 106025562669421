import { Tab } from '@mui/material';

interface StyledTabProps {
  label: string;
  themeColor: string;
}

function StyledTab(props: StyledTabProps) {
  const { themeColor, ...rest } = props;

  return (
    <Tab
      {...rest}
      sx={{
        '&:hover': {
          color: themeColor,
        },
        '&.Mui-selected': {
          color: themeColor,
        },
      }}
    />
  );
}

export default StyledTab;
