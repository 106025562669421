import PageContainer from 'components/PageContainer';
import { CourseIcon } from 'material-icons';

const Upselling = () => {
  return (
    <PageContainer
      title="Upselling"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <>Upselling</>
    </PageContainer>
  );
};

export default Upselling;
