import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface TextFormFieldProps {
  name: string;
  label: string;
  control: any;
  errors?: any;
  icon?: React.ReactElement;
  position?: 'start' | 'end';
  margin?: 'normal' | 'dense' | 'none';
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  required?: boolean;
  sx?: SxProps<Theme>;
  placeholder?: string;
  inputLabelProps?: any;
}

const TextFormField = ({
  name,
  label,
  control,
  errors,
  icon,
  position = 'start',
  margin = 'normal',
  multiline = false,
  rows = 1,
  disabled = false,
  required,
  sx,
  placeholder,
  inputLabelProps,
}: TextFormFieldProps) => {
  let inputProps = {};

  if (icon) {
    if (position === 'start') {
      inputProps = {
        startAdornment: icon,
      };
    }

    if (position === 'end') {
      inputProps = {
        endAdornment: icon,
      };
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={sx}
          required={required}
          variant="outlined"
          label={label}
          fullWidth
          margin={margin}
          multiline={multiline}
          rows={rows}
          error={Boolean(errors)}
          placeholder={placeholder}
          InputLabelProps={inputLabelProps}
          helperText={
            errors ? <span color="error">{errors.message}</span> : ' '
          }
          InputProps={inputProps}
          disabled={disabled}
        />
      )}
    />
  );
};

export default TextFormField;
