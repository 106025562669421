import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 7.75c-1.036 0-1.875.84-1.875 1.875V11.5h-3.75V9.625a5.625 5.625 0 0111.25 0V11.5h-3.75V9.625c0-1.036-.84-1.875-1.875-1.875z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.875 12.333h11.25c.575 0 1.042.467 1.042 1.042v3.75c0 .575-.467 1.042-1.042 1.042H5.875a1.042 1.042 0 01-1.042-1.042v-3.75c0-.575.467-1.042 1.042-1.042z"
    />
  </g>
);
