import { useState, useEffect } from 'react';

import { SelectOptionProps } from 'components/formFields/types';
import { SelectOptionPropsWithTypeId } from 'types/SelectOptionPropsWithTypeId';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

const useCourseOptions = () => {
  const { callService } = useCallService();
  const [courseType, setCourseType] = useState<number>();
  const [courses, setCourses] = useState<SelectOptionPropsWithTypeId[]>([]);
  const [courseCategories, setCourseCategories] = useState<SelectOptionProps[]>(
    []
  );
  const [isFetching, setIsFetching] = useState(true);

  const getCourses = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getCourses(),
    });

    const _courses: SelectOptionPropsWithTypeId[] = [];

    return response
      ? _courses.concat(
          response.map(
            (item: { id: string; name: string; courseTypeId: number }) => {
              return {
                value: item.id,
                label: item.name,
                typeId: item.courseTypeId,
              };
            }
          )
        )
      : [];
  };

  const getCourseType = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getCourseTypes(),
    });

    return response.find((x: any) => x.name === 'Course').id;
  };

  const getCourseCategories = async () => {
    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    return response
      ? response.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [courseType, categories, coursesList] = await Promise.all([
          getCourseType(),
          getCourseCategories(),
          getCourses(),
        ]);
        setCourseType(courseType);
        setCourseCategories(categories);
        setCourses(coursesList);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, []);

  return {
    courseType,
    courseCategories,
    courses,
    isFetching,
  };
};

export default useCourseOptions;
