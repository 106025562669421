import { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const PageContainer = ({
  title,
  icon,
  pageAction,
  isSecondary,
  children,
  showBackButton = true,
  subtitle,
  isLoading,
}: {
  title?: string;
  icon?: ReactNode;
  pageAction?: ReactNode;
  elevation?: number;
  isSecondary?: boolean;
  children: ReactNode;
  showBackButton?: boolean;
  subtitle?: string;
  withPadding?: boolean;
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      id="page-container"
      sx={{
        width: '100%',
        overflow: 'auto',
        backgroundColor: '#F3F7FD',
        marginBottom: matchDownSM ? 0 : 8,
      }}
    >
      {showBackButton && (
        <Box
          sx={{
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: matchDownSM ? 2 : 4,
            p: matchDownSM ? 1 : 2,
            color: '#205072',
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon />
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '100%',
            }}
          >
            Back
          </Box>
        </Box>
      )}
      <Grid
        id="page-header"
        container
        flexDirection="row"
        justifyContent="space-between"
        sx={{ mb: matchDownSM ? 1 : 3, p: 1 }}
      >
        <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
          {title && (
            <Grid
              container
              sx={{ alignItems: 'center', display: 'flex' }}
              flexDirection="row"
            >
              {icon && (
                <Box
                  sx={{
                    mr: 1,
                  }}
                >
                  <Box
                    sx={{
                      color: 'white',
                      borderRadius: '25%',
                      p: 2,
                      background:
                        'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
                    }}
                  >
                    {icon}
                  </Box>
                </Box>
              )}
              <Typography
                variant={isSecondary ? 'h4' : 'h2'}
                sx={{ fontWeight: 'light' }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {subtitle && (
            <Grid item xs={12} ml={4}>
              <Typography variant="body1">{subtitle}</Typography>
            </Grid>
          )}
        </Grid>
        {pageAction && <Grid item>{pageAction}</Grid>}
      </Grid>
      <Box id="page-container-children">{children}</Box>
    </Box>
  );
};

export default PageContainer;
