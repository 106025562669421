import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import BarCharts from '../../../../shared/BarCharts';
import { ChartValues } from '../../../../shared/types';
import TopCommunitiesLoading from './TopCommunitiesLoading';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

const TopCommunities = () => {
  const { callService } = useCallService();

  const [topCommunities, setTopCommunities] = useState<ChartValues[]>([]);
  const [loading, setLoading] = useState(true);

  const getTopCommunities = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.group.getTopCommunities(),
      });

      if (response) {
        setTopCommunities(
          response
            .filter((c: any) => c.completedCount > 0)
            .map((r: any) => ({ label: r.name, value: r.completedCount }))
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTopCommunities();
  }, []);

  return (
    <>
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TOP COMMUNITIES
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by course completion
        </Box>
      </Box>
      {loading ? (
        <TopCommunitiesLoading />
      ) : (
        <BarCharts chartValues={topCommunities} />
      )}
    </>
  );
};

export default TopCommunities;
