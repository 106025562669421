import { Alert } from '@mui/material';
import { REACT_APP_ENABLE_TIME_BASE_REPORT } from 'config/config';
import { formatDateUSA, isAValidDateFilter } from 'utils/date';

type DateFilterMessagesProps = {
  acceptedDateRangeFilter: any;
  selectedStartDate: Date;
  selectedEndDate: Date;
};

const DateFilterMessages = ({
  acceptedDateRangeFilter,
  selectedStartDate,
  selectedEndDate,
}: DateFilterMessagesProps) => {
  return (
    <>
      {!selectedStartDate && selectedEndDate ? (
        <Alert severity="warning">
          Please select a start date to filter by date range.
        </Alert>
      ) : (
        <></>
      )}

      {selectedStartDate && !selectedEndDate ? (
        <Alert severity="warning">
          Please select an end date to filter by date range.
        </Alert>
      ) : (
        <></>
      )}

      {selectedStartDate &&
      selectedEndDate &&
      !isAValidDateFilter(
        acceptedDateRangeFilter,
        selectedStartDate,
        selectedEndDate
      ) ? (
        <Alert severity="warning">
          {`The selected date must be between ${formatDateUSA(
            acceptedDateRangeFilter.calculatedMinDate
          )} and ${formatDateUSA(acceptedDateRangeFilter.calculatedMaxDate)}`}
        </Alert>
      ) : (
        <></>
      )}

      {selectedStartDate &&
      selectedEndDate &&
      selectedStartDate > selectedEndDate ? (
        <Alert severity="warning">
          End date must be greater than start date.
        </Alert>
      ) : (
        <></>
      )}

      <br />

      {REACT_APP_ENABLE_TIME_BASE_REPORT && (
        <Alert severity="info">
          {'Press ENTER or click "Apply Filters" to search by filters'}
        </Alert>
      )}
    </>
  );
};

export default DateFilterMessages;
