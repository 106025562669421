import { ReactNode, useEffect, useState } from 'react';

import { Grid, Box, Tab, Tabs } from '@mui/material';

import PageContainer from 'components/PageContainer';

import UserCertificates from './UserCertificates';
import UserCommunities from './UserCommunities';
import UserCourseReport from './UserCourseReport';
import UserRewards from './UserRewards';
import { CommunityMemberIcon } from 'material-icons';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`group-tabpanel-${index}`}
      aria-labelledby={`group-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `group-tab-${index}`,
    'aria-controls': `group-tabpanel-${index}`,
  };
}

const CourseActivityReport = () => {
  const [value, setValue] = useState(0);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageContainer
      title="Course Activity Report"
      icon={<CommunityMemberIcon sx={{ fontSize: '24px' }} />}
    >
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="group tab"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Course Report" {...a11yProps(0)} />
              <Tab label="Certificates" {...a11yProps(1)} />
              <Tab label="Communities" {...a11yProps(2)} />
              <Tab label="Rewards" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UserCourseReport />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserCertificates />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserCommunities />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <UserRewards />
          </TabPanel>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default CourseActivityReport;
