import { Box, useMediaQuery } from '@mui/material';
import { getDescriptionEllipsis } from 'utils/common';
import { useTheme } from '@mui/material/styles';
import { ChartValues } from './types';
import colorsByIndex from './colors';

const BarCharts = ({ chartValues }: { chartValues: ChartValues[] }) => {
  const maxValue = Math.max(...chartValues.map((community) => community.value));

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        height: '469px',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {chartValues.map((community, index) => {
        const calculatedHeight = Math.abs((community.value * 100) / maxValue);

        return (
          <Box
            sx={{
              height: '100%',
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'end',
            }}
            key={community.label}
          >
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '20px',
                  lineHeight: '23px',
                  color: '#205072',
                  textAlign: 'right',
                }}
              >
                {community.value}
              </Box>
              <Box
                sx={{
                  height: `${calculatedHeight}%`,
                  borderRadius: '15px 15px 0px 0px',
                  backgroundColor: colorsByIndex[index],
                  width: matchDownSM ? '45px' : '90px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                color: '#5A5A5A',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                flex: '0 0 28px',
                width: matchDownSM ? '60px' : '120px',
              }}
            >
              {getDescriptionEllipsis(community.label, matchDownSM ? 12 : 30)}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default BarCharts;
