import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterState } from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';
import { REACT_APP_ENABLE_TIME_BASE_REPORT } from 'config/config';

export const addDateFilterOptions = (
  filterModel: FilterType[],
  minDate: string,
  maxDate: string,
  startDateQS: string | null = null,
  endDateQS: string | null = null
) => {
  if (REACT_APP_ENABLE_TIME_BASE_REPORT) {
    filterModel.unshift(
      {
        id: 'startDate',
        type: 'date',
        label: 'Start date',
        initialValue: startDateQS
          ? new Date(new Date(startDateQS).toUTCString().slice(0, -4))
          : null,
        props: {
          minDate: minDate,
          maxDate: maxDate,
          infoMsg:
            'Press ENTER or click on "Apply filters" after to select start and end date to filter by date range',
        },
      },
      {
        id: 'endDate',
        type: 'date',
        label: 'End date',
        initialValue: endDateQS
          ? new Date(new Date(endDateQS).toUTCString().slice(0, -4))
          : null,
        props: {
          minDate: minDate,
          maxDate: maxDate,
          infoMsg:
            'PRESS ENTER or click on "Apply filters" after to select start and end date to filter by date range',
        },
      }
    );
  }
};

export const setDateRangeFilters = (
  filters: Map<any, any>,
  startDateQS: string | null,
  endDateQS: string | null
) => {
  if (REACT_APP_ENABLE_TIME_BASE_REPORT) {
    if (startDateQS) {
      filters.set(
        'startDate',
        new Date(new Date(startDateQS).toUTCString().slice(0, -4))
      );
    }
    if (endDateQS) {
      filters.set(
        'endDate',
        new Date(new Date(endDateQS).toUTCString().slice(0, -4))
      );
    }
  }
};

export const changeColumnTitles = (
  columns: DataGridColumnProps[],
  selectedStartDate: any,
  selectedEndDate: any
) => {
  if (selectedStartDate && selectedEndDate) {
    columns[1].headerName = 'New Learner';
    columns[2].headerName = 'New Started';
    columns[3].headerName = 'New Completed';
  } else {
    columns[1].headerName = 'Total Learner';
    columns[2].headerName = 'Total Started';
    columns[3].headerName = 'Total Completed';
  }

  return columns;
};

export const getDates = (filters: FilterState) => {
  const selectedStartDate: any = filters.get('startDate')
    ? filters.get('startDate')
    : '';
  const selectedEndDate: any = filters.get('endDate')
    ? filters.get('endDate')
    : '';

  return [selectedStartDate, selectedEndDate];
};
