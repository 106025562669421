import { Box } from '@mui/material';
import Grade from '@mui/icons-material/Grade';

const RatingChip = ({ rating }: { rating?: string }) => {
  return (
    <Box
      sx={{
        height: '20px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grade style={{ color: '#FFD700' }} fontSize="small" />
      <p
        style={{
          margin: '2px',
          color: '#525353',
          fontFamily: 'Raleway',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '10px',
          lineHeight: '20px',
        }}
      >
        {rating ? Number(rating).toFixed(1) : ''}
      </p>
    </Box>
  );
};

export default RatingChip;
