import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import fileSaver from 'file-saver';
import Papa from 'papaparse';

import { Box } from '@mui/material';

import PageContainer from 'components/PageContainer';

import {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import DataGrid from 'components/dataGrid/components/DataGrid';

import { FilterState } from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';

import { renderBoxColumn } from 'utils/tableRendering';
import { formatCurrency, formatPercentageSymbol, formatPercentageSymbolWithPrecision } from 'utils/common';
import httpRoutes from 'utils/httpRoutes';

import { useCallService } from 'hooks';
import { getValueByKpi } from '../../utils';

let multistoreRequestController: AbortController | undefined;

const columns = (navigateToGroup: any): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Store',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { groupId, groupName } = params.row;
        return (
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToGroup(groupId);
            }}
          >
            {groupName}
          </Box>
        );
      },
    },
    {
      headerName: 'Ticket Size',
      field: 'ticketSize',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stats } = params.row;

        const ticketSize = getValueByKpi(stats, 'ticket_size');

        return renderBoxColumn(ticketSize, formatCurrency);
      },
    },
    {
      headerName: 'Transactions Per Hour',
      field: 'transactionsPerHour',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stats } = params.row;
        const transactionsPerHour = getValueByKpi(
          stats,
          'transactions_per_hour'
        );

        return renderBoxColumn(transactionsPerHour);
      },
    },
    {
      headerName: 'Units Per Transaction',
      field: 'unitsPerTransaction',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stats } = params.row;
        const unitsPerTransaction = getValueByKpi(
          stats,
          'units_per_transaction'
        );

        return renderBoxColumn(unitsPerTransaction);
      },
    },
    {
      headerName: 'Average Unit Price',
      field: 'avgUnitPrice',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stats } = params.row;
        const avgUnitPrice = getValueByKpi(stats, 'avg_unit_price');

        return renderBoxColumn(avgUnitPrice, formatCurrency);
      },
    },
    {
      headerName: 'Percent Discount',
      field: 'pct_discount',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stats } = params.row;
        const pctDiscount = getValueByKpi(stats, 'pct_discount');

        return renderBoxColumn(pctDiscount, formatPercentageSymbol);
      },
    },
  ];
};

const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Search by store',
  },
];

const MultiStoreGrid = () => {
  const loadingState = {
    groups: true,
    exporting: false,
  };

  const navigate = useNavigate();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigateToGroup = (id: string) => {
    navigate(`/ddl/performance-report/group/${id}`);
  };

  const getMultiStoreCommunities = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    if (multistoreRequestController) {
      multistoreRequestController.abort();
    }
    try {
      multistoreRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.performanceReport.multiStore.getCommunities({
          controller: multistoreRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        multistoreRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, groups: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportMultiStoreCommunities = async () => {
    setLoading((prevState) => ({ ...prevState, exporting: true }));
    if (multistoreRequestController) {
      multistoreRequestController.abort();
    }
    try {
      multistoreRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.performanceReport.multiStore.export({
          filters,
        }),
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            groupName,
            ticketSize,
            revenuePerHour,
            transactionsPerHour,
            unitsPerTransaction,
            avgUnitPrice,
          } = item;

          return {
            Store: groupName,
            'Ticket Size': ticketSize,
            'Revenue Per Hour': revenuePerHour,
            'Transactions Per Hour': transactionsPerHour,
            'Units Per Transaction': unitsPerTransaction,
            'Average Unit Price': avgUnitPrice,
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Multi Store Performance Report.csv');
        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  useEffect(() => {
    getMultiStoreCommunities();
  }, [filters, state]);

  return (
    <PageContainer title="Multi-Store Performance Report">
      <DataGrid
        rows={gridData.rows}
        columns={columns(navigateToGroup)}
        filters={filterModel}
        handleFilterChange={setFilters}
        handleExport={exportMultiStoreCommunities}
        totalRows={gridData.totalRows}
        loading={loading.groups}
      />
    </PageContainer>
  );
};

const MultiStore = () => (
  <ControlledDataGrid>
    <MultiStoreGrid />
  </ControlledDataGrid>
);

export default MultiStore;
