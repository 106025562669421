import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import useSocket from 'hooks/useSocket';
import {
  REACT_APP_HRB_S3_URL,
  REACT_APP_HRB_CSS_URL,
  REACT_APP_HRB_DOMAIN_ID,
} from 'config/config';
import AssessmentOutcome, { IAssessmentOutcome } from '../dialogs/AssessmentOutcome';

enum AssessmentsSyncEvents {
  JoinRoom = 'ASSESSMENTS_JOIN_ROOM',
  AssessmentsCompleted = 'ASSESSMENTS_COMPLETED',
}

function SxAssessment({
  email,
  domainId,
  assessmentId,
  firstName,
  lastName,
}: {
  email: string;
  domainId: string;
  assessmentId?: string;
  firstName?: string;
  lastName?: string;
}) {
  return (
    <iframe
      title="SxAssessment"
      style={{
        height: '100%',
        width: '100%',
        border: 'none',
      }}
      srcDoc={`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <title>Sx Assessment</title>
        <link rel="stylesheet" href="${REACT_APP_HRB_CSS_URL}">
      </head>
      <body>
        <div
          id="app"
          data-email="${email}"
          data-did="${domainId}"
          data-aid="${assessmentId}"
          data-first-name="${firstName}"
          data-last-name="${lastName}"
        ></div>
        <script type="application/javascript" src="${REACT_APP_HRB_S3_URL}" async></script>
      </body>
      </html>
      `}
    ></iframe>
  );
}

const Assessment = () => {
  const {
    authState: { user },
  } = useAuthState();

  const { showDialog } = useDialogDispatcher();
  const navigate = useNavigate();
  const { socket } = useSocket();
  const params = useParams();

  const { id } = params;

  const handleCompletedAssessments = (assessmentOutcome: IAssessmentOutcome) => {
    showDialog({
      content: <AssessmentOutcome examPassed={assessmentOutcome.examPassed} message={assessmentOutcome.message} score={assessmentOutcome.score} />,
      size: 'sm',
      noPadding: true,
    });
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const userId = user.id;
    const assessmentId = params.id;

    if (userId && assessmentId) {
      socket.emit(AssessmentsSyncEvents.JoinRoom, { userId, assessmentId });
    }
  }, [user]);

  useEffect(() => {
    socket.on(
      AssessmentsSyncEvents.AssessmentsCompleted,
      handleCompletedAssessments
    );

    return () => {
      socket.off(
        AssessmentsSyncEvents.AssessmentsCompleted,
        handleCompletedAssessments
      );
    };
  }, []);

  return (
    <Grid sx={{ width: '100%', height: '100%', overflow: 'scroll' }}>
      <SxAssessment
        email={user.email}
        domainId={REACT_APP_HRB_DOMAIN_ID}
        assessmentId={id}
        firstName={user.firstName}
        lastName={user.lastName}
      />
    </Grid>
  );
};

export default Assessment;
