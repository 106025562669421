import React, { useState, useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';

import { Box, Grid, Button } from '@mui/material';
import { SaveIcon } from 'material-icons';
import { LoadingButton } from '@mui/lab';

import CourseInfo, { firstValidationStep } from './steps/courseInfo/CourseInfo';
import Incentives, {
  secondValidationStep,
} from './steps/incentives/Incentives';
import Assessments, { fourthValidationStep } from './steps/Assessments';
import { thirdValidationStep } from './steps/OutReach';
import OutReach from './steps/OutReach';
import Stepper from '../../../components/Stepper';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useMultistepForm } from 'hooks/useMultistepForm';
import fileManagement from 'utils/s3';
import { StepType } from 'types/StepType';
import { CourseFormInput } from 'types/courseTypes/CourseFormInput';

const validations = [
  firstValidationStep,
  secondValidationStep,
  thirdValidationStep,
  fourthValidationStep,
];

const MultiStepForm = ({
  defaultValues,
  onSubmit,
  isEdit = false,
  isSubmitting = false,
}: {
  defaultValues?: any;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  isSubmitting?: boolean;
}) => {
  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();

  const [validationSchema, setValidationSchema] = useState<any>(validations[0]);
  const [files, setFiles] = useState<File[]>([]);
  const [steps, setSteps] = useState<any>([]);

  const fileUrl = `${decodeURIComponent(defaultValues?.imageUrl || '')}`;

  const preSubmitUpload = async (values: CourseFormInput) => {
    // upload image to S3
    if (files.length > 0) {
      enqueueSnackbar('Uploading Course Image...', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const fileUrl = 'course-images/' + encodeURIComponent(files[0].name);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: files[0],
        key: fileUrl,
      });

      if (response) {
        values.imageFileChanged = true;
        values.imageUrl = fileUrl;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    if (values.courseFile) {
      enqueueSnackbar('Uploading SCORM file. Please stay on page.', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const { name } = values.courseFile[0];

      const fileUrl = 'courses/scorm/' + encodeURIComponent(name);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: values.courseFile[0],
        key: fileUrl,
        contentType: 'application/zip',
      });

      if (response) {
        enqueueSnackbar('File upload successfully!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        values.courseFileChanged = true;
        values.url = fileUrl;

        delete values.courseFile;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    onSubmit(values);
  };

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues?.id || undefined,
      name: defaultValues?.name || '',
      archived: defaultValues?.archived || false,
      description: defaultValues?.description || '',
      imageUrl: defaultValues?.imageUrl || '',
      url: defaultValues?.url || '',
      rewardId: defaultValues?.rewardId || '',
      prerequisiteId: defaultValues?.prerequisiteId || '',
      courseTypeId: defaultValues?.courseTypeId || undefined,
      courseCategoryId: defaultValues?.courseCategoryId || undefined,
      certificateId: defaultValues?.certificateId || '',
      searchType: defaultValues?.searchType || '',
      tags: defaultValues?.tags || [],
      estimatedHours: defaultValues?.estimatedHours || 0,
      customerId: defaultValues?.customerId || '',
      externalCourseId: defaultValues?.externalCourseId || '',
      organizationId: defaultValues?.organizationId || '',
      imageFileChanged: false,
      courseFileChanged: false,
      kpiId: defaultValues?.kpiId || null,
      successMessage: defaultValues?.successMessage || '',
      firstFailureMessage: defaultValues?.firstFailureMessage || '',
      secondFailureMessage: defaultValues?.secondFailureMessage || '',
      thirdFailureMessage: defaultValues?.thirdFailureMessage || '',
      fourthFailureMessage: defaultValues?.fourthFailureMessage || '',
      states: defaultValues?.states,
      firstCourseRoundAttempts: defaultValues?.firstCourseRoundAttempts,
      lockoutPeriod: defaultValues?.lockoutPeriod,
      secondCourseRoundAttempts: defaultValues?.secondCourseRoundAttempts,
      emailBlastDate: defaultValues?.emailBlastDate || null,
      socialMediaBlastDate: defaultValues?.socialMediaBlastDate || null,
      isRevision: defaultValues?.isRevision || false,
      revisionDate: defaultValues?.revisionDate || null,
      rewardAssigned: defaultValues?.rewardAssigned || false,
      rewardTypeId: defaultValues?.rewardTypeId || '',
      hasCommunicationSupport: defaultValues?.hasCommunicationSupport || 'no',
      courseTypeName: defaultValues?.courseTypeName,
      contestName: defaultValues?.contestName,
      isRaffleActive: defaultValues?.isRaffleActive,
      expiresOn: defaultValues?.expiresOn,
      isSwagActive: defaultValues?.isSwagActive,
      swagExpiresOn: defaultValues?.swagExpiresOn,
      isSampleActive: defaultValues?.isSampleActive,
      sampleExpiresOn: defaultValues?.sampleExpiresOn,
      outReachActivationDescription:
        defaultValues?.outReachActivationDescription,
      startActivationDate: defaultValues?.startActivationDate || null,
      outReachDaysActive: defaultValues?.outReachDaysActive,
      outReachStates: defaultValues?.outReachStates,
      assessmentId: defaultValues?.assessmentId,
      sampleName: defaultValues?.sampleName,
      sampleType: defaultValues?.sampleType,
      sampleCompletionThreshold: defaultValues?.sampleCompletionThreshold,
      swagName: defaultValues?.swagName,
      swagType: defaultValues?.swagType,
      swagCompletionThreshold: defaultValues?.swagCompletionThreshold,
      rewardTypeName: defaultValues?.rewardTypeName,
      dateFirstPublished: defaultValues?.dateFirstPublished,
      courseSourceId: defaultValues?.courseSourceId,
      courseBuilderId: defaultValues?.courseBuilderId,
      isEdit,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, watch } = methods;

  const watchCourseType = watch('courseTypeName', '');

  const isAssessment = watchCourseType === 'Assessments';

  useEffect(() => {
    const newSteps = [
      {
        component: (
          <CourseInfo
            isEdit={isEdit}
            imageUrl={fileUrl}
            setFiles={setFiles}
            courseSourceId={defaultValues?.courseSourceId}
            courseBuilderId={defaultValues?.courseBuilderId}
          />
        ),
        title: 'Course Info',
      },
      {
        component: <Incentives />,
        title: 'Incentives',
      },
      {
        component: <OutReach />,
        title: 'Outreach',
      },
      ...(isAssessment
        ? [
            {
              component: <Assessments />,
              title: 'Assessments',
            },
          ]
        : []),
    ];
    setSteps(newSteps);
  }, [isSubmitting, isAssessment]);

  const stepsComponents = steps.map((step: StepType) => step.component);

  const { step, isFirstStep, isLastStep, back, next, currentStepIndex } =
    useMultistepForm(stepsComponents);

  const handleMultiStepSubmit = async (values: any) => {
    if (!isLastStep) {
      next();
    } else {
      await preSubmitUpload(values);
    }
  };

  useEffect(() => {
    setValidationSchema(validations[currentStepIndex]);
  }, [currentStepIndex]);

  const stepsTitles = steps.map((step: StepType) => step.title);

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleMultiStepSubmit)}>
          <Stepper steps={stepsTitles} currentStepIndex={currentStepIndex} />
          {step}
          <Grid container justifyContent="space-between" columnSpacing={1}>
            <Button variant="text" disabled={isSubmitting} onClick={hideDialog}>
              Cancel
            </Button>
            <Box>
              {!isFirstStep && (
                <Button variant="text" disabled={isSubmitting} onClick={back}>
                  Back
                </Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                loading={isSubmitting}
                loadingPosition="start"
                {...(isLastStep ? { startIcon: <SaveIcon /> } : {})}
              >
                {isLastStep ? 'Finish' : 'Next'}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default MultiStepForm;
