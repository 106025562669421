import { TableRow, TableCell, Skeleton } from '@mui/material';

const LoadingTableBody = ({
  columns,
  // isSelectable,
  // isExpandable,
  // expandedLabel,
  rowsPerPage,
  actionColumn: ActionColumn,
  rowStyle,
}: {
  columns: any;
  rowsPerPage: number;
  actionColumn?: any;
  rowStyle?: any;
}) => {
  const rows = [...Array(rowsPerPage)];

  return (
      <>
        {
          rows.map((x, i) => (
              <TableRow style={rowStyle} key={i}>
                {/*{isSelectable && (*/}
                {/*  <TableCell>*/}
                {/*    <Checkbox*/}
                {/*      color="primary"*/}
                {/*      containerStyle={{ marginBottom: 0 }}*/}
                {/*      iconStyle={{ top: 0 }}*/}
                {/*    />*/}
                {/*  </TableCell>*/}
                {/*)}*/}
                {columns.map((column: any, index: number) => (
                    <TableCell key={`${column.field} ${index}`}>
                      <Skeleton variant="text" />
                    </TableCell>
                ))}
                {ActionColumn && (
                    <TableCell>
                      <ActionColumn disabled />
                    </TableCell>
                )}
                {/*{isExpandable && (*/}
                {/*  <StyledTableCell>*/}
                {/*    <Flex justifyContent="flex-end">*/}
                {/*      <Button aria-label="expand row" size="small">*/}
                {/*        <Typography variant="caption"> {expandedLabel} </Typography>*/}
                {/*        <KeyboardArrowUp />*/}
                {/*      </Button>*/}
                {/*    </Flex>*/}
                {/*  </StyledTableCell>*/}
                {/*)}*/}
              </TableRow>
          ))
        }
      </>
  );
};

export default LoadingTableBody;
