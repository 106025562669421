import { instance as apiClient } from 'utils/axios';

import { PaginationInterface } from 'components/dataGrid/components/DataGrid';

import { IProfileFormInputs } from './fragments/GeneralInfo';
import { ISecurityFormInputs } from './fragments/Security';

const apiBase = '/api/v1';

const resources = {
  updateUserProfile(payload: IProfileFormInputs) {
    return apiClient.put(`${apiBase}/users/profile`, payload);
  },
  getUserProfile() {
    return apiClient.get(`${apiBase}/users/profile`);
  },
  getUserCertificates({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: Map<string, any>;
  }) {
    const formattedParams = {
      ...params,
      name: filters.get('name'),
    };

    return apiClient.get(`${apiBase}/users/certificates`, {
      params: formattedParams,
    });
  },
  getUserCommunities({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: Map<string, any>;
  }) {
    const formattedParams = {
      ...params,
      name: filters.get('name'),
    };

    return apiClient.get(`${apiBase}/users/communities`, {
      params: formattedParams,
    });
  },
  getUserCommunitiesWithoutBrands() {
    return apiClient.get(`${apiBase}/users/communities/without-brands`);
  },
  getUserCourses({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: Map<string, any>;
  }) {
    const formattedParams = {
      ...params,
      name: filters.get('name'),
    };

    return apiClient.get(`${apiBase}/users/courses`, {
      params: formattedParams,
    });
  },
  getUserRewards({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: Map<string, string>;
  }) {
    const formattedParams = {
      ...params,
      rewardName: filters.get('rewardName'),
    };

    return apiClient.get(`${apiBase}/users/rewards`, {
      params: formattedParams,
    });
  },
  changeUserPassword(values: ISecurityFormInputs) {
    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };

    return apiClient.put(`${apiBase}/users/change-password`, payload);
  },
  setPrimaryGroup(userId: string, groupId: string) {
    const payload = {
      userId,
      groupId,
    };

    return apiClient.post(`${apiBase}/users/${userId}/primary-group`, payload);
  },
};

export default resources;
