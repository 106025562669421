import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const loadingState = {
  categories: true,
  courses: true,
  exporting: false,
};

let coursesRequestController: AbortController | undefined;

const AdminCourseReportGrid = () => {
  const [loading, setLoading] = useState(loadingState);
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (coursesRequestController) {
      coursesRequestController.abort();
    }
    try {
      coursesRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.reporting.course.getAdminCourseReport({
          controller: coursesRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        coursesRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, courses: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportCourses = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.course.exportAdminCourseReport({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Course Summary Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Category',
      field: 'courseCategory',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Total Started',
      field: 'totalStarted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      align: 'right',
      flex: 0.5,
    },
    {
      headerName: 'Total Completed',
      field: 'totalCompleted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      align: 'right',
      flex: 0.5,
    },
    {
      headerName: 'Percent Completed',
      field: 'test',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        console.log(params.row.totalCompleted);
        if (parseFloat(params.row.totalCompleted) === 0) {
          return <>-- %</>;
        }

        const _percentage = params.row.totalCompleted / params.row.totalStarted;

        if (_percentage === 1) {
          return <>100 %</>;
        }

        return (
          <>
            {(
              (params.row.totalCompleted / params.row.totalStarted) *
              100
            ).toPrecision(2)}{' '}
            %
          </>
        );
      },
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        if (params.row.averageScore) {
          return <>{params.row.averageScore.toFixed(2)} %</>;
        }
        return <>-- %</>;
      },
    },
  ];

  return (
    <PageContainer
      title="Course Summary Report"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        totalRows={gridData.totalRows}
        filters={filterModel}
        handleExport={exportCourses}
        handleFilterChange={setFilters}
        loading={loading.courses}
        onRowClick={(params) => {
          navigate(`/admin/reports/courses/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

const AdminCourseReport = () => (
  <ControlledDataGrid>
    <AdminCourseReportGrid />
  </ControlledDataGrid>
);

export default AdminCourseReport;
