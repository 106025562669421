import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4a8 8 0 100 16 8 8 0 000-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6a1 1 0 011 1v4h2a1 1 0 110 2h-3a1 1 0 01-1-1V7a1 1 0 011-1z"
    />
  </g>
);
