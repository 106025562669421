import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';

import { SaveIcon } from 'material-icons';

import { useCallService } from 'hooks';

import {
  PhoneFormField,
  CheckboxFormField,
  TextFormField,
  SelectFormField,
  AsyncSelectFormField,
} from 'components/formFields';
import { SelectOptionProps } from 'components/formFields/types';

import { getStatesList } from 'dataSets/states';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

export type CommunityFormInput = {
  id?: string | undefined;
  name: string;
  organizationId?: string;
  organization?: any;
  parentId?: string;
  private?: boolean;
  address?: string;
  city?: string;
  state: string;
  postal?: string;
  phone?: string;
  code?: string;
  archived?: boolean;
  discoverable?: boolean;
  imageUrl?: string;
  homepage?: string;
  foundedYear?: string;
  description?: string;
  groupType?: string;
  rssFeed?: string;
  rssSize?: number;
  billable?: number;
  headerImage?: string;
  viewable?: boolean;
  groupTypeId?: string;
};

export const COMMUNITY_FORM_INITIAL_STATE = {
  id: undefined,
  name: '',
  organizationId: '',
  parentId: '',
  private: false,
  address: '',
  city: '',
  state: '',
  postal: '',
  phone: '',
  code: '',
  archived: false,
  discoverable: false,
  imageUrl: '',
  homepage: '',
  foundedYear: '',
  description: '',
  groupType: '',
  rssFeed: '',
  rssSize: 0,
  billable: 0,
  headerImage: '',
  viewable: false,
  groupTypeId: '',
};

const Form = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: CommunityFormInput;
  onSubmit: (values: CommunityFormInput) => void;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const [types, setTypes] = useState<SelectOptionProps[]>([]);

  const getOrganizations = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.organizations.search(value),
    });

    if (response) {
      return response.map((item: any) => {
        return {
          id: item.id,
          label: `${item.name}`,
        };
      });
    }
  };

  const getGroupTypes = async () => {
    const { response } = await callService({
      resource: httpRoutes.groups.getTypes(),
    });

    if (response) {
      setTypes(
        response.map((item: any) => {
          return {
            value: item.id,
            label: `${item.name}`,
          };
        })
      );
    }
  };

  const getUsers = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.users.searchUsers(value),
    });

    if (response) {
      return response.map((item: any) => {
        return {
          id: item.id,
          label: `${item.firstName} ${item.lastName} (${item.email})`,
        };
      });
    }
  };

  useEffect(() => {
    getGroupTypes();
  }, []);

  const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string().required('A community name is required'),
    organizationId: Yup.string().required('An organization is required'),
    parentId: Yup.string().nullable(),
    private: Yup.boolean().nullable(),
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().required('A state is required'),
    postal: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    code: Yup.string().nullable(),
    archived: Yup.boolean().nullable(),
    discoverable: Yup.boolean().nullable(),
    imageUrl: Yup.string().nullable(),
    homepage: Yup.string().nullable(),
    foundedYear: Yup.string().nullable(),
    description: Yup.string().nullable(),
    groupTypeId: Yup.string().required('A group type is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CommunityFormInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || undefined,
      name: defaultValues?.name || '',
      organizationId: defaultValues?.organizationId || '',
      parentId: defaultValues?.parentId || '',
      private: defaultValues?.private || false,
      address: defaultValues?.address || '',
      city: defaultValues?.city || '',
      state: defaultValues?.state || '',
      postal: defaultValues?.postal || '',
      phone: defaultValues?.phone || '',
      code: defaultValues?.code || '',
      archived: defaultValues?.archived || false,
      discoverable: defaultValues?.discoverable || false,
      imageUrl: defaultValues?.imageUrl || '',
      homepage: defaultValues?.homepage || '',
      foundedYear: defaultValues?.foundedYear || '',
      description: defaultValues?.description || '',
      groupType: defaultValues?.groupType || '',
      billable: defaultValues?.billable || 0,
      headerImage: defaultValues?.headerImage || '',
      viewable: defaultValues?.viewable || false,
      groupTypeId: defaultValues?.groupTypeId || '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const stateOptions = getStatesList();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Community
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <TextFormField
                name="name"
                control={control}
                label="Community Name*"
                errors={errors.name}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFormField
                name="address"
                control={control}
                label="Street Address"
                errors={errors.address}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFormField
                name="city"
                control={control}
                label="City"
                errors={errors.city}
                margin="dense"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectFormField
                name="state"
                control={control}
                label="State"
                errors={errors.state}
                margin="dense"
                options={stateOptions}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFormField
                name="postal"
                control={control}
                label="Zip Code"
                errors={errors.postal}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneFormField
                name="phone"
                control={control}
                label="Contact Phone"
                errors={errors.phone}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <AsyncSelectFormField
                name="organizationId"
                defaultValue={{
                  id: defaultValues?.organization.id,
                  label: defaultValues?.organization.name,
                }}
                control={control}
                label="Select A Parent Organization*"
                getOptions={getOrganizations}
                errors={errors.organizationId}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={3}>
              <SelectFormField
                name="groupTypeId"
                control={control}
                label="Community Type*"
                errors={errors.groupTypeId}
                margin="dense"
                options={types}
              />
            </Grid>
            {!defaultValues && (
              <Grid item xs={12} mt={2}>
                <AsyncSelectFormField
                  name="facilitatorId"
                  control={control}
                  label="Select A Facilitator"
                  getOptions={getUsers}
                />
              </Grid>
            )}

            <Grid container xs={12}>
              <Grid item xs={6} sm={3}>
                <CheckboxFormField
                  name="private"
                  control={control}
                  label="Private"
                  errors={errors.private}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CheckboxFormField
                  name="discoverable"
                  control={control}
                  label="Discoverable"
                  errors={errors.discoverable}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CheckboxFormField
                  name="archived"
                  control={control}
                  label="Archived"
                  errors={errors.archived}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CheckboxFormField
                  name="billable"
                  control={control}
                  label="Billable"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <SaveIcon sx={{ mr: 1 }} /> Save
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
