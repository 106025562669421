import CourseTypeProps from './CourseTypeProps';
import CourseIncentiveProps from './CourseIncentiveProps';
import GroupCourseAssignment from '../GroupCourseAssignment';
import Organization from '../Organization';

type CourseProps = {
  id: string;
  name: string;
  reference?: string;
  updated: Date;
  created: Date;
  archived: boolean;
  description?: string;
  imageUrl?: string;
  url?: string;
  prerequisiteId?: string;
  contentCreatorId?: string;
  rewardId?: string;
  maxAttempts?: number;
  waitingPeriod?: number;
  html?: string;
  title?: string;
  numWaitingPeriods?: number;
  certificateId?: string;
  searchType?: string;
  tags?: string;
  minScore?: number;
  templateId?: string;
  estimatedHours?: number;
  customerId?: string;
  externalCourseId?: string;
  timeLimit?: number;
  courseTypeId?: number;
  updatedBy?: string;
  createdBy?: string;
  courseFileName?: string;
  imageFileName?: string;
  courseCategoryId?: number;
  courseCategoryName?: string;
  courseTypeName?: string;
  courseType?: CourseTypeProps;
  progress: number;
  status?: string;
  canStartCourse: boolean;
  lockedTime?: string | null;
  isLastRound: boolean;
  courseIncentive?: CourseIncentiveProps;
  dateFirstPublished: Date;
  groupCourseAssignments?: GroupCourseAssignment[];
  rating?: string;
  organization?: Organization;
  courseSourceId: number;
};

export const Course_Initial_State: CourseProps = {
  id: '',
  name: '',
  reference: '',
  updated: new Date(),
  created: new Date(),
  archived: false,
  description: '',
  imageUrl: '',
  url: '',
  prerequisiteId: '',
  contentCreatorId: '',
  rewardId: '',
  maxAttempts: 0,
  waitingPeriod: 0,
  html: '',
  title: '',
  numWaitingPeriods: 0,
  certificateId: '',
  searchType: '',
  tags: '',
  minScore: 0,
  templateId: '',
  estimatedHours: 0,
  customerId: '',
  externalCourseId: '',
  timeLimit: 0,
  courseTypeId: undefined,
  updatedBy: '',
  createdBy: '',
  courseFileName: '',
  imageFileName: '',
  courseCategoryId: undefined,
  courseCategoryName: '',
  courseTypeName: '',
  progress: 0,
  status: '',
  canStartCourse: false,
  lockedTime: null,
  isLastRound: false,
  dateFirstPublished: new Date(),
  courseSourceId: 0,
};

export default CourseProps;
