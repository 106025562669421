import * as React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { TextField, InputAdornment } from '@mui/material';

import InputMask from 'react-input-mask';

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const EndAdornment = () => (
  <InputAdornment position="end">
    <PhoneIphoneIcon />
  </InputAdornment>
);

interface PhoneFormFieldProps {
  name: string;
  label: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
}

export default function PhoneFormField({
  name,
  label,
  control,
  errors,
  margin = 'normal',
  required,
}: PhoneFormFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputMask
          {...field}
          mask="(999) 999-9999"
          value={field.value}
          maskChar=" "
          onChange={(e: {
            target: { value: React.SetStateAction<undefined> };
          }) => {
            field.onChange(e.target.value);
          }}
        >
          {() => (
            <TextField
              required={required}
              label={label}
              margin={margin}
              fullWidth
              error={Boolean(errors)}
              helperText={
                errors ? <span color="error">{errors.message}</span> : ' '
              }
              InputProps={{
                endAdornment: <EndAdornment />,
              }}
            />
          )}
        </InputMask>
      )}
    />
  );
}
