import { Backdrop, CircularProgress } from '@mui/material';

interface OverlayProps {
  open: boolean;
  zIndex?: number;
}

const Overlay = (props: OverlayProps) => {
  const { open, zIndex } = props;
  return (
    <Backdrop sx={{ color: '#fff', zIndex }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Overlay;
