import { FC, useEffect, useState } from 'react';

import {
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import * as Yup from 'yup';

import { useFormContext } from 'react-hook-form';

import {
  TextFormField,
  SelectFormField,
  AutoCompleteFormField,
  NumberFormField,
  MultipleSelectCheckmarksFormField,
  CheckboxFormField,
} from 'components/formFields';

import Dropzone from 'components/dropzone/DropzoneImage';

import { getStatesList } from 'dataSets/states';

import { filterPreRequisiteOptions } from '../../../utils';
import LoadingState from 'components/LoadingState';
import useCourseOptions from 'features/course-builder/hooks/useCourseOptions';
import { CourseInfoProps } from 'types/courseTypes/CourseInfoProps';

export const firstValidationStep = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  description: Yup.string().required('A description is required').max(1024),
  imageUrl: Yup.string().nullable(),
  url: Yup.string().nullable(),
  prerequisiteId: Yup.string().nullable(),
  estimatedHours: Yup.number().min(0).nullable(true).notRequired(),
  courseCategoryId: Yup.number().required('Please select a course category'),
});

const CourseInfo: FC<CourseInfoProps> = ({
  setFiles,
  imageUrl,
  isEdit = false,
}) => {
  const { courses, courseCategories, courseType, isFetching } =
    useCourseOptions();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [defaultPrerequisiteLoading, setDefaultPrerequisiteLoading] =
    useState(false);

  const [defaultPrerequisite, setDefaultPrerequisite] = useState<any>(null);

  const prerequisiteId = watch('prerequisiteId');

  useEffect(() => {
    const getPrerequisiteDefault = () => {
      try {
        if (prerequisiteId) {
          setDefaultPrerequisiteLoading(true);
          const prerequisite = courses.find(
            (item: any) => item.value === prerequisiteId
          );

          if (prerequisite) {
            setDefaultPrerequisite({
              value: prerequisite.value,
              label: prerequisite.label,
            });
            setDefaultPrerequisiteLoading(false);
          }
        }
      } catch (e) {
        setDefaultPrerequisiteLoading(false);
      }
    };

    getPrerequisiteDefault();
  }, [courses, prerequisiteId]);

  if (defaultPrerequisiteLoading || isFetching) return <LoadingState />;

  const filteredPreRequisites = filterPreRequisiteOptions(
    false,
    courses,
    courseType
  );

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
      style={{
        padding: matchDownLG ? '10%' : '1%',
      }}
    >
      <Grid container columnSpacing={1}>
        <Tooltip
          placement="bottom-start"
          title="For best results, the image should cover entire area."
        >
          <Grid
            item
            sm={12}
            sx={{ display: 'flex', justifyContent: 'center', width: '70vw' }}
          >
            <Dropzone
              maxFiles={1}
              maxSize={5000000}
              handleAcceptedFiles={(acceptedFiles: File[]) => {
                setFiles(acceptedFiles);
              }}
              defaultValue={imageUrl}
              helperText="Drag and drop the main image here."
            />
          </Grid>
        </Tooltip>
        <Grid item xs={12} mt={1}>
          <Grid item xs={12} md={12}>
            <TextFormField
              name="name"
              control={control}
              label="Course Name*"
              errors={errors.name}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFormField
              name="description"
              control={control}
              label="Description*"
              errors={errors.description}
              margin="dense"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1} mb={3}>
        <Grid item xs={12} sm={12}>
          <SelectFormField
            name="courseCategoryId"
            control={control}
            label="Course Category*"
            disabled={isFetching}
            options={courseCategories}
            errors={errors.courseCategoryId}
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={12}>
          <AutoCompleteFormField
            defaultValue={defaultPrerequisite}
            name="prerequisiteId"
            control={control}
            label="Prerequisite"
            options={filteredPreRequisites}
            errors={errors.prerequisiteId}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={12}>
          <NumberFormField
            name="estimatedHours"
            control={control}
            label="Educational Hours"
            errors={errors.estimatedHours}
            icon={<span>hours</span>}
            position="end"
            margin="dense"
            step={0.01}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Grid item xs={12} mb={2}>
          <Typography variant="h6">States Multi Select</Typography>
        </Grid>
        <MultipleSelectCheckmarksFormField
          name="states"
          limitTags={3}
          color="#1565c0"
          options={getStatesList('name')}
          control={control}
          label="Select all states"
        />
      </Grid>

      {isEdit && (
        <Grid container mt={2}>
          <CheckboxFormField
            name="archived"
            control={control}
            label="Archive Course"
            errors={errors.archived}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CourseInfo;
