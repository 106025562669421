import { useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  Tooltip,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { lighten } from 'polished';

import Icon from 'icons/Icon';

import { InfoOutlinedIcon } from 'material-icons';

interface IResult {
  result: string;
  label: string;
  icon: string;
  color: string;
  tooltipLabel?: string;
}

const ResultCard = ({ result, label, icon, color, tooltipLabel }: IResult) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item container rowSpacing={1} xs={12} sm={12} md={12} lg={6} xl={3}>
      <Grid
        item
        xl={12}
        md={6}
        xs={6}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Avatar
          sx={{
            backgroundColor: lighten(0.3, color),
            width: 62,
            height: 62,
          }}
        >
          <Icon name={icon} fill={color} />
        </Avatar>
      </Grid>
      <Grid
        container
        item
        xl={12}
        xs={6}
        md={6}
        rowSpacing={1}
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '14px',
              textTransform: 'uppercase',
              color: '#205072',
              mr: matchDownSM ? '4px' : '8px',
            }}
          >
            {label}
          </Box>
          {tooltipLabel && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '13px',
                color: '#205072',
                cursor: 'pointer',
              }}
            >
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipLabel}
                >
                  <InfoOutlinedIcon
                    onClick={handleTooltipOpen}
                    fontSize="inherit"
                  />
                </Tooltip>
              </ClickAwayListener>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '14px',
              textTransform: 'uppercase',
              color: '#205072',
            }}
          >
            {result}
          </Box>
        </Grid>
      </Grid>
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: 'column',*/}
      {/*    gap: 2,*/}
      {/*    justifyContent: 'center',*/}
      {/*    alignItems: 'center',*/}
      {/*    textAlign: 'center',*/}
      {/*    flex: 1,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Box sx={matchUpXS ? { flex: 1 } : {}}>*/}
      {/*    <Avatar*/}
      {/*      sx={{*/}
      {/*        backgroundColor: lighten(0.3, color),*/}
      {/*        opacity: '0.9',*/}
      {/*        width: 62,*/}
      {/*        height: 62,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <Icon name={icon} fill={color} />*/}
      {/*    </Avatar>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: 1,*/}
      {/*      justifyContent: 'center',*/}
      {/*      alignItems: 'center',*/}
      {/*      textAlign: 'center',*/}
      {/*      ...(matchUpXS && { flex: 1 }),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        fontFamily: 'Roboto',*/}
      {/*        fontWeight: '600',*/}
      {/*        fontSize: '14px',*/}
      {/*        lineHeight: '14px',*/}
      {/*        textTransform: 'uppercase',*/}
      {/*        color: '#205072',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {label}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      gap: 1,*/}
      {/*      justifyContent: 'center',*/}
      {/*      alignItems: 'center',*/}
      {/*      textAlign: 'center',*/}
      {/*      ...(matchUpXS && { flex: 1 }),*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        fontFamily: 'Roboto',*/}
      {/*        fontWeight: '600',*/}
      {/*        fontSize: '24px',*/}
      {/*        lineHeight: '14px',*/}
      {/*        textTransform: 'uppercase',*/}
      {/*        color: '#205072',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {result}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </Grid>
  );
};

export default ResultCard;
