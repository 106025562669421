import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import { Box, useMediaQuery, Avatar, Skeleton } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { lighten } from 'polished';

import httpRoutes from 'utils/httpRoutes';

import { useTheme } from '@mui/material/styles';
import Stats from './partials/stats/Stats';
import TopLearners from './partials/topLearners/TopLearners';
import TopCourses from './partials/topCourses/TopCourses';
import Icon from 'icons';
import Counter from '../../shared/Counter';
import AvgHoursLearning from './partials/avgHoursLearning/AvgHoursLearning';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'First Name',
    field: 'firstName',
    sortable: true,
    filterable: false,
    flex: 0.25,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    sortable: true,
    filterable: false,
    flex: 0.25,
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: true,
    filterable: false,
    flex: 0.25,
  },
  {
    headerName: 'Courses',
    field: 'totalCourses',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.1,
  },
  {
    headerName: 'Started',
    field: 'totalStarted',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.1,
  },
  {
    headerName: 'Completed',
    field: 'totalCompleted',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.1,
  },
  {
    headerName: 'YTD Hours',
    field: 'ytdHours',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.1,
  },
  {
    headerName: 'Average Score',
    field: 'averageScore',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.1,
    ColumnComponent: (params: any) => {
      if (params.row.averageScore) {
        return <>{params.row.averageScore} %</>;
      }
      return <>-- %</>;
    },
  },
];

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'email',
    type: 'text',
    label: 'Learner Email',
  },
];

const loadingState = {
  learners: true,
  exporting: false,
  groupName: true,
};

let learnersRequestController: AbortController | undefined;

const LearnersSpecificCommunityReportGrid = () => {
  const theme = useTheme();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchUpLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));
  const matchUpMD = useMediaQuery(theme.breakpoints.down('md'));

  const params = useParams();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const [groupName, setGroupName] = useState('');

  const navigate = useNavigate();

  const groupId = params.id || '';

  const getGroup = async () => {
    try {
      if (groupId) {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(groupId),
        });

        if (response) {
          setGroupName(response.name);
        }
      }
    } finally {
      setLoading((prevState) => ({ ...prevState, groupName: false }));
    }
  };

  const getLearners = async () => {
    setLoading((prevState) => ({ ...prevState, learners: true }));
    if (learnersRequestController) {
      learnersRequestController.abort();
    }

    try {
      learnersRequestController = new AbortController();
      const filtersMapped = new Map(filters);
      filtersMapped.set('groupId', groupId);

      const { response } = await callService({
        resource: httpRoutes.reporting.learner.getFacilitatorReport({
          controller: learnersRequestController,
          params: { ...state },
          filters: filtersMapped,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        learnersRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, learners: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, learners: false }));
    }
  };

  const exportLearners = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const filtersMapped = new Map(filters);
      filtersMapped.set('groupId', groupId);

      const { response } = await callService({
        resource: httpRoutes.reporting.learner.exportFacilitatorReport({
          params: { ...state },
          filters: filtersMapped,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Learner Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  useEffect(() => {
    getLearners();
  }, [filters, state]);

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 12 }}>
        <Box sx={{ mb: 2 }}>
          {loading.groupName ? (
            <Skeleton variant="rectangular" height={70} width="50%" />
          ) : (
            <>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: 600,
                  fontSize: matchUpXS ? '20px' : '40px',
                  color: '#162738',
                  lineHeight: '130%',
                }}
              >
                Learners Report
              </Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '500',
                  fontSize: matchUpXS ? '20px' : '40px',
                  lineHeight: '130%',
                  color: '#162738',
                }}
              >
                {groupName}
              </Box>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1 }}>
          <Stats groupId={groupId} />
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Box
            sx={{
              p: 4,
              borderRadius: '15px',
              backgroundColor: 'white',
              flex: '0.50 0',
              display: 'flex',
              ...(matchUpMD && { width: '100%' }),
              flexDirection: 'column',
            }}
          >
            <TopLearners groupId={groupId} />
          </Box>
          <Box
            sx={{
              p: 4,
              borderRadius: '15px',
              backgroundColor: 'white',
              ...(matchUpMD && { width: '100%' }),
              flex: '0.50 0',
            }}
          >
            <TopCourses groupId={groupId} />
          </Box>
          <Box
            sx={{
              p: 4,
              gap: 4,
              borderRadius: '15px',
              backgroundColor: 'white',
              flex: `${matchUpLG ? '0.25 0' : '1 0 auto'}`,
              ...(matchDownXL && { width: '100%' }),
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AvgHoursLearning groupId={groupId} />
          </Box>
        </Box>
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleExport={exportLearners}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          loading={loading.learners}
          onRowClick={(values) => {
            navigate(`/reports/learners/${values.id}`);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const LearnersSpecificCommunityReport = () => (
  <ControlledDataGrid>
    <LearnersSpecificCommunityReportGrid />
  </ControlledDataGrid>
);

export default LearnersSpecificCommunityReport;
