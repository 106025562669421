import { Grid, Box } from '@mui/material';
import PageContainer from 'components/PageContainer';
import { CommunityIcon } from 'material-icons';
import PendingMembers from '../partials/PendingMembers';

const ApproveMembers = () => {
  return (
    <PageContainer
      title="Pending Members"
      icon={<CommunityIcon sx={{ fontSize: '24px' }} />}
    >
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <PendingMembers />
          </Box>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default ApproveMembers;
