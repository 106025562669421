import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lighten } from 'polished';

import {
  Avatar,
  Box,
  Skeleton,
  useMediaQuery,
  Grid,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import ResponsiveCarrousel from 'layout/NewLayout/CourseCarrousel/ResponsiveCarrousel';

import ConditionalComponent from 'components/ConditionalComponent';
import PageContainer from 'components/PageContainer';
import DisplayText from 'components/DisplayText';

import { useCallService } from 'hooks';

import Icon from 'icons/Icon';

import httpRoutes from 'utils/httpRoutes';
import LearnerFlow from './LearnerFlow';
import CourseLoading from './CourseLoading';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import PrimaryGroup from '../dialogs/PrimaryGroup';

export type IDashboardStats = {
  inProgressCount: number;
  requiredCount: number;
  completedCount: number;
  certificatesCount: number;
  newCount: number;
};

const Dashboard = () => {
  const {
    authState: {
      user: { id, firstName, lastName },
      isAlias,
    },
  } = useAuthState();

  const navigate = useNavigate();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();
  const theme = useTheme();
  const matchUpXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [stats, setStats] = useState<IDashboardStats>({} as IDashboardStats);
  const [requiredCourses, setRequiredCourses] = useState<any[]>([]);
  const [requiredCoursesLoading, setRequiredCoursesLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState<any[]>([]);
  const [recommendedCoursesLoading, setRecommendedCoursesLoading] =
    useState(true);
  const [loadingStats, setLoadingStats] = useState(true);
  const [
    loadingShouldPrimaryGroupModalRender,
    setLoadingShouldPrimaryGroupModalRender,
  ] = useState(true);
  const [shouldOpenPrimaryGroupModal, setShouldOpenPrimaryGroupModal] =
    useState(false);

  const getDashBoardStats = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.dashboard.getStats(),
      });
      if (response) {
        setStats(response);
      }
    } finally {
      setLoadingStats(false);
    }
  };

  const getUserShouldSelectPrimaryGroup = async () => {
    const { response } = await callService({
      resource: httpRoutes.users.getUserShouldSelectPrimaryGroup(id),
    });
    return response;
  };

  const getUserGroups = async () => {
    const { response } = await callService({
      resource: httpRoutes.groups.getHasGroupsByUserId(id),
    });
    return response;
  };

  const shouldPrimaryGroupModalRender = async () => {
    try {
      const shouldSelectPrimaryGroup = await getUserShouldSelectPrimaryGroup();
      if (shouldSelectPrimaryGroup) {
        const hasGroups = await getUserGroups();
        if (!hasGroups) {
          await callService({
            resource: httpRoutes.groups.addToSeedTalentPublicGroup(),
          });
        }

        setShouldOpenPrimaryGroupModal(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingShouldPrimaryGroupModalRender(false);
    }
  };

  const getRequiredCourses = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.dashboard.getRequiredCourses(),
      });

      if (response) {
        const _requiredCourses = response.map((item: any) => {
          return {
            ...item,
            status:
              item.userTestResults.length > 0
                ? item.userTestResults[0].status
                : 'To-Do',
            progress:
              item.userTestResults.length > 0
                ? item.userTestResults[0].progress
                : 0,
            courseCategoryName: item.courseCategory.name,
          };
        });

        setRequiredCourses(_requiredCourses);
      }
    } finally {
      setRequiredCoursesLoading(false);
    }
  };

  const getLatestCourses = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.courses.getLatest30(),
      });

      if (response) {
        const _recommendedCourses = response.map((item: any) => {
          return {
            ...item,
            status:
              item.userTestResults.length > 0
                ? item.userTestResults[0].status
                : 'To-Do',
            progress:
              item.userTestResults.length > 0
                ? item.userTestResults[0].progress
                : 0,
            courseCategoryName: item?.courseCategory?.name,
          };
        });
        setRecommendedCourses(_recommendedCourses);
      }
    } finally {
      setRecommendedCoursesLoading(false);
    }
  };

  const goToCourseLibrary = () => {
    navigate('/courses');
  };

  const showPrimaryGroupDialog = () => {
    if (shouldOpenPrimaryGroupModal && !isAlias) {
      showDialog({
        content: <PrimaryGroup />,
        size: 'md',
        enableBackdropClose: false,
        disableEscapeKeyDown: true,
        open: shouldOpenPrimaryGroupModal,
      });
    }
  };

  useEffect(() => {
    showPrimaryGroupDialog();
  }, [shouldOpenPrimaryGroupModal, isAlias]);

  useEffect(() => {
    if (id !== '') {
      shouldPrimaryGroupModalRender();
    }
  }, [id]);

  useEffect(() => {
    getDashBoardStats();
  }, []);

  useEffect(() => {
    getRequiredCourses();
    getLatestCourses();
  }, []);

  const {
    certificatesCount,
    completedCount,
    inProgressCount,
    newCount,
    requiredCount,
  } = stats;

  return (
    <PageContainer showBackButton={false}>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: matchUpXS ? '20px' : '40px',
            lineHeight: '130%',
          }}
        >
          {`Welcome back, ${firstName} ${lastName}.`}
        </Box>
      </Box>
      <Box>
        <Box sx={{ mb: 2 }}>
          <DisplayText type="sh2" text="Your Course Stats" variant="medium" />
        </Box>
        <Box>
          {loadingStats || loadingShouldPrimaryGroupModalRender ? (
            <Box
              sx={{
                borderRadius: '15px',
                backgroundColor: 'white',
                p: 4,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Skeleton variant="rectangular" height="70px" width="100%" />
              </Box>
            </Box>
          ) : (
            <Grid
              container
              rowGap={4}
              sx={{
                p: 3,
                borderRadius: '15px',
                backgroundColor: 'white',
                justifyContent: !matchUpXL ? 'center' : 'unset',
              }}
            >
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={2}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#56C596'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name="in-progress-outlined"
                      size="extraLarge"
                      fill="#56C596"
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    In progress
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {inProgressCount?.toString()}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={2}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#FF5572'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name="warning-outlined"
                      size="extraLarge"
                      fill="#FF5572"
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Required
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {requiredCount?.toString()}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={2}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#199BC5'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name="completed-outlined"
                      size="extraLarge"
                      fill="#199BC5"
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Completed
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {completedCount?.toString()}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={2}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#12D1D0'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name="reward-outlined"
                      size="extraLarge"
                      fill="#12D1D0"
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    Certificates
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {certificatesCount?.toString()}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                xs={12}
                sm={4}
                md={4}
                xl={2}
              >
                <Box sx={matchUpXS ? { flex: 1 } : {}}>
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#AA5BB9'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon
                      name="new-outlined"
                      size="extraLarge"
                      fill="#AA5BB9"
                    />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...(matchUpXS && { flex: 1 }),
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textTransform: 'uppercase',
                    }}
                  >
                    New
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '600',
                      fontSize: '40px',
                      lineHeight: '56px',
                    }}
                  >
                    {newCount?.toString()}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      <LearnerFlow />
      <Box sx={{ mt: 2 }}>
        {requiredCoursesLoading ? (
          <CourseLoading />
        ) : (
          <ConditionalComponent showComponent={requiredCourses.length > 0}>
            <Box sx={{ mt: 10 }}>
              <ResponsiveCarrousel
                title="Courses you need to complete"
                courses={requiredCourses}
              />
            </Box>
          </ConditionalComponent>
        )}

        {recommendedCoursesLoading ? (
          <CourseLoading />
        ) : (
          <ConditionalComponent showComponent={recommendedCourses.length > 0}>
            <Box sx={{ mt: 10 }}>
              <ResponsiveCarrousel
                title="Recommended Courses"
                courses={recommendedCourses}
              />
            </Box>
          </ConditionalComponent>
        )}
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              color: '#205072',
              borderBottom: '1px solid #205072',
              cursor: 'pointer',
            }}
            onClick={goToCourseLibrary}
          >
            <DisplayText
              type="sh1"
              text="View all courses"
              variant="semiBold"
            />
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default Dashboard;
