import { Box, Grid, Skeleton, TextField } from '@mui/material';

const LoadingEmployeeSelectionTable = () => {
  const rows = [...Array(4)];

  return (
    <Grid
      p={2}
      rowGap={1}
      container
      sx={{ background: 'white', borderRadius: '0px 0px 30px 30px' }}
    >
      {rows.map((x, i) => (
        <Grid
          key={i}
          p={1}
          rowGap={1}
          container
          sx={{
            background: 'white',
          }}
        >
          <Skeleton variant="rectangular" height="50px" width="100%" />
        </Grid>
      ))}
    </Grid>
  );
};

export default LoadingEmployeeSelectionTable;
