import {
  Alert,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import DropzoneScorm from 'components/dropzone/DropzoneScorm';
import * as Yup from 'yup';

export const scormValidationStep = Yup.object().shape({
  courseFile: Yup.array().required('Please upload an scorm file'),
});

const ScormUpload = ({ isEdit = false }: { isEdit?: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Container
      style={{
        marginBottom: '5px',
      }}
    >
      <Grid container columnSpacing={1} mb={3}>
        <Grid item xs={12} md={12} mb={1}>
          <Alert severity="warning">
            Only SCORM2004 4th edition files are allowed.
          </Alert>
        </Grid>
        <Grid item xs={12} md={12} mb={1}>
          <Typography
            variant="h2"
            sx={{
              marginTop: '15px',
              textAlign: 'center',
            }}
          >
            {isEdit ? 'Update Course File' : 'Course File'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} mb={1}>
          <Controller
            name="courseFile"
            control={control}
            render={({ field }) => (
              <FormControl
                margin="normal"
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <Stack>
                  <DropzoneScorm
                    maxFiles={1}
                    maxSize={5000000}
                    handleAcceptedFiles={(acceptedFiles: File[]) => {
                      field.onChange(acceptedFiles);
                    }}
                    helperText="Drag and drop the course file here, or click to select your file."
                  />
                  <FormHelperText error={Boolean(errors.courseFile)}>
                    <>{errors?.courseFile?.message}</>
                  </FormHelperText>
                </Stack>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScormUpload;
