import Tag from './Tag';
import { ITagColor } from './types';

export interface IOnBoardingStatusTag {
  status:
    | 'Onboarding'
    | 'Data Activation'
    | 'In Review'
    | 'Active'
    | 'Inactive';
}

const getFlagLabel = (status: IOnBoardingStatusTag['status']) => {
  switch (status) {
    case 'Onboarding':
      return 'Onboarding';
    case 'Data Activation':
      return 'Data Activation';
    case 'In Review':
      return 'In Review';
    case 'Active':
      return 'Active';
    default:
      return 'Inactive';
  }
};

const getFlagColor = (status: IOnBoardingStatusTag['status']): ITagColor => {
  switch (status) {
    case 'Onboarding':
      return { labelColor: '#AA5B00', backgroundColor: '#FCF2E6' };
    case 'Data Activation':
      return { labelColor: '#D1293D', backgroundColor: '#FFEDEF' };
    case 'In Review':
      return { labelColor: '#5E5ADB', backgroundColor: '#EDEDFC' };
    case 'Active':
      return { labelColor: '#14804A', backgroundColor: '#E1FCEF' };
    default:
      return { labelColor: '#5A6376', backgroundColor: '#E9EDF5' };
  }
};

const OnBoardingStatusTag = ({ status }: IOnBoardingStatusTag) => {
  const label = getFlagLabel(status);
  const { labelColor, backgroundColor } = getFlagColor(status);

  return (
    <Tag
      label={label}
      labelColor={labelColor}
      backgroundColor={backgroundColor}
    />
  );
};

export default OnBoardingStatusTag;
