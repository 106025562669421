import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Avatar } from '@mui/material';
import { lighten } from 'polished';

import Counter from '../../../../shared/Counter';
import {formatTime} from '../../../../shared/utils';

import StatsLoading from './StatsLoading';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import Icon from 'icons';

const statsItems = [
  {
    icon: 'three-users',
    label: 'learners',
    color: '#12D1D0',
    field: 'totalLearners',
  },
  {
    icon: 'house',
    label: 'Communities',
    color: '#FF5572',
    field: 'totalCommunities',
  },
  {
    icon: 'check',
    label: 'completed courses',
    color: '#199CC5',
    field: 'totalCompletions',
  },
  {
    icon: 'clock',
    label: 'hours of engagement',
    color: '#56C596',
    field: 'hoursOfEngagement',
  },
];

const Stats = () => {
  const theme = useTheme();
  const matchUpMD = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();

  const [stats, setStats] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.reporting.learner.getLearnerReportStatsAsFacilitator(),
      });

      if (response) {
        setStats(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  if(loading) {
    return <StatsLoading />;
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      <Box sx={{ display: 'none' }}>
        <Icon name="house" size="extraLarge" fill="red" />
      </Box>
      {statsItems.map((item) => {
        return (
          <Box
            key={item.label}
            sx={{
              p: 4,
              borderRadius: '15px',
              backgroundColor: 'white',
              flex: '0.25 0 auto',
              ...(matchUpMD && { width: '100%' }),
              height: '291px',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                flexDirection: 'column',
                display: 'flex',
                gap: 4,
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, item.color),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon name={item.icon} size="extraLarge" fill={item.color} />
              </Avatar>
              <Counter
                label={item.label}
                counter={stats ? stats[item.field] : 0}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Stats;
