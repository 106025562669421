import { createContext } from 'react';

export enum AssignmentType {
  CurrentAndFuture = 'CurrentAndFuture',
  Future = 'Future',
  Current = 'Current',
}

export type CoursesAssignation = {
  courseId: string;
  daysToComplete: number;
};

export interface AssignCourseState extends AssignCourseType {
  learners: number;
}

export type AssignCourseType = {
  name: string;
  groupsIds: string[];
  assignmentType?: AssignmentType;
  coursesAssignations: CoursesAssignation[];
};

export const ASSIGN_COURSE_INITIAL_STATE: AssignCourseState = {
  name: '',
  groupsIds: [],
  assignmentType: undefined,
  coursesAssignations: [],
  learners: 0,
};

const AssignCourseContext = createContext<{
  assignCourseState: AssignCourseState;
  setAssignCourseState: any;
}>({
  assignCourseState: ASSIGN_COURSE_INITIAL_STATE,
  setAssignCourseState: () => {},
});

export default AssignCourseContext;
