import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { useCallService } from 'hooks';

import resources from 'features/certificates/resources';

const Delete = ({
  onSuccess,
  hideDialog,
  certificateName,
  certificateId,
}: {
  onSuccess: VoidFunction;
  hideDialog: VoidFunction;
  certificateName: string;
  certificateId: string;
}) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const onSubmit = async () => {
    const { response } = await callService({
      resource: resources.delete(certificateId),
      successMessage: 'Certificate deleted successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <Stack>
      <WarningAmberOutlinedIcon
        sx={{
          fontSize: '160px',
          color: theme.palette.warning.dark,
          margin: '0 auto',
        }}
      />
      <Typography fontSize={24} fontWeight="bold" textAlign="center">
        {certificateName}
      </Typography>
      <Typography variant="body1" align="center">
        Are you sure you want to delete this certificate?
      </Typography>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>

        <Button variant="contained" color="error" onClick={onSubmit}>
          Yes, Delete
        </Button>
      </Grid>
    </Stack>
  );
};

export default Delete;
