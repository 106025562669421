import React, { useRef, useState } from 'react';

import { Avatar, Box, IconButton } from '@mui/material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import Slider from 'react-slick';

import DisplayText from 'components/DisplayText';

import { CourseCarouselProps } from './types';
import CourseCard from './CourseCard';

const MobileCourseCarousel = ({
  title,
  courses,
  titleComponent,
  style,
}: CourseCarouselProps) => {
  const sliderRef = useRef<any>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    const sliderElement = sliderRef.current;
    if (
      sliderElement &&
      sliderElement.innerSlider &&
      sliderElement.innerSlider.state
    ) {
      sliderElement.slickNext();
      const {
        innerSlider: {
          state: { currentSlide },
        },
      } = sliderElement;
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevious = () => {
    const sliderElement = sliderRef.current;
    if (
      sliderElement &&
      sliderElement.innerSlider &&
      sliderElement.innerSlider.state
    ) {
      sliderElement.slickPrev();
      const {
        innerSlider: {
          state: { currentSlide },
        },
      } = sliderElement;
      setCurrentSlide(currentSlide - 1);
    }
  };

  const totalItems = courses.length || 0;
  const showPagination = totalItems > 1;

  const hasNext = currentSlide !== totalItems;
  const hasPrevious = currentSlide !== 0;

  return (
    <Box sx={{ position: 'relative', ...style?.container }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          mb: 1,
          alignItems: 'center',
        }}
      >
        <Box>
          {titleComponent ? (
            titleComponent
          ) : (
            <DisplayText type="sh2" text={title} variant="medium" />
          )}
        </Box>
        {showPagination && (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ cursor: 'pointer' }} onClick={handlePrevious}>
              <IconButton disabled={!hasPrevious}>
                <Avatar
                  sx={{
                    background: hasPrevious
                      ? 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)'
                      : 'white',
                    height: 30,
                    width: 30,
                  }}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={{
                      color: hasPrevious ? 'white' : '#AA5BB9',
                    }}
                    fontSize="medium"
                  />
                </Avatar>
              </IconButton>
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={handleNext}>
              <IconButton disabled={!hasNext}>
                <Avatar
                  sx={{
                    background: hasNext
                      ? 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)'
                      : 'white',
                    height: 30,
                    width: 30,
                  }}
                >
                  <KeyboardArrowRightRoundedIcon
                    fontSize="medium"
                    sx={{
                      color: hasNext ? 'white' : '#AA5BB9',
                    }}
                  />
                </Avatar>
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Slider
        ref={sliderRef}
        slidesToShow={1}
        lazyLoad="ondemand"
        onSwipe={(swipeDirection: string) => {
          if (swipeDirection === 'left') {
            handleNext();
          } else {
            handlePrevious();
          }
        }}
        infinite={false}
        arrows={false}
        dots={false}
      >
        {courses.map((course: any) => (
          <Box
            key={course.id}
            sx={{ display: 'flex !important', justifyContent: 'center' }}
          >
            <CourseCard course={course} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default MobileCourseCarousel;
