import { Box, Grid, useMediaQuery } from '@mui/material';
import { getDescriptionEllipsis } from 'utils/common';
import { useTheme } from '@mui/material/styles';
import { ChartValues } from './types';

const colorsByIndex = ['#205072', '#329D9C', '#56C596', '#71AB8C', '#71AB8C'];

const HorizontalBarCharts = ({
  chartValues,
}: {
  chartValues: ChartValues[];
}) => {
  const maxValue = Math.max(...chartValues.map((community) => community.value));

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{ display: 'flex', height: '382px', gap: 1, alignItems: 'center', flexDirection: 'column' }}
    >
      {chartValues.map((community, index) => {
        const calculatedWidth = Math.abs((community.value * 100) / maxValue);

        return (
          <Grid container key={community.label}>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  color: '#5A5A5A',
                  fontSize: '12px',
                  lineHeight: '14px',
                  textAlign: 'center',
                }}
              >
                {community.label}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    height: '70px',
                    width: `${calculatedWidth}%`,
                    borderRadius: '0px 15px 15px 0px',
                    backgroundColor: colorsByIndex[index],
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '20px',
                  lineHeight: '23px',
                  color: '#205072',
                  textAlign: 'right',
                }}
              >
                {community.value}
              </Box>
            </Grid>
          </Grid>
          // <Box
          //   sx={{
          //     width: '100%',
          //     height: '100%',
          //     gap: 1,
          //     display: 'flex',
          //     // flexDirection: 'column',
          //     alignItems: 'center',
          //     justifyContent: 'end',
          //   }}
          //   key={community.label}
          // >
          //   <Box
          //     sx={{
          //       flex: '1',
          //       display: 'flex',
          //       flexDirection: 'column',
          //       alignItems: 'center',
          //       justifyContent: 'flex-end',
          //       gap: 1,
          //     }}
          //   >
          //     <Box
          //       sx={{
          //         display: 'flex',
          //         justifyContent: 'center',
          //         fontFamily: 'RobotoSlab',
          //         fontStyle: 'normal',
          //         fontWeight: '400',
          //         fontSize: '20px',
          //         lineHeight: '23px',
          //         color: '#205072',
          //         textAlign: 'right',
          //       }}
          //     >
          //       {community.value}
          //     </Box>
          //     <Box
          //       sx={{
          //         height: '112px',
          //         // height: `${calculatedHeight}%`,
          //         borderRadius: '15px 15px 0px 0px',
          //         backgroundColor: colorsByIndex[index],
          //         width: `${calculatedHeight}%`
          //         // width: matchDownSM ? '45px' : '90px',
          //       }}
          //     />
          //   </Box>
          //   <Box
          //     sx={{
          //       display: 'flex',
          //       justifyContent: 'center',
          //       fontFamily: 'RobotoSlab',
          //       fontStyle: 'normal',
          //       fontWeight: '400',
          //       color: '#5A5A5A',
          //       fontSize: '12px',
          //       lineHeight: '14px',
          //       textAlign: 'center',
          //       flex: '0 0 28px',
          //       width: matchDownSM ? '60px' : '120px',
          //     }}
          //   >
          //     {getDescriptionEllipsis(community.label, matchDownSM ? 12 : 30)}
          //   </Box>
          // </Box>
        );
      })}
    </Box>
  );
};

export default HorizontalBarCharts;
