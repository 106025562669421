import * as React from 'react';

import { lighten } from 'polished';

import Box from '@mui/material/Box';

import {
  Avatar,
  Skeleton,
  SvgIconProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ShoppingCartOutlinedIcon,
  HourglassEmptyOutlinedIcon,
  SwapHorizOutlinedIcon,
  LocalAtmOutlinedIcon,
  DiscountIcon,
} from 'material-icons';

import { formatKpiWithSymbol } from '../../utils';

import Difference from './Difference';
import Trend from './Trend';

const transitionStyle = {
  // transition: 'transform 0.5s ease',
  '&:hover': {
    '& > div': {
      transform: 'translateY(-10px)',
    },
    '& > #navigation': {
      transform: 'translateY(-40px)',
    },
  },
};

type KpiCardProps = {
  kpiName: string;
  kpiId: string;
  percentageChange: number | null;
  intervalStat: number;
  stat: number;
  statType: 'percentage' | 'value';
  rank: number;
  total: number;
  isLoading: boolean;
  intervalLoading: boolean;
  interval: string;
  navigate?: (() => void) | null;
};

type CustomIconComponent = (props: SvgIconProps) => JSX.Element;

interface IconInfo {
  icon: React.ElementType<SvgIconProps> | CustomIconComponent;
  color: string;
}

const iconMap: Record<string, IconInfo> = {
  'Ticket Size': {
    icon: ShoppingCartOutlinedIcon,
    color: '#3498DB', // Example color
  },
  'Transactions Per Hour': {
    icon: HourglassEmptyOutlinedIcon,
    color: '#BB6BD9', // Example color
  },
  'Units Per Transaction': {
    icon: SwapHorizOutlinedIcon,
    color: '#F39C12', // Example color
  },
  'Average Unit Price': {
    icon: LocalAtmOutlinedIcon,
    color: '#9dc067', // Example color
  },
  'Percent Discount': {
    icon: DiscountIcon,
    color: '#e04973fc',
  },
};

const KpiCard = ({
  kpiName,
  kpiId,
  percentageChange,
  stat,
  intervalStat,
  rank,
  total,
  isLoading,
  intervalLoading,
  navigate = null,
  interval,
}: KpiCardProps) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const Icon = iconMap[kpiName] ? iconMap[kpiName].icon : ShoppingCartOutlinedIcon;

  const iconColor = iconMap[kpiName]?.color || '#3498DB';

  const noData = rank === 0;

  const difference =
    intervalStat === null || noData ? null : stat - intervalStat;

  return (
    <Box
      sx={{
        p: isLoading || matchDownSM ? 2 : 4,
        borderRadius: '15px',
        flexDirection: 'column',
        backgroundColor: 'white',
        minWidth: '343px',
        height: '200px',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        ...(navigate && transitionStyle),
      }}
    >
      {isLoading ? (
        <Skeleton variant="rounded" height="100%" />
      ) : (
        <>
          <Box sx={navigate ? { transition: 'transform 0.5s ease' } : {}}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 0.5,
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '14px',
                    textTransform: 'uppercase',
                    color: '#205072',
                  }}
                >
                  {kpiName}
                </Box>
                <Box>
                  {intervalLoading ? (
                    <Skeleton variant="rounded" height="15px" width="81px" />
                  ) : (
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '130%',
                        color: '#445D74',
                        visibility: !rank ? 'hidden' : 'visible',
                      }}
                    >
                      Rank # {rank} of {total}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Box>
                  <Avatar
                    sx={{ bgcolor: lighten(0.3, iconColor) }}
                    variant="rounded"
                  >
                    <Icon sx={{ color: iconColor }} />
                  </Avatar>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '600',
                    fontSize: '34px',
                    textTransform: 'uppercase',
                    color: '#205072',
                  }}
                >
                  {formatKpiWithSymbol(kpiName, stat)}
                </Box>
                {intervalLoading ? (
                  <Skeleton variant="rounded" height="20px" width="61px" />
                ) : (
                  <Difference value={difference} />
                )}
              </Box>
              <Box mt={2}>
                {intervalLoading ? (
                  <Skeleton variant="rounded" height="20px" width="100%" />
                ) : (
                  <Trend
                    intervalStat={intervalStat}
                    percentageChange={percentageChange}
                    interval={interval}
                    noData={noData}
                    kpiId={kpiId}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {navigate && (
            <Box
              id="navigation"
              sx={{
                transition: 'transform 0.5s ease',
                bottom: '-20px',
                right: '15px',
                position: 'absolute',
                cursor: 'pointer',
                color: '#1976d2',
              }}
              onClick={navigate}
            >
              View details →
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default KpiCard;
