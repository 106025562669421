import React from 'react';
import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface StackProps extends MuiStackProps {
  dataId?: string;
  sx?: SxProps<Theme>;
}

function Stack({ dataId, sx, ...props }: StackProps) {
  return <MuiStack data-id={dataId} sx={sx} {...props}></MuiStack>;
}

export default Stack;
