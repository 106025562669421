import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import EmployeeSelectionRow from './EmployeeSelectionRow';
import LoadingEmployeeSelectionTable from './LoadingEmployeeSelectionTable';

const EmployeeSelectionTable = ({
  onEmployeeSelection,
  initializeSelectedEmployees,
  selectedEmployees,
  needsUpdate,
}: {
  onEmployeeSelection: (employee: any) => void;
  initializeSelectedEmployees: (employee: any) => void;
  selectedEmployees: any;
  needsUpdate: boolean;
}) => {
  const params = useParams();
  const { organizationId, groupId } = params;

  const { callService } = useCallService();

  const [employeesLoading, setEmployeesLoading] = useState(false);
  const [employees, setEmployees] = useState<any>([]);

  const [options, setOptions] = useState<any>([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  const getEmployees = async () => {
    if (!organizationId || !groupId) return;
    setEmployeesLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.ddl.getEmployeesByOrganizationAndGroup({
          organizationId,
          groupId,
        }),
      });

      if (response) {
        setEmployees(response);
        initializeSelectedEmployees(response);
      }
    } finally {
      setEmployeesLoading(false);
    }
  };

  const getOptions = async () => {
    if (!organizationId || !groupId) return;
    try {
      const { response } = await callService({
        resource: httpRoutes.ddl.getMatchEmployees({
          groupId,
        }),
      });

      if (response) {
        const formattedOptions = response.map((option: any) => ({
          hcBudtenderMapId: option.id,
          loginId: `${option.loginId} (${option.posId})`,
        }));
        setOptions(formattedOptions);
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, [groupId]);

  useEffect(() => {
    getEmployees();
  }, [organizationId, groupId, needsUpdate]);

  if (employeesLoading || optionsLoading)
    return <LoadingEmployeeSelectionTable />;

  return (
    <Grid
      p={2}
      rowGap={1}
      container
      sx={{ background: 'white', borderRadius: '0px 0px 30px 30px' }}
    >
      {employees.map((employee: any) => (
        <EmployeeSelectionRow
          key={employee.id}
          onEmployeeSelection={onEmployeeSelection}
          selectedEmployees={selectedEmployees}
          employee={employee}
          options={options}
        />
      ))}
    </Grid>
  );
};

export default EmployeeSelectionTable;
