import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { InputAdornment, TextField } from '@mui/material';

import Envelope from '@mui/icons-material/MailOutline';

const EndAdornment = () => (
  <InputAdornment position="end">
    <Envelope />
  </InputAdornment>
);

interface EmailFormFieldProps {
  name: string;
  label: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
}

const EmailFormField = ({
  name,
  label,
  control,
  errors,
  margin = 'normal',
  required,
}: EmailFormFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        type="email"
        variant="outlined"
        label={label}
        fullWidth
        margin={margin}
        required={required}
        error={Boolean(errors)}
        helperText={errors ? <span color="error">{errors.message}</span> : ' '}
        InputProps={{
          endAdornment: <EndAdornment />,
        }}
      />
    )}
  />
);

export default EmailFormField;
