import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import DataGrid from 'components/dataGrid/components/DataGrid';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import ThresholdTag from './ThresholdTag';
import {
  formatKpiWithSymbol,
  kpiLabel,
} from '../../utils';

let employeeRequestController: AbortController | undefined;

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Search by employee',
  },
];

const EmployeeTableViewDataGrid = ({ interval }: { interval: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const { groupId, kpiName = '' } = params;

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [groupDailySalesStats, setGroupDailySalesStats] = useState<any>({
    threshold: 0,
    normalizedMean: 0,
  });

  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigateTo = (id: string) => {
    navigate(`/ddl/performance-report/group/${groupId}/user/${id}${location.search} `);
  };

  const columns = (
    normalizedMean: number,
    threshold: number,
    kpiName: string,
    navigateTo: any
  ): DataGridColumnProps[] => [
    {
      headerName: 'Employee',
      field: 'name',
      sortable: false,
      ColumnComponent: (params: any) => {
        const { userId, firstName, lastName } = params.row;

        const name = `${firstName} ${lastName}`;
        return (
          <Box
            onClick={() => navigateTo(userId)}
            sx={{
              fontFamily: 'Raleway',
              fontSize: '16px',
              color: '#508AC2',
              cursor: 'pointer',
            }}
          >
            {name}
          </Box>
        );
      },
    },
    {
      headerName: `Latest ${kpiLabel(kpiName)}`,
      field: 'stat',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { stat } = params.row;

        return (
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              lineHeight: '150%',
            }}
          >
            {formatKpiWithSymbol(kpiName, stat)}
          </Box>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      ColumnComponent: (params: any) => {
        const { intervalStat } = params.row;

        if (!intervalStat) return '-';

        return (
          <ThresholdTag
            normalizedMean={normalizedMean}
            threshold={threshold}
            ticketSize={intervalStat}
          />
        );
      },
    },
    // {
    //   headerName: `${kpiLabel(kpiName)} ${
    //     interval != 'latest' ? ` from ${intervalDisplayName[interval]}` : ''
    //   }`,
    //   field: 'intervalStat',
    //   sortable: true,
    //   ColumnComponent: (params: any) => {
    //     const { intervalStat } = params.row;
    //     return (
    //       <Box
    //         sx={{
    //           fontFamily: 'Roboto',
    //           fontSize: '16px',
    //           lineHeight: '150%',
    //         }}
    //       >
    //         {!intervalStat ? '-' : formatKpiWithSymbol(kpiName, intervalStat)}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   headerName: 'Change',
    //   field: 'percentageChange',
    //   sortable: true,
    //   ColumnComponent: (params: any) => {
    //     const { percentageChange } = params.row;
    //     return (
    //       <Box
    //         sx={{
    //           fontFamily: 'Roboto',
    //           fontSize: '16px',
    //           lineHeight: '150%',
    //         }}
    //       >
    //         {!percentageChange
    //           ? '-'
    //           : renderBoxColumn(percentageChange, formatPercentageSymbol)}
    //       </Box>
    //     );
    //   },
    // },
  ];

  const getEmployees = async () => {
    if (groupId && kpiName) {
      setLoading(true);
      if (employeeRequestController) {
        employeeRequestController.abort();
      }
      try {
        employeeRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.ddl.performanceReport.getEmployeePaginated({
            groupId,
            kpiName,
            interval,
            controller: employeeRequestController,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          setGroupDailySalesStats({
            threshold: parseFloat(response.threshold),
            normalizedMean: parseFloat(response.normalizedMean),
          });
          employeeRequestController = undefined;
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getEmployees();
  }, [filters, state, groupId, interval]);

  return (
    <DataGrid
      tableTitle="All Employees"
      rows={gridData.rows}
      columns={columns(
        groupDailySalesStats.normalizedMean,
        groupDailySalesStats.threshold,
        kpiName,
        navigateTo
      )}
      filters={filterModel}
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading}
    />
  );
};

const EmployeeTableView = ({ interval }: { interval: string }) => (
  <ControlledDataGrid>
    <EmployeeTableViewDataGrid interval={interval} />
  </ControlledDataGrid>
);

export default EmployeeTableView;
