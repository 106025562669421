import { createContext } from 'react';

export type BusinessUnitType = {
  businessUnit: string;
};

export const BUSINESS_UNIT_INITIAL_STATE: BusinessUnitType = {
  businessUnit: '',
};

const BusinessUnitContext = createContext<{
  businessUnitState: BusinessUnitType;
}>({
  businessUnitState: BUSINESS_UNIT_INITIAL_STATE,
});

export default BusinessUnitContext;
