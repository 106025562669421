import { useEffect, useState } from 'react';

import { CheckCircleOutline } from '@mui/icons-material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { DenyIcon } from 'material-icons';

import { useCallService } from 'hooks';

import { getDateDifference } from 'utils/date';
import httpRoutes from 'utils/httpRoutes';

import { getStatesList } from 'dataSets/states';

const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Member Name',
  },
  {
    id: 'groupName',
    type: 'text',
    label: 'Community Name',
  },
  {
    id: 'state',
    type: 'select',
    label: 'State',
    options: [{ label: 'All States', value: '' }, ...getStatesList()],
  },
];

let pendingUsersRequestController: AbortController | undefined;

const AdminMembershipReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getPendingUsers = async () => {
    setLoading(true);
    if (pendingUsersRequestController) {
      pendingUsersRequestController.abort();
    }
    try {
      pendingUsersRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.groups.getPendingMembers({
          controller: pendingUsersRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        pendingUsersRequestController = undefined;
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const approveUser = async (groupId: string, userId: string) => {
    const { response } = await callService({
      resource: httpRoutes.groups.approveMemberRequest(groupId, userId),
      successMessage: 'Member added to community successfully!',
    });

    if (response) {
      getPendingUsers();
    }
  };

  const denyUser = async (groupId: string, userId: string) => {
    const { response } = await callService({
      resource: httpRoutes.groups.denyMemberRequest(groupId, userId),
      successMessage: 'Member denied access to community!',
    });

    if (response) {
      getPendingUsers();
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.user.firstName} {params.row.user.lastName}
          </>
        );
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.3,
      ColumnComponent: (params: any) => {
        return <>{params.row.user.email}</>;
      },
    },
    {
      headerName: 'Community',
      field: 'groupName',
      sortable: true,
      filterable: false,
      flex: 0.3,
      ColumnComponent: (params: any) => {
        return <>{params.row.group.name}</>;
      },
    },
    {
      headerName: 'Days Since Request',
      field: 'created',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      flex: 0.3,
      type: 'number',
      ColumnComponent: (params: any) => {
        const _daysSince = getDateDifference(new Date(params.row.created));

        return <>{_daysSince}</>;
      },
    },
    {
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.25,
      ColumnComponent: (params: any) => {
        const _groupId = params.row.group.id;
        const _userId = params.row.user.id;

        return (
          <>
            <IconButton onClick={() => approveUser(_groupId, _userId)}>
              <CheckCircleOutline color="success" />
            </IconButton>
            <IconButton onClick={() => denyUser(_groupId, _userId)}>
              <DenyIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getPendingUsers();
  }, [filters, state]);

  return (
    <PageContainer title="Membership Report">
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const AdminMembershipReport = () => (
  <ControlledDataGrid>
    <AdminMembershipReportGrid />
  </ControlledDataGrid>
);

export default AdminMembershipReport;
