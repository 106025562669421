import { styled } from '@mui/system';
import { lighten } from 'polished';
import { Controller } from 'react-hook-form';

const UnCheckedIcon = () => (
  <svg width={61} height={36} fill="none">
    <rect x={3.254} y={3.109} width={30} height={30} rx={8} fill="#B5B5B5" />
    <path
      d="M24.079 12.285l-11.65 11.65M24.078 23.934L12.43 12.285"
      stroke="#999"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

const CheckedIcon = () => (
  <svg width={61} height={36} fill="none">
    <rect x={28.516} y={3.109} width={30} height={30} rx={8} fill="#56C596" />
    <path
      d="M51.95 13.277l-9.665 9.665M42.285 22.942l-4.832-4.833"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

const SwitchLabel = styled('label')({
  position: 'relative',
  display: 'inline-block',
  width: '60px',
  height: '34px',
  outline: 'none',
});

const Input = styled('input')({
  position: 'absolute',
  top: '-99999px',
  left: '-99999px',
});

const Slider = styled('div')(({ isChecked }: { isChecked: boolean }) => ({
  position: 'absolute',
  cursor: 'pointer',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  width: '60px',
  height: '35px',
  borderRadius: '10px',
  backgroundColor: isChecked ? lighten(0.24, '#56C596') : '#ccc',
  WebkitTransition: '.4s',
  transition: '.4s',
  '&:before': {
    position: 'absolute',
    content: '""',
    left: '4px',
    bottom: '4px',
    WebkitTransition: '.4s',
    transition: '.4s',
    borderRadius: '50%',
  },
}));

interface ToggleFormFieldProps {
  name: string;
  control: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleFormField = ({ name, control, onChange }: ToggleFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, value, ...rest } = field;

        return (
          <SwitchLabel>
            <Input
              {...rest}
              type="checkbox"
              onChange={(e: any) => {
                if (onChange) {
                  onChange(e);
                }
                fieldOnChange(e.target.checked);
              }}
            />
            <Slider isChecked={value}>
              {value ? <CheckedIcon /> : <UnCheckedIcon />}
            </Slider>
          </SwitchLabel>
        );
      }}
    />
  );
};

export default ToggleFormField;
