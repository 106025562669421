import { ChunkTypes } from './chunkSizeStrategy/enum/ChunkTypes';
import { IChunkSizesStrategy } from './chunkSizeStrategy/interfaces';
import { IPromiseManager } from './interfaces';
import { ExecutionError, ExecutionResult, PromiseAdapter } from './models';

export class PromiseManager implements IPromiseManager {
  private readonly _chunkSizesStrategy: IChunkSizesStrategy;

  constructor(chunkSizesStrategy: IChunkSizesStrategy) {
    this._chunkSizesStrategy = chunkSizesStrategy;
  }

  public async executePromises<T = any>(
    promises: any[],
    chunks = 100,
    chunkType = ChunkTypes.Percentage
  ): Promise<ExecutionResult<T>> {
    const executionResult: ExecutionResult<T> = new ExecutionResult();

    if (
      (chunks === 1 && chunkType === ChunkTypes.Amount) ||
      (chunks === 100 && chunkType === ChunkTypes.Percentage)
    ) {
      await this.executeAllSettled(promises, executionResult);
    } else {
      const chunkSize = this._chunkSizesStrategy.calculateChunkSize(
        chunkType,
        promises,
        chunks
      );
      await this.processPromises(promises, chunkSize, executionResult);
    }

    return executionResult;
  }

  public getPromisesResult = async <T>(
    promises: any[],
    chunks = 1,
    chunkType = ChunkTypes.Percentage
  ): Promise<ExecutionResult<T>> => {
    const executionResults = await this.executePromises<T>(
      promises,
      chunks,
      chunkType
    );
    return executionResults;
  };

  private async executeAllSettled<T = any>(
    promises: any[],
    executionResult: ExecutionResult<T>
  ) {
    const mappedPromises = promises.map((promise: any) =>
      promise instanceof PromiseAdapter
        ? promise.execute.apply(promise.context, promise.params)
        : promise
    );
    const results = await Promise.allSettled(mappedPromises);
    this.mapPromiseResults(promises, results, executionResult);
  }

  private async processPromises<T = any>(
    promises: any[],
    chunkSize: number,
    executionResult: ExecutionResult<T>
  ) {
    const promisesToExecute: any[] = promises.slice(0, chunkSize);
    await this.executeAllSettled(promisesToExecute, executionResult);
    if (chunkSize < promises.length) {
      promises.splice(0, chunkSize);
    } else {
      promises.splice(0, promises.length);
    }
    if (promises.length > 0) {
      await this.processPromises(promises, chunkSize, executionResult);
    }
  }

  private mapPromiseResults<T = any>(
    promises: any[],
    results: any[],
    executionResult: ExecutionResult<T>
  ): void {
    for (let index = 0; index < promises.length; index++) {
      const result = results[index];
      if (result.status === 'rejected') {
        const promise = promises[index];
        const value =
          promise instanceof PromiseAdapter ? promise.params : promise;
        executionResult.errors.push(new ExecutionError(value, result.reason));
      } else {
        executionResult.results.push(
          (result as PromiseFulfilledResult<T>).value
        );
      }
    }
  }
}
