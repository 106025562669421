import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import useQuery from 'hooks/useQuery';

import EmployeeKpiCard from '../components/employees/EmployeeKpiCard';
import SelectIntervalFilter from '../components/SelectIntervalFilter';
import { kpis } from '../utils';

const EmployeeView = () => {
  const query = useQuery();

  const params = useParams();
  const { userId, groupId } = params;

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();

  const [employee, setEmployee] = useState<any>(null);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  // const [interval, setInterval] = useState<string>(
  //   query.get('interval') || 'latest'
  // );
  const [interval, setInterval] = useState<string>('latest');

  const getEmployee = async () => {
    if (userId && groupId) {
      try {
        const { response } = await callService({
          resource: httpRoutes.ddl.performanceReport.getSingleEmployeeStats(
            userId,
            groupId
          ),
        });

        if (response) {
          setEmployee(response);
        }
      } finally {
        setEmployeeLoading(false);
      }
    }
  };

  useEffect(() => {
    getEmployee();
  }, [userId, groupId]);

  const responsiveStyle: SxProps<Theme> = matchDownSM
    ? { flexDirection: 'column' }
    : { flexWrap: 'wrap', rowGap: 2 };

  return (
    <PageContainer>
      <Box
        sx={{
          my: 2,
          justifyContent: 'space-between',
          display: 'flex',
          ...responsiveStyle,
        }}
      >
        <Box>
          <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
            {employeeLoading ? (
              <Skeleton height="40px" width="245px" variant="rounded" />
            ) : (
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: matchDownSM ? '25px' : '32px',
                  lineHeight: '130%',
                  color: '#162738',
                }}
              >
                {employee?.fullName}
              </Box>
            )}
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '150%',
                color: '#162738',
              }}
            >
              Employee Key Performance Indicators
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            background: 'white',
            borderRadius: '15px',
            p: matchDownSM ? 2 : 4,
            ...(!employeeLoading && {
              gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))',
            }),
            gridGap: '10px',
            ...(matchDownMD && {
              width: '100%',
            }),
          }}
        >
          {employeeLoading ? (
            <Skeleton height="40px" width="245px" variant="rounded" />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '150%',
                    color: '#162738',
                    justifyContent: 'center',
                  }}
                >
                  Courses Taken
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '150%',
                    color: '#162738',
                  }}
                >
                  {employee?.totalCompletions || 0}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '150%',
                    color: '#162738',
                    justifyContent: 'center',
                  }}
                >
                  Engagement hours
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '150%',
                    color: '#162738',
                  }}
                >
                  {employee?.hoursOfEngagement || 0}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <SelectIntervalFilter value={interval} setValue={setInterval} />
      <Box
        sx={{
          display: 'grid',
          ...(!matchDownSM && {
            gridTemplateColumns: 'repeat(auto-fit, minmax(343px, 1fr))',
          }),
          gridGap: '10px',
        }}
      >
        {kpis.map((kpi, index) => (
          <EmployeeKpiCard
            key={index}
            interval={interval}
            kpiName={kpi.kpiName}
            kpiId={kpi.kpiId}
          />
        ))}
      </Box>
    </PageContainer>
  );
};

export default EmployeeView;
