import React, { useEffect, useState } from 'react';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import TopCoursesList from '../../../../shared/topCourses/TopCourses';

const TopCourses = () => {
  const { callService } = useCallService();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCourses = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.learner.getTopCoursesAsFacilitator(),
      });

      if (response) {
        setCourses(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return <TopCoursesList courses={courses} loading={loading} />;
};

export default TopCourses;
