/* eslint-disable react/jsx-key */
import { useState } from 'react';

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Controller, FieldError } from 'react-hook-form';

import { SelectOptionProps } from './types';

type AutoCompleteFormFieldProps = {
  name: string;
  label: string;
  defaultValue?: any;
  errors?: any;
  control: any;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  options: SelectOptionProps[];
};

const AutoCompleteFormField = ({
  name,
  defaultValue,
  errors,
  label,
  control,
  placeholder,
  disabled,
  options,
  loading,
}: AutoCompleteFormFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          id="search"
          freeSolo
          fullWidth
          selectOnFocus
          defaultValue={defaultValue}
          disabled={disabled}
          loading={loading}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_event, value ) => {
            const newValue = value ? value.value : undefined;
            onChange(newValue);
          }}
          options={options}
          getOptionLabel={(option) => option.label || option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={Boolean(errors)}
              helperText={
                errors ? <span color="error">{errors.message}</span> : ' '
              }
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutoCompleteFormField;
