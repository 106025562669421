/* eslint-disable */
import { memo, useMemo, useState, ReactNode, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import BusinessUnitContext, {
  BUSINESS_UNIT_INITIAL_STATE,
} from './contexts/BusinessUnitContext';
import { getBusinessUnit } from '../../utils/businessUnit';

const BusinessUnitProvider = ({ children }: { children: ReactNode }) => {
  const [businessUnitState, setBusinessUnitState] = useState(
    BUSINESS_UNIT_INITIAL_STATE
  );
  const [searchParams] = useSearchParams();

  const loadBusinessUnitFromCookies = async () => {
    const businessUnit = getBusinessUnit();

    const setLocalStorage = (bu: string) => {
      localStorage.setItem('business-unit', bu);
      setBusinessUnitState({
        businessUnit: bu,
      });
    };

    const bu = searchParams.get('bu');
    if (businessUnit === '') {
      if (bu) {
        setLocalStorage(bu);
      } else {
        setLocalStorage('cannabis');
      }
    } else {
      if (bu) {
        setLocalStorage(bu);
      } else {
        setLocalStorage(businessUnit);
      }
    }
  };

  useEffect(() => {
    loadBusinessUnitFromCookies();
  }, []);

  const value = useMemo(
    () => ({
      businessUnitState,
    }),
    [businessUnitState]
  );

  return (
    <BusinessUnitContext.Provider value={value}>
      {children}
    </BusinessUnitContext.Provider>
  );
};

export default memo(BusinessUnitProvider);
