import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import { formatCourseFormValuesOnCreation } from '../utils';

import MultiStepForm from '../forms/MultiStepForm';
import { useState } from 'react';
import { CourseFormInput } from 'types/courseTypes/CourseFormInput';

const Create = ({
  onSuccess,
}: {
  onSuccess: (scormAlert?: boolean) => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { callService } = useCallService();

  const onSubmit = async (values: CourseFormInput) => {
    try {
      setIsSubmitting(true);
      const formattedValues = formatCourseFormValuesOnCreation(values);

      const { response } = await callService({
        resource: httpRoutes.courseManagement.createAdmin(formattedValues),
        successMessage: 'Course created successfully!',
      });

      if (response) {
        onSuccess(Boolean(values.courseFileChanged));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <MultiStepForm
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        defaultValues={{
          startActivationDate: null,
          emailBlastDate: null,
          socialMediaBlastDate: null,
          dateFirstPublished: new Date(),
          revisionDate: undefined,
          isRevision: false,
          rewardAssigned: false,
          hasCommunicationSupport: 'no',
          expiresOn: undefined,
        }}
      />
    </div>
  );
};

export default Create;
