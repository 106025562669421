import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

import { WarningAmberOutlinedIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

const DeleteAssignment = ({
  assignmentId,
  onSuccess,
  name,
}: {
  assignmentId: string;
  onSuccess: VoidFunction;
  name: string;
}) => {
  const theme = useTheme();

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const onSubmit = async () => {
    const { response } = await callService({
      resource: httpRoutes.assignCourse.remove(assignmentId),
      successMessage: `${name} removed successfully!`,
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return (
    <Stack>
      <WarningAmberOutlinedIcon
        sx={{
          fontSize: '160px',
          color: theme.palette.warning.dark,
          margin: '0 auto',
        }}
      />
      <Typography fontSize={24} fontWeight="bold" textAlign="center">
        {name}
      </Typography>
      <Typography variant="body1" align="center">
        Are you sure you want to remove {name}?
      </Typography>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>

        <Button variant="contained" color="error" onClick={onSubmit}>
          Yes, Remove
        </Button>
      </Grid>
    </Stack>
  );
};

export default DeleteAssignment;
