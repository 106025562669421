import React, { useState, useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';

import { Box, Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { SaveIcon } from 'material-icons';
import { LoadingButton } from '@mui/lab';

import CourseInfo, { firstValidationStep } from './steps/courseInfo/CourseInfo';
import Stepper from '../../../components/Stepper';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useMultistepForm } from 'hooks/useMultistepForm';
import fileManagement from 'utils/s3';

import CourseSource, {
  secondValidationStep,
} from './steps/courseSource/CourseSource';
import Coassemble, { coassembleValidationStep } from './steps/coassemble';
import ScormUpload, { scormValidationStep } from './steps/scorm';
import { StepType } from 'types/StepType';
import { CourseBuilderFormInput } from 'types/courseTypes/CourseBuilderFormInput';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

const validations: any = [
  firstValidationStep,
  secondValidationStep,
  scormValidationStep,
];

const MultiStepForm = ({
  defaultValues,
  onSubmit,
  isEdit = false,
  isSubmitting = false,
}: {
  defaultValues?: any;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  isSubmitting?: boolean;
}) => {
  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();

  const [validationSchema, setValidationSchema] = useState<any>(validations[0]);
  const [files, setFiles] = useState<File[]>([]);
  const [steps, setSteps] = useState<any>([]);
  const {
    authState: {
      organization: { id: organizationId },
    },
  } = useAuthState();

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const fileUrl = `${decodeURIComponent(defaultValues?.imageUrl || '')}`;

  const preSubmitUpload = async (values: CourseBuilderFormInput) => {
    // upload image to S3
    if (files.length > 0) {
      enqueueSnackbar('Uploading Course Image...', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const fileUrl = 'course-images/' + encodeURIComponent(files[0].name);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: files[0],
        key: fileUrl,
      });

      if (response) {
        values.imageFileChanged = true;
        values.imageUrl = fileUrl;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    if (values.courseFile) {
      enqueueSnackbar('Uploading SCORM file. Please stay on page.', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const { name } = values.courseFile[0];

      const fileUrl = 'courses/scorm/' + encodeURIComponent(name);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: values.courseFile[0],
        key: fileUrl,
        contentType: 'application/zip',
      });

      if (response) {
        enqueueSnackbar('File upload successfully!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        values.courseFileChanged = true;
        values.url = fileUrl;

        delete values.courseFile;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    onSubmit(values);
  };

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues?.id || undefined,
      name: defaultValues?.name || '',
      description: defaultValues?.description || '',
      imageUrl: defaultValues?.imageUrl || '',
      url: defaultValues?.url || '',
      courseCategoryId: defaultValues?.courseCategoryId || undefined,
      prerequisiteId: defaultValues?.prerequisiteId || undefined,
      estimatedHours: defaultValues?.estimatedHours || 0,
      externalCourseId: defaultValues?.externalCourseId || undefined,
      organizationId: defaultValues?.organizationId || organizationId,
      imageFileChanged: false,
      courseFileChanged: false,
      states: defaultValues?.states,
      archived: defaultValues?.archived || false,
      source:
        defaultValues?.source || isEdit
          ? !defaultValues?.externalCourseId
            ? 'scorm'
            : 'ai'
          : 'ai',
      editSource: false,
      isEdit,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, watch } = methods;

  const watchSource = watch('source', 'ai');
  const watchExternalCourseId = watch('externalCourseId');

  useEffect(() => {
    setStepValues();
  }, [watchSource]);

  const setStepValues = () => {
    const optionSteps = [
      {
        component: (
          <CourseInfo imageUrl={fileUrl} setFiles={setFiles} isEdit={isEdit} />
        ),
        title: 'Course Info',
      },
    ];

    if (!isEdit) {
      optionSteps.push({
        component: <CourseSource />,
        title: 'Source',
      });
    }

    optionSteps.push(
      watchSource === 'scorm'
        ? {
            component: <ScormUpload isEdit={isEdit} />,
            title: 'Course Content',
          }
        : {
            component: (
              <Coassemble
                isEdit={isEdit}
                flow={watchSource}
                courseId={defaultValues?.externalCourseId}
              />
            ),
            title: 'Course Content',
          }
    );

    validations.pop();
    validations.push(
      watchSource === 'scorm' ? scormValidationStep : coassembleValidationStep
    );

    setSteps(optionSteps);
  };

  const stepsComponents = steps.map((step: StepType) => step.component);

  const { step, isFirstStep, isLastStep, back, next, currentStepIndex } =
    useMultistepForm(stepsComponents);

  const handleMultiStepSubmit = async (values: any) => {
    if (!isLastStep) {
      next();
    } else {
      await preSubmitUpload(values);
    }
  };

  useEffect(() => {
    setValidationSchema(validations[currentStepIndex]);
  }, [currentStepIndex]);

  const stepsTitles = steps.map((step: StepType) => step.title);

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleMultiStepSubmit)}>
          <Stepper steps={stepsTitles} currentStepIndex={currentStepIndex} />
          {step}
          <Grid container justifyContent="space-between" columnSpacing={1}>
            <Button
              variant="text"
              disabled={isSubmitting}
              onClick={hideDialog}
              style={{
                marginLeft: isLastStep
                  ? undefined
                  : matchDownLG
                  ? '10vw'
                  : '1vw',
              }}
            >
              Cancel
            </Button>
            <Box>
              {!isFirstStep && !isLastStep && (
                <Button variant="text" disabled={isSubmitting} onClick={back}>
                  Back
                </Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={
                  isSubmitting || (isLastStep && !watchExternalCourseId)
                }
                loading={isSubmitting}
                loadingPosition="start"
                style={{
                  marginRight: matchDownLG
                    ? '10vw'
                    : !isLastStep
                    ? '2vw'
                    : undefined,
                }}
                {...(isLastStep ? { startIcon: <SaveIcon /> } : {})}
              >
                {isLastStep ? 'Finish' : 'Next'}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default MultiStepForm;
