import { Box, Container, FormControl, FormHelperText } from '@mui/material';
import CreateCourse from './CreateCourse';
import EditCourse from './EditCourse';

import * as Yup from 'yup';
import { Controller, useFormContext } from 'react-hook-form';

export const coassembleValidationStep = Yup.object().shape({
  externalCourseId: Yup.number().required(
    'The course should be created before to save.'
  ),
});

const Coassemble = ({
  isEdit,
  flow,
  courseId,
}: {
  isEdit: boolean;
  flow?: string;
  courseId?: string;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name="externalCourseId"
      control={control}
      render={({ field }) => (
        <FormControl
          margin="normal"
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Container
            style={{
              minWidth: '90vw',
              height: '85vh',
              marginBottom: '5px',
              border: 'solid',
            }}
          >
            <Box width="100%" height="100%">
              {isEdit ? (
                <EditCourse
                  handleCourseEvent={(event: any) => {
                    field.onChange(event.data.id);
                  }}
                  courseId={courseId}
                />
              ) : (
                <CreateCourse
                  handleCourseEvent={(event: any) => {
                    field.onChange(event.data.id);
                  }}
                  flow={flow}
                />
              )}
              <FormHelperText error={Boolean(errors.externalCourseId)}>
                <>{errors?.externalCourseId?.message}</>
              </FormHelperText>
            </Box>
          </Container>
        </FormControl>
      )}
    />
  );
};

export default Coassemble;
