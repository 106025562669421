import states from './stateList.json';

export const getStatesList = (key = 'label') =>
  states.map((s) => ({ label: s[key], name: s.name, value: s.value }));

export const getStateNameByAbbreviation = (abbreviation) => {
  const state = states.find((s) => s.abbreviation === abbreviation);

  if (state) {
    return state.name;
  }
  return '';
};
