import { Grid, Box } from '@mui/material';
import AuthWrapper from '../../authentication/components/AuthWrapper';
import RightDescriptionContent from '../../authentication/fragments/rightDescriptionContent';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import Button from 'components/buttons/Button';

const CommunityInvitationConfirmContent = ({
  communityName,
  isPrivate = false,
}: {
  communityName: string;
  isPrivate: boolean;
}) => {
  const navigate = useNavigate();
  const { communityId = '' } = useParams();

  const [userGroup, setUserGroup] = useState<any>(null);

  const {
    authState: { user },
  } = useAuthState();

  const { callService } = useCallService();

  const navigateToCourses = () => {
    navigate('/courses');
  };

  const confirmJoin = async () => {
    const { response } = await callService({
      resource: httpRoutes.groups.addMember({
        userId: user.id,
        groupId: communityId,
        facilitator: false,
        notify: true,
        pending: isPrivate,
      }),
      successMessage: `Success! You're now a member of ${communityName}!`,
    });

    if (response) {
      navigate('/');
    }
  };

  useEffect(() => {
    const getUserGroup = async () => {
      const { response } = await callService({
        resource: httpRoutes.groups.getUserGroupByUserIdAndGroupId({
          userId: user.id,
          groupId: communityId,
        }),
      });

      if (response) {
        setUserGroup(response);
      }
    };

    if (user.id) {
      getUserGroup();
    }
  }, [user]);

  const isAlreadyInGroup = !!userGroup;

  return (
    <Grid container>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          {isAlreadyInGroup
            ? `Since you're already in this community, just click the\n
                                      button below to enjoy the courses from it.`
            : `Since you're already logged in, just click the\n
                                      button below to join the community.`}
        </Box>
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="button"
          onClick={isAlreadyInGroup ? navigateToCourses : confirmJoin}
          label={isAlreadyInGroup ? 'Go to courses' : `Join ${communityName}`}
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
          }}
        />
      </Grid>
    </Grid>
  );
};

const CommunityInvitationConfirm = () => {
  const { communityId = '' } = useParams();

  const { callService } = useCallService();

  const [groupLoading, setGroupLoading] = useState(true);
  const [group, setGroup] = useState<any>(null);

  useEffect(() => {
    const getGroup = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(communityId),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    };

    getGroup();
  }, []);

  return (
    <AuthWrapper
      leftContent={
        <CommunityInvitationConfirmContent
          isPrivate={group && group.private}
          communityName={group ? group.name : ''}
        />
      }
      rightContent={
        <RightDescriptionContent
          isPrivate={group && group.private}
          communityLoading={groupLoading}
          communityName={group ? group.name : ''}
        />
      }
    />
  );
};

export default CommunityInvitationConfirm;
