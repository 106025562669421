import { createContext } from 'react';
import { isAuthenticated } from 'utils/authentication';

export type User = {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  state?: string;
  lastLogin?: Date;
  roles: string[];
  facilitatorGroupsIds: string[];
  managerGroupIds: string[];
  enrolledInDdl: boolean;
  ddl: {
    basketSize: boolean;
    houseBrands: boolean;
    hasDutchieIntegration: boolean;
  };
  managerOrganizationId: string;
};

export type AuthStateType = {
  authenticated: boolean;
  user: User;
  token: string;
  tokenExpiration: number;
  permissions: string[];
  isAlias: boolean;
  adminId?: string;
  organization: { id: string; name: string };
  isAdmin?: boolean;
};

export const AUTH_INITIAL_STATE: AuthStateType = {
  authenticated: isAuthenticated(),
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    facilitatorGroupsIds: [],
    managerGroupIds: [],
    enrolledInDdl: false,
    ddl: {
      basketSize: false,
      houseBrands: false,
      hasDutchieIntegration: false,
    },
    managerOrganizationId: '',
  },
  token: '',
  tokenExpiration: 0,
  permissions: [],
  isAlias: false,
  organization: { id: '', name: '' },
  isAdmin: false
};

const AuthContext = createContext<{
  authState: AuthStateType;
  login: ({
    user,
    token,
    isAlias,
    adminId,
    facilitatorGroupsIds,
    managerGroupIds,
    enrolledInDdl,
    ddl,
    organization,
  }: {
    user: User;
    token: string;
    isAlias: boolean;
    adminId: string;
    facilitatorGroupsIds: string[];
    managerGroupIds: string[];
    enrolledInDdl: boolean;
    ddl: {
      basketSize: boolean;
      houseBrands: boolean;
      hasDutchieIntegration: boolean;
    };
    organization: { id: string; name: string };
    isAdmin: boolean;
  }) => void;
  logout: () => void;
  updateUser: ({ user }: { user: User }) => void;
  getPermissions: () => string[];
}>({
  authState: AUTH_INITIAL_STATE,
  login: () => null,
  logout: () => null,
  updateUser: () => null,
  getPermissions: () => [],
});

export default AuthContext;
