import * as React from 'react';

export default (
        <g>
            <path
                d="M16.5 31C23.956 31 30 24.956 30 17.5S23.956 4 16.5 4 3 10.044 3 17.5 9.044 31 16.5 31z"
                fill="#CFF4D2"
            />
            <path
                d="M44.29 22.36h-10.3c-.55 0-1-.45-1-1v-10.3c0-.55.45-1 1-1s1 .45 1 1v9.3h9.3c.55 0 1 .45 1 1s-.45 1-1 1z"
                fill="#205072"
            />
            <path
                d="M30.38 50.2H15.2c-.55 0-1-.45-1-1V11.06c0-.55.45-1 1-1h18.79c.27 0 .52.11.71.29L45 20.65c.19.19.29.44.29.71v4.67c0 .55-.45 1-1 1s-1-.45-1-1v-4.25l-9.71-9.71H16.2V48.2h14.18c.55 0 1 .45 1 1s-.45 1-1 1z"
                fill="#205072"
            />
            <path
                d="M27.38 37.89h-6.51c-.55 0-1-.45-1-1s.45-1 1-1h6.51c.55 0 1 .45 1 1s-.45 1-1 1zM23.7 43.93h-2.83c-.55 0-1-.45-1-1s.45-1 1-1h2.83c.55 0 1 .45 1 1s-.45 1-1 1zM30 31.84h-9.13c-.55 0-1-.45-1-1s.45-1 1-1H30c.55 0 1 .45 1 1s-.45 1-1 1zM30 25.8h-9.13c-.55 0-1-.45-1-1s.45-1 1-1H30c.55 0 1 .45 1 1s-.45 1-1 1zM26.54 19.76h-5.67c-.55 0-1-.45-1-1s.45-1 1-1h5.67c.55 0 1 .45 1 1s-.45 1-1 1z"
                fill="#205072"
            />
            <path
                d="M41.55 55.04c-8.37 0-15.17-6.81-15.17-15.17S33.19 24.7 41.55 24.7s15.17 6.81 15.17 15.17-6.81 15.17-15.17 15.17zm0-28.35c-7.26 0-13.17 5.91-13.17 13.17s5.91 13.17 13.17 13.17 13.17-5.91 13.17-13.17-5.91-13.17-13.17-13.17z"
                fill="#205072"
            />
            <path
                d="M41.56 51.29c-.5 0-.9-.4-.9-.9v-3.62c0-.5.4-.9.9-.9s.9.4.9.9v3.62c0 .5-.4.9-.9.9z"
                fill="#205072"
            />
            <path
                d="M40.25 47.76c-2.68 0-7.24-.74-9.47-3.24a.902.902 0 01-.2-.83c.08-.29.3-.53.59-.62 1.38-.45 2.82-.64 4.23-.58-1.68-1.65-3.3-3.98-3.68-7a.899.899 0 011.22-.95c2.63 1.02 4.48 2.42 5.78 3.78-.15-3.17.54-6.33 2.04-9.19.16-.3.46-.48.8-.48.33 0 .64.19.8.48 1.5 2.86 2.19 6.02 2.04 9.18 1.29-1.36 3.15-2.76 5.77-3.77.29-.12.63-.07.88.13.25.19.38.51.34.82-.38 3.02-2 5.34-3.68 7 1.42-.07 2.85.12 4.23.58a.896.896 0 01.39 1.45c-2.65 2.98-8.62 3.46-10.77 3.17-.35.05-.79.08-1.31.08v-.01zm-6.81-3.38c2.62 1.54 6.74 1.72 7.97 1.51.1-.02.2-.02.3 0 1.22.21 5.34.03 7.96-1.51a9.97 9.97 0 00-5.51.78c-.44.2-.95.02-1.17-.41a.903.903 0 01.35-1.19c1.47-.85 4.7-3.06 5.88-6.62-3.34 1.74-4.88 4.13-5.52 5.51-.2.42-.69.62-1.13.46a.897.897 0 01-.55-1.09c.94-3.36.77-6.88-.46-10.09-1.23 3.22-1.4 6.74-.46 10.09.13.45-.11.92-.55 1.09a.908.908 0 01-1.13-.46c-.65-1.38-2.2-3.78-5.53-5.51 1.19 3.56 4.41 5.76 5.88 6.62.42.24.57.76.35 1.19-.22.43-.73.61-1.17.41a9.97 9.97 0 00-5.51-.78z"
                fill="#205072"
            />
        </g>
);
