import React from 'react';

import { Controller } from 'react-hook-form';

import { TextField } from '@mui/material';

interface NumberFormFieldProps {
  name: string;
  label: string;
  control: any;
  onBlur?: (event: any) => void;
  onChange?: (e: any) => void;
  errors?: any;
  icon?: React.ReactChild;
  position?: 'start' | 'end';
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  disabled?: boolean;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  triggerFieldOnchange?: boolean;
  className?: any;
  disableWheel?: boolean;
}

const NumberFormField = ({
  onBlur,
  onChange,
  name,
  label,
  control,
  errors,
  icon,
  position = 'start',
  margin = 'normal',
  required,
  disabled = false,
  value,
  min = 0,
  max = 2147483647,
  step,
  triggerFieldOnchange = true,
  className,
  disableWheel = false,
}: NumberFormFieldProps) => {
  let inputProps = {};

  if (icon) {
    if (position === 'start') {
      inputProps = {
        startAdornment: icon,
        pattern: '[0-9]{1,15}',
      };
    }

    if (position === 'end') {
      inputProps = {
        endAdornment: icon,
        pattern: '[0-9]{1,15}',
      };
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          className={className}
          onBlur={onBlur}
          required={required}
          type={disableWheel ? 'tel' : 'number'}
          variant="outlined"
          label={label}
          fullWidth
          margin={margin}
          error={Boolean(errors)}
          disabled={disabled}
          value={value ? value : field.value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            if (triggerFieldOnchange) {
              field.onChange(e.target.value);
            }
          }}
          helperText={
            errors ? <span color="error">{errors.message}</span> : ' '
          }
          InputProps={{
            ...inputProps,
            inputProps: {
              min,
              max,
              ...(step && { step }),
            },
          }}
        />
      )}
    />
  );
};

export default NumberFormField;
