import { createContext } from 'react';

export type Kpi = {
  id: string;
  kpi: string;
  name: string;
};

export type KpiType = {
  kpis: Kpi[];
};

export const KPI_INITIAL_STATE: KpiType = {
  kpis: [],
};

const KpiContext = createContext<{
  kpiState: KpiType;
  getKpis: () => Kpi[];
}>({
  kpiState: KPI_INITIAL_STATE,
  getKpis: () => [],
});

export default KpiContext;
