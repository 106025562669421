import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  UserManagementIcon,
  UploadFileIcon,
} from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import CreateUser from '../dialogs/CreateUser';
import DeleteUser from '../dialogs/DeleteUser';
import EditUser from '../dialogs/EditUser';
import UploadUsers from '../dialogs/UploadUsers';

import httpRoutes from 'utils/httpRoutes';
import { Box } from '@mui/material';
import { getUserStatusesOptions } from 'dataSets/users';
import resources from '../../organizations/resources';
import { useAuthState } from '../../../providers/AuthProvider/hooks/useAuthState';

let usersRequestController: AbortController | undefined;

const UserManagementGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();
  const navigate = useNavigate();
  const {
    authState: { isAdmin },
  } = useAuthState();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const [rolesOptions, setRolesOptions] = useState([]);

  const onSuccess = () => {
    getUsers();
    hideDialog();
  };

  const getRoles = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.getRoles(),
    });

    if (response) {
      const defaultOption = {
        label: 'Any Role',
        value: '',
      };

      const roleOptions = response.map((item: { id: string; name: string }) => {
        return { value: item.id, label: item.name };
      });

      roleOptions.unshift(defaultOption);

      setRolesOptions(roleOptions);
    }
  };

  const getGroups = async (value: string) => {
    const { response } = await callService({
      resource: resources.groups.search(value),
    });

    if (response) {
      return response.map((item: { id: string; name: string }) => {
        return { id: item.id, label: item.name };
      });
    }
  };

  const showCreateDialog = () => {
    showDialog({
      content: <CreateUser onSuccess={onSuccess} />,
      size: 'md',
    });
  };

  const showEditDialog = (userId: string) => {
    showDialog({
      content: (
        <EditUser
          onSuccess={onSuccess}
          hideDialog={hideDialog}
          userId={userId}
        />
      ),
      size: 'md',
    });
  };

  const showDeleteDialog = (values: { id: string; email: string }) => {
    showDialog({
      content: (
        <DeleteUser
          onSuccess={onSuccess}
          hideDialog={hideDialog}
          values={values}
        />
      ),
      size: 'sm',
    });
  };

  const showUploadDialog = () => {
    showDialog({
      content: <UploadUsers onSuccess={onSuccess} />,
      size: 'md',
    });
  };

  useEffect(() => {
    getUsers();
  }, [filters, state]);

  useEffect(() => {
    getRoles();
  }, []);

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Search Users',
    },
    {
      id: 'status',
      type: 'select',
      label: 'Status',
      options: getUserStatusesOptions(),
    },
    {
      id: 'role',
      type: 'select',
      label: 'Roles',
      options: rolesOptions,
    },
    {
      id: 'group',
      type: 'async',
      label: 'Groups',
      getOptions: getGroups,
    },
  ];

  const getUsers = async () => {
    setLoading(true);
    if (usersRequestController) {
      usersRequestController.abort();
    }

    try {
      usersRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.userManagement.getMany({
          controller: usersRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        usersRequestController = undefined;
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Archived',
      field: 'archived',
      type: 'boolean',
      flex: 0.3,
    },
    {
      headerName: 'Action',
      field: 'action',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditDialog(params.row.id)}>
              <EditIcon />
            </IconButton>
            {isAdmin && (
              <IconButton onClick={() => showDeleteDialog(params.row)}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <PageContainer
      title="User Management"
      icon={<UserManagementIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box>
            <Button
              onClick={showUploadDialog}
              startIcon={<UploadFileIcon />}
              label="Import User"
            />
          </Box>
          <Box>
            <Button
              onClick={showCreateDialog}
              startIcon={<AddIcon />}
              label="Create User"
            />
          </Box>
        </Box>
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
        onCellClick={(params) => {
          if (isAdmin && params.field === 'firstName') {
            return navigate(
              `/admin/user-management/learner-activity/${params.row.id}`
            );
          }
        }}
      />
    </PageContainer>
  );
};

const UserManagement = () => (
  <ControlledDataGrid>
    <UserManagementGrid />
  </ControlledDataGrid>
);

export default UserManagement;
