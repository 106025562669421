import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { SelectOption, FilterProps } from '../filterTypes';
import {InputAdornment, styled} from '@mui/material';
import Icon from 'icons';

const StyledFilterSelect = styled(Select)`
  border-radius: 30px;
  background: white;
`;

export default function SelectFilter({
  id,
  label,
  value = '',
  options = [],
  onChange,
}: FilterProps) {
  return  (
      <Box sx={{ cursor: 'pointer'}}>
        <StyledFilterSelect
            size="small"
            id={id}
            value={value}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={(e) => onChange(e.target.value)}
            sx={{
              width: '100%',
                '&:hover': {
                    '&& fieldset': {
                        borderColor: '#56C596',
                    }
                },
            }}
            startAdornment={
              <InputAdornment position="start">
                <Icon name="filter" fill="#56C596" />
              </InputAdornment>
            }
            IconComponent={(props: any) => (
                <KeyboardArrowDownRoundedIcon
                    sx={{ color: 'black !important' }}
                    className={`material-icons ${props.className}`}
                />
            )}
        >
            <MenuItem disabled value="">
                <em>{label}</em>
            </MenuItem>
          {options.map((option: SelectOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
          ))}
        </StyledFilterSelect>
      </Box>
  );
}
