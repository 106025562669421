import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Box, Tab, Tabs } from '@mui/material';

import PageContainer from 'components/PageContainer';

import { CommunityMemberIcon } from 'material-icons';

import ActiveMembers from './ActiveMembers';
import PendingMembers from './PendingMembers';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`group-tabpanel-${index}`}
      aria-labelledby={`group-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `group-tab-${index}`,
    'aria-controls': `group-tabpanel-${index}`,
  };
}

const AllMembers = () => {
  const [value, setValue] = useState(0);

  const params = useParams();

  const groupId = params && params.id ? params?.id.toString() : '';

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageContainer showBackButton={false} isLoading={false}>
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="group tab"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Active Members" {...a11yProps(0)} />
              {/* <Tab label="Pending Members" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ActiveMembers groupId={groupId} />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <PendingMembers />
          </TabPanel> */}
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default AllMembers;
