import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCallService} from 'hooks';
import {useAuthDispatcher} from 'providers/AuthProvider/hooks/useAuthDispatcher';
import httpRoutes from 'utils/httpRoutes';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {Box, Grid} from '@mui/material';
import PasswordFormField from 'components/formFields/PasswordFormField2';
import Button from 'components/buttons/Button';

export interface IConfirmRegistrationFormInputs {
  password: string;
  passwordConfirmation: string;
}

const CompleteRegistration = () => {
  const { invitationToken = '' } = useParams();

  const [userLoading, setUserLoading] = useState(true);
  const [user, setUser] = useState<any>({});

  const { callService } = useCallService();

  const { login } = useAuthDispatcher();
  const navigate = useNavigate();

  const validateInvitationToken = async () => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.onBoarding.validateInvitationToken(invitationToken),
      });

      if (response) {
        setUser(response);
      }
    } finally {
      setUserLoading(false);
    }
  };

  const onGoToLogin = () => {
    navigate('/login');
  };

  const onSubmit = async(values: IConfirmRegistrationFormInputs) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.onBoarding.completeRegistration(invitationToken, values),
      });
      if (response) {
        login(response);

        navigate('/', { replace: true});
      }
    }catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    validateInvitationToken();
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string().max(255).required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password must be confirmed'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<IConfirmRegistrationFormInputs>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (<form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
    <Grid item xs={12}>
      <Box
        sx={{
          color: 'white',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '40px',
          lineHeight: '100%',
        }}
      >
        Welcome to Seed Talent
      </Box>
    </Grid>
    <Grid item xs={12} mb={4}>
      <Box
        sx={{
          color: 'white',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '32px',
        }}
      >
        Please set your new password to continue.
      </Box>
    </Grid>
    <Grid xs={12} item mb={3}>
      <PasswordFormField
        name="password"
        control={control}
        placeholder="Password"
        errors={errors.password}
      />
    </Grid>
    <Grid xs={12} item>
      <PasswordFormField
        name="passwordConfirmation"
        control={control}
        placeholder="Confirm Password"
        errors={errors.passwordConfirmation}
        margin="dense"
      />
    </Grid>
    <Grid
      xs={12}
      item
      mb={3}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '150%',
          color: '#56C596',
          cursor: 'pointer',
        }}
        onClick={onGoToLogin}
      >
        Already have an account?
      </Box>
    </Grid>
    <Grid item xs={12} mb={1}>
      <Button
        type="submit"
        label="Complete Registration"
        disabled={isSubmitting}
        sx={{
          textTransform: 'uppercase',
          fontFamily: 'Raleway',
          fontWeight: '700',
          fontSize: '20px',
          lineHeight: '24px',
          background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
        }}
      />
    </Grid>
  </form>);
};

export default CompleteRegistration;
