import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import {calculateDateRange, DateConfig} from '../../utils/date';

interface DatePickerProps {
  label: string;
  onChange?: (value: Date | null) => void;
  value: Date;
  disabled?: boolean;
  disablePast?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
  error?: any;
  disableKeyBoard?: boolean;
  sx?: any;
  minDate?: DateConfig;
  maxDate?: DateConfig;
  customMinDate?: string;
  customMaxDate?: string;
}

const DatePicker = ({
  label,
  onChange,
  value,
  fullWidth = false,
  disabled = false,
  disablePast = false,
  readOnly = false,
  disableKeyBoard = false,
  sx,
  minDate = 'none',
  maxDate = 'none',
  customMinDate,
  customMaxDate,
  ...props
}: DatePickerProps) => {
  const handleChange = (newValue: Date | null) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const { calculatedMinDate, calculatedMaxDate } = calculateDateRange(minDate, maxDate, customMinDate, customMaxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        disabled={disabled}
        readOnly={readOnly}
        label={label}
        value={value}
        disablePast={disablePast}
        onChange={handleChange}
        minDate={calculatedMinDate}
        maxDate={calculatedMaxDate}
        renderInput={(params: any) => {
          const { error, ...otherParams } = params;
          return (
            <TextField
              sx={sx}
              {...otherParams}
              fullWidth={fullWidth}
              {...props}
              onKeyDown={disableKeyBoard ? onKeyDown : (_e: any) => {}}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
