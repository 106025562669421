import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SaveIcon } from 'material-icons';
import { Grid, Typography } from '@mui/material';

const OboardingSecondaryConfirmation = (props: any) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography fontSize={24} fontWeight="bold" textAlign="left">
            {'Secondary confirmation for active onboarding status'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" align="left">
              Moving the Onboarding Status to Active will send a Welcome Email
              to all of the selected DDL Facilitators. Would you like to
              Proceed?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" mt={2}>
            <Button variant="text" onClick={handleClose}>
              No, Go Back
            </Button>
            <Button type="submit" variant="contained" onClick={props.save}>
              <SaveIcon sx={{ mr: 1 }} /> Proceed
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default OboardingSecondaryConfirmation;
