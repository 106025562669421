import { Box } from '@mui/material';
import { lighten } from 'polished';

interface ITag {
  label: string;
  labelColor: string;
  backgroundColor?: string;
}

const Tag = ({ label, labelColor, backgroundColor }: ITag) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor || lighten(0.24, labelColor),
        borderRadius: '4px',
        width: 'fit-content',
        px: 1,
        alignItems: 'center',
        display: 'flex',
        gap: 1
      }}
    >
      <Box
        sx={{
          width: '6px',
          height: '6px',
          background: labelColor,
          borderRadius: '2px',
        }}
      />
      <Box
        sx={{
          fontFamily: 'Roboto',
          fontWeight: 500,
          fontSize: '12px',
          color: labelColor,
          lineHeight: '18px',
        }}
      >
        {label}
      </Box>
    </Box>
  );
};

export default Tag;
