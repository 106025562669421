import * as React from 'react';

export default (
  <g>
    <path
      d="M6 9l5.823 5.823a.25.25 0 00.354 0L18 9"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </g>
);
