export class PromiseAdapter {
  public execute: any;
  public params: any[];
  public context?: any;

  public constructor(execute: any, params: any, context?: any) {
    this.execute = execute;
    this.params = params;
    this.context = context;
  }
}
