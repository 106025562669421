import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  Skeleton,
  useMediaQuery,
} from '@mui/material';

import HouseBrandsEmployeesPerformanceChart
  from '../../../components/employees/houseBrands/HouseBrandsEmployeesPerformanceChart';
import HouseBrandsEmployeeTableView from '../../../components/employees/houseBrands/HouseBrandsEmployeeTableView';

import { SelectOption } from 'components/filters/filterTypes';

import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import GroupSelectField from '../../../components/GroupSelectField';


interface IAllEmployees {
  kpiId: string;
  groupId: string;
  onGroupChange: (groupId: string) => void;
  groupsOptions: SelectOption[];
}

const AllEmployees = ({
  kpiId,
  groupId,
  groupsOptions,
  onGroupChange,
}: IAllEmployees) => {
  const [view, setView] = useState('Graph');
  const [group, setGroup] = useState<any>(null);
  const [groupLoading, setGroupLoading] = useState(false);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const handleChange = (event: SelectChangeEvent) => {
    setView(event.target.value as string);
  };

  let groupIdToUse = groupId;

  if (!groupId) {
    groupIdToUse = groupsOptions[0] ? groupsOptions[0].value : '';
  }

  const getGroup = async () => {
    if (groupIdToUse) {
      try {
        setGroupLoading(true);

        if (!groupId) {
          onGroupChange(groupIdToUse);
        }

        const { response } = await callService({
          resource: httpRoutes.groups.getOne(groupIdToUse),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  return (
    <Box>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '25px' : '32px',
              lineHeight: '130%',
              color: '#162738',
            }}
          >
            House Brands Dashboard
          </Box>
          {groupLoading ? (
            <Skeleton width={200} variant="rectangular" />
          ) : (
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '150%',
                color: '#162738',
              }}
            >
              {`for ${group?.name}`}
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <GroupSelectField
            groupId={groupId}
            setGroupId={onGroupChange}
            disabled={groupLoading}
            options={groupsOptions}
            label="Store"
          />
          <Box sx={{ width: '150px' }}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  '&.Mui-focused': { color: '#56C596' },
                }}
              >
                View
              </InputLabel>
              <Select
                sx={{
                  textAlign: 'center',
                  background: 'white',
                  borderRadius: '30px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#56C596',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#56C596',
                  },
                }}
                value={view}
                label="View"
                onChange={handleChange}
                disabled={groupLoading}
              >
                <MenuItem value="Graph">Graph</MenuItem>
                <MenuItem value="Table">Table</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {view === 'Graph' ? (
        <HouseBrandsEmployeesPerformanceChart
          kpiId={kpiId}
          groupId={groupIdToUse}
          groupLoading={groupLoading}
        />
      ) : (
        <HouseBrandsEmployeeTableView
          kpiId={kpiId}
          groupId={groupIdToUse}
          groupLoading={groupLoading}
        />
      )}
    </Box>
  );
};

export default AllEmployees;
