import React, { useEffect, useState } from 'react';

import { Avatar, Box, Skeleton } from '@mui/material';
import { lighten } from 'polished';

import Counter from '../../../../shared/Counter';
import {formatTime} from '../../../../shared/utils';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import Icon from 'icons';

const AvgHoursLearning = ({ groupId }: { groupId: string }) => {
  const { callService } = useCallService();
  const [avgHours, setAvgHours] = useState('0');
  const [loading, setLoading] = useState(true);

  const getAvgHours = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.learner.avgHoursLearning(groupId),
      });

      if (response) {
        const { avgHoursLearning } = response;

        setAvgHours(avgHoursLearning);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvgHours();
  }, []);

  if(loading) {
    return (<Skeleton variant="rectangular" width="100%" height="100%" />);
  }

  return (
    <Box
      sx={{
        flexDirection: 'column',
        display: 'flex',
        gap: 4,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{
          backgroundColor: lighten(0.3, '#56C596'),
          opacity: '0.9',
          width: 62,
          height: 62,
        }}
      >
        <Icon name="clock" size="extraLarge" fill="#56C596" />
      </Avatar>
      <Box>
        <Counter label={'Average hours of learning'} counter={avgHours} />
      </Box>
    </Box>
  );
};

export default AvgHoursLearning;
