import React from 'react';

import { FieldError } from 'react-hook-form';

import { Box, InputLabel } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import RichTextEditor from '@mantine/rte';

interface RichTextFormFieldReadOnlyProps {
  value: string | undefined;
  name: string;
  sx?: any;
  label?: string;
  placeholder?: string;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  inputLabelSx?: SxProps<Theme>;
}

const RichTextFormFieldReadOnly = ({
  name,
  label,
  value,
  sx = { minWidth: '100%' },
  placeholder,
  margin = 'normal',
  inputLabelSx,
}: RichTextFormFieldReadOnlyProps) => {
  return (
    <Box sx={sx} margin={margin}>
      {label && (
        <InputLabel sx={inputLabelSx} id={`rich-text-box-${name}-label`}>
          {label}
        </InputLabel>
      )}
      <RichTextEditor
        style={{
          border: 'none'
        }}
        id={`rich-textbox-${name}`}
        controls={[]}
        value={value}
        //placeholder={placeholder}
        readOnly={true}
      />
    </Box>
  );
};

export default RichTextFormFieldReadOnly;
