import { useState } from 'react';
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material';

import { FilterProps } from '../filterTypes';

export default function SwitchFilter({ id, label, onChange }: FilterProps) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <Box width="10%" sx={{ mr: 1, ml: 1, mt: 0.5 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              id={id}
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                onChange(isChecked);
              }}
            />
          }
          label={label}
        />
      </FormGroup>
    </Box>
  );
}
