import React, { useEffect, useState } from 'react';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import TopLearnersList from '../../../../shared/topLearners/TopLearners';

const TopLearners = ({ groupId }: { groupId: string }) => {
  const { callService } = useCallService();
  const [learners, setLearners] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLearners = async () => {
    try {
      if (groupId) {
        const { response } = await callService({
          resource:
            httpRoutes.reporting.group.getTopLearnersByGroup(
              groupId
            ),
        });

        if (response) {
          setLearners(response);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLearners();
  }, []);

  return <TopLearnersList loading={loading} topLearners={learners} />;
};

export default TopLearners;
