import React, { useEffect, useState } from 'react';

import {Box, Grid, useMediaQuery} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import colorsByIndex from '../../../../shared/colors';
import Counter from '../../../../shared/Counter';
import CoursesCategoryList from '../../../../shared/totalCourses/CoursesCategoryList';
import TotalCoursesLoading from '../../../facilitator/partials/totalCourses/TotalCoursesLoading';
import {useTheme} from '@mui/material/styles';

const TotalCourses = ({ groupId }: { groupId: string }) => {
  const theme = useTheme();
  const matchUpLG = useMediaQuery(theme.breakpoints.up('lg'));

  const { callService } = useCallService();
  const [categoriesList, setCategoriesList] = useState<string[]>([]);
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });
  const [totalCourses, setTotalCourses] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const getTotalCourses = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.group.getTotalCoursesByGroup(groupId),
      });

      if (response) {
        const { totalCoursesByCategoryList, totalCourses } = response;
        const data: number[] = [];
        const categories: string[] = [];

        totalCoursesByCategoryList.forEach(
          (category: { count: number; name: string }) => {
            const { name, count } = category;
            data.push(count);
            categories.push(name);
          }
        );

        const doughnutData = {
          labels: categories,
          datasets: [
            {
              data,
              backgroundColor: colorsByIndex,
              borderWidth: 1,
            },
          ],
        };

        setChartData(doughnutData);
        setTotalCourses(totalCourses);
        setCategoriesList(categories);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTotalCourses();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100%',
          borderRadius: '15px',
          backgroundColor: 'white',
          p: 4,
        }}
      >
        <TotalCoursesLoading />
      </Box>
    );
  }

  return (
    <Grid
      container
      rowGap={4}
      sx={{
        width: matchUpLG ? '348px' : '100%',
        p: 4,
        height: '100%',
        borderRadius: '15px',
        backgroundColor: 'white',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Counter counter={totalCourses} label="Courses" reverse={true} />
      </Grid>
      <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
        <Doughnut
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
          }}
          data={chartData}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CoursesCategoryList categories={categoriesList} />
      </Grid>
    </Grid>
  );
};

export default TotalCourses;
