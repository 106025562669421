/* eslint-disable */
import {
  memo,
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import PendingMembersContext, {
  PENDING_MEMBERS_INITIAL_STATE,
} from './contexts/PendingMembersContext';
import httpRoutes from '../../utils/httpRoutes';
import { useCallService } from 'hooks';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

const PendingMembersProvider = ({ children }: { children: ReactNode }) => {
  const [pendingMembersState, setPendingMembersState] = useState(
    PENDING_MEMBERS_INITIAL_STATE
  );
  const { callService } = useCallService();
  const {
    authState: { user, isAdmin },
  } = useAuthState();

  const checkNotifications = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getPendingUsersCount(),
      });

      if (response) {
        setPendingMembersState({ hasNotifications: response.count > 0 });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (
      user.facilitatorGroupsIds.length > 0 ||
      isAdmin ||
      user.managerGroupIds.length > 0
    ) {
      checkNotifications();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      pendingMembersState,
      checkNotifications,
    }),
    [pendingMembersState, checkNotifications]
  );

  return (
    <PendingMembersContext.Provider value={value}>
      {children}
    </PendingMembersContext.Provider>
  );
};

export default memo(PendingMembersProvider);
