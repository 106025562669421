import { Box, useMediaQuery } from '@mui/material';
import Statistics from '../../../components/Statistics';
import MultiStoreCommunitiesGrid from '../../../components/MultiStoreCommunitiesGrid';
import { useTheme } from '@mui/material/styles';
import { DataGridColumnProps } from '../../../../../components/dataGrid/components/DataGridColumn';
import Tag from '../../../components/Tag';
import { renderBoxColumn } from '../../../../../utils/tableRendering';
import {
  formatCurrency,
  formatPercentageSymbol,
} from '../../../../../utils/common';

interface IMultiStore {
  kpiId: string;
  groupIds?: string[];
  onGroupClick: (groupId: string) => void;
}

// set columns
const columns = (navigateToGroup: any): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Store',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToGroup(params.row.id);
            }}
          >
            {params.row.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'ddlOnboardingStatus',
      sortable: false,
      ColumnComponent: (params: any) => {
        return params.row.ddlOnboardingStatus ? (
          <Tag
            label="Enrolled"
            labelColor="#14804A"
            backgroundColor="#E1FCEF"
          />
        ) : (
          <Tag
            label="Not Enrolled"
            labelColor="#5A6376"
            backgroundColor="#E9EDF5"
          />
        );
      },
    },
    {
      headerName: 'Enrolled on',
      field: 'ddlActivatedOn',
      sortable: true,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.ddlActivatedOn) {
          return (
            <>
              {new Date(params.row.ddlActivatedOn).toLocaleDateString('en-US')}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Store Ticket Average',
      field: 'storeTicketAvg',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.storeTicketAvg, formatCurrency),
    },
    {
      headerName: 'House Brands Ticket %',
      field: 'normalizedMean',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.normalizedMean, formatPercentageSymbol),
    },
    {
      headerName: 'House Brands Ticket Avg',
      field: 'houseBrandsTicketAvg',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.houseBrandsTicketAvg, formatCurrency),
    },
    {
      headerName: '14 Day Growth',
      field: 'dollarsAdded14day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.dollarsAdded14day, formatCurrency),
    },
    {
      headerName: '14 Day % Change',
      field: 'uplift14Day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.uplift14Day, formatPercentageSymbol),
    },
    {
      headerName: '30 Day Growth',
      field: 'dollarsAdded30day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.dollarsAdded30day, formatCurrency),
    },
    {
      headerName: '30 Day % Change',
      field: 'uplift30Day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.uplift30Day, formatPercentageSymbol),
    },
  ];
};

const MultiStore = ({ kpiId, groupIds, onGroupClick }: IMultiStore) => {
  const theme: any = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box sx={{ my: 2 }}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: matchDownSM ? '25px' : '32px',
            lineHeight: '130%',
            color: '#162738',
          }}
        >
          House Brands Dashboard
        </Box>
      </Box>
      <Statistics
        type="multi"
        groupIds={groupIds}
        kpiId={kpiId}
        labelResult1="14 day growth"
        labelResult2="14 day % change"
        labelResult3="30 day growth"
        labelResult4="30 day % change"
      />
      <MultiStoreCommunitiesGrid
        columns={columns}
        kpiId={kpiId}
        onGroupClick={onGroupClick}
      />
    </Box>
  );
};

export default MultiStore;
