import React, { useEffect, useState } from 'react';
import { useCallService } from 'hooks';
import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import httpRoutes from 'utils/httpRoutes';
import CircularProgress from 'features/reporting/shared/CircularProgress';
import { SelectOption } from 'components/filters/filterTypes';

interface IEmployeeCompletionRate {
  kpiId: string;
  type: 'multi' | 'single';
  groupId?: string;
  groupIds?: string[];
  groupsOptions?: SelectOption[];
}

let groupsRequestController: AbortController | undefined;

export type CompletionRate = {
  totalAssigned: number;
  totalCompleted: number;
  totalIncomplete: number;
  completionRate: number;
};

const EmployeeCompletionRate = ({
  type,
  kpiId,
  groupId,
  groupIds,
  groupsOptions,
}: IEmployeeCompletionRate) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { callService } = useCallService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [completionRateData, setCompletionRateData] = useState<CompletionRate>({
    totalAssigned: 0,
    totalCompleted: 0,
    totalIncomplete: 0,
    completionRate: 0,
  });

  const getCompletionRate = async () => {
    setLoading(true);
    try {
      let response = null;
      if (!kpiId) {
        return;
      }
      if (type === 'single') {
        let groupIdToUse = groupId || '';

        if (!groupId) {
          groupIdToUse = groupsOptions ? groupsOptions[0].value : '';
        }
        const data = await callService({
          resource: httpRoutes.ddl.getCompletionRate({
            groupId: groupIdToUse,
            kpiId,
          }),
        });
        response = data.response;
      }

      if (type === 'multi') {
        const data = await callService({
          resource: httpRoutes.ddl.getCompletionRateMultiStore({
            groupIds,
            kpiId,
          }),
        });
        response = data.response;
      }

      if (response) {
        setCompletionRateData(response);
        groupsRequestController = undefined;
      } else {
        setError(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompletionRate();
  }, [groupId, kpiId]);

  const getColor = (completionRate: number): string => {
    if (completionRate <= 30) {
      return '#FF5572';
    }

    if (completionRate > 31 && completionRate <= 70) {
      return '#ADE1FF';
    }

    return '#56C596';
  };

  return (
    <Box
      sx={{
        margin: '5px',
        p: 2,
        borderRadius: '15px',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: 'white',
        flex: `0.50 0 ${matchDownLG ? 'auto' : '50px'}`,
        display: 'flex',
      }}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
            textTransform: 'uppercase',
          }}
        >
          Employee Statistics
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          Learners assigned courses
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ width: '100%', height: '100%' }}>
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              value={completionRateData.completionRate}
              color={getColor(completionRateData.completionRate)}
            />
          </Box>

          {error && (
            <Box
              sx={{
                fontWeight: '700',
                fontSize: '14px',
                textTransform: 'uppercase',
                color: '#205072',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Something went wrong!
            </Box>
          )}

          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '150%',
              textAlign: 'center',
              color: '#205072',
            }}
          >
            <span
              style={{
                color: '#56C596',
                fontWeight: '900',
              }}
            >
              {completionRateData.totalCompleted}
            </span>{' '}
            of{' '}
            <span
              style={{
                color: '#56C596',
                fontWeight: '900',
              }}
            >
              {completionRateData.totalAssigned}
            </span>{' '}
            <span style={{ fontWeight: '900' }}>learners</span> completed their
            assigned course
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmployeeCompletionRate;
