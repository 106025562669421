import { useState, useEffect } from 'react';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { getStatesList } from 'dataSets/states';
import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
  {
    id: 'state',
    type: 'select',
    label: 'State',
    options: [{ label: 'All States', value: '' }, ...getStatesList()],
  },
];

type SelectAllData = {
  groupsIds: string[];
  totalLearners: number;
};

const CommunitiesGrid = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const [selectAllData, setSelectAllData] = useState<SelectAllData>({
    groupsIds: [],
    totalLearners: 0,
  });
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const { groupsIds } = assignCourseState;

  const selectCommunity = (
    selected: boolean,
    groupId: string,
    learners: any
  ) => {
    let updatedGroupsIds: string[] = [];
    if (selected) {
      updatedGroupsIds = addCommunity(groupId);
    } else {
      updatedGroupsIds = removeCommunity(groupId);
      learners *= -1;
    }

    setSelectAllChecked(false);
    setAssignCourseState((prevState: any) => ({
      ...prevState,
      groupsIds: updatedGroupsIds,
      learners: parseFloat(prevState.learners) + parseFloat(learners),
    }));
  };

  const addCommunity = (groupId: any) => {
    const updatedGroupsIds = [...groupsIds];
    updatedGroupsIds.push(groupId);

    return updatedGroupsIds;
  };

  const removeCommunity = (groupId: string) => {
    const updatedGroupsIds = groupsIds.filter(
      (groupsId: string) => groupsId !== groupId
    );

    return updatedGroupsIds;
  };

  const selectAll = (checked: boolean) => {
    let result: any;

    if (checked) {
      result = addCommunities();
    } else {
      result = removeCommunities();
    }

    const updatedGroupsIds: string[] = result[0];
    const learners = result[1];

    setAssignCourseState((prevState: any) => ({
      ...prevState,
      groupsIds: updatedGroupsIds,
      learners: parseFloat(prevState.learners) + learners,
    }));
    setSelectAllChecked(checked);
  };

  const addCommunities = () => {
    const updatedGroupsIds = [...groupsIds, ...selectAllData.groupsIds];

    return [[...new Set(updatedGroupsIds)], selectAllData.totalLearners];
  };

  const removeCommunities = () => {
    const updatedGroupsIds = [...groupsIds];
    selectAllData.groupsIds.forEach((x: string) => {
      const index = updatedGroupsIds.indexOf(x);
      if (index > -1) {
        updatedGroupsIds.splice(index, 1);
      }
    });

    return [updatedGroupsIds, -selectAllData.totalLearners];
  };

  const compareGroupIdsArray = () => {
    if (groupsIds.length === 0 || selectAllData.groupsIds.length === 0) {
      return;
    }

    const checker = (arr: string[], target: string[]) =>
      target.every((v: string) => arr.includes(v));

    const checkSelectAll = checker(groupsIds, selectAllData.groupsIds);
    if (checkSelectAll) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  };

  useEffect(() => {
    getOrganizationCommunities(isOrganizationManager);
  }, [filters, state]);

  useEffect(() => {
    compareGroupIdsArray();
  }, [groupsIds, gridData, selectAllData]);

  const getOrganizationCommunities = async (isOrganizationManager: boolean) => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getOrganizationGroupsPaginated({
          organizationId: organizationId,
          isOrganizationManager,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.pagingResponse.items,
          totalRows: response.pagingResponse.totalRows,
        });
        setSelectAllData({
          groupsIds: response.groupsIds,
          totalLearners: parseFloat(response.totalLearners),
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <Checkbox
            checked={groupsIds.some((x) => x === params.row.id)}
            disabled={!params.row.learners || params.row.learners === 0}
            onClick={(e: any) => {
              selectCommunity(
                e.target.checked,
                params.row.id,
                params.row.learners
              );
            }}
          />
        );
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Learners',
      field: 'learners',
      sortable: false,
      filterable: false,
      flex: 0.3,
    },
  ];

  return (
    <>
      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              name="selectAllGroups"
              checked={selectAllChecked}
              onClick={(e: any) => {
                selectAll(e.target.checked);
              }}
            />
          }
          label="Select all communities"
        />
      </Grid>
      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        columnSpacing={1}
      >
        <Box
          sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
          mt={2}
        >
          <DataGrid
            rows={gridData.rows}
            columns={columns}
            filters={filterModel}
            handleFilterChange={setFilters}
            totalRows={gridData.totalRows}
            loading={loading}
          />
        </Box>
      </Grid>
    </>
  );
};

const CommunitiesTable = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => (
  <ControlledDataGrid>
    <CommunitiesGrid
      organizationId={organizationId}
      isOrganizationManager={isOrganizationManager}
    />
  </ControlledDataGrid>
);

export default CommunitiesTable;
