import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import EmailFormField from 'components/formFields/EmailFormField2';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';
import { useSnackbar } from 'notistack';
import PageContainer from 'components/PageContainer';
import LoadingButton from 'components/buttons/LoadingButton';
import { isProdOrDev } from 'utils/environment';

export interface ILoopsValidateEmailInputs {
  email: string;
}

const Loops = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { callService } = useCallService();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: ILoopsValidateEmailInputs) => {
    setIsSubmitted(true);
    try {
      const result = await callService({
        resource: httpRoutes.loops.createContact(values),
      });
      if (!result.error) {
        enqueueSnackbar('The contact has been created!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setIsSubmitted(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ILoopsValidateEmailInputs>({
    defaultValues: {
      email: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <PageContainer title="Loops Panel">
      {isProdOrDev() ? (
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '600',
                fontSize: '40px',
                lineHeight: '100%',
              }}
            >
              {!isSubmitted ? 'Please enter your email below.' : 'Email sent!'}
            </Box>
          </Grid>
          <Grid xs={12} item mb={3}>
            <EmailFormField
              name="email"
              control={control}
              placeholder="Email"
              errors={errors.email}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <LoadingButton
              label="Create Contact"
              variant="contained"
              loading={isSubmitted}
              type="submit"
            />
          </Grid>
        </form>
      ) : (
        <label>Low environment are not allowed to access here!</label>
      )}
    </PageContainer>
  );
};

export default Loops;
