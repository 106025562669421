import { useState, useEffect } from 'react';

import { SelectOptionProps } from 'components/formFields/types';
import { SelectOptionPropsWithTypeId } from 'types/SelectOptionPropsWithTypeId';
import { TagSelectOptionType } from 'types/TagSelectOptionType';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

const useCourseOptions = () => {
  const { callService } = useCallService();
  const [courseTypes, setCourseTypes] = useState<SelectOptionProps[]>([]);
  const [courses, setCourses] = useState<SelectOptionPropsWithTypeId[]>([]);
  const [courseCategories, setCourseCategories] = useState<SelectOptionProps[]>(
    []
  );
  const [rewards, setRewards] = useState<SelectOptionProps[]>([]);
  const [certificates, setCertificates] = useState<SelectOptionProps[]>([]);
  const [kpis, setKpis] = useState<SelectOptionProps[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const getCourses = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getCourses(),
    });

    const _courses: SelectOptionPropsWithTypeId[] = [];

    return response
      ? _courses.concat(
          response.map(
            (item: { id: string; name: string; courseTypeId: number }) => {
              return {
                value: item.id,
                label: item.name,
                typeId: item.courseTypeId,
              };
            }
          )
        )
      : [];
  };

  const getCourseTypes = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getCourseTypes(),
    });

    return response
      ? response.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  };

  const getCourseCategories = async () => {
    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    return response
      ? response.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
  };

  const getRewards = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getRewards(),
    });

    const defaultReward: SelectOptionProps[] = [
      {
        value: '',
        label: 'No reward',
      },
    ];

    return response
      ? defaultReward.concat(
          response.map((item: { id: string; name: string }) => ({
            value: item.id,
            label: item.name,
          }))
        )
      : defaultReward;
  };

  const getCertificates = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getCertificates(),
    });

    const _certificates: SelectOptionProps[] = [
      {
        value: '',
        label: 'No certificate',
      },
    ];

    return response
      ? _certificates.concat(
          response.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })
        )
      : [];
  };

  const getKpis = async () => {
    const { response } = await callService({
      resource: httpRoutes.kpis.getKpis(),
    });

    return response
      ? response.map((item: { id: string; name: string; kpi: string }) => {
          return { value: item.id, label: item.name, kpi: item.kpi };
        })
      : [];
  };

  const getCourseTags = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.courseManagement.getTags(value),
    });

    if (response) {
      return response.map((item: { id: string; label: string }) => {
        return item as TagSelectOptionType;
      });
    }
  };

  const getOrganizations = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.organizations.search(value),
    });

    if (response) {
      return response.map((item: { id: string; name: string }) => {
        return { id: item.id, label: item.name };
      });
    }
    return [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          types,
          categories,
          rewardsList,
          coursesList,
          certificateList,
          kpis,
        ] = await Promise.all([
          getCourseTypes(),
          getCourseCategories(),
          getRewards(),
          getCourses(),
          getCertificates(),
          getKpis(),
        ]);
        setCourseTypes(types);
        setCourseCategories(categories);
        setRewards(rewardsList);
        setCourses(coursesList);
        setCertificates(certificateList);
        setKpis(kpis);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, []);

  return {
    courseTypes,
    courseCategories,
    rewards,
    courses,
    certificates,
    kpis,
    isFetching,
    getCourseTags,
    getOrganizations,
  };
};

export default useCourseOptions;
