import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { useCallService, useDebouncedEffect } from 'hooks';

import Button from 'components/buttons/Button';
import LoadingButton from 'components/buttons/LoadingButton';

import { DeleteIcon, SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

export type CommunityBundleFormInput = {
  bundleId: string;
  communitiesToAdd?: string[];
  communitiesToDelete?: string[];
};

const CommunityBundleForm = ({
  bundleId,
  bundleName,
  communities,
  loading,
  onSubmit,
}: {
  bundleId: string;
  bundleName: string;
  communities?: { id: string; name: string }[];
  loading: boolean;
  onSubmit: (values: CommunityBundleFormInput) => void;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const [currentCommunities, setCurrentCommunities] = useState<
    { id: string; name: string }[]
  >(communities || []);

  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const presubmitFormat = (values: CommunityBundleFormInput) => {
    const communitiesIds = communities?.map((community) => community.id);
    const currentCommunitiesIds = currentCommunities.map(
      (community) => community.id
    );

    const communitiesToAdd = currentCommunitiesIds.filter(
      (community) => !communitiesIds?.includes(community)
    );
    const communitiesToDelete = communitiesIds?.filter(
      (community) => !currentCommunitiesIds?.includes(community)
    );

    values.communitiesToAdd = communitiesToAdd;
    values.communitiesToDelete = communitiesToDelete;

    return onSubmit(values);
  };

  const addCommunity = (community: { id: string; name: string }) => {
    const updatedCommunities = [...currentCommunities];

    updatedCommunities.push(community);
    setCurrentCommunities(updatedCommunities);
  };

  const removeCourse = (id: string) => {
    const updatedCommunities = currentCommunities.filter(
      (community) => community.id !== id
    );

    setCurrentCommunities(updatedCommunities);
  };

  const getCommunities = async () => {
    try {
      setOptionsLoading(true);
      const communityListIds = currentCommunities.map((x) => x.id);

      const { response } = await callService({
        resource: httpRoutes.groups.search(inputValue, communityListIds),
      });

      if (response) {
        setOptions(
          response.map((item: { id: string; name: string }) => {
            return { id: item.id, label: item.name };
          })
        );
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getCommunities();
  }, [currentCommunities]);

  useDebouncedEffect(getCommunities, 200, [inputValue]);

  const validationSchema = Yup.object().shape({
    bundleId: Yup.string().required(),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CommunityBundleFormInput>({
    mode: 'onChange',
    defaultValues: {
      bundleId: bundleId,
      communitiesToAdd: [],
      communitiesToDelete: [],
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(presubmitFormat)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Manage {bundleName} Bundle Communities
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Add/Remove Communities" />
            <Grid item xs={12}>
              <Autocomplete
                id="search"
                freeSolo
                fullWidth
                selectOnFocus
                loading={areOptionsLoading}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue, reason) => {
                  if (reason === 'input') {
                    setInputValue(newInputValue);
                  }
                  if (reason === 'reset') {
                    setInputValue('');
                  }
                }}
                onChange={(_event, value) => {
                  if (value) {
                    const newCommunity = {
                      name: value.label,
                      id: value.id,
                    };

                    addCommunity(newCommunity);
                  }
                }}
                options={options}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search communities..."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <>
                          {areOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box
              sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
              mt={2}
            >
              {currentCommunities.length > 0 && (
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#f4f4f4',
                      '& th:nth-of-type(1)': {
                        borderRadius: '1em 0 0 0',
                      },
                      '& th:nth-of-type(7)': {
                        borderRadius: '0 1em 0 0',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="80%"
                      >
                        Community Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& tr:nth-of-type(even)': {
                        backgroundColor: '#f4f4f4',
                      },
                      '& tr:last-of-type': {
                        '& td:nth-of-type(1)': {
                          borderRadius: '0 0 0 1em',
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(2)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(3)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(4)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(5)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(6)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(7)': {
                          borderRadius: '0 0 1em 0',
                          borderBottom: '0px',
                        },
                      },
                      '& tr:first-of-type': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    {currentCommunities.map((community) => (
                      <TableRow key={community.id}>
                        <TableCell
                          sx={{
                            padding: matchDownSM
                              ? '0px 0px 0px 16px'
                              : '0px 16px',
                            fontSize: matchDownSM ? '10px' : '14px',
                          }}
                          size="small"
                          width="80%"
                        >
                          {community.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeCourse(community.id);
                            }}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog} label="Cancel" />
          <LoadingButton
            label="Save"
            type="submit"
            variant="contained"
            disabled={isSubmitting || loading}
            loading={isSubmitting || loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          />
        </Grid>
      </form>
    </div>
  );
};

export default CommunityBundleForm;
