import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import resources from '../resources';
import { CheckboxFormField } from 'components/formFields';

export type RemoveOrganizationMemberFormInput = {
  id: string;
};

const RemoveOrganizationMemberForm = ({
  defaultValues,
  metaData,
  onSuccess,
}: {
  defaultValues: RemoveOrganizationMemberFormInput;
  metaData: { firstName?: string; lastName?: string; email: string };
  onSuccess: VoidFunction;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: RemoveOrganizationMemberFormInput) => {
    const { response } = await callService({
      resource: resources.organization.removeMember(values),
      successMessage: 'Member removed successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RemoveOrganizationMemberFormInput>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues.id,
    },
    shouldUnregister: false,
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Remove Organization Member
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pb: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Name: </span>
                {metaData.firstName} {metaData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pb: 2 }}>
                <span style={{ fontWeight: 'bold' }}>Email: </span>
                {metaData.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default RemoveOrganizationMemberForm;
