import { useState, useEffect } from 'react';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';
import ManageCourseWrapper from 'components/courseBuilder/ManageCourseWrapper';
import { Button } from '@mui/material';
import { delay } from 'utils/common';

const Preview = ({
  courseId,
  hideDialog,
}: {
  courseId: string;
  hideDialog: () => void;
}) => {
  const {
    authState: { organization, user },
  } = useAuthState();

  const { callService } = useCallService();

  const [url, setUrl] = useState('');
  const [retry, setRetry] = useState(3);

  const getCourseCreateUrl = async () => {
    const requestData: {
      userId: string;
      courseId: string;
      organizationId: string;
    } = {
      userId: user.id,
      courseId: courseId,
      organizationId: organization.id,
    };

    if (!requestData.userId || !requestData.organizationId) {
      return;
    }

    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCoursePreviewUrl(requestData),
    });

    if (response) {
      const url = encodeURI(`${response}`);

      setUrl(url);
    } else {
      await delay(500);
      if (retry > 0) {
        setRetry(retry - 1);
        getCourseCreateUrl();
      }
    }
  };

  useEffect(() => {
    getCourseCreateUrl();
  }, []);

  if (url === '') {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div style={{ height: '90%' }}>
        <ManageCourseWrapper coassembleURL={url} />
      </div>
      <br />
      <Button variant="text" onClick={hideDialog}>
        Close
      </Button>
    </>
  );
};

export default Preview;
