import _ from 'lodash';

// Function to get the name of the integrationParter from the id
export const getIntegrationPartnerName = (
  integrationPartnerOptions: any[],
  id?: string
) => {
  return integrationPartnerOptions.find((item: any) => item.value === id)
    ?.label;
};

export const hasDuplicatedHcBudtenderMapId = (array: any[]) => {
  const filteredArray = array.filter((item) => item.hcBudtenderMapId && item.hcBudtenderMapId !== '');
  const grouped = _.groupBy(filteredArray, 'hcBudtenderMapId');

  return _.some(grouped, (group) => group.length > 1);
};
