import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Button, Grid, Typography } from 'components/atomic';

import { SaveIcon } from 'material-icons';

import {
  TextFormField,
  CheckboxFormField,
  FileFormField,
} from 'components/formFields';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import fileManagement from 'utils/s3';
import { useState } from 'react';

export type RewardFormInput = {
  id?: string | undefined;
  name: string;
  template: string;
  subject: string;
  active: boolean;
  url: string;
  archived: boolean;
  rewardCodesFile?: File[];
  newRewardCodesUrl?: string;
};

export const INITIAL_FORM_STATE: RewardFormInput = {
  id: undefined,
  name: '',
  template: '',
  subject: '',
  active: true,
  archived: false,
  url: '',
};

const Form = ({
  defaultValues,
  onSubmit,
  isSubmitting,
}: {
  defaultValues?: RewardFormInput;
  onSubmit: (values: RewardFormInput) => void;
  isSubmitting: boolean;
}) => {
  const [disabledActive, setDisabledActive] = useState(
    defaultValues ? defaultValues.archived : false
  );

  const edit = defaultValues?.id;

  const { enqueueSnackbar } = useSnackbar();
  const { hideDialog } = useDialogDispatcher();

  const preSubmitUpload = async (values: RewardFormInput) => {
    if (values.rewardCodesFile) {
      // check file type for xlsx files only
      const fileName = values.rewardCodesFile[0].name;
      const fileTypeIndex = fileName.lastIndexOf('.');
      const fileType = fileName.slice(fileTypeIndex);

      if (fileType !== '.xlsx') {
        enqueueSnackbar('File format must be XLSX format to upload.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
        return;
      }

      enqueueSnackbar('Uploading file. Please stay on page.', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const { name } = values.rewardCodesFile[0];

      const fileUrl = 'reward-codes/' + encodeURIComponent(name);

      const response = await fileManagement.putItemWithPresignedUrl({
        body: values.rewardCodesFile[0],
        key: fileUrl,
      });

      if (response) {
        enqueueSnackbar('File uploaded successfully!', {
          variant: 'info',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        values.newRewardCodesUrl = fileUrl;

        delete values.rewardCodesFile;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }
    onSubmit(values);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required('A name is required'),
    template: Yup.string().max(255).required('A template is required'),
    subject: Yup.string().max(255).required('A subject is required'),
    url: Yup.string().max(255).required('A url is required'),
    active: Yup.boolean(),
    archived: Yup.boolean(),
    rewardCodesFile: Yup.mixed().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
  } = useForm<RewardFormInput>({
    mode: 'onBlur',
    defaultValues: defaultValues || INITIAL_FORM_STATE,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const handleArchivedOnChange = () => {
    if (!getValues('archived')) {
      setValue('active', false);
      setDisabledActive(true);
    } else {
      setDisabledActive(false);
    }
  };

  // onChange();

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Reward
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <TextFormField
                name="name"
                control={control}
                label="Reward Name*"
                errors={errors.name}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="template"
                control={control}
                label="Template*"
                errors={errors.template}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12}>
              <TextFormField
                name="subject"
                control={control}
                label="Subject*"
                errors={errors.subject}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFormField
                name="url"
                control={control}
                label="Redemption URL*"
                errors={errors.url}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <FileFormField
                name="rewardCodesFile"
                control={control}
                label="Upload Reward Codes"
                errors={
                  errors.rewardCodesFile ? errors.rewardCodesFile[0] : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxFormField
                disabled={disabledActive}
                name="active"
                control={control}
                label="Is reward active?"
                errors={errors.active}
              />
            </Grid>
            {edit && (
              <Grid item xs={12} sm={6} alignContent="center">
                <CheckboxFormField
                  name="archived"
                  control={control}
                  onChange={handleArchivedOnChange}
                  label="Is reward archived?"
                  errors={errors.archived}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <SaveIcon sx={{ mr: 1 }} /> Save
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
