import React from 'react';
import { Avatar, Box, Grid, useMediaQuery } from '@mui/material';
import TopCoursesLoading from './TopCoursesLoading';
import { useTheme } from '@mui/material/styles';

const TopCourses = ({
  courses,
  loading = false,
}: {
  courses: any[];
  loading?: boolean;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TOP COURSES
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by course completion by learner
        </Box>
      </Box>
      {loading ? (
        <TopCoursesLoading />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {courses.map((course, index) => {
            return (
              <Grid
                container
                columnSpacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom:
                    index !== courses.length - 1 ? '1px solid #BCC6CF' : 'none',
                  pb: 1,
                }}
                key={course.name}
              >
                <Grid item xs={2} md={2}>
                  <Avatar
                    sx={{
                      backgroundColor: '#DEF4FF',
                      color: '#445D74',
                      fontSize: course.count > 1000 ? '15px' : '20px',
                    }}
                  >
                    {course.count}
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={10}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontSize: matchDownSM ? '13px' : '16px',
                      lineHeight: matchDownSM ? '16px' : '22px',
                      color: 'black',
                    }}
                  >
                    {course.name}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default TopCourses;
