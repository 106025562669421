import React, { useEffect, useState } from 'react';

import TopLearnersList from '../../../../../shared/topLearners/TopLearners';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

const TopLearners = () => {
  const { callService } = useCallService();
  const [learners, setLearners] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLearners = async () => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.reporting.group.getAdminTopLearners(),
      });

      if (response) {
        setLearners(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLearners();
  }, []);

  return <TopLearnersList loading={loading} topLearners={learners} />;
};

export default TopLearners;
