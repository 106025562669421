import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { SelectFormField } from 'components/formFields';
import LoadingButton from 'components/buttons/LoadingButton';
import { SelectOptionProps } from 'components/formFields/types';

import { useCallService } from 'hooks';

import resources from '../../user/resources';

export type PrimaryGroupInput = {
  groupId: string;
};

export const INITIAL_FORM_STATE: PrimaryGroupInput = {
  groupId: '',
};

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

const PrimaryGroupForm = ({
  onSubmit,
  isSubmitting,
}: {
  onSubmit: (values: PrimaryGroupInput) => void;
  isSubmitting: boolean;
}) => {
  const [areGroupsLoading, setGroupsLoading] = useState(false);
  const [groups, setGroups] = useState<SelectOptionProps[]>([]);

  const { callService } = useCallService();

  const preSubmitUpload = async (values: PrimaryGroupInput) => {
    onSubmit({ ...values });
  };

  const getUserCommunities = async () => {
    setGroupsLoading(true);
    try {
      const { response } = await callService({
        resource: resources.getUserCommunitiesWithoutBrands(),
      });

      if (response) {
        setGroups(
          response.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setGroupsLoading(false);
    }
  };

  useEffect(() => {
    getUserCommunities();
  }, []);

  const { control, handleSubmit, watch } = useForm<PrimaryGroupInput>({
    mode: 'onChange',
  });

  const watchGroupId = watch('groupId', '');

  return (
    <div>
      <form onSubmit={handleSubmit(preSubmitUpload)}>
        <Grid container flexDirection="column">
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Choose your primary community" />
            <p>
              Our records show that you are currently a member of multiple store
              communities. Please select your primary working location, so that
              we can display accurate data for your store.
            </p>
            <Grid item xs={12}>
              <SelectFormField
                name="groupId"
                control={control}
                label="Select primary working location"
                options={groups}
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container marginTop={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={
                  areGroupsLoading || isSubmitting || watchGroupId === ''
                }
                loading={areGroupsLoading || isSubmitting}
                loadingPosition="center"
                label="Continue"
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default PrimaryGroupForm;
