import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { LiveChatWidget } from '@livechat/widget-react';
//import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

import themes from 'themes';
import 'index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import createEmotionCache from 'utils/createEmotionCache';

import { AppProvider } from 'providers/AppProvider';
import AuthProvider from 'providers/AuthProvider/AuthProvider';
import DialogProvider from 'providers/DialogProvider/DialogProvider';
import RightDrawerProvider from 'providers/RightDrawerProvider/RightDrawerProvider';
import BusinessUnitProvider from 'providers/BusinessUnitProvider/BusinessUnitProvider';

import Routes from 'routes';
import KpiProvider from 'providers/KpiProvider/KpiProvider';
import PendingMembersProvider from 'providers/PendingMembersProvider/PendingMembersProvider';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useEffect } from 'react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function App() {
  /*const { authState } = useAuthState();
  const { setPathPageView, setIdentity } = useTrackingCode();

  useEffect(() => {
    if (authState.authenticated && authState.user) {
      setIdentity(authState.user.email);
    }
  }, [authState.authenticated, authState.user, setIdentity]);

  setPathPageView(window.location.pathname);*/

  return (
    <AppProvider>
      <CacheProvider value={clientSideEmotionCache}>
        <ThemeProvider theme={themes()}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <AuthProvider>
              <DialogProvider>
                <RightDrawerProvider>
                  <BusinessUnitProvider>
                    <KpiProvider>
                      <PendingMembersProvider>
                        <Routes />
                      </PendingMembersProvider>
                    </KpiProvider>
                  </BusinessUnitProvider>
                </RightDrawerProvider>
              </DialogProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
      <LiveChatWidget license="13069899" />
    </AppProvider>
  );
}

export default App;
