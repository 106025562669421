import { SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';

import useCallService from 'hooks/useCallService';

import { BundleIcon, EditIcon } from 'material-icons';

import PageContainer from 'components/PageContainer';
import { Grid } from 'components/atomic';
import { TabPanel, a11yProps } from 'components/tabPanel';

import httpRoutes from 'utils/httpRoutes';
import { EditBundle } from '../dialogs';
import Button from 'components/buttons/Button';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import BundleCourses from '../partials/BundleCourses';
import BundleCommunities from '../partials/BundleCommunities';
import {useAuthState} from '../../../providers/AuthProvider/hooks/useAuthState';

interface BundleDetails {
  id: string;
  name: string;
  code: string;
  courseCount: number;
  groupCount: number;
  archived: boolean;
}

const BundleDetailPage = () => {
  const { bundleId = '' } = useParams();
  const { callService } = useCallService();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialogDispatcher();
  const {
    authState: { user, isAdmin },
  } = useAuthState();

  const [bundle, setBundle] = useState<BundleDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);

  const fetchBundle = async () => {
    setIsLoading(true);
    const { response } = await callService({
      resource: httpRoutes.bundles.getOne(bundleId),
    });

    if (response) {
      setBundle(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBundle();
  }, [bundleId]);

  const showEditDialog = () => {
    if (!bundle) {
      return;
    }
    showDialog({
      content: <EditBundle onSuccess={fetchBundle} defaultValues={bundle} />,
      size: 'sm',
    });
  };

  const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (!bundle) {
    return <div>Bundle not found</div>;
  }

  return (
    <PageContainer
      title={`${bundle.name} Bundle`}
      icon={<BundleIcon sx={{ fontSize: '24px' }} />}
      pageAction={
      isAdmin || user.managerOrganizationId &&
        <Button
          label="Edit Bundle"
          onClick={showEditDialog}
          startIcon={<EditIcon />}
        />
      }
    >
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="group tab"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Courses" {...a11yProps(0)} />
              <Tab label="Communities" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {!isLoading && (
              <BundleCourses bundleId={bundleId} bundleName={bundle.name} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {!isLoading && (
              <BundleCommunities bundleId={bundleId} bundleName={bundle.name} />
            )}
          </TabPanel>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default BundleDetailPage;
