import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import {
  PasswordFormField,
} from 'components/formFields';

import { useCallService } from 'hooks';

import userResources from 'features/user/resources';

export interface ISecurityFormInputs {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const INITIAL_SECURITY_FORM_STATE: ISecurityFormInputs = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const Security = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const { callService } = useCallService();

  const onSubmit = async (values: ISecurityFormInputs) => {
    const { response } = await callService({
      resource: userResources.changeUserPassword(values),
      successMessage: 'Password successfully changed!',
    });

    if (response) {
      reset(INITIAL_SECURITY_FORM_STATE);
      onSuccess();
    }
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string()
      .min(5, 'Password must be at least 5 digits')
      .required(''),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'New passwords must match')
      .required('New password must be confirmed'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ISecurityFormInputs>({
    mode: 'onBlur',
    defaultValues: INITIAL_SECURITY_FORM_STATE,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container flexDirection="column" sx={{ p: 2 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          Security
        </Typography>
        <Typography variant="caption" sx={{ pb: 1 }}>
          To change your password, please enter your current and new password
          below.
        </Typography>
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          columnSpacing={1}
        >
          <Grid item xs={12} md={12}>
            <PasswordFormField
              name="currentPassword"
              control={control}
              label="Current Password*"
              errors={errors.currentPassword}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordFormField
              name="newPassword"
              control={control}
              label="New Password*"
              errors={errors.newPassword}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordFormField
              name="newPasswordConfirmation"
              control={control}
              label="Confirm New Password*"
              errors={errors.newPasswordConfirmation}
              margin="dense"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" columnSpacing={1}>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          <SaveOutlinedIcon sx={{ mr: 1 }} /> Save
        </Button>
      </Grid>
    </form>
  );
};

export default Security;
