import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2a9 9 0 017.032 14.617l3.675 3.676a1 1 0 01-1.32 1.497l-.094-.083-3.676-3.675A9 9 0 1111 2zm0 2a7 7 0 100 14 7 7 0 000-14z"
      fill="#56C596"
    />
  </g>
);
