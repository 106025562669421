import React from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { SelectOption } from 'components/filters/filterTypes';

const GroupSelectField = ({
  groupId,
  setGroupId,
  disabled,
  options,
  label,
  width,
}: {
  groupId: string;
  setGroupId: (groupId: string) => void;
  disabled?: boolean;
  options: SelectOption[];
  label: string;
  width?: string;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setGroupId(event.target.value as string);
  };

  return (
    <Box sx={{ width: width ? width : '150px' }}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            '&.Mui-focused': { color: '#56C596' },
          }}
        >
          {label}
        </InputLabel>
        <Select
          sx={{
            textAlign: 'center',
            background: 'white',
            borderRadius: '30px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#56C596',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#56C596',
            },
          }}
          value={groupId}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GroupSelectField;
