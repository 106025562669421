import React from 'react';
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface GridProps extends MuiGridProps {
  dataId?: string;
  sx?: SxProps<Theme>;
}

function Grid({ dataId, sx, ...props }: GridProps) {
  return <MuiGrid data-id={dataId} sx={sx} {...props}></MuiGrid>;
}

export default Grid;
