import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { useCallService, useDebouncedEffect } from 'hooks';

import Button from 'components/buttons/Button';
import LoadingButton from 'components/buttons/LoadingButton';

import { DeleteIcon, SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

export type CourseBundleFormInput = {
  bundleId: string;
  coursesToAdd?: string[];
  coursesToDelete?: string[];
};

const CourseBundleForm = ({
  bundleId,
  bundleName,
  courses,
  loading,
  onSubmit,
}: {
  bundleId: string;
  bundleName: string;
  courses: { id: string; name: string }[];
  loading: boolean;
  onSubmit: (values: CourseBundleFormInput) => void;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const [currentCourses, setCurrentCourses] = useState<
    { id: string; name: string }[]
  >(courses || []);

  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const presubmitFormat = (values: CourseBundleFormInput) => {
    const currentCoursesIds = currentCourses.map((course) => course.id);
    const coursesIds = courses.map((course) => course.id);

    const coursesToAdd = currentCoursesIds.filter(
      (course) => !coursesIds.includes(course)
    );

    const coursesToDelete = coursesIds?.filter(
      (course) => !currentCoursesIds.includes(course)
    );

    values.coursesToAdd = coursesToAdd;
    values.coursesToDelete = coursesToDelete;

    return onSubmit(values);
  };

  const addCourse = (course: { id: string; name: string }) => {
    const updatedCourses = [...currentCourses];

    updatedCourses.push(course);
    setCurrentCourses(updatedCourses);
  };

  const removeCourse = (id: string) => {
    const updatedCourses = currentCourses.filter((course) => course.id !== id);

    setCurrentCourses(updatedCourses);
  };

  const getCourses = async () => {
    try {
      setOptionsLoading(true);
      const courseListIds = currentCourses.map((x) => x.id);

      const { response } = await callService({
        resource: httpRoutes.courseManagement.search(inputValue, courseListIds),
      });

      if (response) {
        setOptions(
          response.map((item: { id: string; name: string }) => {
            return { id: item.id, label: item.name };
          })
        );
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, [currentCourses]);

  useDebouncedEffect(getCourses, 200, [inputValue]);

  const validationSchema = Yup.object().shape({
    bundleId: Yup.string().required(),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CourseBundleFormInput>({
    mode: 'onChange',
    defaultValues: {
      bundleId: bundleId,
      coursesToAdd: [],
      coursesToDelete: [],
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(presubmitFormat)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Manage {bundleName} Bundle Courses
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Add/Remove Courses" />
            <Grid item xs={12}>
              <Autocomplete
                id="search"
                freeSolo
                fullWidth
                selectOnFocus
                loading={areOptionsLoading}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue, reason) => {
                  if (reason === 'input') {
                    setInputValue(newInputValue);
                  }
                  if (reason === 'reset') {
                    setInputValue('');
                  }
                }}
                onChange={(_event, value) => {
                  if (value) {
                    const newCourse = {
                      name: value.label,
                      id: value.id,
                    };

                    addCourse(newCourse);
                  }
                }}
                options={options}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search courses..."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <>
                          {areOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box
              sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
              mt={2}
            >
              {currentCourses.length > 0 && (
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#f4f4f4',
                      '& th:nth-of-type(1)': {
                        borderRadius: '1em 0 0 0',
                      },
                      '& th:nth-of-type(7)': {
                        borderRadius: '0 1em 0 0',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="80%"
                      >
                        Course Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& tr:nth-of-type(even)': {
                        backgroundColor: '#f4f4f4',
                      },
                      '& tr:last-of-type': {
                        '& td:nth-of-type(1)': {
                          borderRadius: '0 0 0 1em',
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(2)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(3)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(4)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(5)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(6)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(7)': {
                          borderRadius: '0 0 1em 0',
                          borderBottom: '0px',
                        },
                      },
                      '& tr:first-of-type': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    {currentCourses.map((course) => (
                      <TableRow key={course.id}>
                        <TableCell
                          sx={{
                            padding: matchDownSM
                              ? '0px 0px 0px 16px'
                              : '0px 16px',
                            fontSize: matchDownSM ? '10px' : '14px',
                          }}
                          size="small"
                          width="80%"
                        >
                          {course.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeCourse(course.id);
                            }}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog} label="Cancel" />
          <LoadingButton
            label="Save"
            type="submit"
            variant="contained"
            disabled={isSubmitting || loading}
            loading={isSubmitting || loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          />
        </Grid>
      </form>
    </div>
  );
};

export default CourseBundleForm;
