import React from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { SxProps } from '@mui/system';

interface CheckboxFormFieldProps {
  name: string;
  label: string | React.ReactElement;
  control: any;
  errors?: any;
  size?: 'small' | 'medium';
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
  checkedIcon?: React.ReactElement;
  icon?: React.ReactElement;
  disabled?: boolean;
  sx?: SxProps;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const CheckboxFormField = ({
  name,
  label,
  control,
  errors,
  size = 'medium',
  labelPlacement = 'end',
  checkedIcon = <CheckBoxIcon />,
  icon = <CheckBoxOutlineBlankIcon />,
  disabled = false,
  sx,
  required,
  onChange,
}: CheckboxFormFieldProps) => (
  <FormControl error={Boolean(errors)}>
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
      sx={{ alignItems: 'center', ...sx }}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const { onChange: fieldOnChange, ...rest } = field;
            return (
              <Checkbox
                {...rest}
                onChange={(e, checked) => {
                  if (onChange) {
                    onChange(e);
                  }
                  fieldOnChange(e, checked);
                }}
                sx={sx}
                checked={Boolean(field.value)}
                checkedIcon={checkedIcon}
                icon={icon}
                size={size}
                disabled={disabled}
                required={required}
              />
            );
          }}
        />
      }
    />
  </FormControl>
);

export default CheckboxFormField;
