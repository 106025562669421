import { Fragment, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { Grid, Box } from '@mui/material';

import PageContainer from 'components/PageContainer';
import LoadingButton from 'components/buttons/LoadingButton';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { useSnackbar } from 'notistack';

const NpmLogs = () => {
  const [loading, setLoading] = useState(false);
  const [apiLogFile, setApiLogFile] = useState<any>(null);
  const [apiLogFiles, setApiLogFiles] = useState<any>([]);
  const [posApiLogFile, setPosApiLogFile] = useState<any>(null);
  const [posApiLogFiles, setPosApiLogFiles] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  const { callService } = useCallService();

  const getApiLogFiles = async () => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.api.getLogFiles(),
      });
      setApiLogFiles(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getFileContentApi = async (fileName: string) => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.api.getFileContent(fileName),
      });
      setApiLogFile(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getNewestLogFileContentApi = async () => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.api.getNewestLogFileContent(),
      });
      setApiLogFile(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getPosApiLogFiles = async () => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.posapi.getLogFiles(),
      });
      setPosApiLogFiles(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getFileContentPosApi = async (fileName: string) => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.posapi.getFileContent(fileName),
      });
      setPosApiLogFile(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getNewestLogFileContentPosApi = async () => {
    setLoading(true);
    try {
      const result = await callService({
        resource: httpRoutes.npmlogs.posapi.getNewestLogFileContent(),
      });
      setPosApiLogFile(result.response);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApiLogFiles();
    getPosApiLogFiles();
  }, []);

  return (
    <PageContainer title="Npm Logs">
      <Grid container mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Api
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {apiLogFiles &&
            apiLogFiles.map((apiLogFile: string) => {
              return (
                <Box key={'API_LOG_FILE_' + apiLogFile}>
                  <LoadingButton
                    onClick={() => getFileContentApi(apiLogFile)}
                    label={apiLogFile}
                    variant="contained"
                    loading={loading}
                  />
                </Box>
              );
            })}
          {apiLogFile && (
            <Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {apiLogFile.fileDirectory}
              </Box>
              <textarea
                value={apiLogFile.fileContent}
                readOnly={true}
                style={{
                  width: '100%',
                  height: '150px',
                  boxSizing: 'border-box',
                  padding: '8px',
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid container mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Pos Api
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {posApiLogFiles &&
            posApiLogFiles.map((posApiLogFile: string) => {
              return (
                <Box key={'POS_LOG_FILE_' + posApiLogFile}>
                  <LoadingButton
                    onClick={() => getFileContentPosApi(posApiLogFile)}
                    label={posApiLogFile}
                    variant="contained"
                    loading={loading}
                  />
                </Box>
              );
            })}
          {posApiLogFile && (
            <Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {posApiLogFile.fileDirectory}
              </Box>
              <textarea
                value={posApiLogFile.fileContent}
                readOnly={true}
                style={{
                  width: '100%',
                  height: '150px',
                  boxSizing: 'border-box',
                  padding: '8px',
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default NpmLogs;
