import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Box, Grid } from '@mui/material';

import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';
import useCallService from 'hooks/useCallService';

import httpRoutes from 'utils/httpRoutes';
import { validateAge } from 'utils/date';

import EmailFormField from 'components/formFields/EmailFormField2';
import TextFormField from 'components/formFields/TextFormField2';
import NumberFormField from 'components/formFields/NumberFormField2';
import BirthdayFormField from 'components/formFields/BirthdayFormField2';
import PasswordFormField from 'components/formFields/PasswordFormField2';
import AsyncSelectFormField from 'components/formFields/AsyncSelectFormField2';
import { CheckboxFormField } from 'components/formFields';
import Button from 'components/buttons/Button';

interface IRegisterFormInputs {
  firstName: string;
  lastName: string;
  postal: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndConditions: boolean;
  groupId: string;
  birthDate: string;
}

const SignUp = ({
  redirectUrl,
  handleBackToLogin,
}: {
  redirectUrl?: string;
  handleBackToLogin?: () => void;
}) => {
  const { callService } = useCallService();
  const router = useNavigate();
  const { login } = useAuthDispatcher();
  const navigate = useNavigate();

  const onSubmit = async (values: IRegisterFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.onBoarding.signUp(values),
      successMessage: 'Success! You are now a member of Seed Talent!',
    });

    if (response) {
      login(response);
      router(`/${redirectUrl || ''}`, { replace: true });
    }
  };

  const getGroups = async (value: string) => {
    const { response } = await callService({
      resource: httpRoutes.authentication.searchGroups(value),
    });

    if (response) {
      return response.map((item: { id: string; name: string }) => {
        return { id: item.id, label: item.name };
      });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    postal: Yup.string()
      .matches(
        /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/,
        'Please enter a valid US or Canadian postal code'
      )
      .required('Postal code is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
    birthDate: Yup.string()
      .test(
        'test-birthDate',
        'You must be older than 21 years old',
        function (value) {
          if (!value) {
            return true;
          }

          const [month, day, year] = value.split('/');

          if (month.trim() && day.trim() && year.trim()) {
            const date = new Date(value);
            return validateAge(date);
          }
          return true;
        }
      )
      .required('Birth date is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password must be confirmed'),
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'The terms and conditions must be accepted.')
      .required('The terms and conditions must be accepted.'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<IRegisterFormInputs>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      postal: '',
      passwordConfirmation: '',
      groupId: '',
      termsAndConditions: false,
      birthDate: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const onGoToLogin = () => {
    if (handleBackToLogin) {
      return handleBackToLogin();
    }
    navigate('/login');
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '130%',
          }}
        >
          Sign up
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px%',
          }}
        >
          Enter your credentials to continue
        </Box>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="firstName"
            control={control}
            placeholder="First Name"
            errors={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="lastName"
            control={control}
            placeholder="Last Name"
            errors={errors.lastName}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <TextFormField
            name="postal"
            control={control}
            placeholder="Postal code"
            errors={errors.postal}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BirthdayFormField
            name="birthDate"
            control={control}
            placeholder="Birthday"
            errors={errors.birthDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <EmailFormField
          name="email"
          control={control}
          placeholder="Email"
          errors={errors.email}
        />
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <PasswordFormField
            name="password"
            control={control}
            placeholder="Password"
            errors={errors.password}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordFormField
            name="passwordConfirmation"
            control={control}
            placeholder="Confirm Password"
            errors={errors.passwordConfirmation}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} mb={3}>
          <AsyncSelectFormField
            name="groupId"
            control={control}
            placeholder="Search Employers"
            getOptions={getGroups}
          />
        </Grid>
        <Grid mb={3} container>
          <Grid xs={6} item>
            <CheckboxFormField
              sx={{
                '.MuiFormControlLabel-label': {
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                },
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
              name="termsAndConditions"
              size="small"
              control={control}
              label="Agree to Terms & Conditions."
              errors={errors.termsAndConditions}
            />
          </Grid>
          <Grid
            xs={6}
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '150%',
                color: '#56C596',
                cursor: 'pointer',
              }}
              onClick={onGoToLogin}
            >
              Already have an account?
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            label="Sign up"
            disabled={isSubmitting || !isValid}
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '20px',
              lineHeight: '24px',
              background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUp;
