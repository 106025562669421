import { Box } from '@mui/material';

const EstimatedHoursChip = ({
  estimatedHours,
}: {
  estimatedHours?: number;
}) => {
  const _estimatedHoursInMinutes = estimatedHours ? estimatedHours * 60 : 0;
  return (
    <Box
      sx={{
        height: '20px',
        borderRadius: '30px',
        border: '1px solid #525353',
        display: 'flex',
        alignItems: 'center',
        px: 1,
      }}
    >
      <p
        style={{
          margin: 0,
          color: '#525353',
          fontFamily: 'Raleway',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '10px',
          lineHeight: '20px',
        }}
      >
        {`${_estimatedHoursInMinutes} min`}
      </p>
    </Box>
  );
};

export default EstimatedHoursChip;
