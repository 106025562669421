import React from 'react';

import {Box, Skeleton} from '@mui/material';

const StatsBarLoading = () => (
    <Box sx={{ width: '100%'}}>
        <Skeleton variant="rectangular" height="70px" width="100%" />
    </Box>
);

export default StatsBarLoading;
