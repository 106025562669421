/* eslint-disable react/jsx-key */
import { useState, memo } from 'react';

import {
  CircularProgress,
  TextField,
  Box,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import Icon from 'icons';
import { useDebouncedEffect } from 'hooks';
import { FilterProps } from '../filterTypes';

const AsyncSelectFormFilter = ({
  id,
  label,
  getOptions,
  onChange,
}: FilterProps) => {
  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async () => {
    if (getOptions) {
      if (inputValue) {
        setOptionsLoading(true);

        const response = await getOptions(inputValue);

        setOptions(response);

        setOptionsLoading(false);
      }
    }
  };

  useDebouncedEffect(fetchOptions, 200, [inputValue]);

  return (
    <Box sx={{ minWidth: '252px'}}>
      <Autocomplete
        id={id}
        fullWidth
        selectOnFocus
        size="small"
        loading={areOptionsLoading}
        clearIcon={<CircularProgress color="inherit" size={20} />}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
          if (newInputValue === '') {
            onChange('');
          }
        }}
        onChange={(_event, value: any) => {
          if (value) {
            onChange(value.id);
          }
        }}
        options={options}
        getOptionLabel={(option: any) => option.label || option}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={label}
            sx={{
              background: 'white',
              width: '100%',
              borderRadius: '30px',
              '& .MuiOutlinedInput-root': {
                height: '40.13px',
                '& fieldset': {
                  borderRadius: '30px',
                },
                '&:hover fieldset': {
                  borderColor: '#56C596',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#56C596',
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name="lens" fill="#56C596" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  {areOptionsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default memo(AsyncSelectFormFilter);
