import { MouseEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { AddIcon, BundleIcon, DeleteIcon } from 'material-icons';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import Button from 'components/buttons/Button';
import PageContainer from 'components/PageContainer';
import IconButton from 'components/buttons/IconButton';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Create from '../dialogs/CreateBundle';
import Delete from '../dialogs/DeleteBundle';

import httpRoutes from 'utils/httpRoutes';

import { BundleFormInput } from '../forms/BundleForm';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Bundle Name',
  },
];

const BundlesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const onSuccess = async (bundleId?: string) => {
    await getBundles();

    if (bundleId) {
      navigate(`/bundles/${bundleId}`);
    }
    hideDialog();
  };

  const showCreateDialog = () => {
    showDialog({
      content: <Create onSuccess={onSuccess} />,
      size: 'sm',
    });
  };

  const showDeleteDialog = (values: BundleFormInput) => {
    if (!values.name || !values.id) {
      return;
    }

    showDialog({
      content: (
        <Delete
          onSuccess={onSuccess}
          id={values.id}
          name={values.name}
          hideDialog={hideDialog}
        />
      ),
      size: 'sm',
    });
  };

  useEffect(() => {
    getBundles();
  }, [filters, state]);

  const getBundles = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.bundles.getMany({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.6,
    },
    {
      headerName: 'Code',
      field: 'code',
      sortable: true,
      filterable: false,
    },
    {
      headerName: 'In Use By',
      field: 'groupCount',
      sortable: true,
      filterable: false,
      ColumnComponent: (params: any) => {
        return (
          <Box>
            {params.row.groupCount}{' '}
            {Number(params.row.groupCount) === 1 ? 'Community' : 'Communities'}
          </Box>
        );
      },
    },
    {
      headerName: 'Total Courses',
      field: 'courseCount',
      sortable: true,
      filterable: false,
    },
    {
      headerName: 'Archived',
      field: 'archived',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'boolean',
      flex: 0.5,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {!params.row.archived && (
              <IconButton
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  showDeleteDialog(params.row);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <PageContainer
      title="Bundle Management"
      icon={<BundleIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Create Bundle"
          onClick={showCreateDialog}
          startIcon={<AddIcon />}
        />
      }
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        totalRows={gridData.totalRows}
        filters={filterModel}
        handleFilterChange={setFilters}
        loading={loading}
        onRowClick={(params) => {
          navigate(`/bundles/${params.id}`);
        }}
      />
    </PageContainer>
  );
};

const Bundles = () => (
  <ControlledDataGrid>
    <BundlesGrid />
  </ControlledDataGrid>
);

export default Bundles;
