import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import PageContainer from 'components/PageContainer';

import EmployeesPerformanceChart from '../components/employees/EmployeesPerformanceChart';
import EmployeeTableView from '../components/employees/EmployeeTableView';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import { kpiLabel } from '../utils';


const EmployeeSummaryView = () => {
  const params = useParams();
  const { kpiName, groupId } = params;

  // const navigate = useNavigate();
  // const query = useQuery();
  const { callService } = useCallService();

  // const [interval, setInterval] = useState<string>(
  //   query.get('interval') || 'latest'
  // );

  const [group, setGroup] = useState<any>(null);
  const [groupLoading, setGroupLoading] = useState(true);


  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   params.set('interval', interval);
  //   navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  // }, [interval, navigate, location]);

  const getGroup = async () => {
    if (groupId) {
      try {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(groupId),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  return (
    <PageContainer>
      <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
        {groupLoading ? (
          <Skeleton height="40px" width="245px" variant="rounded" />
        ) : (
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '25px' : '32px',
              lineHeight: '130%',
              color: '#162738',
            }}
          >
            {group?.name}
          </Box>
        )}
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#162738',
          }}
        >
          {kpiLabel(kpiName)}
        </Box>
      </Box>
      {/*<SelectIntervalFilter value={latest} setValue={setInterval} />*/}
      <EmployeesPerformanceChart interval="latest" />
      <EmployeeTableView interval="latest" />
    </PageContainer>
  );
};

export default EmployeeSummaryView;
