import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { formatPercentageValue } from 'utils/common';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import Overlay from 'features/courses/components/Overlay';
import {
  calculateDateRange,
  DateConfig,
  formatDateUSA,
  isAValidDateFilter,
} from '../../../utils/date';
import { REACT_APP_ENABLE_TIME_BASE_REPORT } from 'config/config';
import { addDateFilterOptions, changeColumnTitles, getDates } from './utils';
import DateFilterMessages from './DateFilterMessages';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Course Name',
    field: 'courseName',
    sortable: true,
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Total Learners',
    field: 'totalLearners',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Total Started',
    field: 'totalStarted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Total Completed',
    field: 'totalCompleted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    align: 'right',
    flex: 0.5,
  },
  {
    headerName: 'Percent Completed',
    field: 'percentageCompleted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      // TODO: investigate why percentageCompleted is over 100%
      return (
        <>
          {params.row.percentageCompleted
            ? params.row.percentageCompleted >= 100
              ? '100%'
              : formatPercentageValue(
                  parseFloat(params.row.percentageCompleted),
                  0
                )
            : '-'}
        </>
      );
    },
  },
  {
    headerName: 'Average Score',
    field: 'averageScore',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      // TODO: investigate why there are null averageScore values
      const { averageScore } = params.row;
      if (!averageScore) {
        return <>-</>;
      }

      return <>{formatPercentageValue(parseFloat(params.row.averageScore))}</>;
    },
  },
];

if (REACT_APP_ENABLE_TIME_BASE_REPORT) {
  columns.push({
    headerName: 'Threshold Met At',
    field: 'thresholdMetAt',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const { thresholdMetAt } = params.row;
      if (!thresholdMetAt) {
        return <>-</>;
      }

      return <>{formatDateUSA(params.row.thresholdMetAt)}</>;
    },
  });
}

const loadingState = {
  categories: true,
  courses: true,
  exporting: false,
};

let engagementReportRequestController: AbortController | undefined;

const CreatorCourseReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [categories, setCategories] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const restarLoading = () => {
    setLoading((prevState: any) => ({
      ...prevState,
      categories: false,
      courses: false,
      exporting: false,
    }));
  };

  const acceptedDateRangeFilter = calculateDateRange(
    '-3 months' as DateConfig,
    '-1 day' as DateConfig
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getCourses();
  }, [filters.get('courseCategoryId'), state]);

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    const selectedStartDate = filters.get('startDate');
    const selectedEndDate = filters.get('endDate');
    if (!selectedStartDate && selectedEndDate) {
      restarLoading();
      return;
    }

    if (selectedStartDate && !selectedEndDate) {
      restarLoading();
      return;
    }

    const validDateFilter = isAValidDateFilter(
      acceptedDateRangeFilter,
      selectedStartDate,
      selectedEndDate
    );
    if (selectedStartDate && selectedEndDate && !validDateFilter) {
      restarLoading();
      return;
    }

    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedStartDate > selectedEndDate
    ) {
      restarLoading();
      return;
    }

    if (engagementReportRequestController)
      engagementReportRequestController.abort();

    try {
      engagementReportRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.reporting.course.getCreatorCourseReport({
          params: { ...state },
          filters: filters,
          controller: engagementReportRequestController,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });

        changeColumnTitles(columns, selectedStartDate, selectedEndDate);
        engagementReportRequestController = undefined;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const getCourseCategories = async () => {
    setLoading((prevState) => ({ ...prevState, categories: true }));

    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    if (response) {
      const _categoriesList: SelectOption[] = [
        {
          label: 'All Categories',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _categoriesList.push({ label: item.name, value: item.id });
      });

      setCategories(_categoriesList);

      setLoading((prevState) => ({ ...prevState, categories: false }));
    }
  };

  const exportCourses = async () => {
    try {
      const selectedStartDate = filters.get('startDate');
      const selectedEndDate = filters.get('endDate');
      if (!selectedStartDate && selectedEndDate) {
        enqueueSnackbar('Please select a start date to filter by date range.', {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        restarLoading();
        return;
      }

      if (selectedStartDate && !selectedEndDate) {
        enqueueSnackbar('Please select an end date to filter by date range.', {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        restarLoading();
        return;
      }

      const validDateFilter = isAValidDateFilter(
        acceptedDateRangeFilter,
        selectedStartDate,
        selectedEndDate
      );

      if (selectedStartDate && selectedEndDate && !validDateFilter) {
        enqueueSnackbar(
          `The selected date must be between ${formatDateUSA(
            acceptedDateRangeFilter.calculatedMinDate
          )} and ${formatDateUSA(acceptedDateRangeFilter.calculatedMaxDate)}`,
          {
            variant: 'warning',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );

        restarLoading();
        return;
      }

      if (
        selectedStartDate &&
        selectedEndDate &&
        selectedStartDate > selectedEndDate
      ) {
        enqueueSnackbar('End date must be greater than start date.', {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        restarLoading();
        return;
      }

      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.course.exportCreatorCourseReport({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const prefix = selectedStartDate && selectedEndDate ? 'New' : 'Total';
        const formatCSVResults = (results: any[]) => {
          return results.map((result: any) => {
            return {
              'Course Name': result['Course Name'],
              [`${prefix} Learners`]: result[`${prefix} Learners`],
              [`${prefix} Started`]: result[`${prefix} Started`],
              [`${prefix} Completed`]: result[`${prefix} Completed`],
              'Average Score': result['Average Score'],
              'Percentage Completed': result['Percentage Completed'],
            };
          });
        };
        const csv = Papa.unparse(formatCSVResults(response));
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });
        fileSaver.saveAs(csvData, 'Course Engagement Report.csv');

        const formatResults = (results: any[]) => {
          return results.map((result: any) => {
            return {
              courseId: result.courseId,
              courseName: result['Course Name'],
              totalLearners: result[`${prefix} Learners`],
              totalStarted: result[`${prefix} Started`],
              totalCompleted: result[`${prefix} Completed`],
              averageScore: result['Average Score'],
              percentageCompleted: result['Percentage Completed'],
            };
          });
        };

        setGridData({
          rows: formatResults(response),
          totalRows: response.length,
        });
      }
    } catch (e) {
      console.log('Error');
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
      props: {
        infoMsg:
          'Press ENTER or click "Apply Filters" to search by course name',
        onClearValue: getCourses,
      },
    },
    {
      id: 'courseCategoryId',
      type: 'select',
      label: 'Course Category',
      options: categories,
    },
  ];

  addDateFilterOptions(filterModel, '-3 months', '-1 day');
  const [selectedStartDate, selectedEndDate] = getDates(filters);

  return (
    <PageContainer
      title="Engagement Report"
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <Overlay
        open={loading.courses || loading.categories || loading.exporting}
        zIndex={9999999}
      />
      <Box
        onKeyUpCapture={(e: any) => {
          if (e.keyCode === 13) {
            getCourses();
          }
        }}
      >
        {REACT_APP_ENABLE_TIME_BASE_REPORT && (
          <DateFilterMessages
            acceptedDateRangeFilter={acceptedDateRangeFilter}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
          />
        )}

        <DataGrid
          sync={REACT_APP_ENABLE_TIME_BASE_REPORT}
          rows={gridData.rows}
          totalRows={gridData.totalRows}
          columns={columns}
          handleFilterChange={setFilters}
          loading={loading.courses}
          filters={filterModel}
          handleExport={exportCourses}
          filtersButtonCallback={getCourses}
          onRowClick={(params) => {
            let url = `/reports/engagement-report/${
              params.id || params.courseId
            }`;
            if (selectedStartDate) {
              url += `?startDate=${
                selectedStartDate.toISOString().split('T')[0]
              }`;
              if (selectedEndDate) {
                url += `&endDate=${
                  selectedEndDate.toISOString().split('T')[0]
                }`;
              }
            }
            navigate(url);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const CreatorCourseReport = () => (
  <ControlledDataGrid>
    <CreatorCourseReportGrid />
  </ControlledDataGrid>
);

export default CreatorCourseReport;
