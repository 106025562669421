import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Button, CircularProgress, Grid, Typography } from '@mui/material';

const ErrorFallback = () => {
  return (
    <Grid container justifyContent="center" flexDirection="row" spacing={2}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2">
          Oops, something went wrong :({' '}
        </Typography>
      </Grid>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </Grid>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div>
          <CircularProgress size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};
