import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.707 11.293a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414L7.586 13H4a1 1 0 110-2h3.586l-.293-.293a1 1 0 011.414-1.414l2 2zM13.293 11.293a1 1 0 000 1.414l2 2a1 1 0 001.414-1.414L16.414 13H20a1 1 0 100-2h-3.586l.293-.293a1 1 0 00-1.414-1.414l-2 2z"
      fill="#fff"
    />
  </g>
);
