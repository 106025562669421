import { Grid, Box } from '@mui/material';

import PageContainer from 'components/PageContainer';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { OrganizationIcon } from 'material-icons';
import AssignmentList from './AssignmentList';

const AssignmentPublicView = () => {
  const { authState } = useAuthState();

  return (
    <PageContainer
      title={'Course Assignment'}
      icon={<OrganizationIcon sx={{ fontSize: '24px' }} />}
    >
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <AssignmentList
              organizationId={authState.user.managerOrganizationId}
              isOrganizationManager={true}
            />
          </Box>
        </Box>
      </Grid>
    </PageContainer>
  );
};

export default AssignmentPublicView;
