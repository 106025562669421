import { useState, useEffect } from 'react';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import PageContainer from 'components/PageContainer';

import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { RewardIcon, ContentCopyIcon } from 'material-icons';

import resources from '../../user/resources';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { Button, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import IconButton from 'components/buttons/IconButton';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'rewardName',
    type: 'text',
    label: 'Reward Name',
  },
];

const UserRewardsGrid = () => {
  const { callService } = useCallService();
  const { state } = useDataGridState();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    const getUserRewards = async () => {
      setLoading(true);
      try {
        const { response } = await callService({
          resource: resources.getUserRewards({
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows || 0,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getUserRewards();
  }, [filters, state]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'rewardName',
      sortable: true,
      filterable: false,
      flex: 0.15,
    },
    {
      headerName: 'Course',
      field: 'courseName',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Code',
      field: 'rewardCode',
      sortable: false,
      filterable: false,
      flex: 0.2,
      ColumnComponent: (param: any) => {
        const copyToClipBoard = () => {
          navigator.clipboard.writeText(param.row.rewardCode);
          enqueueSnackbar('Copied reward code!', {
            variant: 'default',
            autoHideDuration: 2000,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
          });
        };

        return (
          <Button
            size="small"
            variant="text"
            onClick={copyToClipBoard}
            sx={{ justifyContent: 'flex-start' }}
          >
            <ContentCopyIcon fontSize="small" />
            {param.row.rewardCode}
          </Button>
        );
      },
    },

    {
      headerName: 'Url',
      field: 'rewardUrl',
      sortable: false,
      filterable: false,
      flex: 0.4,
      ColumnComponent: (params: any) => {
        const _url = params.row.rewardUrl;

        const openReward = () => {
          window.open(_url, '_blank');
        };

        return <Link sx={{ cursor: 'pointer' }} onClick={openReward}>{_url}</Link>;
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        tableTitle="My Rewards"
        handleFilterChange={setFilters}
        rowIdentifier="rewardCodeId"
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </>
  );
};

const UserRewards = () => (
  <ControlledDataGrid>
    <UserRewardsGrid />
  </ControlledDataGrid>
);

export default UserRewards;
