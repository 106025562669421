import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import resources from '../resources';
import { AsyncSelectFormField, CheckboxFormField } from 'components/formFields';

export type CreateOrganizationMemberFormInput = {
  organizationId: string;
  userId: string;
};

const CreateOrganizationMemberForm = ({
  organizationId,
  onSuccess,
}: {
  organizationId: string;
  onSuccess: VoidFunction;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: CreateOrganizationMemberFormInput) => {
    const { response } = await callService({
      resource: resources.organization.addMember(values),
      successMessage: 'Member added successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  const getUsers = async (value: string) => {
    const { response } = await callService({
      resource: resources.members.searchUsers(value),
    });

    if (response) {
      return response.map((item: any) => {
        if (item.firstName && item.lastName) {
          return {
            label: `${item.email} (${item.firstName} ${item.lastName})`,
            id: item.id,
          };
        }
        return { label: `${item.email}`, id: item.id };
      });
    }
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string().max(255).required('Please select a user'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateOrganizationMemberFormInput>({
    mode: 'onChange',
    defaultValues: {
      organizationId: organizationId,
      userId: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Add Organization Member
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} mt={1}>
              <AsyncSelectFormField
                name="userId"
                control={control}
                label="Search User"
                getOptions={getUsers}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default CreateOrganizationMemberForm;
