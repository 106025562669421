import { useSnackbar } from 'notistack';
import ManageCourseWrapper from 'components/courseBuilder/ManageCourseWrapper';
import { useCallService } from 'hooks';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CoassembleType from 'types/CoassembleType';
import httpRoutes from 'utils/httpRoutes';
import CourseReview from '../dialogs/CourseReview';
import PageContainer from 'components/PageContainer';
import { SuccessIcon } from 'material-icons';
import { CoassembleStatus } from 'types/courseTypes/CoassembleStatus';

const CoassembleCourse = () => {
  const {
    authState: {
      user: { id: userId },
      organization,
    },
  } = useAuthState();

  const { callService } = useCallService();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { externalCourseId } = params;

  const courseId = Number(externalCourseId);

  const [url, setUrl] = useState('');
  const [utrId, setUTRId] = useState('');
  const [courseCompleted, setCourseCompleted] = useState<boolean>(false);

  const handleCourseEvent = async (event: any) => {
    const values: CoassembleType = {
      externalCourseId: courseId,
      status: event.event,
      progress: event.data.progress,
    };

    if (values.status === CoassembleStatus.Complete) {
      setCourseCompleted(true);
    }

    await updateCourseProgress(values);
  };

  const updateCourseProgress = async (values: CoassembleType) => {
    await callService({
      resource: httpRoutes.courseBuilder.updateCourseProgress(values),
    });
  };

  const getCourseUrl = async () => {
    if (!courseId) {
      return;
    }

    const requestData: {
      courseId: number;
      userId: string;
      organizationId: string;
    } = {
      courseId: courseId,
      userId: userId,
      organizationId: organization.id,
    };

    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseUrl(requestData),
    });

    if (response) {
      const url = encodeURI(`${response}`);

      setUrl(url);
    }
  };

  const getCourseUTR = async () => {
    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseUTRId({
        externalCourseId: courseId,
        organizationId: organization.id,
      }),
    });

    if (response) {
      setUTRId(response);
    }
  };

  const onSuccess = () => {
    navigate(`/organization/${organization.id}/dashboard`);
    enqueueSnackbar('The feedback has been sent!', {
      variant: 'success',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
    });
  };

  useEffect(() => {
    getCourseUTR();
    getCourseUrl();
  }, [courseId]);

  if (url === '') {
    return <div>Loading...</div>;
  }

  return (
    <>
      {courseCompleted ? (
        <PageContainer
          title="You’re all done!"
          icon={<SuccessIcon sx={{ fontSize: '24px' }} />}
        >
          <CourseReview utrId={utrId} isModal={false} onSuccess={onSuccess} />
        </PageContainer>
      ) : (
        <ManageCourseWrapper
          coassembleURL={url}
          handleCourseEvent={handleCourseEvent}
        />
      )}
    </>
  );
};

export default CoassembleCourse;
