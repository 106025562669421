import React, { MouseEvent, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import Icon from 'icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import Tag from '../../components/Tag';
import EditOrganization from './dialogs/EditOrganization';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Organization Name',
  },
];
// set columns
const columns = (
  handleEdit: any,
  navigateToOrg: any
): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToOrg(params.row.id);
            }}
          >
            {params.row.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'ddlActivated',
      sortable: true,
      ColumnComponent: (params: any) => {
        return params.row.ddlActivated ? (
          <Tag
            label="Enrolled"
            labelColor="#14804A"
            backgroundColor="#E1FCEF"
          />
        ) : (
          <Tag
            label="Not Enrolled"
            labelColor="#5A6376"
            backgroundColor="#E9EDF5"
          />
        );
      },
    },
    {
      headerName: 'Enrolled on',
      field: 'ddlActivatedOn',
      sortable: false,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.ddlActivatedOn) {
          return (
            <>
              {new Date(params.row.ddlActivatedOn).toLocaleDateString('en-US')}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Unenrolled on',
      field: 'ddlDeactivatedOn',
      sortable: false,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.ddlDeactivatedOn) {
          return (
            <>
              {new Date(params.row.ddlDeactivatedOn).toLocaleDateString(
                'en-US'
              )}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleEdit(params);
              }}
            >
              <Icon name="edit" />
            </IconButton>
          </>
        );
      },
    },
  ];
};

let organizationRequestController: AbortController | undefined;

const OrgManagementGrid = () => {
  const navigate = useNavigate();
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const showEditDialog = (params: any) => {
    const {
      row: { id: organizationId },
    } = params;

    showDialog({
      content: (
        <EditOrganization
          organizationId={organizationId}
          onSuccess={getOrganizations}
        />
      ),
      size: 'md',
    });
  };

  const navigateToOrg = (id: string) => {
    navigate(`/admin/ddl/org-management/${id}`);
  };

  const getOrganizations = async () => {
    setLoading(true);
    if (organizationRequestController) {
      organizationRequestController.abort();
    }
    try {
      organizationRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.getOrganizationsPaginated({
          controller: organizationRequestController,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        organizationRequestController = undefined;
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, [filters, state]);

  return (
    <PageContainer title="Organization Management">
      <DataGrid
        rows={gridData.rows}
        columns={columns(showEditDialog, navigateToOrg)}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const OrgManagement = () => (
  <ControlledDataGrid>
    <OrgManagementGrid />
  </ControlledDataGrid>
);

export default OrgManagement;
