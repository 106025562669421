import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import Counter from '../../../../shared/Counter';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

type GroupStats = {
  totalLearners: number;
  learnersAtLeastCompletedOneCoursePercentage: number;
  learnersHaveNotStartedAnyCoursePercentage: number;
};

const Stats = ({ groupId }: { groupId: string }) => {
  const { callService } = useCallService();

  const [stats, setStats] = useState<GroupStats>({
    totalLearners: 0,
    learnersAtLeastCompletedOneCoursePercentage: 0,
    learnersHaveNotStartedAnyCoursePercentage: 0,
  });

  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      if (groupId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.group.getStatsByGroup(groupId),
        });

        if (response) {
          const { totalLearners, learnersAtLeastCompletedOneCourse, learnersHaveNotStartedAnyCourse} = response;

          const learnersHaveNotStartedAnyCoursePercentage = Math.round((learnersHaveNotStartedAnyCourse * 100) / Number(totalLearners)) || 0;

          const learnersAtLeastCompletedOneCoursePercentage = Math.round((learnersAtLeastCompletedOneCourse * 100) / Number(totalLearners)) || 0;

          setStats({
            totalLearners,
            learnersAtLeastCompletedOneCoursePercentage,
            learnersHaveNotStartedAnyCoursePercentage
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '1 0 auto',
          width: '307px',
        }}
      >
        {loading ? (
          <Skeleton height="94px"/>
        ) : (
          <Counter counter={stats.totalLearners} label="Learners" />
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '1 0 auto',
          width: '307px',
        }}
      >
        {loading ? (
          <Skeleton height="94px"/>
        ) : (
          <Counter
            counter={stats.learnersAtLeastCompletedOneCoursePercentage}
            label="Have completed at least one course"
            isPercentage={true}
            color="#56C596"
          />
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '1 0 auto',
          width: '307px',
        }}
      >
        {loading ? (
          <Skeleton height="94px"/>
        ) : (
          <Counter
            counter={stats.learnersHaveNotStartedAnyCoursePercentage}
            label="haven’t started any courses"
            isPercentage={true}
            color="#FF5572"
          />
        )}
      </Box>
    </Box>
  );
};

export default Stats;
