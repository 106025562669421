import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.436 5.684C3 6.54 3 7.66 3 9.9v5.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748c.856.436 1.976.436 4.216.436h5.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C21 18.46 21 17.34 21 15.1V9.9c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C17.96 3.5 16.84 3.5 14.6 3.5H9.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748zm6.271 5.109a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L12 13.086l-2.293-2.293z"
      fill="#56C596"
    />
  </g>
);
