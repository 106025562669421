import React, { useEffect, useState } from 'react';

import { Box, useMediaQuery, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useCallService } from 'hooks';

import { StarRateRoundedIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

import ResponsiveCarrousel from 'layout/NewLayout/CourseCarrousel/ResponsiveCarrousel';

const TitleComponent = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {!matchDownSM && (
        <Chip
          variant="filled"
          label="ASSIGNED"
          sx={{
            backgroundColor: '#205072',
            borderRadius: '5px',
            color: '#D6F3D5',
            fontSize: '12px',
            fontWeight: '700',
            marginBottom: '24px',
          }}
          icon={<StarRateRoundedIcon color="inherit" fontSize="small" />}
        />
      )}
      <Box
        sx={{
          color: '#205072',
          fontFamily: 'Raleway',
          fontSize: matchDownSM ? '16px' : '30px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: matchDownSM ? '16px' : '36px',
          pl: matchDownSM ? '10px' : '0px',
          marginBottom: '24px',
        }}
      >
        Time sensitive courses you need to complete
      </Box>
    </Box>
  );
};

const LearnerFlow = () => {
  const { callService } = useCallService();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [outstandingCourses, setOutstandingCourses] = useState<any[]>([]);
  const [loadingOutstandingCourses, setLoadingOutstandingCourses] =
    useState(true);

  const getOutstandingCourses = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.dashboard.getOutstandingCourses(),
      });

      if (response) {
        const _outstandingCourses = response.map((item: any) => {
          return {
            ...item,
            status:
              item.userTestResults.length > 0
                ? item.userTestResults[0].status
                : 'To-Do',
            progress:
              item.userTestResults.length > 0
                ? item.userTestResults[0].progress
                : 0,
            courseCategoryName: item.courseCategory.name,
          };
        });

        setOutstandingCourses(_outstandingCourses);
      }
    } finally {
      setLoadingOutstandingCourses(false);
    }
  };

  useEffect(() => {
    getOutstandingCourses();
  }, []);

  const style = {
    container: {
      background: 'linear-gradient(157deg, #D6F3D5 3.17%, #89CCA4 102.13%)',
      p: matchDownSM ? '10px' : '35px',
    },
  };

  return (
    <>
      {!loadingOutstandingCourses && outstandingCourses.length > 0 && (
        <Box sx={{ mt: 10 }}>
          <ResponsiveCarrousel
            titleComponent={<TitleComponent />}
            mobileTitleComponent={<TitleComponent />}
            style={style}
            title="Time sensitive courses you need to complete"
            courses={outstandingCourses}
          />
        </Box>
      )}
    </>
  );
};

export default LearnerFlow;
