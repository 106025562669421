import { useNavigate } from 'react-router-dom';

import { Box, CardMedia } from '@mui/material';

import DisplayText from 'components/DisplayText';
import { getDescriptionEllipsis } from 'utils/common';
import CourseCardButton from 'layout/NewLayout/CourseCarrousel/CourseCardButton';
import Icon from 'icons';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import {
  BlockedChip,
  RequiredChip,
  DueDateChip,
  EstimatedHoursChip,
  CourseIncentiveChip,
} from './chips';

import { formatDate } from './utils';
import GroupCourseAssignment from '../../../types/GroupCourseAssignment';
import CourseProps from 'types/courseTypes/CourseProps';
import RatingChip from './chips/RatingChip';

const mapCategoryWithIcon: Record<string, string> = {
  'Brand Training': 'brand',
  'Company Required': 'company-required',
  Compliance: 'state-required',
  'Industry Principles': 'cannabis-principles',
  'Career Principles': 'carrer-principles',
  Assessments: 'assessments',
};

const getIconName = (courseCategoryName: string) => {
  return mapCategoryWithIcon[courseCategoryName] || 'assessments';
};

const CourseCard = ({ course }: { course: CourseProps }) => {
  const {
    imageUrl,
    description,
    name,
    status,
    progress,
    estimatedHours,
    courseCategoryName = '',
    courseTypeName = '',
    canStartCourse,
    id,
    lockedTime,
    isLastRound,
    groupCourseAssignments,
    courseIncentive,
    externalCourseId,
    rating,
    //organization,
    courseSourceId,
  } = course;

  const navigate = useNavigate();

  const navigateToCourse = () => {
    if (courseSourceId === 3) {
      navigate(`/course/course-builder/${externalCourseId}`);
    } else {
      navigate(`/course/${id}`);
    }
  };

  const _imageUrl = `${REACT_APP_CLOUDFRONT_URL}/${imageUrl}`;

  const findClosestDueDate = (
    groupCourseAssignments?: GroupCourseAssignment[]
  ): Date | null => {
    const today = new Date();
    const dueDates: Date[] | undefined = groupCourseAssignments?.flatMap(
      (groupCourseAssignment) =>
        groupCourseAssignment.userAssignments.map(
          (userAssignment) => new Date(userAssignment.dueDate)
        )
    );

    if (!dueDates || dueDates.length === 0) {
      return null;
    }

    let nearestDueDate: Date = dueDates[0];
    let smallestDiff = Math.abs(today.getTime() - nearestDueDate.getTime());

    dueDates.forEach((dueDate) => {
      const diff = Math.abs(today.getTime() - dueDate.getTime());
      if (diff < smallestDiff) {
        smallestDiff = diff;
        nearestDueDate = dueDate;
      }
    });
    return nearestDueDate;
  };

  const dueDate = findClosestDueDate(groupCourseAssignments);

  return (
    <Box
      sx={{
        height: '430px',
        width: '100%',
        maxWidth: '301px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {lockedTime && (
          <BlockedChip untilDate={lockedTime} isLastRound={isLastRound} />
        )}
        <CardMedia
          component="img"
          height="144"
          sx={{
            borderRadius: '15px 15px 0 0',
            borderBottom: '1px solid #EFEFF0',
          }}
          image={_imageUrl}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          px: '20px',
          py: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mb: '6px',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Icon name={getIconName(courseCategoryName)} size="large" />
          <RequiredChip courseCategoryName={courseCategoryName} />
          {status !== 'In-Progress' && (
            <EstimatedHoursChip estimatedHours={estimatedHours} />
          )}
        </Box>

        {dueDate && (
          <Box
            sx={{
              display: 'flex',
              mb: '6px',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <DueDateChip dueDate={formatDate(dueDate)} />
          </Box>
        )}

        {courseIncentive && courseIncentive.expiresOn && (
          <Box
            sx={{
              display: 'flex',
              mb: '6px',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <CourseIncentiveChip incentive={courseIncentive} />
          </Box>
        )}

        {rating && <RatingChip rating={rating} />}

        <Box>
          <DisplayText
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            type="sh2"
            text={name}
            variant="bold"
          />
        </Box>
        <Box>
          <DisplayText
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 500,
              color: '#205072',
            }}
            type="b2"
            //text={organization?.name || ''}
            text={''}
            variant="regular"
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '6px',
            flex: '1',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', flex: '1' }}>
            <DisplayText
              type="b2"
              text={getDescriptionEllipsis(description, 60)}
              variant="regular"
            />
          </Box>
        </Box>
        <Box>
          <CourseCardButton
            courseName={name}
            canStartCourse={canStartCourse}
            handleOnClick={navigateToCourse}
            status={status}
            courseCategoryName={courseCategoryName}
            courseTypeName={courseTypeName}
            progress={progress}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CourseCard;
