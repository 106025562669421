import React, { useState } from 'react';

import { Controller, FieldError } from 'react-hook-form';

import { Button, FormControl, FormHelperText, Typography } from '@mui/material';

import { UploadFileIcon } from 'material-icons';

interface FileFormFieldProps {
  name: string;
  label: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
}

const FileFormField = ({
  name,
  label,
  control,
  errors,
  margin = 'normal',
}: FileFormFieldProps) => {
  const [value, setValue] = useState('');

  function getFilename(fullPath: string) {
    return fullPath.replace(/^.*[\\/]/, '');
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          margin={margin}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ marginRight: '1rem' }}
          >
            {label}
            <input
              type="file"
              hidden
              onChange={(e) => {
                const files = (e.target as HTMLInputElement).files;
                if (files) {
                  const { name } = files[0];
                  setValue(getFilename(name));

                  field.onChange(files);
                }
              }}
            />
          </Button>
          <Typography noWrap sx={{ mt: 1 }}>
            {value}
          </Typography>
          <FormHelperText>
            {errors ? <span color="error">{errors.message}</span> : ' '}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FileFormField;
