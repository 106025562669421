import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography } from '@mui/material';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

const DeleteBundle = ({
  onSuccess,
  hideDialog,
  name,
  id,
}: {
  onSuccess: VoidFunction;
  hideDialog: VoidFunction;
  name: string;
  id: string;
}) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const onSubmit = async () => {
    const { response } = await callService({
      resource: httpRoutes.bundles.delete(id),
      successMessage: `${name} deleted successfully!`,
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <Stack>
      <WarningAmberOutlinedIcon
        sx={{
          fontSize: '160px',
          color: theme.palette.warning.dark,
          margin: '0 auto',
        }}
      />
      <Typography fontSize={24} fontWeight="bold" textAlign="center">
        {name}
      </Typography>
      <Typography variant="body1" align="center">
        Are you sure you want to archive this bundle?
      </Typography>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>

        <Button variant="contained" color="error" onClick={onSubmit}>
          Yes, archive
        </Button>
      </Grid>
    </Stack>
  );
};

export default DeleteBundle;
