import { Fragment, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { Grid, Box, Checkbox, FormControlLabel } from '@mui/material';

import PageContainer from 'components/PageContainer';
import LoadingButton from 'components/buttons/LoadingButton';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { useSnackbar } from 'notistack';

const Cronjobs = () => {
  const [loading, setLoading] = useState(false);
  const [assignedOnDate, setAssignedOnDate] = useState(new Date());
  const [isSPMVActive, setIsSPMVActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { callService } = useCallService();

  const mapUsersMetadata = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.mapUsersMetadata(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const postCreateCourseAssignment = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.createCourseAssignment(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const courseCompletionRecords = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.courseCompletionRecords(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const budtenderDailySnapshot = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.budtenderDailySnapshot(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const groupDailySalesStats = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.groupDailySalesStats(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeCreatorContentReportSummaryRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.executeCreatorContentReportSummaryRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeCreatorContentReportDetailsRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.executeCreatorContentReportDetailsRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeFacilitatorCourseReportRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.executeFacilitatorCourseReportRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeCommunityReportRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.executeCommunityReportRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeCommunityReportDetailsRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.executeCommunityReportDetailsRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const turnOffMaterializedViews = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.turnOffMaterializedViews(),
      });
      setIsSPMVActive(false);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const turnOnMaterializedViews = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.happyCabbage.turnOnMaterializedViews(),
      });
      setIsSPMVActive(true);
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getMaterializedViewSPStatus = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource:
          httpRoutes.cronjobs.happyCabbage.getMaterializedViewSPStatus(),
      });

      if (response) {
        setIsSPMVActive(response);
      }
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // const updateAssignedOn = async (date: any) => {
  //   setLoading(true);
  //   try {
  //     await callService({
  //       resource: httpRoutes.cronjobs.happyCabbage.updateAssignedOn(date),
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const mapEmployeeMetadata = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.mapEmployeeMetadata(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const mapProducts = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.mapProducts(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const mapProductsAttributes = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.mapProductsAttributes(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const mapTransactions = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.mapTransactions(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const mapInventory = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.mapInventory(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeEmployeePctDiscountRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeEmployeePctDiscountRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeStoreTicketSizeRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.executeStoreTicketSizeRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeStorePctDiscountRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.executeStorePctDiscountRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeStoreUnitsPerTransactionRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeStoreUnitsPerTransactionRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeEmployeeTicketSizeRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeEmployeeTicketSizeRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeEmployeeUnitsPerTransactionRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeEmployeeUnitsPerTransactionRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeEmployeeAvgUnitPriceRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeEmployeeAvgUnitPriceRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeStoreTransactionsPerHourRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeStoreTransactionsPerHourRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeEmployeeTransactionsPerHourRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.dutchie.executeEmployeeTransactionsPerHourRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeStoreAvgUnitPriceRefresh = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.dutchie.executeStoreAvgUnitPriceRefresh(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const generateUserCourses = async () => {
    setLoading(true);
    try {
      await callService({
        resource: httpRoutes.cronjobs.seedtalent.generateUserCourses(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const executeINSERTEngagementReportDaily = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
          httpRoutes.cronjobs.seedtalent.executeINSERTEngagementReportDaily(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const flushEngagementReportCache = async () => {
    setLoading(true);
    try {
      await callService({
        resource:
            httpRoutes.cronjobs.seedtalent.flushEngagementReportCache(),
      });
    } catch (e: any) {
      console.log(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaterializedViewSPStatus();
  }, []);

  return (
    <PageContainer title="Cronjobs">
      <Grid container mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Happy Cabbage
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <LoadingButton
              onClick={() => mapUsersMetadata()}
              label="Map Users Metadata"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => postCreateCourseAssignment()}
              label="Create Course Assignment"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => courseCompletionRecords()}
              label="Course Completion Records"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => budtenderDailySnapshot()}
              label="Budtender Daily Snapshot"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => groupDailySalesStats()}
              label="Group Daily Sales Stats"
              variant="contained"
              loading={loading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container mb={2}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Happy Cabbage Materialized Views
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isSPMVActive}
                onClick={(e: any) => {
                  if (e.target.checked) {
                    turnOnMaterializedViews();
                  } else {
                    turnOffMaterializedViews();
                  }
                }}
              />
            }
            label="Refresh Scheduled Materialized Views Active"
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <LoadingButton
              onClick={() => executeCreatorContentReportSummaryRefresh()}
              label="Execute Creator Content Report Summary Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeCreatorContentReportDetailsRefresh()}
              label="Execute Creator Content Report Details Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeFacilitatorCourseReportRefresh()}
              label="Execute Facilitator Course Report Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeCommunityReportRefresh()}
              label="Execute Community Report Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeCommunityReportDetailsRefresh()}
              label="Execute Community Report Details Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Dutchie
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <LoadingButton
              onClick={() => mapEmployeeMetadata()}
              label="Map Employees Metadata"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => mapProductsAttributes()}
              label="Map Products attributes (categories, brands and styles)"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => mapProducts()}
              label="Map Products"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => mapTransactions()}
              label="Map transactions"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => mapInventory()}
              label="Map inventory"
              variant="contained"
              loading={loading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Dutchie ETL scripts
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <LoadingButton
              onClick={() => executeEmployeePctDiscountRefresh()}
              label="Execute Employee Pct Discount Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeStoreTicketSizeRefresh()}
              label="Execute Store Ticket Size Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeStorePctDiscountRefresh()}
              label="Execute Store Pct Discount Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeStoreUnitsPerTransactionRefresh()}
              label="Execute Store Units Per Transaction Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeEmployeeTicketSizeRefresh()}
              label="Execute Employee Ticket Size Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeEmployeeUnitsPerTransactionRefresh()}
              label="Execute Employee Units Per Transaction Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeEmployeeAvgUnitPriceRefresh()}
              label="Execute Employee Avg Unit Price Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeStoreTransactionsPerHourRefresh()}
              label="Execute Store Transactions Per Hour Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeEmployeeTransactionsPerHourRefresh()}
              label="Execute Employee Transactions Per Hour Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeStoreAvgUnitPriceRefresh()}
              label="Execute Store Avg Unit Price Refresh"
              variant="contained"
              loading={loading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          Seedtalent
        </Box>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => generateUserCourses()}
              label="Generate User Courses"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <LoadingButton
              onClick={() => executeINSERTEngagementReportDaily()}
              label="Execute INSERT Engagement Report Daily"
              variant="contained"
              loading={loading}
            />
          </Box>
          <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                wordBreak: 'break-all',
              }}
          >
            <LoadingButton
                onClick={() => flushEngagementReportCache()}
                label="Flush Engagement Report Cache"
                variant="contained"
                loading={loading}
            />
          </Box>
        </Box>
      </Grid>
      {/*<Grid container>
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex-row',
              alignItems: 'center',
              wordBreak: 'break-all',
            }}
          >
            <h2>Select assigned date to be updated with the current date.</h2>
            <DatePicker
              selected={assignedOnDate}
              onChange={(date: Date) => setAssignedOnDate(date)}
            />
            <br />
            <br />
            <Button
              variant="contained" disabled={loading}
              onClick={() => updateAssignedOn(assignedOnDate)}
            >
              Update assigned on
            </Button>
          </Box>
        </Box>
          </Grid>*/}
    </PageContainer>
  );
};

export default Cronjobs;
