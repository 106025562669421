import { FC } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import GradingIcon from '@mui/icons-material/Grading';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import EditIcon from '@mui/icons-material/Edit';

type IconVariants = 'scorm' | 'document' | 'ai' | 'scratch';

type SourceOptionProps = {
  icon: IconVariants;
  title: string;
  description: string;
};

const OptionIcon = (props: any) => {
  let muiIcon = <></>;
  switch (props.icon) {
    case 'scorm':
      muiIcon = <DriveFolderUploadIcon color="primary" />;
      break;
    case 'document':
      muiIcon = <GradingIcon color="success" />;
      break;
    case 'ai':
      muiIcon = <SmartToyIcon color="disabled" />;
      break;
    case 'scratch':
      muiIcon = <EditIcon color="warning" />;
      break;
  }

  return muiIcon;
};

const SourceOption: FC<SourceOptionProps> = ({ icon, title, description }) => {
  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        columnSpacing={1}
      >
        <Grid item xs={12} mt={1}>
          <Grid item xs={12} md={12}>
            <Stack alignItems="center" direction="row" gap={2}>
              <OptionIcon icon={icon} />
              <Typography variant={'h2'}>{title}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant={'subtitle1'}>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SourceOption;
