import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Grid, Typography } from '@mui/material';

import { CheckboxFormField, TextFormField } from 'components/formFields';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import Button from 'components/buttons/Button';
import LoadingButton from 'components/buttons/LoadingButton';
import { SaveIcon } from 'material-icons';

export type BundleFormInput = {
  id?: string | undefined;
  name: string;
  archived?: boolean;
};

const BundleForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: BundleFormInput;
  onSubmit: (values: BundleFormInput) => void;
}) => {
  const { hideDialog } = useDialogDispatcher();

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required('A name is required'),
    archived: Yup.boolean().nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<BundleFormInput>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues ? defaultValues.id : undefined,
      name: defaultValues ? defaultValues.name : '',
      archived: defaultValues ? defaultValues.archived : false,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Bundles
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={12}>
              <TextFormField
                name="name"
                control={control}
                label="Bundle Name*"
                errors={errors.name}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CheckboxFormField
                name="archived"
                control={control}
                label="Archived"
                errors={errors.archived}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog} label="Cancel" />
          <LoadingButton
            label="Save"
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          />
        </Grid>
      </form>
    </div>
  );
};

export default BundleForm;
