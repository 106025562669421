import { useState, useEffect } from 'react';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import resources from '../resources';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    filterable: false,
    flex: 0.3,
  },
];

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const UserCoursesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    const getUserCourses = async () => {
      setLoading(true);
      try {
        const { response } = await callService({
          resource: resources.getUserCourses({
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    getUserCourses();
  }, [filters, state]);

  return (
    <>
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </>
  );
};

const UserCourses = () => (
  <PageContainer
    title="My Courses"
    icon={<CourseIcon sx={{ fontSize: '24px' }} />}
  >
    <ControlledDataGrid>
      <UserCoursesGrid />
    </ControlledDataGrid>
  </PageContainer>
);

export default UserCourses;
