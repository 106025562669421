import React from 'react';
import {Box, Skeleton} from '@mui/material';

const TopCommunitiesLoading = () => (
    <Box sx={{ width: '100%', height: '100%'}}>
        <Skeleton variant="rectangular" height="100%" width="100%" />
    </Box>
);

export default TopCommunitiesLoading;
