import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import PageContainer from 'components/PageContainer';

import StoreKpiCard from '../components/store/StoreKpiCard';
import SelectIntervalFilter from '../components/SelectIntervalFilter';

import { kpis } from '../utils';

const SingleStore = () => {
  const params = useParams();
  const { groupId } = params;

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();

  const [group, setGroup] = useState<any>(null);
  const [groupLoading, setGroupLoading] = useState(true);
  const [interval, setInterval] = useState<string>('latest');

  const getGroup = async () => {
    if (groupId) {
      try {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(groupId),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    }
  };

  useEffect(() => {
    getGroup();
  }, [groupId]);

  return (
    <PageContainer>
      <Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
            {groupLoading ? (
              <Skeleton height="40px" width="245px" variant="rounded" />
            ) : (
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: matchDownSM ? '25px' : '32px',
                  lineHeight: '130%',
                  color: '#162738',
                }}
              >
                {group?.name}
              </Box>
            )}
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '150%',
                color: '#162738',
              }}
            >
              Key Performance Indicators
            </Box>
          </Box>
        </Box>
        <SelectIntervalFilter value={interval} setValue={setInterval} />
        <Box
          sx={{
            display: 'grid',
            ...(!matchDownSM && {
              gridTemplateColumns: 'repeat(auto-fit, minmax(343px, 1fr))',
            }),
            gridGap: '10px',
          }}
        >
          {kpis.map((kpi, index) => (
            <StoreKpiCard
              key={index}
              interval={interval}
              kpiName={kpi.kpiName}
              kpiId={kpi.kpiId}
            />
          ))}
        </Box>
      </Box>
    </PageContainer>
  );
};

export default SingleStore;
