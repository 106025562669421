import * as React from 'react';

export default (
  <g fill="none">
    <path
      d="M16.5 31C23.956 31 30 24.956 30 17.5S23.956 4 16.5 4 3 10.044 3 17.5 9.044 31 16.5 31z"
      fill="#FCBEC9"
    />
    <path
      d="M22 19H9l2.784-7H23M39 12h10.891L53 19H38.5M34 49V32h9v17"
      stroke="#205072"
      strokeWidth={2}
      fill="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 28v21h35V28M24 21.227v2.737C24 25.636 22.427 27 20.5 27S17 25.636 17 23.964V20M44 20v3.964C44 25.636 42.427 27 40.5 27S37 25.636 37 23.964V21.5M17 20v3.964C17 25.636 15.427 27 13.5 27S10 25.636 10 23.964V20M51 20v3.964C51 25.636 49.427 27 47.5 27S44 25.636 44 23.964V20M18 44h10"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29 32H18v8h11v-8z"
      stroke="#205072"
      fill="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5 8.2c-4.08 0-7.389 3.447-7.389 7.699 0 2.426 1.41 4.694 3.383 6.476l3.633 3.28a.553.553 0 00.746 0l3.633-3.28c1.973-1.782 3.383-4.05 3.383-6.476 0-4.252-3.308-7.7-7.389-7.7zM21 15.899C21 10.432 25.253 6 30.5 6s9.5 4.432 9.5 9.899c0 3.329-1.91 6.15-4.111 8.138l-3.633 3.28a2.6 2.6 0 01-3.512 0l-3.633-3.28c-2.2-1.988-4.111-4.81-4.111-8.138z"
      fill="#205072"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.67 12.342c.44.455.44 1.194 0 1.65l-4.5 4.666a1.097 1.097 0 01-1.59 0l-2.25-2.333a1.198 1.198 0 010-1.65 1.097 1.097 0 011.59 0l1.455 1.508 3.705-3.841a1.097 1.097 0 011.59 0z"
      fill="#205072"
    />
  </g>
);
