import React, { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Papa from 'papaparse';
import fileSaver from 'file-saver';

import { Box, Skeleton } from '@mui/material';
import { UploadFileIcon } from 'material-icons';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';
import Button from 'components/buttons/Button';

import Icon from 'icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

import Tag from '../../components/Tag';

import EditEmployee from './dialogs/EditEmployee';
import UploadEmployees from './dialogs/UploadEmployees';

const statusOptions = [
  {
    value: '',
    label: 'Any status',
  },
  {
    value: 'enrolled',
    label: 'Enrolled',
  },
  {
    value: 'notEnrolled',
    label: 'Not Enrolled',
  },
  {
    value: 'removed',
    label: 'Removed',
  },
];
// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Employee Name',
  },
  {
    id: 'status',
    label: 'Status',
    type: 'select',
    options: statusOptions,
  },
];

const columns = (handleEdit: any): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
            }}
          >
            {`${params.row.firstName} ${params.row.lastName}`}
          </Box>
        );
      },
    },
    {
      headerName: 'Enrolled on',
      field: 'enrolledOn',
      sortable: true,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.hcBudtenderMap && params.row.hcBudtenderMap.enrolledOn) {
          return (
            <>
              {new Date(
                params.row.hcBudtenderMap.enrolledOn
              ).toLocaleDateString('en-US')}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Status',
      field: 'isEnrolled',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { row } = params;

        if (row.hcBudtenderMap) {
          const { hcBudtenderMap } = row;
          if (hcBudtenderMap.isRemoved) {
            return (
              <Tag
                label="Removed"
                labelColor="#AA5B00"
                backgroundColor="#FCF2E6"
              />
            );
          }

          if (hcBudtenderMap.isEnrolled) {
            return (
              <Tag
                label="Enrolled"
                labelColor="#14804A"
                backgroundColor="#E1FCEF"
              />
            );
          }

          return (
            <Tag
              label="Not Enrolled"
              labelColor="#5A6376"
              backgroundColor="#E9EDF5"
            />
          );
        }

        return params.row.hcBudtenderMap &&
          params.row.hcBudtenderMap.isEnrolled ? (
          <Tag
            label="Enrolled"
            labelColor="#14804A"
            backgroundColor="#E1FCEF"
          />
        ) : (
          <Tag
            label="Not Enrolled"
            labelColor="#5A6376"
            backgroundColor="#E9EDF5"
          />
        );
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      ColumnComponent: (params: any) => {
        if (params.row.email) {
          return <>{params.row.email}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'POS ID',
      field: 'posId',
      ColumnComponent: (params: any) => {
        if (params.row.posId) {
          return <>{params.row.posId}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleEdit(params);
              }}
            >
              <Icon name="edit" />
            </IconButton>
          </>
        );
      },
    },
  ];
};

let groupsRequestController: AbortController | undefined;

const OrgManagementEmployeesGrid = () => {
  const params = useParams();
  const { organizationId, groupId } = params;

  const navigate = useNavigate();
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const [groupLoading, setGroupLoading] = useState(false);
  const [group, setGroup] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  //
  // const [group]

  const getEmployees = async () => {
    if (organizationId && groupId) {
      setLoading(true);
      if (groupsRequestController) {
        groupsRequestController.abort();
      }
      try {
        groupsRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.ddl.getEmployeesByOrganizationAndGroupPaginated({
            controller: groupsRequestController,
            params: { ...state },
            filters: filters,
            organizationId,
            groupId,
          }),
        });

        if (response) {
          if (response.items.length > 0) {
            const headerData = response.items[0].userGroups[0];
            setOrgName(headerData.group.organization.name);
            setGroupName(headerData.group.name);
          }

          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          groupsRequestController = undefined;
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const getGroup = async () => {
    try {
      if (!groupId) return;

      const { response } = await callService({
        resource: httpRoutes.ddl.getOneGroupById(groupId),
      });

      if (response) {
        setGroup(response);
      }
    } finally {
      setGroupLoading(false);
    }
  };

  const showEditDialog = (params: any) => {
    const {
      row: {
        id,
        email,
        firstName,
        lastName,
        posId: userPosId,
        notes,
        userGroups,
        hcBudtenderMap,
      },
    } = params;

    const posId = hcBudtenderMap?.posId || '';
    const isEnrolled = hcBudtenderMap?.isEnrolled || false;

    const { group } = userGroups.find((x: any) => x.groupId === groupId);

    const employee = {
      name: `${firstName} ${lastName}`,
      id,
      email,
      organizationName: group.organization?.name || '',
      groupId: groupId,
      groupName: group.name || '',
      posId,
      notes,
      userPosId,
      isEnrolled,
      isAlreadyMapped: !!hcBudtenderMap,
    };

    showDialog({
      content: <EditEmployee employee={employee} onSuccess={getEmployees} />,
      size: 'md',
    });
  };

  const showUploadDialog = () => {
    showDialog({
      content: (
        <UploadEmployees
          organizationId={organizationId}
          groupId={groupId}
          onSuccess={getEmployees}
        />
      ),
      size: 'sm',
    });
  };

  const navigationToMatchEmployee = () => {
    navigate(
      `/admin/ddl/org-management/${organizationId}/group/${groupId}/match-employees`
    );
  };

  const handleExport = async () => {
    if (organizationId && groupId) {
      try {
        const { response } = await callService({
          resource: httpRoutes.ddl.exportEmployeesByOrganizationAndGroup({
            params: { ...state },
            filters: filters,
            organizationId,
            groupId,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, 'Organization Management - Employees.csv');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getEmployees();
  }, [filters, state]);

  useEffect(() => {
    getGroup();
  }, [groupId]);

  const safeGroup = group || {};

  const hasIntegration = safeGroup.hcGroupMap;
  const isHappyCabbage = safeGroup.hcGroupMap?.hcStoreId;

  return (
    <PageContainer
      title={groupName}
      subtitle={orgName}
      pageAction={
        groupLoading ? (
          <Skeleton
            variant="rounded"
            height="48px"
            width="160px"
            sx={{ borderRadius: '30px' }}
          />
        ) : (
          <Box sx={{ display: 'flex' }}>
            {hasIntegration && isHappyCabbage && (
              <Box>
                <Button
                  onClick={showUploadDialog}
                  startIcon={<UploadFileIcon />}
                  label="Import Employees"
                />
              </Box>
            )}
            {hasIntegration && !isHappyCabbage && (
              <Box>
                <Button
                  onClick={navigationToMatchEmployee}
                  label="Match Employees"
                />
              </Box>
            )}
          </Box>
        )
      }
    >
      <DataGrid
        tableTitle="Store Employees"
        rows={gridData.rows}
        columns={columns(showEditDialog)}
        filters={filterModel}
        handleExport={handleExport}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const OrgManagementEmployees = () => (
  <ControlledDataGrid>
    <OrgManagementEmployeesGrid />
  </ControlledDataGrid>
);

export default OrgManagementEmployees;
