import { useTheme } from '@mui/material/styles';

import { Box, Grid, TextField, useMediaQuery } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Icon from 'icons';

const matchedSx = (alreadyMatched: boolean) => ({
  border: `solid ${alreadyMatched ? '#56C596' : '#ff5572'} 1px`,
  borderRadius: '4px',
});

const defaultSx = {
  background: 'white',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const EmployeeSelectionRow = ({
  employee,
  options,
  onEmployeeSelection,
  selectedEmployees,
}: {
  employee: any;
  options: any[];
  onEmployeeSelection: (employee: any) => void;
  selectedEmployees: any;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchUpLG = useMediaQuery(theme.breakpoints.up('lg'));

  const { u_first_name, u_last_name, u_email, u_id, hcBudtenderMap_id } = employee;

  const fullName = `${u_first_name} ${u_last_name}`;

  const handleEmployeeSelection = (newValue: any) => {
    const selectedEmployee = {
      stUserId: u_id,
      hcBudtenderMapId: newValue?.hcBudtenderMapId,
      loginId: `${newValue?.loginId}`,
    };
    onEmployeeSelection(selectedEmployee);
  };

  const alreadyMatched = !!hcBudtenderMap_id;

  return (
    <Grid
      p={1}
      pb={2}
      rowGap={1}
      container
      sx={{
        background: 'white',
        ...(!matchUpLG ? matchedSx(alreadyMatched) : {}),
      }}
    >
      <Grid item lg={6} sx={matchUpLG ? matchedSx(alreadyMatched) : {}}>
        <Box
          sx={{
            color: '#162738',
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: matchDownSM ? '13px' : '16px',
            lineHeight: matchDownSM ? '115%' : '130%',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`${fullName} (${u_email})`}
        </Box>
        {!alreadyMatched && (
          <Box
            sx={{
              pt: '4px',
              width: 'fit-content',
              color: '#d32f2f',
              minHeight: '19px',
            }}
          >
            This employee does not have a match
          </Box>
        )}
      </Grid>
      <Grid
        item
        lg={6}
        gap={1}
        sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            disablePortal
            size={matchDownSM ? 'small' : 'medium'}
            defaultValue={selectedEmployees[u_id]}
            options={options}
            getOptionLabel={(option) =>
              `${option.loginId} (${option.posId})` || ''
            }
            onChange={(event, newValue) => {
              handleEmployeeSelection(newValue);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ fontSize: matchDownSM ? '13px' : '16px' }}>
                  {option.loginId}
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ...defaultSx,
                  '& .MuiInputBase-root': {
                    fontSize: matchDownSM ? '13px' : '16px',
                  },
                }}
              />
            )}
          />
        </Box>
        <Box>
          <Icon
            name={alreadyMatched ? 'filled-check' : 'filled-warning'}
            size="small"
            fill={alreadyMatched ? '#56C596' : '#FF5572'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmployeeSelectionRow;
