import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import CourseBundleForm, {
  CourseBundleFormInput,
} from '../forms/CourseBundleForm';

import httpRoutes from 'utils/httpRoutes';

const ManageCourses = ({
  onSuccess,
  bundleId,
  bundleName,
}: {
  onSuccess: VoidFunction;
  bundleId: string;
  bundleName: string;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState<{ id: string; name: string }[]>([]);

  const getBundleCourses = async () => {
    setIsLoading(true);
    const { response } = await callService({
      resource: httpRoutes.bundles.getAllCoursesByBundleId(bundleId),
    });

    if (response) {
      setCourses(
        response.items.map((course: any) => ({
          id: course.id,
          name: course.name,
        }))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBundleCourses();
  }, [bundleId]);

  const onSubmit = async (values: CourseBundleFormInput) => {
    console.log(values);
    const { response } = await callService({
      resource: httpRoutes.bundles.updateBundleCourses(values),
      successMessage: 'Course bundles updated successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <CourseBundleForm
        bundleId={bundleId}
        bundleName={bundleName}
        onSubmit={onSubmit}
        courses={courses}
        loading={isLoading}
      />
    </div>
  );
};

export default ManageCourses;
