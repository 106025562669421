import { useState, useEffect } from 'react';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType, SelectOption } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

const loadingState = {
  categories: true,
  courses: true,
  exporting: false,
};

const UserCourseReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.course.getUserCourseDetails({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const getCourseCategories = async () => {
    setLoading((prevState) => ({ ...prevState, categories: true }));

    const { response } = await callService({
      resource: httpRoutes.courses.getCourseCategories(),
    });

    if (response) {
      const _categoriesList: SelectOption[] = [
        {
          label: 'All Categories',
          value: '',
        },
      ];

      response.forEach((item: { name: string; id: string }) => {
        _categoriesList.push({ label: item.name, value: item.id });
      });

      setCategories(_categoriesList);

      setLoading((prevState) => ({ ...prevState, categories: false }));
    }
  };

  const exportCourses = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.course.exportUserCourseDetails({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'My Course Report.csv');

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getCourseCategories();
  }, []);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 1.25,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filterable: false,
      flex: 0.5,
    },
    {
      headerName: 'Started',
      field: 'start',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 1,
      ColumnComponent: (params: any) => {
        if (params.row.start) {
          return <>{new Date(params.row.start).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Completed',
      field: 'end',
      sortable: false,
      filterable: false,
      type: 'date',
      headerAlign: 'center',
      align: 'right',
      flex: 1,
      ColumnComponent: (params: any) => {
        if (params.row.end) {
          return <>{new Date(params.row.end).toLocaleString()}</>;
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Passed',
      field: 'passed',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'boolean',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return <>{params.row.passed ? 'Yes' : 'No'}</>;
      },
    },
    {
      headerName: 'Hours',
      field: 'estimatedHours',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return <>{params.row.estimatedHours} hrs</>;
      },
    },
    {
      headerName: 'Score',
      field: 'score',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return <>{params.row.score}</>;
      },
    },
  ];

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
    {
      id: 'courseCategoryId',
      type: 'select',
      label: 'Course Category',
      options: categories,
    },
  ];

  return (
    <DataGrid
      rows={gridData.rows}
      columns={columns}
      tableTitle="My Course Report"
      filters={filterModel}
      handleExport={exportCourses}
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading.courses}
    />
  );
};

const UserCourseReport = () => (
  <ControlledDataGrid>
    <UserCourseReportGrid />
  </ControlledDataGrid>
);

export default UserCourseReport;
