import httpRoutes from 'utils/httpRoutes';
import { useEffect, useState } from 'react';
import MultiStepForm, {
  AssignCourseFormInput,
} from '../../forms/MultiStepForm';
import { useCallService } from 'hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useSnackbar } from 'notistack';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { CoursesAssignation } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import {
  CreateGroupCourseAssignmentRequest,
  CreateUserAssignmentRequest,
  UserAssignmentEmailRequest,
} from 'features/course-assignment/interfaces';
import {
  ChunkTypes,
  ExecutionResult,
  IPromiseManager,
  PromiseAdapter,
  PromiseManagerFactory,
} from 'utils/promiseManager';
import { IPromiseManagerFactory } from 'utils/promiseManager/interfaces/IPromiseManagerFactory';
import _ from 'lodash';
import { Box } from '@mui/material';
import { LinearProgressWithLabel } from 'components/atomic';
import { delay } from 'utils/common';

let promiseManager: IPromiseManager;

const AssignCourse = ({
  organizationId,
  isOrganizationManager,
  onSuccess,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
  onSuccess: VoidFunction;
}) => {
  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { assignCourseState } = useAssignCourseDispatcher();
  const { learners } = assignCourseState;
  const [progress, setProgress] = useState({
    label: '',
    value: 0,
    requests: 0,
    responses: 0,
  });

  useEffect(() => {
    const promiseManagerFactory: IPromiseManagerFactory =
      new PromiseManagerFactory();
    promiseManager = promiseManagerFactory.create();
  });

  const onSubmit = async (body: AssignCourseFormInput) => {
    setIsSubmitting(true);
    try {
      if (
        (body.coursesAssignations.length >= 5 && learners > 50) ||
        learners >= 100
      ) {
        enqueueSnackbar(
          'This process could take some time because it is necessary to process a high volume of data, please be patient while we crunch the numbers.',
          {
            variant: 'info',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }
        );
      }

      setProgress({
        label: `STEP 1 - Creating course assignment ${body.name}:`,
        value: 0,
        requests: 1,
        responses: 0,
      });
      const { response } = await callService({
        resource: httpRoutes.assignCourse.create(body),
      });

      if (response) {
        calculateProgress();
        await delay(100);

        setProgress({
          label: 'STEP 2 - Creating group course assignments:',
          value: 0,
          requests: 0,
          responses: 0,
        });
        const courseAssignmentRequests = buildGroupCourseAssignmentRequests(
          response.id,
          body
        );
        const groupCourseAssignmentIds: number[] =
          await createCoursesAssignations(courseAssignmentRequests);

        setProgress({
          label: 'STEP 3 - Creating user assignments:',
          value: 0,
          requests: 0,
          responses: 0,
        });

        const createUserAssignmentRequests: CreateUserAssignmentRequest[] =
          buildUserAssignmentRequestsRequests(groupCourseAssignmentIds);
        const userIds = await createUserAssignments(
          createUserAssignmentRequests
        );

        setProgress({
          label: 'FINAL STEP - Send notification emails to assigned users:',
          value: 0,
          requests: 0,
          responses: 0,
        });

        const userAssignmentEmailRequests: UserAssignmentEmailRequest[] =
          buildUserAssignmentEmailRequest(userIds);
        await sendEmailsToUserAssignments(userAssignmentEmailRequests);

        enqueueSnackbar('Assigment created successfully!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        onSuccess();
        hideDialog();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const createCoursesAssignations = async (
    createGroupCourseAssignmentRequests: CreateGroupCourseAssignmentRequest[]
  ) => {
    const promises: PromiseAdapter[] = createGroupCourseAssignmentRequests.map(
      (createGroupCourseAssignmentRequest) =>
        new PromiseAdapter(
          createCoursesAssignation,
          [createGroupCourseAssignmentRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 20);
    const groupCourseAssignmentIds: number[] = executionResult.results
      .flat()
      .map((x: any) => x.id);

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return groupCourseAssignmentIds;
  };

  const createCoursesAssignation = async (
    createGroupCourseAssignmentRequest: CreateGroupCourseAssignmentRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.createGroupCourseAssignment(
          createGroupCourseAssignmentRequest
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildGroupCourseAssignmentRequests = (
    organizationAssignmentId: number,
    body: AssignCourseFormInput,
    requestLength = 10
  ) => {
    const createGroupCourseAssignmentRequests: CreateGroupCourseAssignmentRequest[] =
      [];
    const coursesAssignationsToSplice = _.cloneDeep(body.coursesAssignations);

    const initCourseAssignments = () => {
      if (coursesAssignationsToSplice.length === 0) {
        return;
      }

      let coursesAssignations: CoursesAssignation[] = [];
      if (coursesAssignationsToSplice.length >= requestLength) {
        coursesAssignations = coursesAssignationsToSplice.splice(
          0,
          requestLength
        );
      } else {
        coursesAssignations = coursesAssignationsToSplice.splice(
          0,
          coursesAssignationsToSplice.length
        );
      }

      const groupsIdsToBuild = _.cloneDeep(body.groupsIds);

      const getGroupIds = (groupsIdsToBuild: string[]) => {
        let groupsIds = [];
        if (groupsIdsToBuild.length >= requestLength) {
          groupsIds = groupsIdsToBuild.splice(0, requestLength);
        } else {
          groupsIds = groupsIdsToBuild.splice(0, groupsIdsToBuild.length);
        }
        const createGroupCourseAssignmentRequest: CreateGroupCourseAssignmentRequest =
          {
            organizationAssignmentId,
            groupsIds,
            coursesAssignations,
          };
        createGroupCourseAssignmentRequests.push(
          createGroupCourseAssignmentRequest
        );
        if (groupsIdsToBuild.length > 0) {
          getGroupIds(groupsIdsToBuild);
        }
      };

      getGroupIds(groupsIdsToBuild);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: createGroupCourseAssignmentRequests.length,
    }));

    return createGroupCourseAssignmentRequests;
  };

  const createUserAssignments = async (
    createUserAssignmentRequests: CreateUserAssignmentRequest[]
  ) => {
    const promises: PromiseAdapter[] = createUserAssignmentRequests.map(
      (createUserAssignmentRequest) =>
        new PromiseAdapter(
          createUserAssignment,
          [createUserAssignmentRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(promises, 10);
    const assignedUserIds: string[] = executionResult.results
      .flat()
      .map((x: any) => x.userId);

    const userIds: string[] = [...new Set(assignedUserIds)];
    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return userIds;
  };

  const createUserAssignment = async (
    createUserAssignmentRequest: CreateUserAssignmentRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.createUserAssignment(
          createUserAssignmentRequest
        ),
      });

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildUserAssignmentRequestsRequests = (
    groupCourseAssignmentIds: number[],
    requestLength = 5
  ) => {
    const createUserAssignmentRequests: CreateUserAssignmentRequest[] = [];
    const groupCourseAssignmentIdsToSplice = _.cloneDeep(
      groupCourseAssignmentIds
    );

    const initCourseAssignments = () => {
      if (groupCourseAssignmentIdsToSplice.length === 0) {
        return;
      }

      let groupCourseAssignmentIds = [];
      if (groupCourseAssignmentIdsToSplice.length >= requestLength) {
        groupCourseAssignmentIds = groupCourseAssignmentIdsToSplice.splice(
          0,
          requestLength
        );
      } else {
        groupCourseAssignmentIds = groupCourseAssignmentIdsToSplice.splice(
          0,
          groupCourseAssignmentIdsToSplice.length
        );
      }
      const createUserAssignmentRequest: CreateUserAssignmentRequest = {
        groupCourseAssignmentIds,
      };

      createUserAssignmentRequests.push(createUserAssignmentRequest);

      initCourseAssignments();
    };

    initCourseAssignments();
    setProgress((prevState: any) => ({
      ...prevState,
      requests: createUserAssignmentRequests.length,
    }));

    return createUserAssignmentRequests;
  };

  const sendEmailsToUserAssignments = async (
    userAssignmentEmailRequests: UserAssignmentEmailRequest[]
  ) => {
    const promises: PromiseAdapter[] = userAssignmentEmailRequests.map(
      (userAssignmentEmailRequest) =>
        new PromiseAdapter(
          sendEmailToUserAssignment,
          [userAssignmentEmailRequest],
          this
        )
    );

    const executionResult: ExecutionResult<any> =
      await promiseManager.getPromisesResult<any>(
        promises,
        5,
        ChunkTypes.Amount
      );
    const results: any[] = executionResult.results.flat();

    setProgress((prevState: any) => ({ ...prevState, value: 100 }));
    await delay(100);

    return results;
  };

  const sendEmailToUserAssignment = async (
    userAssignmentEmailRequest: UserAssignmentEmailRequest
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.sendEmailToUserAssignment(
          userAssignmentEmailRequest
        ),
      });

      await delay(100);

      if (response) {
        calculateProgress();
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const buildUserAssignmentEmailRequest = (userIds: string[]) => {
    const userAssignmentEmailRequests: UserAssignmentEmailRequest[] =
      userIds.map((userId) => {
        const userAssignmentEmailRequest: UserAssignmentEmailRequest = {
          userId,
        };
        return userAssignmentEmailRequest;
      });

    setProgress((prevState: any) => ({
      ...prevState,
      requests: userAssignmentEmailRequests.length,
    }));

    return userAssignmentEmailRequests;
  };

  const calculateProgress = () => {
    const progressPercentage = (responses: number, requests: number) =>
      Math.ceil((responses / requests) * 100);

    setProgress((prevState: any) => ({
      ...prevState,
      value: progressPercentage(prevState.responses + 1, prevState.requests),
      responses: prevState.responses + 1,
    }));
  };

  return (
    <>
      <MultiStepForm
        onSubmit={onSubmit}
        organizationId={organizationId}
        isOrganizationManager={isOrganizationManager}
        isSubmitting={isSubmitting}
      />

      {isSubmitting && (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            label={progress.label}
            value={progress.value}
          />
        </Box>
      )}
    </>
  );
};

export default AssignCourse;
