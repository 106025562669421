import { useEffect, useState } from 'react';

import { Box, Divider, Skeleton } from '@mui/material';

import OrganizationForm from '../forms/OrganizationForm';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { decrypt, encrypt } from 'utils/crypto';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

const EditOrganization = ({
  organizationId,
  onSuccess,
}: {
  organizationId: string;
  onSuccess: VoidFunction;
}) => {
  const [organization, setOrganization] = useState<any>({});
  const [organizationLoading, setOrganizationLoading] = useState(true);

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: any) => {
    let updatedOrganization = {
      ...values,
      apiKey: encrypt(values.apiKey),
      organizationId,
    };

    if (!values.ddlActivated) {
      updatedOrganization = {
        organizationId,
        apiKey: '',
        hcOrganizationId: '',
        ddlActivated: false,
        groupIds: [],
      };
    }

    const { response } = await callService({
      resource: httpRoutes.ddl.updateOrganization(updatedOrganization),
      successMessage: 'Organization successfully updated!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  const getOrganization = async () => {
    if (organizationId) {
      try {
        const { response } = await callService({
          resource: httpRoutes.ddl.getOneOrganizationById(organizationId),
        });

        if (response) {
          const {
            id,
            name,
            ddlActivated,
            hcOrganizationMap,
            groups,
          } = response;

          setOrganization({
            id,
            name,
            apiKey: hcOrganizationMap ? decrypt(hcOrganizationMap.apiKey) : '',
            hcOrganizationId: hcOrganizationMap ? hcOrganizationMap.hcOrganizationId : '',
            ddlActivated,
            groupIds: groups
              .filter((group: any) => group.ddlActivated)
              .map((group: any) => group.id),
          });
        }
      } finally {
        setOrganizationLoading(false);
      }
    }
  };

  useEffect(() => {
    getOrganization();
  }, []);

  if (organizationLoading) {
    return (
      <Box>
        <Skeleton variant="rectangular" height={300} width="100%" />
      </Box>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: '32px',
          lineHeight: '130%',
        }}
      >
        {organization.name}
      </Box>
      <Divider
        sx={{
          my: 3,
        }}
      />
      <OrganizationForm onSubmit={onSubmit} defaultValues={organization} />
    </Box>
  );
};

export default EditOrganization;
