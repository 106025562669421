import { Box } from '@mui/material';
import { CardGiftcardIcon } from 'material-icons';
import { formatDate } from '../utils';
import CourseIncentiveProps from 'types/courseTypes/CourseIncentiveProps';

const CourseIncentiveChip = ({
  incentive,
}: {
  incentive: CourseIncentiveProps;
}) => {
  const { rewardType, expiresOn } = incentive;

  const rewardName = rewardType ? rewardType.name : '';

  const label = `${rewardName} expires on ${formatDate(expiresOn)}`;

  return (
    <Box
      sx={{
        height: '20px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CardGiftcardIcon fontSize="small" />
      <p
        style={{
          margin: '2px',
          color: '#525353',
          fontFamily: 'Raleway',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '10px',
          lineHeight: '20px',
        }}
      >
        {label}
      </p>
    </Box>
  );
};

export default CourseIncentiveChip;
