import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  TextFormField,
  MultipleSelectCheckmarksFormField,
  ToggleFormField,
} from 'components/formFields';
import LoadingButton from 'components/buttons/LoadingButton';

import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

const textFieldStyle = {
  background: 'white',
  '& .MuiInputLabel-root': {
    '&.Mui-focused': { color: '#56C596' },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

export interface IOrganizationFormInput {
  id: string;
  apiKey?: string;
  hcOrganizationId?: string;
  ddlActivated: boolean;
  groupIds?: string[];
}

interface IOrganizationFormProps {
  defaultValues?: IOrganizationFormInput;
  onSubmit: (values: IOrganizationFormInput) => void;
}

const OrganizationForm = ({
  defaultValues,
  onSubmit,
}: IOrganizationFormProps) => {
  const theme = useTheme();
  const [options, setOptions] = useState<any[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(true);

  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const getGroups = async () => {
    if (defaultValues?.id) {
      try {
        const { response } = await callService({
          resource: httpRoutes.groups.searchByOrganizationId({
            params: {
              limit: 9990,
            },
            organizationId: defaultValues.id,
          }),
        });

        if (response) {
          setOptions(
            response.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })
          );
        }
      } finally {
        setOptionsLoading(false);
      }
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IOrganizationFormInput>({
    mode: 'onBlur',
    defaultValues: {
      apiKey: defaultValues?.apiKey,
      hcOrganizationId: defaultValues?.hcOrganizationId,
      ddlActivated: defaultValues?.ddlActivated,
      groupIds: defaultValues?.groupIds,
    },
    shouldUnregister: false,
  });

  const isDDlActivated = watch('ddlActivated');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        alignItems="center"
        columnSpacing={3}
        rowSpacing={3}
        sx={{ mb: matchUpXS ? 3 : 1 }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Enrolled in Data Driven Learning
            </Box>
            <Box>
              <ToggleFormField name="ddlActivated" control={control} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              visibility: isDDlActivated ? 'visible' : 'hidden',
            }}
          >
            <MultipleSelectCheckmarksFormField
              name="groupIds"
              limitTags={3}
              options={options}
              disabled={optionsLoading}
              control={control}
              label="Choose stores to assign data Driven learning"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              // visibility: isDDlActivated ? 'visible' : 'hidden',
              visibility: 'hidden',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="apiKey"
              control={control}
              label="Api Key"
              errors={errors.apiKey}
              margin="dense"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              // visibility: isDDlActivated ? 'visible' : 'hidden',
              visibility: 'hidden',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="hcOrganizationId"
              control={control}
              label="HC organization Id"
              errors={errors.hcOrganizationId}
              margin="dense"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          label="Save"
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          loadingPosition="start"
          icon={<SaveIcon />}
        />
      </Grid>
    </form>
  );
};

export default OrganizationForm;
