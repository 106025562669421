import React, { memo, useCallback } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box, Skeleton } from '@mui/material';

export interface RightDrawerProps {
  isOpen: boolean;
  title?: string;
  size?: 'sm' | 'md' | 'lg';
  content: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
}

const RightDrawer = ({
  isOpen,
  title,
  content,
  size = 'sm',
  setIsOpen,
  disableEscapeKeyDown,
  enableBackdropClose = true,
}: RightDrawerProps) => {
  const handleBackdropClick = useCallback(() => {
    setIsOpen(false);
  }, [enableBackdropClose]);

  return (
    <MuiDrawer
      open={isOpen}
      anchor="right"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onBackdropClick={handleBackdropClick}
    >
      <Box sx={{ width: 420 }}>
        {title && (
          <>
            <Typography variant="h3" sx={{ mt: 2, ml: 2, mb: 2 }}>
              {title}
            </Typography>
            <Divider />
          </>
        )}
        {content}
      </Box>
    </MuiDrawer>
  );
};

export default memo(RightDrawer);
