import { Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const DueDateChip = ({ dueDate }: { dueDate?: string }) => {
  const formatDueDate = (dueDate?: string) => {
    if (!dueDate) return;
    const currentDate = new Date();
    const courseDueDate = new Date(dueDate);

    if (courseDueDate < currentDate) {
      return `Overdue since ${courseDueDate.toLocaleDateString()}`;
    } else {
      return `Due by ${dueDate}`;
    }
  };
  return (
    <Box
      sx={{
        height: '20px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CalendarMonthIcon fontSize="small" />
      <p
        style={{
          margin: '2px',
          color: '#525353',
          fontFamily: 'Raleway',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '10px',
          lineHeight: '20px',
        }}
      >
        {formatDueDate(dueDate)}
      </p>
    </Box>
  );
};

export default DueDateChip;
