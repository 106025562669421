import React, { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import Icon from 'icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

import Tag from '../../components/Tag';
import OnBoardingStatusTag from '../../components/OnBoardingStatusTag';
import KpiTag from '../../components/KpiTag';
import EditGroup from './dialogs/EditGroup';
import MatchDutchieStore from './dialogs/MatchDutchieStore';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Group Name',
  },
];

const columns = (
  handleEdit: any,
  navigateToEmployees: any
): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToEmployees(params.row.id);
            }}
          >
            {params.row.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Enrolled on',
      field: 'ddlActivatedOn',
      sortable: true,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.ddlActivatedOn) {
          return (
            <>
              {new Date(params.row.ddlActivatedOn).toLocaleDateString('en-US')}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Status',
      field: 'ddlActivated',
      sortable: true,
      ColumnComponent: (params: any) => {
        return params.row.ddlActivated ? (
          <Tag
            label="Enrolled"
            labelColor="#14804A"
            backgroundColor="#E1FCEF"
          />
        ) : (
          <Tag
            label="Not Enrolled"
            labelColor="#5A6376"
            backgroundColor="#E9EDF5"
          />
        );
      },
    },
    {
      headerName: 'Onboarding Status',
      field: 'ddlOnboardingStatus',
      sortable: true,
      ColumnComponent: (params: any) => {
        const {
          row: { ddlOnboardingStatus },
        } = params;
        return ddlOnboardingStatus ? (
          <OnBoardingStatusTag status={params.row.ddlOnboardingStatus} />
        ) : (
          '-'
        );
      },
    },
    {
      headerName: 'Kpis',
      field: 'groupKpis',
      ColumnComponent: (params: any) => {
        const {
          row: { groupKpis },
        } = params;

        if (!groupKpis) {
          return '';
        }

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', p: 1 }}>
            {groupKpis.map((gkp: any) => (
              <KpiTag
                key={`KpiTag_${gkp?.kpi?.kpi}`}
                kpi={gkp?.kpi?.kpi}
                name={gkp?.kpi?.name}
              />
            ))}
          </Box>
        );
      },
    },
    {
      headerName: 'POS Integration Partner',
      field: 'integrationPartner',
      ColumnComponent: (params: any) => {
        const { row } = params;
        const { hcGroupMap } = row;

        if (!hcGroupMap) {
          return '';
        }

        const { integrationPartner } = hcGroupMap;

        if (!integrationPartner) {
          return '';
        }

        return (
          <Box
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '150%',
              color: '#162738',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {integrationPartner.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleEdit(params);
              }}
            >
              <Icon name="edit" />
            </IconButton>
          </>
        );
      },
    },
  ];
};

let groupsRequestController: AbortController | undefined;

const OrgManagementGroupsGrid = () => {
  const params = useParams();
  const { organizationId } = params;

  const navigate = useNavigate();

  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [orgName, setOrgName] = useState('');
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getGroups = async () => {
    if (organizationId) {
      setLoading(true);
      if (groupsRequestController) {
        groupsRequestController.abort();
      }
      try {
        groupsRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.ddl.getGroupsByOrganizationPaginated({
            controller: groupsRequestController,
            params: { ...state },
            filters: filters,
            organizationId,
          }),
        });

        if (response) {
          if (response.items.length > 0) {
            const titleData = response.items[0].organization.name;
            setOrgName(titleData);
          }
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          groupsRequestController = undefined;
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const onSuccess = async (group: any) => {
    await getGroups();

    if (group.integrationPartnerName === 'Dutchie') {
      if (!group.dtStoreName) {
        showDialog({
          content: <MatchDutchieStore group={group} />,
          size: 'sm',
        });
      }
    }
  };

  const showEditDialog = (params: any) => {
    const { row } = params;

    showDialog({
      content: (
        <EditGroup
          groupId={row.id}
          organizationId={organizationId || ''}
          onSuccess={onSuccess}
        />
      ),
      size: 'md',
    });
  };

  const navigateToEmployees = (id: string) => {
    navigate(`/admin/ddl/org-management/${organizationId}/group/${id}`);
  };

  useEffect(() => {
    getGroups();
  }, [filters, state]);

  return (
    <PageContainer title={orgName} subtitle="Organization Management">
      <DataGrid
        rows={gridData.rows}
        columns={columns(showEditDialog, navigateToEmployees)}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const OrgManagementGroups = () => (
  <ControlledDataGrid>
    <OrgManagementGroupsGrid />
  </ControlledDataGrid>
);

export default OrgManagementGroups;
