import { useState, useEffect } from 'react';

import Button from 'components/buttons/Button';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { DeleteIcon, BundleIcon, AddIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import DeleteBundle from '../dialogs/DeleteBundle';
import OrganizationBundleForm from '../forms/OrganizationBundleForm';

import resources from '../resources';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Bundle Name',
  },
];

const BundlesGrid = ({ organizationId }: { organizationId: string }) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const { showDialog, hideDialog } = useDialogDispatcher();

  const showAddBundleDialog = () => {
    showDialog({
      content: (
        <OrganizationBundleForm
          organizationId={organizationId}
          onSuccess={getOrganizationBundles}
        />
      ),
      size: 'sm',
    });
  };

  const showDeleteDialog = (values: any) => {
    if (!values.name || !values.id) {
      return;
    }

    showDialog({
      content: (
        <DeleteBundle
          onSuccess={getOrganizationBundles}
          hideDialog={hideDialog}
          name={values.name}
          organizationBundle={values.bundleOrganizations[0]}
        />
      ),
      size: 'sm',
    });
  };

  useEffect(() => {
    getOrganizationBundles();
  }, [filters, state]);

  const getOrganizationBundles = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: resources.organization.getOrganizationBundlesPaginated({
          organizationId: organizationId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: 'Action',
      field: 'action',
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            {/* <IconButton onClick={() => showUserList(params.row)}>
              <EditIcon />
            </IconButton> */}
            <IconButton onClick={() => showDeleteDialog(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      title="Organization Bundles"
      icon={<BundleIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label="Add Bundle"
          onClick={showAddBundleDialog}
          startIcon={<AddIcon />}
        />
      }
      isSecondary
      elevation={0}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const Bundles = ({ organizationId }: { organizationId: string }) => (
  <ControlledDataGrid>
    <BundlesGrid organizationId={organizationId} />
  </ControlledDataGrid>
);

export default Bundles;
