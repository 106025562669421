import { useEffect, useState } from 'react';

import { useCallService } from 'hooks';

import { Box } from '@mui/material';

import httpRoutes from 'utils/httpRoutes';

import CourseCompletionsLoading from './CourseCompletionsLoading';

let courseCompletionsRequestController: AbortController | undefined;

const CourseCompletions = ({
  courseId,
  startDate,
  endDate,
  state,
  name,
  setLoading,
  loading,
}: {
  courseId: string;
  startDate: Date | null;
  endDate: Date | null;
  state?: string;
  name?: string;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
}) => {
  const { callService } = useCallService();

  const [completions, setCompletions] = useState({
    allTimeCompletions: 0,
    activeUsersCompletions: 0,
  });

  const getCourseCompletions = async () => {
    if (courseCompletionsRequestController)
      courseCompletionsRequestController.abort();
    try {
      if (courseId) {
        courseCompletionsRequestController = new AbortController();
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getCourseCompletions(
            courseId,
            startDate,
            endDate,
            state,
            name,
            courseCompletionsRequestController
          ),
        });

        if (response) {
          const { allTimeCompletions, activeUsersCompletions } = response;
          setCompletions({
            allTimeCompletions,
            activeUsersCompletions,
          });
          courseCompletionsRequestController = undefined;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState: any) => ({
        ...prevState,
        courseCompletions: false,
      }));
    }
  };

  useEffect(() => {
    getCourseCompletions();
  }, [courseId, loading.courseCompletions === true]);

  return (
    <Box
      sx={{
        borderRadius: '15px',
        height: '159px',
        background: 'linear-gradient(to right, #A8D5BA 50%, #D3EEFF 50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '10px',
            lineHeight: '130%',
            textTransform: 'uppercase',
            color: '#205072',
          }}
        >
          {startDate && endDate ? 'New' : 'Total'} Completions
        </Box>
        <Box>
          {loading.courseCompletions ? (
            <CourseCompletionsLoading />
          ) : (
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '200',
                fontSize: '40px',
                lineHeight: '56px',
                color: '#205072',
              }}
            >
              {completions.allTimeCompletions}
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '10px',
            lineHeight: '130%',
            textTransform: 'uppercase',
            color: '#205072',
          }}
        >
          Active Completions
        </Box>
        <Box>
          {loading.courseCompletions ? (
            <CourseCompletionsLoading />
          ) : (
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '200',
                fontSize: '40px',
                lineHeight: '56px',
                color: '#205072',
              }}
            >
              {completions.activeUsersCompletions}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CourseCompletions;
