import React, { useState, useEffect } from 'react';

import { Box, Grid, Button } from '@mui/material';
import { SaveIcon } from 'material-icons';
import { LoadingButton } from '@mui/lab';

import Communities from './steps/Communities';
import Summary from './steps/Summary';
import Stepper from '../../../components/Stepper';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useMultistepForm } from 'hooks/useMultistepForm';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { AssignCourseType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';
import CourseList from './steps/CourseList';
import AssignmentTypeStep from './steps/AssignmentTypeStep';

export interface AssignCourseFormInput extends AssignCourseType {
  organizationId: string;
}

type Step = {
  component: React.ReactNode;
  title: string;
};

const MultiStepForm = ({
  organizationId,
  isOrganizationManager,
  isSubmitting,
  onSubmit,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
  isSubmitting: boolean;
  onSubmit: (values: any) => void;
}) => {
  const { assignCourseState } = useAssignCourseDispatcher();
  const { name, assignmentType, coursesAssignations, groupsIds } =
    assignCourseState;
  const { hideDialog } = useDialogDispatcher();
  const [steps, setSteps] = useState<any>([]);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    const newSteps = [
      {
        component: (
          <Communities
            organizationId={organizationId}
            isOrganizationManager={isOrganizationManager}
          />
        ),
        title: 'Communities',
      },
      {
        component: <CourseList />,
        title: 'Courses',
      },
      {
        component: <AssignmentTypeStep />,
        title: 'Assignment Type',
      },
      {
        component: <Summary />,
        title: 'Review & Save',
      },
    ];
    setSteps(newSteps);
  }, [isSubmitting, assignCourseState]);

  const stepsComponents = steps.map((step: Step) => step.component);

  const { step, isFirstStep, isLastStep, back, next, goTo, currentStepIndex } =
    useMultistepForm(stepsComponents);

  const handleMultiStepSubmit = async () => {
    if (!isLastStep) {
      setCompleted({ ...completed, [currentStepIndex]: true });
      next();
    } else {
      const body: AssignCourseFormInput = {
        ...assignCourseState,
        organizationId: organizationId,
      };

      onSubmit(body);
    }
  };

  const stepsTitles = steps.map((step: Step) => step.title);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        nonLinear={true}
        alternativeLabel={true}
        steps={stepsTitles}
        currentStepIndex={currentStepIndex}
        goTo={goTo}
        completedSteps={completed}
      />
      {step}
      <Grid container justifyContent="space-between" columnSpacing={1}>
        <Button disabled={isSubmitting} variant="text" onClick={hideDialog}>
          Cancel
        </Button>
        <Box>
          {!isFirstStep && (
            <Button disabled={isSubmitting} variant="text" onClick={back}>
              Back
            </Button>
          )}
          <LoadingButton
            type="button"
            variant="contained"
            onClick={handleMultiStepSubmit}
            disabled={
              isSubmitting ||
              (currentStepIndex === 0 &&
                (groupsIds.length === 0 || name === '')) ||
              (currentStepIndex === 1 && coursesAssignations.length === 0) ||
              (currentStepIndex === 2 && !assignmentType) ||
              (isLastStep &&
                (name === '' ||
                  groupsIds.length === 0 ||
                  coursesAssignations.length === 0 ||
                  !assignmentType))
            }
            loading={isSubmitting}
            loadingPosition="start"
            {...(isLastStep ? { startIcon: <SaveIcon /> } : {})}
          >
            {isLastStep ? 'Confirm' : 'Next'}
          </LoadingButton>
        </Box>
      </Grid>
    </Box>
  );
};

export default MultiStepForm;
