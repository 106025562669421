import { ReactNode } from 'react';

import { LoadingButton as MUILoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type LoadingButtonProps = {
  label: string;
  onClick?: () => void;
  loading: boolean;
  loadingPosition?: 'start' | 'center' | 'end';
  variant?: 'contained' | 'outlined' | 'text';
  icon?: ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  startIcon?: ReactNode;
  sx?: SxProps<Theme>;
};

const LoadingButton = ({
  label,
  onClick,
  loading,
  loadingPosition = 'center',
  variant = 'contained',
  icon,
  type = 'button',
  disabled,
  startIcon,
  sx,
}: LoadingButtonProps) => {
  return (
    <MUILoadingButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      loadingPosition={loadingPosition}
      variant={variant}
      sx={{
        p: 1,
        background: 'linear-gradient(180deg, #56C596 0%, #16915D 100%)',
        color: 'white',
        borderRadius: '32px',
        paddingLeft: 1.5,
        ...sx,
      }}
      startIcon={startIcon ?? icon}
    >
      {label}
    </MUILoadingButton>
  );
};

export default LoadingButton;
