import statuses from './userStatusesList.json';

export const getUserStatusesOptions = () => {
    const defaultValue = {
        label: 'Any Status',
        value: '',
    };

    const userStatuses = statuses.map((s) => ({ label: s.label, value: s.value }));

    userStatuses.unshift(defaultValue);

    return userStatuses;
};
