import { useState, useEffect } from 'react';

import { Box, Divider, Skeleton } from '@mui/material';

import GroupForm, { IGroupFormInput } from '../forms/GroupForm';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import { getIntegrationPartnerName } from '../utils';

const EditGroup = ({
  groupId,
  organizationId,
  onSuccess,
}: {
  groupId: string;
  organizationId: string;
  onSuccess: (group: any) => void;
}) => {
  const [group, setGroup] = useState<any>({});
  const [groupLoading, setGroupLoading] = useState(true);

  const [hcOrganizationId, setHcOrganizationId] = useState<any>('');
  const [organizationLoading, setOrganizationLoading] = useState(true);

  const [integrationPartnerOptions, setIntegrationPartnerOptions] = useState<
    any[]
  >([]);
  const [
    integrationPartnerOptionsLoading,
    setIntegrationPartnerOptionsLoading,
  ] = useState(true);

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const onSubmit = async (values: IGroupFormInput) => {
    const integrationPartnerName = getIntegrationPartnerName(
      integrationPartnerOptions,
      values.integrationPartnerId
    );

    const isHappyCabbageForm = integrationPartnerName === 'Happy Cabbage';

    const formattedValues = {
      ...values,
      hcStoreId: isHappyCabbageForm ? values.hcStoreId : null,
      primaryKey: isHappyCabbageForm ? null : values.primaryKey,
      secondaryKey: isHappyCabbageForm ? null : values.secondaryKey,
    };

    const { response } = await callService({
      resource: httpRoutes.ddl.updateGroup({
        values: formattedValues,
        groupId,
        organizationId: group.organization.id,
      }),
      successMessage: 'Group successfully updated!',
    });

    if (response) {
      onSuccess({ ...group, integrationPartnerName });
      hideDialog();
    }
  };

  const getGroup = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.ddl.getOneGroupById(groupId),
      });

      if (response) {
        const {
          id,
          name,
          ddlActivated,
          ddlOnboardingStatus,
          hcGroupMap,
          userGroups,
          organization,
          groupKpis,
        } = response;

        const safeHcGroupMap = hcGroupMap || {};

        const {
          hcStoreId = '',
          primaryKey = '',
          secondaryKey = '',
          integrationPartnerId = '',
          hcOrganizationMap = {},
        } = safeHcGroupMap;

        const safeHcOrganizationMap = hcOrganizationMap || {};

        const hcOrganizationId = safeHcOrganizationMap.hcOrganizationId || '';

        setGroup({
          id,
          name,
          hcStoreId,
          primaryKey,
          secondaryKey,
          hcOrganizationId,
          ddlActivated,
          integrationPartnerId,
          ddlOnboardingStatus,
          dtStoreName: safeHcGroupMap.dtStoreName,
          groupKpis: groupKpis.map((item: any) => item.kpi?.id),
          userIds: userGroups
            .filter((ug: any) => ug.ddl)
            .map((ug: any) => ug.userId),
          organization,
        });
      }
    } finally {
      setGroupLoading(false);
    }
  };

  const getOrganization = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.ddl.getOneOrganizationById(organizationId),
      });

      if (response) {
        const { hcOrganizationMap } = response;

        setHcOrganizationId(hcOrganizationMap?.hcOrganizationId || '');
      }
    } finally {
      setOrganizationLoading(false);
    }
  };

  const getIntegrationPartner = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.integrationPartner.getIntegrationPartner(),
      });

      if (response) {
        const _options = response
          .filter((item: any) => item.name !== 'Sweed') // TODO remove this when Sweed is ready
          .map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          });

        setIntegrationPartnerOptions(_options);
      }
    } finally {
      setIntegrationPartnerOptionsLoading(false);
    }
  };

  useEffect(() => {
    getIntegrationPartner();
  }, []);

  useEffect(() => {
    getOrganization();
  }, []);

  useEffect(() => {
    getGroup();
  }, []);

  if (groupLoading || organizationLoading) {
    return (
      <Box>
        <Skeleton variant="rectangular" height={300} width="100%" />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '32px',
            lineHeight: '130%',
          }}
        >
          {group.name}
        </Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '130%',
          }}
        >
          {group.organization.name}
        </Box>
      </Box>
      <Divider
        sx={{
          my: 3,
        }}
      />
      <GroupForm
        onSubmit={onSubmit}
        defaultValues={{ ...group, hcOrganizationId }}
        groupId={groupId}
        integrationPartnerOptions={integrationPartnerOptions}
        integrationPartnerOptionsLoading={integrationPartnerOptionsLoading}
      />
    </Box>
  );
};

export default EditGroup;
