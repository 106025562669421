import { Box, Divider, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import EmployeeForm, { IEmployeeFormInput } from '../forms/EmployeeForm';

const EditEmployee = ({
  employee,
  onSuccess,
}: {
  employee: any;
  onSuccess: VoidFunction;
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const {
    id: employeeId,
    name,
    email,
    groupName,
    groupId,
    organizationName,
    posId,
    notes,
    isEnrolled,
    userPosId,
    isAlreadyMapped,
  } = employee;

  const defaultValues: IEmployeeFormInput = {
    posId,
    notes,
    ddlActivated: isEnrolled,
    userPosId,
  };

  const onSubmit = async (values: IEmployeeFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.ddl.updateEmployee({
        values,
        employeeId,
        groupId,
      }),
      successMessage: 'Employee successfully updated!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid xs={12} md={6} container item rowSpacing={matchDownMD ? 0 : 2}>
          <Grid
            xs={12}
            item
            container
            alignItems="center"
            sx={{ display: 'flex' }}
          >
            <Grid item xs={2}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '12px' : '14px',
                  lineHeight: matchDownMD ? '20px' : '24px',
                  letterSpacing: '0.14px',
                  color: '#1A1A1A',
                }}
              >
                Name:
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '20px' : '20px',
                  lineHeight: matchDownMD ? '38px' : '130%',
                  wordBreak: 'break-word',
                  color: '#1A1A1A',
                }}
              >
                {name}
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} item alignItems="center" sx={{ display: 'flex' }}>
            <Grid item xs={2}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '12px' : '14px',
                  lineHeight: matchDownMD ? '20px' : '24px',
                  letterSpacing: '0.14px',
                  color: '#1A1A1A',
                }}
              >
                Email:
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '20px' : '20px',
                  lineHeight: matchDownMD ? '38px' : '130%',
                  wordBreak: 'break-word',
                  color: '#1A1A1A',
                }}
              >
                {email}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} container item rowSpacing={matchDownMD ? 0 : 2}>
          <Grid
            xs={12}
            item
            container
            alignItems="center"
            sx={{ display: 'flex' }}
          >
            <Grid item xs={2}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '12px' : '14px',
                  lineHeight: matchDownMD ? '20px' : '24px',
                  letterSpacing: '0.14px',
                  color: '#1A1A1A',
                }}
              >
                Location:
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '20px' : '20px',
                  lineHeight: matchDownMD ? '38px' : '130%',
                  wordBreak: 'break-word',
                  color: '#1A1A1A',
                }}
              >
                {groupName}
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} item alignItems="center" sx={{ display: 'flex' }}>
            <Grid item xs={2}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '12px' : '14px',
                  lineHeight: matchDownMD ? '20px' : '24px',
                  letterSpacing: '0.14px',
                  color: '#1A1A1A',
                }}
              >
                Org:
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '700',
                  fontSize: matchDownMD ? '20px' : '20px',
                  lineHeight: matchDownMD ? '38px' : '130%',
                  wordBreak: 'break-word',
                  color: '#1A1A1A',
                }}
              >
                {organizationName}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          mt: 8,
          mb: 1,
        }}
      />
      <EmployeeForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        isAlreadyMapped={isAlreadyMapped}
      />
    </Box>
  );
};

export default EditEmployee;
