import { FC } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { AssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

const Summary: FC<any> = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { assignCourseState } = useAssignCourseDispatcher();
  const { name, groupsIds, coursesAssignations, assignmentType, learners } =
    assignCourseState;

  const assignmentTypeLabel = () => {
    switch (assignmentType) {
      case AssignmentType.Current:
        return `Current Users: This will affect up to ${learners} current users`;
      case AssignmentType.Future:
        return 'Futuer Users: This will only affect new users and no current users';
      case AssignmentType.CurrentAndFuture:
        return `Current and Future Users: This will affect up to ${learners} current users and ALL NEW users`;
      default:
        return '';
    }
  };

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid
        container
        columnSpacing={1}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={12} mb={1}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Assignment Summary
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Raleway',
              marginTop: '15px',
              textAlign: 'center',
            }}
          >
            Please review your assignment before saving
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'Raleway',
              marginTop: '15px',
              textAlign: 'center',
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="h2"
            sx={{
              marginTop: '15px',
              textAlign: 'center',
            }}
          >
            {groupsIds.length}{' '}
            {groupsIds.length > 1 ? 'Communities' : 'Community'}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
            }}
          >
            {coursesAssignations.length}{' '}
            {coursesAssignations.length > 0 ? 'Courses' : 'Course'}
          </Typography>
        </Grid>

        <Grid container rowSpacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12} mb={1}>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', mt: 2, fontWeight: 'normal' }}
            >
              {assignmentTypeLabel()}
            </Typography>
          </Grid>

          <Grid item xs={12} mb={1} sx={{ margin: 'auto' }}>
            <Grid item xs={6} sx={{ margin: 'auto' }}>
              <Typography
                variant="h4"
                sx={{ textAlign: 'center', mt: 2, fontWeight: 'normal' }}
              >
                Some users may not be assigned due to their managerial status or
                if they`ve already completed the course.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Grid item xs={6} sx={{ margin: 'auto' }}>
              <Typography
                variant="h4"
                sx={{ textAlign: 'center', mt: 2, fontWeight: 'normal' }}
              >
                <span style={{ fontWeight: 'bold' }}>Please Note: </span>
                By clicking confirm, an email will be sent to all users to
                inform them of their new assignments.{' '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
