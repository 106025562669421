import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { formatCurrency, formatPercentage } from 'utils/common';

import Tag from '../components/Tag';
import { renderBoxColumn } from 'utils/tableRendering';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Search by employee',
  },
];
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Employee',
    field: 'name',
    sortable: true,
    ColumnComponent: (params: any) => {
      return (
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontSize: '16px',
            color: 'black',
          }}
        >
          {`${params.row.userFirstName} ${params.row.userLastName}`}
        </Box>
      );
    },
  },
  {
    headerName: 'Assigned',
    field: 'assignedOn',
    sortable: true,
    type: 'date',
    ColumnComponent: (params: any) => {
      if (params.row.assignedOn) {
        return (
          <>{new Date(params.row.assignedOn).toLocaleDateString('en-US')}</>
        );
      }
      return <>--</>;
    },
  },
  {
    headerName: 'Days to Complete',
    field: 'daysAssigned',
    sortable: true,
    ColumnComponent: (params: any) => {
      return params.row.daysAssigned
        ? params.row.daysAssigned >= 0
          ? params.row.daysAssigned
          : '0'
        : '0';
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    ColumnComponent: (params: any) => {
      const status = params.row.status;
      switch (status) {
        case 'Assigned':
          return (
            <Tag
              label="Assigned"
              labelColor="#5A6376"
              backgroundColor="#E9EDF5"
            />
          );
        case 'Completed':
          return (
            <Tag
              label="Completed"
              labelColor="#14804A"
              backgroundColor="#E1FCEF"
            />
          );
        case 'Past Due':
          return (
            <Tag
              label="Past Due"
              labelColor="#D1293D"
              backgroundColor="#FFEDEF"
            />
          );
        default:
          return (
            <Tag
              label="Assigned"
              labelColor="#205072"
              backgroundColor="#BCC6CF"
            />
          );
      }
    },
  },
  {
    headerName: '14 Day Growth',
    field: 'dollarsAdded14Day',
    sortable: true,
    type: 'usdCurrency',
    ColumnComponent: (params: any) =>
      renderBoxColumn(params.row.dollarsAdded14Day, formatCurrency),
  },
  {
    headerName: '14 Day % Change',
    field: 'uplift14Day',
    sortable: true,
    type: 'percentage',
    ColumnComponent: (params: any) =>
      renderBoxColumn(params.row.uplift14Day, formatPercentage),
  },
  {
    headerName: '30 Day Growth',
    field: 'dollarsAdded30Day',
    sortable: true,
    type: 'usdCurrency',
    ColumnComponent: (params: any) =>
      renderBoxColumn(params.row.dollarsAdded30Day, formatCurrency),
  },
  {
    headerName: '30 Day % Change',
    field: 'uplift30Day',
    sortable: true,
    type: 'percentage',
    ColumnComponent: (params: any) =>
      renderBoxColumn(params.row.uplift30Day, formatPercentage),
  },
];

let groupsRequestController: AbortController | undefined;

const SingleStoreEmployeeGrid = ({
  kpiId,
  groupId,
}: {
  kpiId: string;
  groupId: string;
}) => {
  const loadingState = {
    employees: true,
    exporting: false,
  };

  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getEmployees = async () => {
    setLoading((prevState) => ({ ...prevState, employees: true }));
    if (groupsRequestController) {
      groupsRequestController.abort();
    }
    try {
      groupsRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.getSingleStoreEmployeeStatusByGroup({
          controller: groupsRequestController,
          params: { ...state },
          filters: filters,
          groupId,
          kpiId,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        groupsRequestController = undefined;
        setLoading((prevState) => ({ ...prevState, employees: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, employees: false }));
    }
  };
  const exportEmployees = async () => {
    setLoading((prevState) => ({ ...prevState, exporting: true }));
    if (groupsRequestController) {
      groupsRequestController.abort();
    }
    try {
      groupsRequestController = new AbortController();
      const { response } = await callService({
        resource: httpRoutes.ddl.exportSingleStoreEmployeeStatusByGroup({
          controller: groupsRequestController,
          filters: filters,
          groupId,
          kpiId,
        }),
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            employee,
            assignedOn,
            daysAssigned,
            status,
            dollarsAdded14Day,
            uplift14Day,
            dollarsAdded30Day,
            uplift30Day,
          } = item;

          return {
            Employee: employee,
            Assigned: new Date(assignedOn).toLocaleDateString('en-US'),
            'Days to complete': daysAssigned
              ? daysAssigned >= 0
                ? daysAssigned
                : '0'
              : '0',
            Status: status,
            '14 Day Growth': formatCurrency(dollarsAdded14Day),
            '14 Day % Change': formatPercentage(uplift14Day),
            '30 Day Growth': formatCurrency(dollarsAdded30Day),
            '30 Day % Change': formatPercentage(uplift30Day),
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Single store employee.csv');
        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }
    } catch (e) {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };
  useEffect(() => {
    getEmployees();
  }, [filters, state, groupId]);

  return (
    <DataGrid
      tableTitle="Employees Assigned Uplift Course"
      rows={gridData.rows}
      columns={columns}
      filters={filterModel}
      handleFilterChange={setFilters}
      handleExport={exportEmployees}
      totalRows={gridData.totalRows}
      loading={loading.employees}
    />
  );
};

const SingleStoreEmployees = ({
  kpiId,
  groupId,
}: {
  kpiId: string;
  groupId: string;
}) => (
  <ControlledDataGrid>
    <SingleStoreEmployeeGrid kpiId={kpiId} groupId={groupId} />
  </ControlledDataGrid>
);

export default SingleStoreEmployees;
