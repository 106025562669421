import { useEffect, useState, ReactNode, SyntheticEvent } from 'react';
// material-ui
import { Box, Tab, Tabs, Typography } from '@mui/material';

import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';

import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import userResources from 'features/user/resources';

import GeneralInfo, { INITIAL_PROFILE_STATE } from '../fragments/GeneralInfo';
import Security from '../fragments/Security';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const [generalInfo, setGeneralInfo] = useState(INITIAL_PROFILE_STATE);

  const { callService } = useCallService();

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handlePasswordChangeSuccess = () => {
    setSelectedTab(0);
  };

  const getProfile = async () => {
    try {
      setIsLoading(true);

      const { response } = await callService({
        resource: userResources.getUserProfile(),
      });

      if (response) {
        setGeneralInfo(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <PageContainer
      title="User Profile"
      icon={<FolderSharedOutlinedIcon sx={{ fontSize: 36 }} />}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="user-profile-tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="General Info" {...a11yProps(0)} />
            <Tab label="Security" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <GeneralInfo defaultValues={generalInfo} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Security onSuccess={handlePasswordChangeSuccess} />
        </TabPanel>
      </Box>
    </PageContainer>
  );
};

export default Profile;
