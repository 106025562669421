import React from 'react';
import {Skeleton, Box, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

const StatsLoading = () => {
  const theme = useTheme();
  const matchUpMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {[...Array(4)].map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              p: 4,
              borderRadius: '15px',
              backgroundColor: 'white',
              flex: '0.25 0 auto',
              ...(matchUpMD && { width: '100%' }),
              height: '291px',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                flexDirection: 'column',
                display: 'flex',
                gap: 4,
                height: '100%',
                alignItems: 'center',
              }}
            >
            <Skeleton variant="rectangular" height="100%" width="100%" />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatsLoading;
