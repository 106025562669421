/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useDropzone, DropzoneOptions, Accept } from 'react-dropzone';
import { Grid, Typography } from '@mui/material';
import { UploadFileIcon } from 'material-icons';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

interface DropzoneScormProps {
  handleAcceptedFiles: (values: File[]) => void;
  defaultValue?: string;
  helperText?: string;
  maxFiles?: number;
  maxSize?: number;
}

const DropzoneScorm = ({
  handleAcceptedFiles,
  helperText,
  maxFiles = 1,
}: DropzoneScormProps) => {
  const [value, setValue] = useState('');

  const accept: Accept = {
    'application/zip': ['.zip'],
    'application/x-zip-compressed': ['.zip'],
  };

  function getFilename(fullPath: string) {
    return fullPath.replace(/^.*[\\/]/, '');
  }

  const options: DropzoneOptions = {
    accept: accept,
    // maxSize: maxSize,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles) {
        const { name } = acceptedFiles[0];
        setValue(getFilename(name));
        handleAcceptedFiles(acceptedFiles);
      } else {
        setValue('');
        handleAcceptedFiles([]);
      }
    },
    onDropRejected: (rejectedFiles: any[]) => {
      const _fileErrors = rejectedFiles.map((errors) => {
        return errors;
      });

      console.log(_fileErrors);
      // setErrors(_fileErrors);
    },
  };

  const { getRootProps, getInputProps } = useDropzone(options);

  const _helperText = helperText
    ? helperText
    : 'Drag and drop some files here, or click to select files';

  return (
    <Grid
      sx={{
        mt: 1,
        mb: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        background:
          value === ''
            ? 'repeating-linear-gradient(45deg, lightGray, white 10%)'
            : '',
        width: '400px',
        height: '200px',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
      }}
      {...getRootProps({ className: 'dropzone' })}
    >
      {value === '' && (
        <div>
          <input {...getInputProps()} />
          <Grid container flexDirection="row" justifyContent="center">
            <UploadFileIcon sx={{ fontSize: '64px' }} />
            <Grid container justifyContent="center">
              <Typography variant="caption" textAlign="center">
                {_helperText}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}

      {value !== '' && (
        <div>
          <Grid container flexDirection="row" justifyContent="center">
            <Grid container justifyContent="center">
              <Typography variant="caption" textAlign="center">
                {value}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  );
};

export default DropzoneScorm;
