import { Tabs } from '@mui/material';

interface StyledTabsProps {
  value: any;
  color: string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  children: any;
}

function StyledTabs(props: StyledTabsProps) {
  return (
    <Tabs
      selectionFollowsFocus
      value={props.value}
      onChange={props.onChange}
      aria-label="group tab"
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { background: props.color },
      }}
    >
      {props.children}
    </Tabs>
  );
}

export default StyledTabs;
