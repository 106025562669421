import * as React from 'react';

export default (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.08 18.593h-6.3a.75.75 0 010-1.5h6.3a.75.75 0 010 1.5zM19.19 8.9h-6.3a.75.75 0 010-1.5h6.3a.75.75 0 010 1.5z"
    />
    <mask
      id="prefix__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={3}
      y={5}
      width={7}
      height={7}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5h6.226v6.192H3V5z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.113 6.5c-.89 0-1.613.716-1.613 1.597 0 .88.724 1.595 1.613 1.595.89 0 1.613-.715 1.613-1.595 0-.881-.723-1.597-1.613-1.597zm0 4.692C4.397 11.192 3 9.804 3 8.097A3.11 3.11 0 016.113 5a3.109 3.109 0 013.113 3.097c0 1.707-1.396 3.095-3.113 3.095z"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.388 16.208c-.89 0-1.614.716-1.614 1.596 0 .881.724 1.596 1.614 1.596.889 0 1.612-.715 1.612-1.596 0-.88-.723-1.596-1.612-1.596zm0 4.692c-1.717 0-3.114-1.389-3.114-3.096s1.397-3.096 3.114-3.096a3.108 3.108 0 013.112 3.096 3.108 3.108 0 01-3.112 3.096z"
    />
  </g>
);
