import { useState, useEffect } from 'react';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import useCallService from 'hooks/useCallService';
import httpRoutes from 'utils/httpRoutes';
import ManageCourseWrapper from 'components/courseBuilder/ManageCourseWrapper';
import { delay } from 'utils/common';

const EditCourse = ({
  courseId,
  handleCourseEvent,
}: {
  courseId?: string;
  handleCourseEvent: (event: any) => void;
}) => {
  const {
    authState: { organization, user },
  } = useAuthState();

  const { callService } = useCallService();

  const [url, setUrl] = useState('');
  const [retry, setRetry] = useState(3);

  const getCourseEditUrl = async () => {
    if (!user.id || !organization.id || !courseId) {
      return;
    }

    const requestData: {
      userId: string;
      courseId: string;
      organizationId: string;
    } = {
      userId: user.id,
      courseId,
      organizationId: organization.id,
    };

    const { response } = await callService({
      resource: httpRoutes.courseBuilder.getCourseEditUrl(requestData),
    });

    if (response) {
      const url = encodeURI(`${response}`);

      setUrl(url);
    } else {
      await delay(500);
      if (retry > 0) {
        setRetry(retry - 1);
        getCourseEditUrl();
      }
    }
  };

  useEffect(() => {
    getCourseEditUrl();
  }, [courseId]);

  if (url === '') {
    return <div>Loading...</div>;
  }

  return (
    <ManageCourseWrapper
      coassembleURL={url}
      handleCourseEvent={handleCourseEvent}
    />
  );
};

export default EditCourse;
