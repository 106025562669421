import React, { useState, useEffect } from 'react';
import { Avatar, Box, Grid, Skeleton, useMediaQuery } from '@mui/material';
import CircularProgress from '../../../../shared/CircularProgress';
import { lighten } from 'polished';
import { useTheme } from '@mui/material/styles';
import Icon from 'icons';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

type LearnersStats = {
  totalLearners: number;
  learnersAtLeastCompletedOneCoursePercentage: number;
  learnersHaveNotStartedAnyCoursePercentage: number;
  learnersAtLeastCompletedOneCourse: number;
  learnersHaveNotStartedAnyCourse: number;
};

const LearnersStats = () => {
  const theme = useTheme();
  const matchUpMD = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();

  const [learnersStats, setLearnersStats] = useState<LearnersStats>({
    totalLearners: 0,
    learnersAtLeastCompletedOneCourse: 0,
    learnersHaveNotStartedAnyCourse: 0,
    learnersAtLeastCompletedOneCoursePercentage: 0,
    learnersHaveNotStartedAnyCoursePercentage: 0,
  });

  const [loading, setLoading] = useState(true);

  const getLearnersStats = async () => {
    try {
      const { response } = await callService({
        resource:
          httpRoutes.reporting.learner.getLearnerReportLearnersStatsAsFacilitator(),
      });

      if (response) {
        const {
          totalLearners,
          learnersAtLeastCompletedOneCourse,
          learnersHaveNotStartedAnyCourse,
        } = response;

        const learnersHaveNotStartedAnyCoursePercentage =
          Math.round(
            (learnersHaveNotStartedAnyCourse * 100) / Number(totalLearners)
          ) || 0;

        const learnersAtLeastCompletedOneCoursePercentage =
          Math.round(
            (learnersAtLeastCompletedOneCourse * 100) / Number(totalLearners)
          ) || 0;

        setLearnersStats({
          totalLearners,
          learnersHaveNotStartedAnyCourse,
          learnersAtLeastCompletedOneCourse,
          learnersAtLeastCompletedOneCoursePercentage,
          learnersHaveNotStartedAnyCoursePercentage,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLearnersStats();
  }, []);

  return (
    <>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '1 0 auto',
          display: 'flex',
          ...(matchUpMD && { width: '100%' }),
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100px" />
        ) : (
          <Grid container rowGap={4}>
            <Grid
              item
              xl={3}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box>
                <CircularProgress
                  value={
                    learnersStats.learnersAtLeastCompletedOneCoursePercentage
                  }
                  color="#56C596"
                />
              </Box>
            </Grid>
            <Grid item xl={9} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#12D1D0'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon name="three-users" size="extraLarge" fill="#12D1D0" />
                  </Avatar>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '40px',
                      lineHeight: '120%',
                      color: '#56C596',
                    }}
                  >
                    {learnersStats.learnersAtLeastCompletedOneCourse}
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    lineHeight: '120%',
                    color: '#205072',
                    maxWidth: '250px',
                  }}
                >
                  learners have completed at least one course
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '1 0 auto',
          display: 'flex',
          ...(matchUpMD && { width: '100%' }),
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="100px" />
        ) : (
          <Grid container rowGap={4}>
            <Grid
              item
              xl={3}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box>
                <CircularProgress
                  value={
                    learnersStats.learnersHaveNotStartedAnyCoursePercentage
                  }
                  color="#FF5572"
                />
              </Box>
            </Grid>
            <Grid item xl={9} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: lighten(0.3, '#12D1D0'),
                      opacity: '0.9',
                      width: 62,
                      height: 62,
                    }}
                  >
                    <Icon name="three-users" size="extraLarge" fill="#12D1D0" />
                  </Avatar>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: '300',
                      fontSize: '40px',
                      lineHeight: '120%',
                      color: '#FF5572',
                    }}
                  >
                    {learnersStats.learnersHaveNotStartedAnyCourse}
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    lineHeight: '120%',
                    maxWidth: '250px',
                    color: '#205072',
                  }}
                >
                  learners haven’t started any courses
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default LearnersStats;
