import { Controller, FieldError } from 'react-hook-form';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Chip,
  ListItemText,
} from '@mui/material';

export type MultiSelectOptionProps = {
  value: string | number;
  label: string | number;
  abbr?: string;
};

interface MultiSelectFormFieldProps {
  name: string;
  label: string;
  control: any;
  options: MultiSelectOptionProps[];
  multiple?: boolean;
  errors?: FieldError[] | FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  onChange?: any;
}

const ErrorMessage = (props: any) => {
  if (props.errors.length > 0) {
    return props.errors.map((error: FieldError, index: number) => 
            <span key={`MultiSelectFormField_ErrorMessage_${index}`}>{error?.message}</span>);
  }

  return <span>{props.errors.message}</span>;
};

const handleChange = (field: any, onChange: any) => (event: any) => {
  const value = event.target.value;
  field.onChange(value);
  if (onChange) onChange(value);
};

const MultiSelectFormField = ({
  name,
  label,
  control,
  options,
  errors,
  margin = 'normal',
  required,
  onChange
}: MultiSelectFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{ minWidth: '100%' }}
          margin={margin}
          required={required}
        >
          <InputLabel id={`multi-select-list-${name}`}>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            labelId={`multi-select-list-${name}`}
            error={Boolean(errors)}
            multiple={true}
            fullWidth
            required={required}
            onChange={handleChange(field, onChange)}
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((item: string) => {
                    const _selectedItem = options.find(
                      (option) => option.value === item
                    );

                    if (_selectedItem) {
                      return (
                        <Chip
                          key={_selectedItem.value}
                          label={_selectedItem.label}
                        />
                      );
                    }
                  })}
                </Box>
              );
            }}
          >
            {options.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                <ListItemText primary={o.label} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={Boolean(errors)}>
            {errors && <ErrorMessage errors={errors} />}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default MultiSelectFormField;
