import { Box } from '@mui/material';

import SeedTalentLogoHorizontal from 'assets/images/icons/seed-talent-logo-horizontal.svg';
import SuccessBud from 'assets/images/icons/success-bud.svg';
import FailureBud from 'assets/images/icons/failure-bud.svg';
import CustomButton from 'components/atomic/Button';
import RichTextFormFieldReadOnly from 'components/formFields/RichTextFormFieldReadOnly';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

export interface IAssessmentOutcome {
  examPassed: boolean;
  message: string;
  score: number;
}

const AssessmentOutcome = ({ message, examPassed, score }: IAssessmentOutcome) => {
  const { hideDialog } = useDialogDispatcher();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '30%',
          p: '32px 32px 0 32px',
        }}
      >
        <img height="64px" src={SeedTalentLogoHorizontal} alt="Seed Talent" />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {examPassed ? (
          <img
            style={{ paddingLeft: '33px' }}
            src={SuccessBud}
            alt="Success Bud"
          />
        ) : (
          <img
            style={{ paddingLeft: '33px' }}
            src={FailureBud}
            alt="Failure Bud"
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70%',
          p: '16px 32px 32px 32px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              color: '#000',
              textAlign: 'center',
              fontFamily: 'Raleway',
              fontSize: '32px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
            }}
          >
            {`Your score is ${score}%`}
          </Box>

          <RichTextFormFieldReadOnly
            name={''}
            value={message}
            sx={{ whiteSpace: 'pre-wrap' }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <CustomButton
            sx={{
              width: '121px',
              borderRadius: '30px',
              height: '38px',
              color: 'white',
              background:
                'linear-gradient(180deg, #4B8994 0%, #2E5170 131.25%)',
            }}
            onClick={hideDialog}
          >
            OKAY
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AssessmentOutcome;
