import { SyntheticEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import PageContainer from 'components/PageContainer';
import {
  TabPanel,
  a11yProps,
  StyledTabs,
  StyledTab,
} from 'components/tabPanel';
import Titles from '../../components/Titles';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useKpiDispatcher } from 'providers/KpiProvider/hooks/useKpiDispatcher';

import { findKpiId } from 'features/ddl/utils';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import { AllEmployees, SingleStore, MultiStore } from './panels';

const HouseBrands = () => {
  const [value, setValue] = useState(0);
  const [name, _setName] = useState('name');
  const [role, _setRole] = useState('role');
  const [groupId, setGroupId] = useState('');
  const [groupsOptions, setGroupsOptions] = useState([]);
  const { getKpis } = useKpiDispatcher();
  const kpiId = findKpiId('pct_sales_house', getKpis());

  const { callService } = useCallService();
  const { authState } = useAuthState();

  const theme: any = useTheme();
  const { green } = theme.palette.common;

  const handleChangeTab = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOnGroupClick = (groupId: string) => {
    setValue(1);
    setGroupId(groupId);
  };

  const handleGroupChange = (groupId: string) => {
    setGroupId(groupId);
  };

  const tabOptions = ['Multi-Store', 'Single-Store', 'Store Employees'];

  let adminOptions = null;
  const adminPanel = [];
  let index = 3;

  //TODO: change the permission for the admin permission label when it's defined
  if (!authState.permissions.includes('communities:facilitator')) {
    tabOptions.pop();
    index = 2;
  }

  adminOptions = tabOptions.map((tabOption, index) => (
    <StyledTab
      key={`StyledTab_${index}`}
      label={tabOption}
      themeColor={green}
      {...a11yProps(index)}
    />
  ));

  for (let i = 0; i < index; i++) {
    adminPanel.push(
      <TabPanel value={value} index={i}>
        <Titles
          title="Data Driven Learning Dashboard"
          name={name}
          role={role}
        />
      </TabPanel>
    );
  }

  const getGroupsOptions = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getManyByDDLFacilitator(kpiId),
      });

      if (response) {
        const options = response.map((group: any) => ({
          label: group.name,
          value: group.id,
        }));

        setGroupsOptions(options);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getGroupsOptions();
  }, []);

  return (
    <PageContainer>
      <Box sx={{ width: '100%', mb: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs value={value} onChange={handleChangeTab} color={green}>
            {adminOptions}
          </StyledTabs>
        </Box>

        {value === 0 && (
          <MultiStore onGroupClick={handleOnGroupClick} kpiId={kpiId} />
        )}
        {value === 1 && (
          <SingleStore
            groupId={groupId}
            kpiId={kpiId}
            onGroupChange={handleGroupChange}
            groupsOptions={groupsOptions}
          />
        )}
      </Box>
      {value === 2 && (
        <AllEmployees
          kpiId={kpiId}
          groupId={groupId}
          onGroupChange={handleGroupChange}
          groupsOptions={groupsOptions}
        />
      )}
    </PageContainer>
  );
};

export default HouseBrands;
