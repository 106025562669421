import * as React from 'react';

export default (
  <path
    d="M18.476 18.152a3.501 3.501 0 00-.004-6.437l-3.125-1.335-1.335-3.134A3.499 3.499 0 009.47 5.388 3.499 3.499 0 007.582 7.25L6.249 10.38l-3.131 1.336a3.507 3.507 0 00.004 6.438l3.127 1.334 1.335 3.134a3.499 3.499 0 004.542 1.86 3.498 3.498 0 001.888-1.864l1.332-3.129 3.125-1.334.004-.003h0zm-.964-2.241l-3.571 1.526a1.223 1.223 0 00-.643.643l-1.523 3.575a1.059 1.059 0 01-1.95.005l-1.526-3.58a1.226 1.226 0 00-.643-.643l-3.571-1.525a1.061 1.061 0 01-.005-1.953l3.576-1.527c.29-.125.52-.355.643-.643l1.523-3.575a1.06 1.06 0 011.95-.006l1.527 3.58c.124.29.354.52.642.643l3.57 1.525a1.06 1.06 0 01.002 1.954zm11.792 7.298l-1.709-.729-.73-1.714a2.594 2.594 0 00-3.368-1.378 2.59 2.59 0 00-1.398 1.383l-.729 1.71-1.713.73a2.594 2.594 0 00-1.377 3.372c.258.63.755 1.134 1.381 1.401l1.708.73.73 1.713a2.59 2.59 0 004.767-.004l.729-1.71 1.708-.729.004-.002a2.597 2.597 0 001.374-3.372 2.6 2.6 0 00-1.378-1.402v.002zm-.96 2.533l-2.155.921a1.225 1.225 0 00-.642.644l-.921 2.16-.145.048-.141-.048-.921-2.16a1.224 1.224 0 00-.642-.643l-2.159-.922-.148-.143.148-.144 2.159-.922c.289-.124.518-.355.642-.643l.92-2.16.143-.122.144.122.92 2.16c.124.29.355.52.643.643l2.251 1.066c.005.064.026.102-.034.122l-.061.021zm5.93-20.025l-2.093-.895-.895-2.101A2.808 2.808 0 0028.68 1h-.003a2.806 2.806 0 00-2.613 1.725l-.892 2.096-2.1.897a2.843 2.843 0 00.006 5.226l2.094.895.894 2.101a2.814 2.814 0 002.611 1.72h.003a2.812 2.812 0 002.608-1.725l.892-2.095 2.094-.895.004-.003a2.846 2.846 0 001.507-3.692 2.839 2.839 0 00-1.512-1.533h.002zm-.958 2.985L30.774 9.79a1.223 1.223 0 00-.642.643l-1.082 2.54a.381.381 0 01-.372.25.387.387 0 01-.372-.244l-1.085-2.545a1.224 1.224 0 00-.642-.643l-2.54-1.085a.404.404 0 01-.004-.742l2.544-1.088c.289-.124.519-.354.642-.643l1.083-2.54a.387.387 0 01.373-.25.377.377 0 01.37.243l1.084 2.547c.124.29.355.52.643.644l2.54 1.085a.402.402 0 01.002.743v-.002z"
    fill="#AA5BB9"
    stroke="#F3E0FA"
    strokeWidth={0.5}
  />
);
