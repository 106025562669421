import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

interface IconButtonProps {
  onClick: any;
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  disabled?: boolean;
}

const IconButton = ({
  onClick,
  children,
  size = 'medium',
  color = 'primary',
  disabled = false,
}: IconButtonProps) => (
  <MuiIconButton
    onClick={onClick}
    size={size}
    color={color}
    disabled={disabled}
  >
    {children}
  </MuiIconButton>
);

export default IconButton;
