import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  MultipleSelectCheckmarksFormField,
  SelectFormField,
  TextFormField,
  ToggleFormField,
} from 'components/formFields';

import LoadingButton from 'components/buttons/LoadingButton';
import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useCallService } from 'hooks';

import { getOnBoardingStatusList } from 'dataSets/ddl/onBoardingStatus';

import httpRoutes from 'utils/httpRoutes';
import { getIntegrationPartnerName } from '../utils';

import OnBoardingStatusTag, {
  IOnBoardingStatusTag,
} from '../../../components/OnBoardingStatusTag';
import { filterKpiOptions } from '../../../utils';
import { SelectOptionProps } from '../../../../../components/formFields/types';
import OboardingSecondaryConfirmation from '../dialogs/OboardingSecondaryConfirmation';

const textFieldStyle = {
  marginTop: '1.5rem',
  background: 'white',
  '& .MuiInputLabel-root': {
    '&.Mui-focused': { color: '#56C596' },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

export interface IGroupFormInput {
  hcStoreId?: string | null;
  ddlOnboardingStatus: string;
  ddlActivated: boolean;
  groupKpis?: string[];
  userIds?: string[];
  integrationPartnerId?: string;
  hcOrganizationId?: string;
  primaryKey?: string | null;
  secondaryKey?: string | null;
}

interface IGroupFormProps {
  defaultValues?: IGroupFormInput;
  onSubmit: (values: IGroupFormInput) => void;
  groupId: string;
  integrationPartnerOptions: any[];
  integrationPartnerOptionsLoading: boolean;
}

const GroupForm = ({
  defaultValues,
  onSubmit,
  groupId,
  integrationPartnerOptions,
  integrationPartnerOptionsLoading,
}: IGroupFormProps) => {
  const theme = useTheme();
  const [options, setOptions] = useState<any[]>([]);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [kpis, setKpis] = useState<SelectOptionProps[]>([]);
  const [openSecondary, setOpenSecondary] = useState(false);
  const [values, setValues] = useState<IGroupFormInput>({
    ddlOnboardingStatus: '',
    ddlActivated: false,
  });

  const matchUpXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const getFacilitators = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.groups.getFacilitators(groupId),
      });

      if (response) {
        setOptions(
          response.map((item: { userId: string; user: any }) => {
            return {
              value: item.userId,
              label: `${item.user.firstName} ${item.user.lastName} (${item.user.email})`,
            };
          })
        );
      }
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getFacilitators();
    getKpis();
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<IGroupFormInput>({
    mode: 'onBlur',
    defaultValues: {
      hcStoreId: defaultValues?.hcStoreId,
      ddlOnboardingStatus: defaultValues?.ddlOnboardingStatus,
      ddlActivated: defaultValues?.ddlActivated,
      groupKpis: defaultValues?.groupKpis,
      userIds: defaultValues?.userIds,
      integrationPartnerId: defaultValues?.integrationPartnerId,
      hcOrganizationId: defaultValues?.hcOrganizationId,
      primaryKey: defaultValues?.primaryKey,
      secondaryKey: defaultValues?.secondaryKey,
    },
    shouldUnregister: false,
  });

  const integrationPartnerId = watch('integrationPartnerId');

  const isHappyCabbageForm =
    getIntegrationPartnerName(
      integrationPartnerOptions,
      integrationPartnerId
    ) === 'Happy Cabbage';

  const getKpis = async () => {
    const { response } = await callService({
      resource: httpRoutes.kpis.getKpis(),
    });

    if (response) {
      setKpis(
        response.map((item: { id: string; name: string; kpi: string }) => {
          return { value: item.id, label: item.name, kpi: item.kpi };
        })
      );
    }
  };

  const filteredKpis = filterKpiOptions(isHappyCabbageForm, kpis);

  const save = (values: IGroupFormInput) => {
    if (isDirty) {
      onSubmit(values);
    } else {
      hideDialog();
    }
  };

  const showOboardingSecondaryConfirmation = (values: IGroupFormInput) => {
    setValues(values);
    setOpenSecondary(true);
  };

  const handleDirtySubmit = (values: IGroupFormInput) => {
    if (values.ddlOnboardingStatus === 'Active' && isDirty) {
      showOboardingSecondaryConfirmation(values);
    } else {
      save(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleDirtySubmit)}>
      <Grid
        container
        alignItems="center"
        columnSpacing={3}
        rowSpacing={3}
        sx={{ mb: matchUpXS ? 3 : 1 }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Enrolled in Data Driven Learning
            </Box>
            <Box>
              <ToggleFormField name="ddlActivated" control={control} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectFormField
            name="integrationPartnerId"
            control={control}
            inputLabelSx={{
              '&.Mui-focused': { color: '#56C596' },
            }}
            selectSx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
            }}
            label="Select POS Integration Partner"
            errors={errors.integrationPartnerId}
            margin="dense"
            disabled={integrationPartnerOptionsLoading}
            options={integrationPartnerOptions}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ visibility: !integrationPartnerId ? 'hidden' : 'visible' }}
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              display: !isHappyCabbageForm ? 'none' : 'block',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="hcOrganizationId"
              control={control}
              label="Happy Cabbage Org ID"
              margin="dense"
              errors={errors.hcOrganizationId}
              disabled={!!defaultValues?.hcOrganizationId}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: isHappyCabbageForm ? 'none' : 'block',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="primaryKey"
              control={control}
              label="Primary Key"
              margin="dense"
              errors={errors.primaryKey}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              display: !isHappyCabbageForm ? 'none' : 'block',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="hcStoreId"
              control={control}
              label="Happy Cabbage Store ID"
              margin="dense"
              errors={errors.hcStoreId}
              disabled={!!defaultValues?.hcStoreId}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: isHappyCabbageForm ? 'none' : 'block',
            }}
          >
            <TextFormField
              sx={textFieldStyle}
              name="secondaryKey"
              control={control}
              label="Secondary Key"
              margin="dense"
              errors={errors.secondaryKey}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <MultipleSelectCheckmarksFormField
              name="groupKpis"
              isSearchable={false}
              limitTags={3}
              options={filteredKpis}
              control={control}
              label="Assign KPIs"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <MultipleSelectCheckmarksFormField
              name="userIds"
              limitTags={3}
              options={options}
              disabled={optionsLoading}
              control={control}
              label="Choose Facilitators to Grant Access"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectFormField
            customMenuComponent={({ label }) => {
              const status =
                typeof label === 'number' ? label.toString() : label;
              return (
                <OnBoardingStatusTag
                  status={status as IOnBoardingStatusTag['status']}
                />
              );
            }}
            renderValue={(value) => <OnBoardingStatusTag status={value} />}
            name="ddlOnboardingStatus"
            control={control}
            inputLabelSx={{
              '&.Mui-focused': { color: '#56C596' },
            }}
            selectSx={{
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#56C596',
              },
            }}
            label="Onboarding Status"
            errors={errors.ddlOnboardingStatus}
            margin="dense"
            options={getOnBoardingStatusList()}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" mt={2}>
        <Button variant="text" onClick={hideDialog}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          label="Save"
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          loadingPosition="start"
          icon={<SaveIcon />}
        />
      </Grid>
      <OboardingSecondaryConfirmation
        save={() => save(values)}
        open={openSecondary}
        setOpen={setOpenSecondary}
      />
    </form>
  );
};

export default GroupForm;
