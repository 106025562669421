import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const pesoFormatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MEX',
});

/**
 * Custom column type to format USD Currency
 * @type {{valueFormatter: (function({value?: *}): string), type: string}}
 */
export const usdCurrency = {
  type: 'number',
  valueFormatter: ({ value }: GridRenderCellParams) =>
    usdFormatter.format(Number(value)),
};

/**
 * Custom column type to format MEX Currency
 * @type {{valueFormatter: (function({value?: *}): string), type: string}}
 */
export const mexCurrency = {
  type: 'number',
  valueFormatter: ({ value }: GridRenderCellParams) =>
    pesoFormatter.format(Number(value)),
};

/**
 * Custom column type to format percentages
 * @type {{valueFormatter: (function({value?: *}): string), type: string}}
 */
export const percentage = {
  type: 'number',
  valueFormatter: ({ value }: GridRenderCellParams) =>
    `${(Number(value) * 100).toPrecision(4)} %`,
};

export const dateTime = {
  type: 'dateTime',
  valueFormatter: ({ value }: GridRenderCellParams) => 'Hello!',
};

export interface DataGridColumnProps extends GridColDef {
  field: string;
  headerName: string;
  description?: string;
  hide?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  type?:
    | 'boolean'
    | 'number'
    | 'percentage'
    | 'string'
    | 'date'
    | 'dateTime'
    | 'usdCurrency'
    | 'mexCurrency';
  flex?: number;
  align?: 'center' | 'left' | 'right';
  ColumnComponent?: any;
}

const DataGridColumn = ({
  field,
  headerName,
  description = '',
  hide = false,
  sortable = false,
  filterable = false,
  flex = 1,
  type = 'string',
  align = 'left',
}: DataGridColumnProps) => {
  if (type === 'usdCurrency') {
    return {
      field,
      headerName,
      description,
      hide,
      sortable,
      filterable,
      flex,
      ...usdCurrency,
    };
  }

  if (type === 'mexCurrency') {
    return {
      field,
      headerName,
      description,
      hide,
      sortable,
      filterable,
      flex,
      ...mexCurrency,
    };
  }

  if (type === 'percentage') {
    return {
      field,
      headerName,
      description,
      hide,
      sortable,
      filterable,
      flex,
      ...percentage,
    };
  }

  if (type === 'dateTime') {
    return {
      field,
      headerName,
      description,
      hide,
      sortable,
      filterable,
      flex,
      ...dateTime,
    };
  }

  if (type === 'number') {
    const _field = Intl.NumberFormat('en-US', {
      maximumSignificantDigits: 3,
    }).format(Number(field));

    return {
      field: _field,
      headerName,
      description,
      hide,
      sortable,
      filterable,
      flex,
      type,
      align,
    };
  }

  return {
    field,
    headerName,
    description,
    hide,
    sortable,
    filterable,
    flex,
    type,
    align,
  };
};

export default DataGridColumn;
