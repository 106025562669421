import { CourseFormInput } from 'types/courseTypes/CourseFormInput';

export const getCourseTypeName = (courseTypeOptions: any[], id?: number) => {
  return courseTypeOptions.find((item: any) => item.value === id)?.label;
};

export const filterCourseCategoryOptions = (
  isAssessment: boolean,
  courseTypeOptions: any[]
) => {
  const labels = ['Industry Principles', 'Cultivation', 'Extraction'];

  return courseTypeOptions.filter((item: any) =>
    isAssessment ? labels.includes(item.label) : !labels.includes(item.label)
  );
};

export const filterPreRequisiteOptions = (
  isAssessment: boolean,
  preRequisiteOptions: any[],
  courseTypeId?: number
) => {
  if (isAssessment) {
    return preRequisiteOptions.filter(
      (item: any) => item.typeId === courseTypeId || item.value === ''
    );
  }
  return preRequisiteOptions;
};

export const formatCourseFormValues = (values: CourseFormInput) => {
  const { prerequisiteId, externalCourseId, ...otherValues } = values;

  return {
    ...otherValues,
    externalCourseId: externalCourseId || null,
    prerequisiteId: prerequisiteId || null,
  };
};
