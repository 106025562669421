import { IChunkSizeStrategy } from '../interfaces/IChunkSizeStrategy';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAmountStrategy {}

export class AmountStrategy implements IAmountStrategy, IChunkSizeStrategy {
  public execute(promises: any[], chunks: number): number {
    const chunkSize = Math.ceil(promises.length / chunks);
    return chunkSize;
  }
}
