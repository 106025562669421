import React, { useEffect, useState } from 'react';
import { Avatar, Box, Skeleton, useMediaQuery } from '@mui/material';
import { lighten } from 'polished';
import Icon from 'icons';
import Counter from '../../../../shared/Counter';
import CircularProgress from '../../../../shared/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

type LearnerGroupStats = {
  totalLearners: number;
  learnersAtLeastCompletedOneCoursePercentage: number;
  learnersHaveNotStartedAnyCoursePercentage: number;
  learnersAtLeastCompletedOneCourse: number;
  learnersHaveNotStartedAnyCourse: number;
  totalCompletions: number;
};

const Stats = ({ groupId }: { groupId: string }) => {
  const theme = useTheme();

  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchUpMD = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();

  const [stats, setStats] = useState<LearnerGroupStats>({
    totalLearners: 0,
    learnersAtLeastCompletedOneCourse: 0,
    learnersHaveNotStartedAnyCourse: 0,
    learnersAtLeastCompletedOneCoursePercentage: 0,
    learnersHaveNotStartedAnyCoursePercentage: 0,
    totalCompletions: 0,
  });

  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      if (groupId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.group.getStatsByGroup(groupId),
        });

        if (response) {
          const {
            totalLearners,
            learnersAtLeastCompletedOneCourse,
            learnersHaveNotStartedAnyCourse,
            totalCompletions,
          } = response;

          const learnersHaveNotStartedAnyCoursePercentage =
            Math.round(
              (learnersHaveNotStartedAnyCourse * 100) / Number(totalLearners)
            ) || 0;

          const learnersAtLeastCompletedOneCoursePercentage =
            Math.round(
              (learnersAtLeastCompletedOneCourse * 100) / Number(totalLearners)
            ) || 0;

          setStats({
            totalLearners,
            learnersHaveNotStartedAnyCourse,
            learnersAtLeastCompletedOneCourse,
            learnersAtLeastCompletedOneCoursePercentage,
            learnersHaveNotStartedAnyCoursePercentage,
            totalCompletions,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '0.25 0 auto',
          ...(matchUpMD && { width: '100%' }),
          height: '291px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            gap: 4,
            height: '100%',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <>
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, '#12D1D0'),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon name="three-users" size="extraLarge" fill="#12D1D0" />
              </Avatar>
              <Counter label="Learners" counter={stats.totalLearners} />
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '0.25 0 auto',
          ...(matchUpMD && { width: '100%' }),
          height: '291px',
        }}
      >
        {loading ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center'}}>
            <Box>
              <CircularProgress
                value={stats.learnersAtLeastCompletedOneCoursePercentage}
                color="#56C596"
              />
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '120%',
                color: '#205072',
                maxWidth: '130px',
                textAlign: 'center',
              }}
            >
              <span style={{ color: '#56C596' }}>
                {stats.learnersAtLeastCompletedOneCourse}
              </span>{' '}
              completed at least one course
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '0.25 0 auto',
          ...(matchUpMD && { width: '100%' }),
          height: '291px',
        }}
      >
        {loading ? (
          <Skeleton width="100%" height="100%" />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center'}}>
            <Box>
              <CircularProgress
                value={stats.learnersHaveNotStartedAnyCoursePercentage}
                color="#FF5572"
              />
            </Box>
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '120%',
                color: '#205072',
                maxWidth: '130px',
                textAlign: 'center',
              }}
            >
              <span style={{ color: '#FF5572' }}>
                {stats.learnersHaveNotStartedAnyCourse}
              </span>{' '}
              haven’t started any courses
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: '0.25 0 auto',
          ...(matchUpMD && { width: '100%' }),
          height: '291px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            gap: 4,
            height: '100%',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <>
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, '#199BC5'),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon
                  name="completed-outlined"
                  size="extraLarge"
                  fill="#199BC5"
                />
              </Avatar>
              <Box>
                <Counter
                  label="Completed Courses"
                  counter={stats.totalCompletions}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Stats;
