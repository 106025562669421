import React, { useState } from 'react';
import Icon from '../../icons';
import { Controller, FieldError } from 'react-hook-form';

import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { SxProps } from '@mui/system';

interface EndAdornmentProps {
  setShowPassword: (showPassword: boolean) => void;
  showPassword: boolean;
}

const EndAdornment = ({ setShowPassword, showPassword }: EndAdornmentProps) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowPassword(!showPassword)}
      edge="end"
    >
      {showPassword ? (
        <Icon name="eye" fill="#71AB8C" />
      ) : (
        <Icon name="eye-off" fill="#71AB8C" />
      )}
    </IconButton>
  </InputAdornment>
);

interface PasswordFormFieldProps {
  name: string;
  placeholder?: string;
  control: any;
  errors?: FieldError;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
  sx?: SxProps;
}

const defaultSx = {
  background: 'white',
  borderRadius: '30px',
  '& .MuiOutlinedInput-root': {
    '& :-webkit-autofill': {
      borderRadius: '30px !important',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#56C596',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#56C596',
    },
  },
};

const PasswordFormField = ({
  name,
  placeholder,
  control,
  errors,
  margin = 'normal',
  required,
  sx,
}: PasswordFormFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <TextField
            {...field}
            sx={{ ...defaultSx, ...sx }}
            required={required}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            fullWidth
            margin={margin}
            error={Boolean(errors)}
            InputProps={{
              endAdornment: (
                <EndAdornment
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              ),
            }}
          />
          <Box
            sx={{
              width: 'fit-content',
              color: '#d32f2f',
              fontWeight: 'bold',
              minHeight: '19px',
            }}
          >
            {errors ? errors.message : ''}
          </Box>
        </Box>
      )}
    />
  );
};

export default PasswordFormField;
