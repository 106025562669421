import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import { useCallService, useDebouncedEffect } from 'hooks';

import Button from 'components/buttons/Button';
import LoadingButton from 'components/buttons/LoadingButton';

import { DeleteIcon, SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

export type ManageCommunityBundlesFormInput = {
  groupId: string;
  bundlesToAdd: string[];
  bundlesToDelete: string[];
};

const ManageCommunityBundlesForm = ({
  groupId,
  groupName,
  bundles,
  loading,
  onSubmit,
}: {
  groupId: string;
  groupName: string;
  bundles: { id: string; name: string }[];
  loading: boolean;
  onSubmit: (values: ManageCommunityBundlesFormInput) => void;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const [currentBundles, setCurrentBundles] = useState<
    { id: string; name: string }[]
  >(bundles || []);

  const [areOptionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');

  const presubmitFormat = async (values: ManageCommunityBundlesFormInput) => {
    const bundleIds = bundles?.map((bundle) => bundle.id);
    const currentBundleIds = currentBundles.map((bundle) => bundle.id);

    const bundlesToAdd = currentBundleIds.filter(
      (community) => !bundleIds?.includes(community)
    );
    const bundlesToDelete = bundleIds?.filter(
      (bundle) => !currentBundleIds?.includes(bundle)
    );

    values.bundlesToAdd = bundlesToAdd;
    values.bundlesToDelete = bundlesToDelete;

    return onSubmit(values);
  };

  const addBundle = (community: { id: string; name: string }) => {
    const updateBundles = [...currentBundles];

    updateBundles.push(community);
    setCurrentBundles(updateBundles);
  };

  const removeCourse = (id: string) => {
    const updatedBundles = currentBundles.filter((bundle) => bundle.id !== id);

    setCurrentBundles(updatedBundles);
  };

  // get list of bundles
  const getBundles = async () => {
    setOptionsLoading(true);
    const bundleListIds = currentBundles.map((x) => x.id);

    const { response } = await callService({
      resource: httpRoutes.bundles.searchBundles(inputValue, bundleListIds),
    });

    if (response) {
      setOptions(
        response.map((item: { id: string; name: string }) => {
          return { id: item.id, label: item.name };
        })
      );
    }
    setOptionsLoading(false);
  };

  useEffect(() => {
    getBundles();
  }, [currentBundles]);

  useDebouncedEffect(getBundles, 200, [inputValue]);

  const validationSchema = Yup.object().shape({
    groupId: Yup.string().max(255).required('A group is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ManageCommunityBundlesFormInput>({
    mode: 'onChange',
    defaultValues: {
      groupId: groupId,
      bundlesToAdd: [],
      bundlesToDelete: [],
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(presubmitFormat)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Manage {groupName} Bundles
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <FormSectionTitle title="Add/Remove Bundles" />
            <Grid item xs={12}>
              <Autocomplete
                id="search"
                freeSolo
                fullWidth
                selectOnFocus
                loading={areOptionsLoading}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue, reason) => {
                  if (reason === 'input') {
                    setInputValue(newInputValue);
                  }
                  if (reason === 'reset') {
                    setInputValue('');
                  }
                }}
                onChange={(_event, value) => {
                  if (value) {
                    const newCommunity = {
                      name: value.label,
                      id: value.id,
                    };

                    addBundle(newCommunity);
                  }
                }}
                options={options}
                getOptionLabel={(option) => option.label || option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search bundles..."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      endAdornment: (
                        <>
                          {areOptionsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box
              sx={{ width: '100%', overflow: 'scroll', paddingBottom: '16px' }}
              mt={2}
            >
              {currentBundles.length > 0 && (
                <Table size="small">
                  <TableHead
                    sx={{
                      backgroundColor: '#f4f4f4',
                      '& th:nth-of-type(1)': {
                        borderRadius: '1em 0 0 0',
                      },
                      '& th:nth-of-type(7)': {
                        borderRadius: '0 1em 0 0',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="80%"
                      >
                        Bundle Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          padding: matchDownSM
                            ? '0px 0px 0px 16px'
                            : '6px 16px',
                          fontSize: matchDownSM ? '10px' : '14px',
                        }}
                        size="small"
                        width="5%"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      '& tr:nth-of-type(even)': {
                        backgroundColor: '#f4f4f4',
                      },
                      '& tr:last-of-type': {
                        '& td:nth-of-type(1)': {
                          borderRadius: '0 0 0 1em',
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(2)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(3)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(4)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(5)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(6)': {
                          borderBottom: '0px',
                        },
                        '& td:nth-of-type(7)': {
                          borderRadius: '0 0 1em 0',
                          borderBottom: '0px',
                        },
                      },
                      '& tr:first-of-type': {
                        backgroundColor: 'white',
                      },
                    }}
                  >
                    {currentBundles.map((bundle) => (
                      <TableRow key={bundle.id}>
                        <TableCell
                          sx={{
                            padding: matchDownSM
                              ? '0px 0px 0px 16px'
                              : '0px 16px',
                            fontSize: matchDownSM ? '10px' : '14px',
                          }}
                          size="small"
                          width="80%"
                        >
                          {bundle.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: matchDownSM ? '0px' : '0px 16px' }}
                          size="small"
                          width="5%"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              removeCourse(bundle.id);
                            }}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog} label="Cancel" />
          <LoadingButton
            label="Save"
            type="submit"
            variant="contained"
            disabled={isSubmitting || loading}
            loading={isSubmitting || loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          />
        </Grid>
      </form>
    </div>
  );
};

export default ManageCommunityBundlesForm;
