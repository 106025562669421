import { IChunkSizeStrategy } from '../interfaces/IChunkSizeStrategy';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPercentageStrategy {}

export class PercentageStrategy
  implements IPercentageStrategy, IChunkSizeStrategy
{
  public execute = (promises: any[], chunks: number): number =>
    Math.ceil((promises.length / 100) * chunks);
}
