import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CourseIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
];

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Community Name',
    field: 'groupName',
    sortable: true,
    filterable: false,
    flex: 1.25,
  },
  {
    headerName: 'Learners',
    field: 'learnerCount',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
  },
  {
    headerName: 'Started',
    field: 'totalStarted',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
  },
  {
    headerName: 'Completed',
    field: 'totalCompleted',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
  },
  {
    headerName: 'Average Score',
    field: 'averageScore',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      if (params.row.averageScore) {
        return <>{params.row.averageScore.toFixed(2)} %</>;
      }
      return <>-- %</>;
    },
  },
  {
    headerName: 'Percent Completed',
    field: 'percentCompleted',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const _percentage = params.row.totalCompleted / params.row.learnerCount;

      if (_percentage === 1) {
        return <>100 %</>;
      }

      return <>{(_percentage * 100).toPrecision(2)} %</>;
    },
  },
];

const loadingState = {
  groups: true,
  exporting: false,
};

const AdminGroupsByCourseGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [courseName, setCourseName] = useState('');
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const params = useParams();

  const courseId = params.id;

  useEffect(() => {
    getGroups();
  }, [filters, state, courseId]);

  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    try {
      if (courseId) {
        const { response } = await callService({
          resource: httpRoutes.reporting.course.getAdminGroupsByCourse({
            courseId,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setCourseName(response.items[0].courseName);
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (courseId) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource: httpRoutes.reporting.course.exportAdminGroupsByCourse({
            courseId,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, `${courseName} Community Report.csv`);

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  return (
    <PageContainer
      title={`${courseName} Communities`}
      icon={<CourseIcon sx={{ fontSize: '24px' }} />}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleExport={exportGroups}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.groups}
      />
    </PageContainer>
  );
};

const AdminGroupsByCourse = () => (
  <ControlledDataGrid>
    <AdminGroupsByCourseGrid />
  </ControlledDataGrid>
);

export default AdminGroupsByCourse;
