import { useState, useEffect } from 'react';

import * as Yup from 'yup';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OnboardingDays from './OnboardingDays';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { Grid } from '@mui/material';

type CourseListInput = {
  id?: string;
  onboardingDays?: number;
};

const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const CourseListGrid = ({
  defaultValues,
}: {
  defaultValues?: CourseListInput;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { assignCourseState } = useAssignCourseDispatcher();
  const { groupsIds, coursesAssignations } = assignCourseState;

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const validationSchema = Yup.object().shape({
    onboardingDays: Yup.string().min(1),
  });

  const { control } = useForm<CourseListInput>({
    mode: 'onBlur',
    defaultValues: {
      id: defaultValues?.id || undefined,
      onboardingDays: defaultValues?.onboardingDays,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getCourseList();
  }, [filters, state]);

  const getCourseList = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getCoursesPaginated({
          params: { ...state },
          filters: filters,
          groupsIds: groupsIds,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'courseName',
      sortable: true,
      filterable: false,
      flex: 0.3,
    },
    {
      headerName: '# of days to complete',
      field: 'daysToComplete',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        const coursesAssignation = coursesAssignations.find(
          (x) => x.courseId === params.row.courseId
        );
        return (
          <OnboardingDays
            courseId={params.row.courseId}
            daysToCompleteDefault={
              coursesAssignation
                ? coursesAssignation?.daysToComplete
                : undefined
            }
            control={control}
          />
        );
      },
    },
  ];

  return (
    <PageContainer
      showBackButton={false}
      title="Select Courses to Assign and Set Days To Complete"
      elevation={0}
    >
      <Grid
        container
        sx={{
          height: '400px',
          overflowY: 'scroll',
          width: '100%',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            height: '400px',
            overflowY: 'scroll',
            width: '100%',
          }}
        >
          <DataGrid
            rows={gridData.rows}
            columns={columns}
            filters={filterModel}
            handleFilterChange={setFilters}
            totalRows={gridData.totalRows}
            loading={loading}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const CourseList = () => (
  <ControlledDataGrid>
    <CourseListGrid />
  </ControlledDataGrid>
);

export default CourseList;
