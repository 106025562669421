import { useState } from 'react';

import { Box } from '@mui/material';
import { useCallService } from 'hooks';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import Form, { RewardFormInput } from '../forms/Form';

import httpRoutes from 'utils/httpRoutes';
import LoadingButton from 'components/buttons/LoadingButton';
import { ExportIcon } from 'material-icons';

const loadingState = {
  reward: true,
  exporting: false,
};

const Edit = ({
  onSuccess,
  defaultValues,
}: {
  onSuccess: VoidFunction;
  defaultValues: RewardFormInput;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(loadingState);
  const { callService } = useCallService();

  const onSubmit = async (values: RewardFormInput) => {
    setIsSubmitting(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.rewards.update(values),
        successMessage: 'Reward updated successfully!',
      });

      if (response) {
        onSuccess();
      }
    } catch (e) {
      console.log('Error', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onExport = async () => {
    try {
      if (defaultValues.id) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource: httpRoutes.rewards.exportRewardCodes(defaultValues.id),
          successMessage: 'Reward Codes exported successfully!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, `${defaultValues.name} Reward Codes.csv`);

          setLoading((prevState) => ({ ...prevState, exporting: false }));
        }
      }
    } catch (e) {
      console.log('Error');
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          onClick={() => onExport()}
          label="Export Reward Codes"
          icon={<ExportIcon />}
          variant="text"
          loading={loading.exporting}
        />
      </Box>
      <Form defaultValues={defaultValues} onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default Edit;
