import { createContext } from 'react';

export type PendingMembersType = {
  hasNotifications: boolean;
};

export const PENDING_MEMBERS_INITIAL_STATE: PendingMembersType = {
  hasNotifications: false,
};

const PendingMembersContext = createContext<{
  pendingMembersState: PendingMembersType;
  checkNotifications: () => any;
}>({
  pendingMembersState: PENDING_MEMBERS_INITIAL_STATE,
  checkNotifications: () => {},
});

export default PendingMembersContext;
