import { Box } from '@mui/material';

export const renderBoxColumn = (value: any, formatMethod?: any) => {
  if (value) {
    return (
      <Box
        sx={{
          fontFamily: 'Roboto',
          fontSize: '16px',
          lineHeight: '150%',
        }}
      >
        {formatMethod ? formatMethod(value) : value}
      </Box>
    );
  }
  return <>--</>;
};
