import { useCallService } from 'hooks';

import CommunityForm, { CommunityFormInput } from '../../forms/CommunityForm';

import httpRoutes from 'utils/httpRoutes';

const Edit = ({
  onSuccess,
  defaultValues,
}: {
  onSuccess: VoidFunction;
  defaultValues: CommunityFormInput;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: CommunityFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.groups.update(values),
      successMessage: 'Community updated successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <CommunityForm defaultValues={defaultValues} onSubmit={onSubmit} />
    </div>
  );
};

export default Edit;
