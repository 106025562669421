import * as React from 'react';
import { Box, Skeleton } from '@mui/material';

import Restaurant from 'assets/images/Restaurant.png';

const RightRestaurantDescriptionContent = ({
  isPrivate = false,
  communityLoading = false,
  communityName,
}: {
  isPrivate?: boolean;
  communityLoading?: boolean;
  communityName?: string;
}) => {
  return (
    <>
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="60px"
          width="100%"
          sx={{ mb: 3 }}
        />
      ) : (
        communityName && (
          <Box
            mb={3}
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontSize: '26px',
              lineHeight: '30px',
              textAlign: 'center',
            }}
          >
            Welcome to the{' '}
            <span style={{ fontWeight: '600' }}>{communityName}</span>{' '}
            community!
          </Box>
        )
      )}
      <Box
        sx={{
          color: '#162738',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: communityName ? '20px' : '32px',
          lineHeight: '45px',
        }}
      >
        Join tens of thousands of professionals advancing their knowledge and
        skill sets through Seed Talent!
      </Box>
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="166px"
          width="100%"
          sx={{ mb: 3, mt: 2 }}
        />
      ) : (
        <>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '400',
              fontSize: '18px',
              lineHeight: '32px',
              mt: 4,
            }}
          >
            Seed Talent centralizes professional development for all employee
            enablement in your industry; compliance, company info, foundational
            and sales training.
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img height="300px" src={Restaurant} alt="Restaurant" />
          </Box>
        </>
      )}
    </>
  );
};

export default RightRestaurantDescriptionContent;
