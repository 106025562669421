import { instance as apiClient } from 'utils/axios';
import { GridSortDirection } from '@mui/x-data-grid';

import { CertificateFormInput } from './forms/Form';

export interface PaginationInterface {
  page: number;
  limit: number;
  orderBy?: string;
  order?: GridSortDirection;
}

const apiBase = '/api/v1';

const resources = {
  create(payload: CertificateFormInput) {
    return apiClient.post(`${apiBase}/certificates`, payload);
  },
  update(payload: CertificateFormInput) {
    return apiClient.put(`${apiBase}/certificates`, payload);
  },
  delete(id: string) {
    return apiClient.put(`${apiBase}/certificates/delete/${id}`);
  },
  getOne(id: string) {
    return apiClient.get(`${apiBase}/certificates/${id}`);
  },
  getMany({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: Map<string, any>;
  }) {
    const formattedParams = {
      ...params,
      name: filters.get('name'),
      organizationId: filters.get('organizationId'),
      active: filters.get('active'),
    };

    return apiClient.get(`${apiBase}/certificates/paginated`, {
      params: formattedParams,
    });
  },
};

export default resources;
