import { useCallService } from 'hooks';

import Form, { BundleFormInput } from '../forms/BundleForm';

import httpRoutes from 'utils/httpRoutes';

const EditBundle = ({
  onSuccess,
  defaultValues,
}: {
  onSuccess: VoidFunction;
  defaultValues: BundleFormInput;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: BundleFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.bundles.update(values),
      successMessage: 'Bundle updated successfully!',
    });

    if (response) {
      onSuccess();
    }
  };

  return (
    <div>
      <Form defaultValues={defaultValues} onSubmit={onSubmit} />
    </div>
  );
};

export default EditBundle;
