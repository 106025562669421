export enum CourseCategory {
  BrandTraining = 'Brand Trainig',
  CompanyRequired = 'Company Required',
  Compliance = 'Compliance',
  CareerPrinciples = 'Career Principles',
  Assessments = 'Assessments',
  Cultivation = 'Cultivation',
  Extraction = 'Extraction',
  IndustryPrinciples = 'Industry Principles'
}

