import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import CommunityBundleForm, {
  CommunityBundleFormInput,
} from '../forms/CommunityBundleForm';

import httpRoutes from 'utils/httpRoutes';

const ManageCommunities = ({
  onSuccess,
  bundleId,
  bundleName,
}: {
  onSuccess: VoidFunction;
  bundleId: string;
  bundleName: string;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const [isLoading, setIsLoading] = useState(false);
  const [communities, setCommunities] = useState<
    { id: string; name: string }[]
  >([]);

  const getBundleCommunities = async () => {
    setIsLoading(true);
    const { response } = await callService({
      resource: httpRoutes.bundles.getBundleCommunities({
        bundleId,
        params: { page: 0, limit: 100 },
        filters: new Map(),
      }),
    });

    if (response) {
      setCommunities(
        response.items.map((community: any) => ({
          id: community.id,
          name: community.name,
        }))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBundleCommunities();
  }, [bundleId]);

  const onSubmit = async (values: CommunityBundleFormInput) => {
    const { response } = await callService({
      resource: httpRoutes.bundles.updateBundleCommunities(values),
      successMessage: 'Communities updated successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <CommunityBundleForm
        bundleId={bundleId}
        bundleName={bundleName}
        onSubmit={onSubmit}
        communities={communities}
        loading={isLoading}
      />
    </div>
  );
};

export default ManageCommunities;
