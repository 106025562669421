import React, { useEffect, useState } from 'react';

import { Badge, Box, Collapse } from '@mui/material';

import LeftNavigationItem from 'layout/MainLayout/LeftNavigationDrawer/LeftNavigationItem';
import { ExpandLessIcon, ExpandMoreIcon } from 'material-icons';
import DisplayText from 'components/DisplayText';
import Icon from 'icons/Icon';
import { usePendingMembersState } from 'providers/PendingMembersProvider/hooks/usePendingMembersState';

const LeftNavigationCollapsibleBody = ({
  label,
  icon,
  subItems,
  selectedOption,
  handleSelectedOption,
  drawerIsOpen,
}: {
  label: string;
  icon: string;
  subItems: any;
  handleSelectedOption: (param: string) => void;
  selectedOption: string;
  drawerIsOpen: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        p: '10px 0 10px 10px',
        cursor: 'pointer',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }} onClick={handleClick}>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <Icon name={icon} />
        </Box>
        {drawerIsOpen && (
          <Box
            sx={{
              display: 'flex',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <DisplayText type="bodyMedium" variant="regular" text={label} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </Box>
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subItems.map((item: any) => (
          <LeftNavigationItem
            drawerIsOpen={drawerIsOpen}
            key={item.label}
            item={item}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
          />
        ))}
      </Collapse>
    </Box>
  );
};

const LeftNavigationCollapsible = ({
  label,
  icon,
  subItems,
  selectedOption,
  handleSelectedOption,
  drawerIsOpen = false,
  checkNotifications = false,
}: {
  label: string;
  icon: string;
  subItems: any;
  handleSelectedOption: (param: string) => void;
  selectedOption: string;
  drawerIsOpen: boolean;
  checkNotifications?: boolean;
}) => {
  const { pendingMembersState } = usePendingMembersState();

  const dontShowBadge =
    (!pendingMembersState.hasNotifications && checkNotifications) ||
    !checkNotifications;

  return (
    <Box
      sx={{
        display: 'flex',
        p: drawerIsOpen ? '0px 30px 0px 20px' : 0,
        gap: 1,
        width: '100%',
      }}
    >
      {!dontShowBadge ? (
        <Badge
          color="error"
          variant="dot"
          sx={drawerIsOpen ? { display: 'flex', width: '100%' } : {}}
        >
          <LeftNavigationCollapsibleBody
            label={label}
            icon={icon}
            subItems={subItems}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
            drawerIsOpen={drawerIsOpen}
          />
        </Badge>
      ) : (
        <LeftNavigationCollapsibleBody
          label={label}
          icon={icon}
          subItems={subItems}
          selectedOption={selectedOption}
          handleSelectedOption={handleSelectedOption}
          drawerIsOpen={drawerIsOpen}
        />
      )}
    </Box>
  );
};

export default LeftNavigationCollapsible;
