import { useState, useEffect } from 'react';
import { Box, Button, Chip } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { CommunityIcon } from 'material-icons';

import resources from '../../user/resources';

import httpRoutes from 'utils/httpRoutes';

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Community Name',
  },
];

const UserCommunitiesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    getUserCommunities();
  }, [filters, state]);

  const getUserCommunities = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: resources.getUserCommunities({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 0.3,
      ColumnComponent: ({ row }: { row: any }) => {
        const userGroup = row.userGroups[0];
        const isPrimary = userGroup ? userGroup.isPrimary : false;

        if (isPrimary) {
          return (
            <Box sx={{ gap: 3, display: 'flex', alignItems: 'center' }}>
              {row.name}
              <Chip label="Primary" color="success" size="small" />
            </Box>
          );
        } else {
          return <div>{row.name}</div>;
        }
      },
    },
    {
      headerName: 'Action',
      field: 'userGroups',
      sortable: false,
      filterable: false,
      flex: 0.3,
      ColumnComponent: ({ value }: { value: any }) => {
        const userGroup = value[0];

        const isPrimary = userGroup ? userGroup.isPrimary : false;

        const handleClick = async () => {
          const { userId, groupId } = userGroup;

          const { response } = await callService({
            resource: resources.setPrimaryGroup(userId, groupId),
          });

          if (response) {
            getUserCommunities();
          }
        };

        const handleLeave = async () => {
          const { response } = await callService({
            resource: httpRoutes.groups.leaveGroup(userGroup),
          });

          if (response) {
            getUserCommunities();
          }
        };

        return isPrimary ? (
          <div></div>
        ) : (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleClick}
            >
              Make it primary
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={handleLeave}
            >
              Leave Community
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={gridData.rows}
      columns={columns}
      filters={filterModel}
      tableTitle="My Communities"
      handleFilterChange={setFilters}
      totalRows={gridData.totalRows}
      loading={loading}
    />
  );
};

const UserCommunities = () => (
  <ControlledDataGrid>
    <UserCommunitiesGrid />
  </ControlledDataGrid>
);

export default UserCommunities;
