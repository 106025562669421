import * as React from 'react';

export default (
  <g fill="none">
    <path
      d="M36.5 44C43.956 44 50 37.956 50 30.5S43.956 17 36.5 17 23 23.044 23 30.5 29.044 44 36.5 44z"
      fill="#9CE8F7"
    />
    <path
      d="M36.78 11H13.23C11.45 11 10 12.44 10 14.23V16h30v-1.77c0-1.78-1.44-3.23-3.23-3.23h.01zM10 16h30.05v30.45A3.55 3.55 0 0136.5 50H13.55A3.55 3.55 0 0110 46.45V16z"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.67 26H26c-.55 0-1-.45-1-1s.45-1 1-1h5.67c.55 0 1 .45 1 1s-.45 1-1 1z"
      fill="#205072"
    />
    <path
      d="M26 43h5M26 34h5M26 39h9M26 30h9M26 21h9M19.852 39h-3.704c-.634 0-1.148.585-1.148 1.306v2.388c0 .721.514 1.306 1.148 1.306h3.704c.634 0 1.148-.585 1.148-1.306v-2.388c0-.721-.514-1.306-1.148-1.306zM19.852 30h-3.704c-.634 0-1.148.585-1.148 1.306v2.387c0 .722.514 1.307 1.148 1.307h3.704c.634 0 1.148-.585 1.148-1.306v-2.388c0-.721-.514-1.306-1.148-1.306zM19.852 21h-3.704c-.634 0-1.148.585-1.148 1.306v2.387c0 .722.514 1.307 1.148 1.307h3.704c.634 0 1.148-.585 1.148-1.306v-2.387c0-.722-.514-1.307-1.148-1.307z"
      stroke="#205072"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);
