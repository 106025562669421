import Box from '@mui/material/Box';

import { lighten } from 'polished';

import { describeChange } from '../../utils';
import NoDataAvailable from '../NoDataAvailable';

const Trend = ({
  percentageChange,
  intervalStat,
  kpiId,
  interval,
  noData,
}: {
  percentageChange: number | null;
  intervalStat: number;
  kpiId: string;
  interval: string;
  noData: boolean;
}) => {
  if (noData) {
    return <NoDataAvailable interval={interval} />;
  }

  if (percentageChange === null) {
    return null;
  }

  const color =
    percentageChange === 0
      ? '#507DBC'
      : percentageChange > 0
      ? '#7fd0b2'
      : '#FF5572';

  const symbol = percentageChange === 0 || percentageChange < 0 ? '' : '+';

  return (
    <Box
      sx={{
        visibility: percentageChange === null ? 'hidden' : 'visible',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: '8px',
        alignItems: 'center',
        fontSize: '12px',
      }}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          textOverflow: 'ellipsis',
          color: color,
          fontWeight: '500',

          paddingLeft: '4px',
          paddingRight: '4px',
          backgroundColor: lighten(0.3, color),
          borderRadius: '4px',
          height: '20px',
        }}
      >
        {`${symbol}${percentageChange}%`}
      </Box>
      <Box
        sx={{
          color: '#666',
          wordWrap: 'break-word',
        }}
      >
        <span>{describeChange(percentageChange, interval, kpiId)}</span>
      </Box>
    </Box>
  );
};
export default Trend;
