import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import {
  EmailFormField,
  PhoneFormField,
  TextFormField,
  SelectFormField,
} from 'components/formFields';

import { getStatesList } from 'dataSets/states';

import { useCallService } from 'hooks';

import userResources from 'features/user/resources';

export interface IProfileFormInputs {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  question: string;
  answer: string;
  phone: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  extended?: string;
  emailSecondary: string;
  resume?: string;
  image?: string;
  picture?: string;
  jobTitle?: string;
  birthDate?: string;
}

export const INITIAL_PROFILE_STATE: IProfileFormInputs = {
  id: '',
  email: '',
  lastName: '',
  firstName: '',
  question: '',
  answer: '',
  phone: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  postal: '',
  country: '',
  emailSecondary: '',
};

const GeneralInfo = ({
  defaultValues,
}: {
  defaultValues: IProfileFormInputs;
}) => {
  const { callService } = useCallService();

  const [stateOptions] = useState(getStatesList());

  const onSubmit = async (values: IProfileFormInputs) => {
    await callService({
      resource: userResources.updateUserProfile(values),
      successMessage: 'User profile updated successfully!',
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    question: Yup.string().max(255),
    answer: Yup.string().max(255),
    phone: Yup.string().nullable(),
    address_1: Yup.string().max(255),
    address_2: Yup.string().max(255).nullable(),
    city: Yup.string().max(255),
    state: Yup.string().max(255),
    postal: Yup.string()
      .matches(
        /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/,
        'Please enter a valid US or Canadian postal code'
      )
      .required('Postal code is required'),
    country: Yup.string().max(255).nullable(),
    emailSecondary: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IProfileFormInputs>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            General Info
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} md={6}>
              <TextFormField
                name="firstName"
                control={control}
                label="First Name*"
                errors={errors.firstName}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFormField
                name="lastName"
                control={control}
                label="Last Name*"
                errors={errors.lastName}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} sm={4}>
              <EmailFormField
                name="email"
                control={control}
                label="Primary Email*"
                errors={errors.email}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EmailFormField
                name="emailSecondary"
                control={control}
                label="Secondary Email"
                errors={errors.emailSecondary}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PhoneFormField
                name="phone"
                control={control}
                label="Phone Number"
                errors={errors.phone}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} sm={8}>
              <TextFormField
                name="address_1"
                control={control}
                label="Street Address"
                errors={errors.address_1}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextFormField
                name="address_2"
                control={control}
                label="Suite/Apt #"
                errors={errors.address_2}
                margin="dense"
              />
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12} sm={6}>
              <TextFormField
                name="city"
                control={control}
                label="City"
                errors={errors.city}
                margin="dense"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectFormField
                name="state"
                control={control}
                label="State"
                errors={errors.state}
                margin="dense"
                options={stateOptions}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFormField
                name="postal"
                control={control}
                label="Zip Code"
                errors={errors.postal}
                margin="dense"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" columnSpacing={1}>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            <SaveOutlinedIcon sx={{ mr: 1 }} /> Save
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default GeneralInfo;
