import { useSnackbar } from 'notistack';
import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import UserUploadForm from '../forms/UserUploadForm/UserUploadForm';
import UserUploadFormInput from '../forms/UserUploadForm/UserUploadFormInput';
import AdminUserUploadForm from '../forms/UserUploadForm/AdminUserUploadForm';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import { useState } from 'react';

const UploadUsers = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { callService } = useCallService();
  const {
    authState: { isAdmin },
  } = useAuthState();

  const onSubmit = async (values: UserUploadFormInput) => {
    setIsLoading(true);
    const { response } = await callService({
      resource: httpRoutes.userManagement.uploadUsers(values),
      successMessage: 'Users imported successfully!',
    });

    if (response) {
      if (response.length > 0) {
        const errorMessage =
          response.length === 1
            ? 'A row in your file was incorrectly formatted and the user wasn’t saved. Please review your file and try again.'
            : 'Multiple rows were incorrectly formatted. Please review your file and try again.';

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
      onSuccess();
    }
    setIsLoading(false);
  };

  return isAdmin ? (
    <AdminUserUploadForm onSubmit={onSubmit} isLoading={isLoading} />
  ) : (
    <UserUploadForm onSubmit={onSubmit} isLoading={isLoading} />
  );
};

export default UploadUsers;
