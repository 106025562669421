/* eslint-disable */
import { memo, useMemo, useState, ReactNode } from 'react';
import AssignCourseContext, {
  ASSIGN_COURSE_INITIAL_STATE,
} from './contexts/AssignCourseContext';

const AssignCourseProvider = ({ children }: { children: ReactNode }) => {
  const [assignCourseState, setAssignCourseState] = useState(
    ASSIGN_COURSE_INITIAL_STATE
  );

  const value = useMemo(
    () => ({
      assignCourseState,
      setAssignCourseState,
    }),
    [assignCourseState, setAssignCourseState]
  );

  return (
    <AssignCourseContext.Provider value={value}>
      {children}
    </AssignCourseContext.Provider>
  );
};

export default memo(AssignCourseProvider);
